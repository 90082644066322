(function () {
    'use strict';

    angular.module('UndergroundWebApp').factory('customerSystemsService', customerSystemsService)

    customerSystemsService.$inject = [
        '$http'
    ];

    function customerSystemsService(
        $http
    ) {
        return {
            getCustomerSystems,
            getAllContainerCustomerSystems
        };

        function getCustomerSystems() {
            return $http.get('api/customerSystems')
                .then(result => result.data);
        }

        function getAllContainerCustomerSystems() {
            return $http.get('api/customersystems/containers')
                .then(result => result.data);
        }
    }
})();
