(function () {
    'use strict';

    angular.module('UndergroundWebApp').factory('filterStateService', filterStateService)

    filterStateService.$inject = [
        '$location',
        '$timeout'
    ];

    function filterStateService(
        $location,
        $timeout
    ) {
        return {
            storeGridFilterInQueryString,
            storeGridSortInQueryString,
            getSortFromQueryString,
            getFilterFromQueryString,
            storeStateInQueryString,
            getStateFromQueryString,
            getFilterFromLocalStorage,
            storeFilterInLocalStorage
        };

        function getSortFromQueryString(fallback) {
            return getStateFromQueryString('sort', fallback);
        }

        function getFilterFromQueryString(fallback) {
            return getStateFromQueryString('filter', fallback);
        }

        function getStateFromQueryString(fieldName, fallback) {
            const state = $location.search()[fieldName];
            if (!state) {
                return fallback;
            }

            return JSON.parse(atob(state));
        }

        function storeGridFilterInQueryString(event) {
            waitForStateAndStore('filter', () => event.sender.dataSource.filter());
        }

        function storeGridSortInQueryString(event) {
            waitForStateAndStore('sort', () => event.sender.dataSource.sort());
        }

        function waitForStateAndStore(fieldName, valueGetter) {
            // Wait for Kendo Grid state to change, because filter and sort 
            // event handlers are called before the datasource is modified
            $timeout(() => {
                storeStateInQueryString(fieldName, valueGetter());
            }, 0);
        }

        function storeStateInQueryString(fieldName, value) {
            let result = null;
            if (value != null && (!Array.isArray(value) || value.length > 0)) {
                result = btoa(JSON.stringify(value));
            }
            $location.search(fieldName, result);
        }

        function getFilterFromLocalStorage(key, fallback) {
            let serializedItem = localStorage.getItem(key);
            if (serializedItem) {
                try {
                    var item = JSON.parse(serializedItem)
                    return item.data || fallback;
                }
                catch {
                    return fallback;
                }
            }

            return fallback;
        }

        function storeFilterInLocalStorage(key, filter) {
            if (key && filter) {
                let item = {
                    meta: {
                        createdAt: new Date()
                    },
                    data: filter
                };

                localStorage.setItem(key, JSON.stringify(item));
            }
        }
    }
})();
