(function () {
    'use strict';

    angular.module('UndergroundWebApp').factory('municipalityService', municipalityService);

    municipalityService.$inject = [
        '$http'
    ];

    function municipalityService(
        $http
    ) {
        return {
            getMunicipalities,
        };

        function getMunicipalities() {
            return $http.get('/api/municipalities')
                .then((result) => result.data);
        }
    }
})();
