(function () {
    'use strict';

    angular.module('UndergroundWebApp').factory('areaEditLayerFactory', areaEditLayerFactory);

    areaEditLayerFactory.$inject = [
        '$q',
        'esriLoader',
        '$timeout',
        'areaUtility',
        'mapTypes',
        'mapUtility',
        'selectionSettings',
        'mapSettings',
    ];

    function areaEditLayerFactory(
        $q,
        esriLoader,
        $timeout,
        areaUtility,
        mapTypes,
        mapUtility,
        selectionSettings,
        mapSettings,
    ) {
        const layerFactory = this;
        const readyDeferred = $q.defer();

        layerFactory.createLayerOnAdd = true;
        layerFactory.ready = () => readyDeferred.promise;

        initAreaEditLayer().then(() => readyDeferred.resolve(layerFactory));
        return layerFactory;

        /**
         * Initialize area editing layer
         */
        function initAreaEditLayer() {
            return esriLoader.require([
                'dojo/_base/declare',
                'esri/Graphic',
                'esri/layers/GraphicsLayer',
                'esri/symbols/SimpleMarkerSymbol',
                'esri/symbols/SimpleLineSymbol',
                'esri/symbols/SimpleFillSymbol',
                'esri/geometry/Circle',
                'esri/geometry/Point',
                'esri/geometry/Polyline',
                'esri/geometry/Polygon',
                'esri/geometry/SpatialReference'
            ],
                function (
                    declare,
                    Graphic,
                    GraphicsLayer,
                    SimpleMarkerSymbol,
                    SimpleLineSymbol,
                    SimpleFillSymbol,
                    Circle,
                    Point,
                    Polyline,
                    Polygon,
                    SpatialReference,
                ) {
                    var edgeSymbol = new SimpleMarkerSymbol(selectionSettings.edgeSymbol),
                        edgeSelectedSymbol = new SimpleMarkerSymbol(selectionSettings.edgeSelectedSymbol);

                    const areaLineStyle = {
                        style: SimpleLineSymbol.STYLE_SOLID,
                        color: [227, 139, 79, 0.8],
                        width: 3
                    }

                    const areaFillColor = [255, 255, 127, 0.025];
                    const areaFill = new SimpleFillSymbol(
                        SimpleFillSymbol.STYLE_SOLID,
                        new SimpleLineSymbol(areaLineStyle.style, areaLineStyle.color, areaLineStyle.width), areaFillColor
                    );

                    layerFactory.createLayer = () => new AreaEditLayer({});

                    const AreaEditLayer = declare([GraphicsLayer], {
                        constructor: function () {
                            this.name = 'areaEditLayer';
                            this.isEnabled = false;

                            this.currentArea = null;
                            this.selectedEdgeId = null;

                            this.editAreaDeferred = null;
                            this.points = [];
                        },

                        //Event handlers
                        onClick: function (evt, mapView, hitResults) {
                            if (!evt || !mapView) {
                                return;
                            }

                            var mapPoint = mapView.toMap({
                                x: evt.offsetX,
                                y: evt.offsetY
                            });

                            if (hitResults && hitResults.results && hitResults.results.length > 0) {
                                let edge = hitResults.results.find(r => r.graphic.attributes.type === 'areaEdge');
                                if (edge && edge.graphic) {
                                    this.selectedEdgeId = edge.graphic.attributes.id;
                                } else if (this.selectedEdgeId) {
                                    this.moveEdgeToPoint(mapPoint);
                                }
                            } else if (this.selectedEdgeId) {
                                this.moveEdgeToPoint(mapPoint);
                            }

                            this.render();
                        },

                        //Private functions
                        moveEdgeToPoint(mapPoint) {
                            let relatedPointIdx = this.points.findIndex(p => p.id === this.selectedEdgeId),
                                lastIdx = this.points.length - 1;

                            //Check if related point is first or last: they need to be moved together
                            if (relatedPointIdx === 0 || relatedPointIdx === lastIdx) {
                                this.points[0].X = mapPoint.x;
                                this.points[0].Y = mapPoint.y;
                                this.points[lastIdx].X = mapPoint.x;
                                this.points[lastIdx].Y = mapPoint.y;
                            } else {
                                this.points[relatedPointIdx].X = mapPoint.x;
                                this.points[relatedPointIdx].Y = mapPoint.y;
                            }

                            this.selectedEdgeId = null;
                        },

                        createEdgeGraphic: function (mapPoint, isSelected) {
                            let edgePoint = this.createEdgePoint(mapPoint, mapSettings.mapType);

                            return new Graphic({
                                geometry: edgePoint,
                                symbol: isSelected ? edgeSelectedSymbol : edgeSymbol,
                                attributes: {
                                    id: mapPoint.id,
                                    type: 'areaEdge'
                                }
                            });
                        },

                        createEdgePoint: function (mapPoint) {
                            return new Point(
                                mapPoint.X,
                                mapPoint.Y,
                                new SpatialReference({ wkid: mapSettings.wkid })
                            );
                        },

                        getPointPolyline: function (point) {
                            var polyline = new Polyline({
                                hasZ: false,
                                hasM: false,
                                paths: this.getPolygonPaths(point),
                                spatialReference: { wkid: mapSettings.wkid }
                            });
                            return polyline;
                        },

                        /**
                         * render
                         * clears the graphics and add rectangle
                         * @param point map coordinates
                         */
                        render: function () {
                            this.clearGraphics();

                            if (this.isEnabled) {
                                //Render polyline based on area points
                                let pointCoordinates = this.points.map(point => [point.X, point.Y]);
                                let areaPolygon = areaUtility.createPolygon(pointCoordinates, areaFill, { id: this.currentArea.id },
                                    Graphic, Point, Polygon, SpatialReference);
                                this.add(areaPolygon);

                                //Render edges based on points
                                let edgeGraphics = [];
                                for (var i = 0; i < this.points.length - 1; ++i) {
                                    let isSelected = this.selectedEdgeId === this.points[i].id,
                                        edgeGraphic = this.createEdgeGraphic(this.points[i], isSelected);

                                    edgeGraphics.push(edgeGraphic, isSelected);
                                }

                                this.addMany(edgeGraphics);
                            }
                        },

                        /**
                         * renderRectangle
                         * @param point map coordinates
                         * @returns Polyline the rectangle
                         */
                        renderAreaLines: function (point) {
                            var polyline = new Polyline({
                                hasZ: false,
                                hasM: false,
                                paths: this.getPolygonPaths(point),
                                spatialReference: { wkid: mapSettings.wkid }
                            });
                            return polyline;
                        },

                        renderAreaPoints: function () { },

                        startEditArea: function (area) {
                            this.isEnabled = true;
                            this.currentArea = area;

                            //Get current points of area
                            this.points = area.points
                                .sort((a, b) => a.order - b.order)
                                .map((point) => {
                                    const coords = mapUtility.convertToUTM33N(point.latitude, point.longitude);
                                    return { X: coords.X, Y: coords.Y, id: point.id };
                                });

                            this.render();
                        },

                        finishEditArea: function () {
                            let editedAreaPoints = this.points.map(point => [point.X, point.Y]);

                            this.clearGraphics();

                            this.isEnabled = false;
                            this.points = [];

                            return editedAreaPoints;
                        },

                        /**
                         * getSelectionResult
                         * @returns null|polynom [ [x,y], ..., [m,n] ] 
                         */
                        getSelectionResult: function () {
                            if (this.points.length < 2) {
                                return null;
                            }

                            return this.getPolygonPaths(this.points[this.points.length - 1]);
                        },

                        /**
                         * getPolygonPaths
                         */
                        getPolygonPaths: function () {
                            var path = [];
                            for (var cv = 0; cv < this.points.length; cv++) {
                                path.push([this.points[cv].x, this.points[cv].y]);
                            }

                            return path;
                        },

                        /**
                         * clearGraphics
                         * remove all graphics from the layer
                         */
                        clearGraphics: function () {
                            this.removeAll();
                        },
                    });
                }
            );
        }
    }
})();
