(function () {
    'use strict';

    angular.module('UndergroundWebApp').factory('chartOptionsService', chartOptionsService)

    chartOptionsService.$inject = [
       '$translate'
    ];

    function chartOptionsService($translate) {
        return {
            createChart,
        };

        function getCategories(items) {
            return _.map(items, function (item) {
                return new Date(item.timestamp).getDate();
            });
        };

        function createChart(items, field, name, color, additionalOptions) {
            var options = {
                dataSource: {
                    data: items,
                },
                legend: {
                    position: 'bottom'
                },
                seriesDefaults: {
                    type: "line",
                    missingValues: "gap"
                },
                tooltip: {
                    visible: true,
                    template: `${$translate.instant('G_DAY')}: #= category #<br />#= series.name #: #= value #`
                },
                categoryAxis: {
                    categories: getCategories(items),
                    majorGridLines: {
                        visible: false
                    },
                },
                series: [{
                    field: field,
                    name: name,
                    color: color,
                }],
                chartArea: {
                    height: 200
                },
            };

            return Object.assign(options, additionalOptions);
        }
    }
})();
