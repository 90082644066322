(function () {
    'use strict';

    angular.module('UndergroundWebApp').factory('deviceService', deviceService);

    deviceService.$inject = ['$http'];

    function deviceService($http) {
        return {
            addDevice,
            getDevices,
            getDevice,
            updateDevice,
            deleteDevice,
            disconnectDevice,
        };

        function addDevice(device) {
            return $http.post('/api/device', device)
                .then(result => result.data);
        }

        function getDevices() {
            return $http.get('/api/device')
                .then(result => result.data);
        }

        function getDevice(externalSystemId, externalId) {
            return $http.get('/api/device/' + externalId + '/' + externalSystemId)
                .then(result => result.data);
        }

        function deleteDevice(deviceId) {
            return $http.delete('/api/device/' + deviceId)
                .then(result => result.data);
        }

        function updateDevice(device) {
            return $http.put('/api/device', device)
                .then(result => result.data);
        }

        function disconnectDevice(externalId, externalSystemId) {
            let disconnectDeviceDto = {
                externalId,
                externalSystemId,
            }

            return $http.post('api/device/disconnect', disconnectDeviceDto)
                .then(result => result.data);
        }
    }
})();
