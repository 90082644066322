(function () {
    'use strict';

    angular.module('UndergroundWebApp').controller('LocationsController', LocationsController);

    LocationsController.$inject = [
        '$scope',
        '$state',
        '$stateParams',
        '$rootScope',
        '$kWindow',
        'locationsService',
        'formTypes',
        'fractionsUtility',
        'externalSystemUtility',
        'mapService',
        'selectDisplayedDevices',
        'locationUtility',
        'filterStateService',
        's2wExternalSystemId',
        '$translate'
    ];

    function LocationsController(
        $scope,
        $state,
        $stateParams,
        $rootScope,
        $kWindow,
        locationsService,
        formTypes,
        fractionsUtility,
        externalSystemUtility,
        mapService,
        selectDisplayedDevices,
        locationUtility,
        filterStateService,
        s2wExternalSystemId,
        $translate,
    ) {
        var sortedLocations = [];
        var filteredLocations = [];

        const locationModalOptions = {
            modal: true,
            movable: true,
            title: $translate.instant('LOCATION_LOCATION'),
            resizable: true,
            height: 680,
            width: 400,
            visible: false
        };

        $scope.exportToExcel = exportToExcel;
        $scope.exportAsPdf = exportAsPdf;
        $scope.openNewLocation = openNewLocation;
        $scope.searchLocations = searchLocations;
        $scope.onRowClicked = onRowClicked;

        $scope.isExternalSystemFilterSelected = isExternalSystemFilterSelected;
        $scope.toggleFilterBox = toggleFilterBox;
        $scope.toggleExternalSystemFilter = toggleExternalSystemFilter;
        $scope.clearFilters = clearFilters;
        $scope.getExtSystemPlaceholderColor = externalSystemUtility.getExtSystemPlaceholderColor;

        $scope.filterData = {
            fractions: [],
            externalSystems: [],
        };

        $scope.filter = filterStateService.getStateFromQueryString('filter', getEmptyFilter());
        $scope.filterBoxVisible = !isAdvancedFiltersEmptpy();

        $scope.fractionsDropdownOptions = {
            settings: {
                externalIdProp: '',
            },
            events: {
                onSelectionChanged: handleFractionFilterChanged,
            },
        }

        $scope.dropdownTranslations = {
            checkAll: $translate.instant('G_CHECKALL'),
            uncheckAll: $translate.instant('G_UNCHECK_ALL'),
            buttonDefaultText: $translate.instant('LOCATION_DDL_TRANSLATION_BTN_DEFAULT_TEXT'),
            dynamicButtonTextSuffix: $translate.instant('G_DYNAMIC_BTN_TEXT_SUFFIX'),
        };

        $scope.locationOverviewGridOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: readLocations
                },
                pageSize: 69,
                sort: { field: 'name', dir: 'asc' }
            }),
            excel: {
                allPages: true,
                fileName: 'Hentested.xlsx',
            },
            pdf: {
                allPages: true,
                fileName: 'Hentested.pdf',
                multiPage: true,
                repeatHeaders: true,
                paperSize: "A4",
            },
            columns: [
                {
                    title:  $translate.instant('LOCATION_TABLE_HEADER_LOCATION'),
                    template: getLocationTemplate,
                    attributes: {
                        'class': 'navigable'
                    }
                },
                {
                    title:  $translate.instant('LOCATION_TABLE_HEADER_FRACTION'),
                    template: getFractionsTemplate,
                    width: '190px',
                    attributes: {
                        'class': 'navigable'
                    }
                }
            ],

            sortable: false,
            selectable: false,
            click: onRowClicked,

            scrollable: {
                virtual: true
            },
            excelExport: excelExport
        };

        initController();

        function initController() {
            $('#locationsGrid').on('click', 'td.navigable', onRowClicked);
        }

        function getLocationTemplate(location) {
            const detailText = getLocationAddress(location);

            return `<div class="location-data"><strong>${kendo.htmlEncode(location.name)}</strong><br>${kendo.htmlEncode(detailText)}</div>`;
        }

        function getLocationAddress(location) {
            const postalCodeAndCity = [location.postalCode, location.city].filter(Boolean).join(' ');
            const detailText = [location.address, postalCodeAndCity, location.municipalityCode].filter(Boolean).join(', ');

            return detailText;
        }

        function getFractionsTemplate(location) {
            const fractions = _.chain(location.containers)
                .map((container) => container.fraction)
                .groupBy((fraction) => fractionsUtility.getFractionIcon(fraction))
                .pickBy((_fractions, icon) => !!icon)
                .map((fractions, icon) => formatFraction(icon, fractions))
                .value()
                .sort()
                .join('');
            return `<span class="location-fractions-list">${fractions}</span>`;
        }

        function formatFraction(fractionIcon, fractions) {
            var countHtml = '';
            if (fractions.length > 1) {
                countHtml = `<span>${fractions.length}</span>`;
            }

            var title = _.chain(fractions)
                .map((fraction) => fraction.name)
                .uniq()
                .value()
                .join(', ');
            return `<div class="location-fraction-icon"><img ng-src="${fractionIcon}" title="${title}">${countHtml}</div>`;
        }

        function openNewLocation() {
            var windowInstance = $kWindow.open({
                options: locationModalOptions,
                templateUrl: 'app/location/location-details-modal-view.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'LocationDetailsModalController',
                resolve: {
                    currentFormType: () => formTypes.add,
                    location: () => null
                }
            });

            windowInstance.result.then(function () {
                $('#locationsGrid').getKendoGrid().dataSource.read();
            });
        }

        function readLocations(e) {
            showLocationLoadBusyIndicator();

            locationsService.getLocations(true, false, false, true).then(function (locations) {
                sortedLocations = locations.sort(function (a, b) {
                    return a.address.toLowerCase() < b.address.toLowerCase() ? -1 : 1;
                });

                filteredLocations = [];
                updateFilters(sortedLocations);
                searchLocations();

                e.success(filteredLocations);
            }, function () {
                e.success([]);
            }).finally(() => $rootScope.$broadcast('hideBusyIndicator', 'locationIndicator'));
        }

        function searchLocations() {
            filterStateService.storeStateInQueryString('filter', getCurrentFilter());
            var newFilteredLocations = isFiltersEmpty()
                ? sortedLocations
                : sortedLocations.filter(isLocationMatching);

            if (!arrayEquals(filteredLocations, newFilteredLocations)) {
                filteredLocations = newFilteredLocations;
                $('#locationsGrid').getKendoGrid().dataSource.data(filteredLocations);

                if (!isFiltersEmpty()) {
                    var deviceExternalIds = _.chain(filteredLocations)
                        .flatMap((location) => location.containers)
                        .map((container) => {
                            let relatedDevice = undefined;
                            const s2wDevice = container.devices.find((device) =>
                                device.externalSystem
                                && device.externalSystem.id.toUpperCase() === s2wExternalSystemId
                            );

                            if (s2wDevice) {
                                relatedDevice = s2wDevice;
                            } else {
                                if (container.devices.length > 0) {
                                    relatedDevice = container.devices[0];
                                }
                            }

                            return relatedDevice && relatedDevice.externalId;
                        })
                        .filter(externalId => !!externalId)
                        .value();
                    const locationIds = filteredLocations.map((location) => location.id);

                    mapService.getLayer('s2wClusterLayer').setLocationFilter(deviceExternalIds);
                    mapService.getLayer('locationLayer').setLocationFilter(locationIds);
                } else {
                    mapService.getLayer('s2wClusterLayer').setLocationFilter();
                    mapService.getLayer('locationLayer').setLocationFilter();
                }
            }
        }

        function isLocationMatching(location) {
            return searchTermMatching(location)
                && fractionMatching(location)
                && externalSystemMatching(location)
                && noPositionFilterMatching(location);
        }

        function searchTermMatching(location) {
            if (!$scope.filter.searchTerm) {
                return true;
            }

            return isStringMatching(location.name, $scope.filter.searchTerm)
                || isStringMatching(location.address, $scope.filter.searchTerm)
                || isStringMatching(location.postalCode, $scope.filter.searchTerm)
                || isStringMatching(location.city, $scope.filter.searchTerm)
                || isStringMatching(location.municipalityCode, $scope.filter.searchTerm)
                || location.containers
                && location.containers.some(container =>
                    container.devices
                    && selectDisplayedDevices(container.devices).some((device) =>
                        isStringMatching(device.externalId, $scope.filter.searchTerm)
                    )
                );
        }

        function fractionMatching(location) {
            if ($scope.filter.fractions.length === 0) {
                return true;
            }

            if (!location.containers || location.containers.length === 0) {
                return false;
            }

            return $scope.filter.fractions.some((filter) =>
                location.containers.some((container) =>
                    container.fraction
                    && filter.id === container.fraction.id
                )
            );
        }

        function externalSystemMatching(location) {
            if ($scope.filter.externalSystems.length === 0) {
                return true;
            }

            if (!location.containers || location.containers.length === 0) {
                return false;
            }

            return location.containers.some((container) =>
                container.devices
                && container.devices.filter((device) => device.isActive).length > 0
                && $scope.filter.externalSystems.every((exSys) =>
                    container.devices
                        .filter((device) => device.isActive)
                        .some((device) => device.externalSystemId === exSys.id)
                )
            );
        }

        function isStringMatching(str, term) {
            if (str) {
                return str.toString().toLowerCase().indexOf(term.toLowerCase()) !== -1;
            }
            return false;
        }

        function noPositionFilterMatching(location) {
            return !$scope.filter.noPositionOnly
                || !locationUtility.isValidLocation(location.latitude, location.longitude);
        }

        function arrayEquals(array1, array2) {
            return array1 === array2
                || array1.length === array2.length
                && array1.every(function (value, index) { return value === array2[index]; });
        }

        function onRowClicked() {
            var row = $(this).closest('tr');
            var location = $('#locationsGrid').getKendoGrid().dataItem(row);
            $state.go('main.locationDetails.info', {
                locationId: location.id,
                locationsFilter: $stateParams.filter,
            });
        }

        function updateFilters(locations) {
            $scope.filterData.fractions = _.unionBy(
                getFractionsForFilter(locations),
                $scope.filter.fractions,
                'id'
            );
            $scope.filterData.fractions = _.sortBy($scope.filterData.fractions, 'label');

            $scope.filterData.externalSystems = _.unionBy(
                getExternalSystemsForFilter(locations),
                $scope.filter.externalSystems,
                'id'
            );
        }

        function toggleFilterBox() {
            $scope.filterBoxVisible = !$scope.filterBoxVisible;
            setTimeout(() => {
                $('#locationsGrid').getKendoGrid().resize();
            }, 0);
        }

        function toggleExternalSystemFilter(externalSystem) {
            var index = _.findIndex($scope.filter.externalSystems, { id: externalSystem.id });
            if (index === -1) {
                $scope.filter.externalSystems.push(externalSystem);
            } else {
                $scope.filter.externalSystems.splice(index, 1);
            }
            searchLocations();
        }

        function handleFractionFilterChanged() {
            try {
                searchLocations();
            } catch (error) {
                console.error(error);
            }
        }

        function isExternalSystemFilterSelected(externalSystem) {
            return !!_.find($scope.filter.externalSystems, { id: externalSystem.id });
        }

        function clearFilters() {
            $scope.filter = getEmptyFilter();
            searchLocations();
        }

        //Private functions
        function extendLocation(location) {
            if (location && location.containers) {
                location.containers = location.containers.filter(c => c.status !== 99);
            }
        }

        function showLocationLoadBusyIndicator() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'locationIndicator',
                destination: '#locations-view',
                message: $translate.instant("G_BUSY_INDICATOR"),
                overlay: true,
                positionClass: {
                    top: '50%',
                    left: '0px',
                    right: '0px'
                }
            });
        }

        //Private functions - Filtering
        function getFractionsForFilter(locations) {
            if (!locations) {
                return [];
            }

            return _.chain(locations)
                .flatMap((location) => location.containers)
                .uniqBy((container) => container.fraction && container.fraction.id)
                .map((container) => container.fraction)
                .filter((fraction) => !!fraction)
                .map((fraction) => ({
                    label: fraction.name,
                    id: fraction.id,
                }))
                .value();
        }

        function getExternalSystemsForFilter(locations) {
            if (!locations) {
                return [];
            }

            return _.chain(locations)
                .flatMap((location) => location.containers)
                .flatMap((container) => selectDisplayedDevices(container.devices)
                    .map((device) => device.externalSystem)
                )
                .uniqBy((externalSystem) =>
                    externalSystem
                    && externalSystem.id
                )
                .filter((externalSystem) => externalSystem)
                .map((externalSystem) => ({
                    id: externalSystem.id,
                    name: externalSystem.name,
                    img: externalSystemUtility.getExternalSystemIcon(externalSystem),
                    selected: false,
                }))
                .value();
        }

        function getEmptyFilter() {
            return {
                fractions: [],
                externalSystems: [],
                searchTerm: '',
                noPositionOnly: false,
            };
        }

        function getCurrentFilter() {
            return isFiltersEmpty()
                ? null
                : $scope.filter;
        }

        function isFiltersEmpty() {
            return $scope.filter.searchTerm === ''
                && isAdvancedFiltersEmptpy();
        }

        function isAdvancedFiltersEmptpy(params) {
            return $scope.filter.fractions.length === 0
                && $scope.filter.externalSystems.length === 0
                && !$scope.filter.noPositionOnly;
        }

        function exportToExcel() {
            $scope.locationOverviewGrid.saveAsExcel();
        }

        function exportAsPdf() {
            $scope.locationOverviewGrid.saveAsPDF();
        }

        function excelExport(e) {
            var sheet = e.workbook.sheets[0];

            var headers = [{
                value: $translate.instant("LOCATION_TABLE_HEADER_LOCATION"),
                bold: true
            }, {
                value: $translate.instant("LOCATION_TABLE_HEADER_ADDRESS"),
                bold: true
            }, {
                value: $translate.instant("LOCATION_TABLE_HEADER_FRACTION"),
                bold: true
            }];

            sheet.rows.splice(0, 0, { cells: headers, type: 'header' });

            for (var i = 1; i < sheet.rows.length; i++) {
                var row = sheet.rows[i];
                row.cells = [
                    {
                        value: e.data[i - 1].name
                    },
                    {
                        value: getLocationAddress(e.data[i - 1])
                    },
                    {
                        value: _.chain(e.data[i - 1].containers)
                            .countBy((container) => container.fraction.name)
                            .toPairs()
                            .map(x => formatFractionAndCount(x[0], x[1]))
                            .value()
                            .sort()
                            .join(', ')
                    }
                ];
            }
        }

        function formatFractionAndCount(fraction, count) {
            if (count === 1) {
                return fraction;
            }

            return `${fraction} (${count})`;
        }
    }
})();
