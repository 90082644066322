(function () {
    'use strict';

    angular.module('UndergroundWebApp').controller('MunicipalityLogsController', MunicipalityLogsController);

    MunicipalityLogsController.$inject = [
        '$q',
        '$scope',
        '$rootScope',
        '$kWindow',
        'containerService',
        'locationsService',
        'municipalityService',
        'sensorApiAreaService',
        'filterStateService',
        '$translate'
    ];

    function MunicipalityLogsController(
        $q,
        $scope,
        $rootScope,
        $kWindow,
        containerService,
        locationsService,
        municipalityService,
        sensorApiAreaService,
        filterStateService,
        $translate
    ) {
        let containerLogs = [];

        $scope.dateRange = {};
        $scope.selectedMunicipality = { code: '' };

        $scope.openMediaModal = openMediaModal;
        $scope.loadMunicipalityLogs = loadMunicipalityLogs;
        $scope.exportToExcel = exportToExcel;
        $scope.exportAsPdf = exportAsPdf;

        $scope.selectedMunicipalities = [];

        $scope.selectedMunicipalyy = { code: '' };
        $scope.filterMunicipalitiesData = {
            municipalities: [],
            externalSystems: [],
        };

        $scope.municipalityfilter = filterStateService.getStateFromQueryString('filter', getMunicipalityEmptyFilter());

        $scope.municipalitiesDropdownOptions = {
            settings: {
                externalIdProp: '',
            },
            events: {
                onSelectionChanged: handleMunicipalityFilterChanged,
            },
        }

        $scope.dropdownMunicipalityTranslations = {
            checkAll: $translate.instant("G_CHECKALL"),
            uncheckAll: $translate.instant("G_UNCHECK_ALL"),
            buttonDefaultText: $translate.instant("REPORTS_MUNINCIPALITY_LOGS_CONTROLLER_MUNINCIPALITY_DDL_BUTTON_DEFAULT_TEXT"),
            dynamicButtonTextSuffix: $translate.instant("G_DYNAMIC_BTN_TEXT_SUFFIX")
        };

        $scope.selectedAreas = [];

        $scope.selectedArea = { code: '' };
        $scope.filterData = {
            areas: [],
            externalSystems: [],
        };

        $scope.filter = filterStateService.getStateFromQueryString('filter', getEmptyFilter());

        $scope.areasDropdownOptions = {
            settings: {
                externalIdProp: '',
            },
            events: {
                onSelectionChanged: handleAreaFilterChanged,
            },
        }

        $scope.dropdownTranslations = {
            checkAll: $translate.instant("G_CHECKALL"),
            uncheckAll: $translate.instant("G_UNCHECK_ALL"),
            buttonDefaultText: $translate.instant("REPORTS_MUNINCIPALITY_LOGS_CONTROLLER_DDL_BUTTON_DEFAULT_TEXT"),
            dynamicButtonTextSuffix: $translate.instant("G_DYNAMIC_BTN_TEXT_SUFFIX")
        };

        $scope.municipalityLogsGridOptions = {
            dataSource: {
                data: [],
                sort: { field: 'time', dir: 'desc' },
                pageSize: 70,
            },
            sortable: true,
            filterable: true,
            resizable: true,
            noRecords: true,
            messages: {
                noRecords: $translate.instant("G_NO_DATA")
            },
            scrollable: {
                virtual: true
            },
            excel: {
                allPages: true,
            },
            pdf: {
                allPages: true,
            },
            columns: [
                {
                    field: 'time',
                    title: $translate.instant("REPORTS_MUNINCIPALITY_LOGS_CONTROLLER_TABLE_HEADER_TIME"),
                    template: `#= kendo.toString(kendo.parseDate(time), "${$translate.instant("G_DEFAULT_DATE_FORMAT")}") #`,
                    filterable: false,
                    width: 145,
                },
                {
                    field: 'locationName',
                    title: $translate.instant("REPORTS_MUNINCIPALITY_LOGS_CONTROLLER_TABLE_HEADER_LOCATION_NAME"),
                },
                {
                    field: 'deviceExternalId',
                    title: $translate.instant("REPORTS_MUNINCIPALITY_LOGS_CONTROLLER_TABLE_HEADER_DEVICE_EXTERNAL_ID"),
                    width: 70,
                },
                {
                    field: 'container.containerNumber',
                    title: $translate.instant("REPORTS_MUNINCIPALITY_LOGS_CONTROLLER_TABLE_HEADER_CONTAINER_NUMBER"),
                    width: 80,
                },
                {
                    field: 'fractionName',
                    title: $translate.instant("REPORTS_MUNINCIPALITY_LOGS_CONTROLLER_TABLE_HEADER_FRACTION"),
                    width: 110,
                    filterable: {
                        multi: true,
                    },
                },
                {
                    field: 'logAction',
                    title: $translate.instant("REPORTS_MUNINCIPALITY_LOGS_CONTROLLER_TABLE_HEADER_LOG_ACTION"),
                    width: 120,
                    filterable: {
                        multi: true,
                    },
                },
                {
                    field: 'comment',
                    title: $translate.instant("REPORTS_MUNINCIPALITY_LOGS_CONTROLLER_TABLE_HEADER_COMMENT"),
                    width: 80,
                },
                {
                    field: 'extUser',
                    title: $translate.instant("REPORTS_MUNINCIPALITY_LOGS_CONTROLLER_TABLE_HEADER_EXTERNAL_USER"),
                    width: 100,
                },
                {
                    field: 'image',
                    title: $translate.instant("G_IMAGE"),
                    width: 70,
                    sortable: false,
                    filterable: false,
                    template: function (dataItem) {
                        if (dataItem.media.length) {
                            return '<i class="fa fa-1-5x fa-image" ng-click="openMediaModal(dataItem.media)"></i>'
                        }
                        return '';
                    }
                }
            ],
        };

        initController();

        function initController(){
            loadMunicipalities();
            loadAreas();
        }

        function loadAreas() {
            showBusyIndicator();
            sensorApiAreaService.getAreas().then((areas) => {
                const activeAreas = areas.filter(area => area.isActive === true);
                activeAreas.sort((a,b) => a.description > b.description ? 1 : -1);
                
                updateFilters(activeAreas);
            }).finally(hideBusyIndicator);
        }

        function updateFilters(areas) {
            $scope.filterData.areas = _.unionBy(
                getAreasForFilter(areas),
                $scope.filter.areas,
                'id'
            );
            $scope.filterData.areas = _.sortBy($scope.filterData.areas, 'description');
        }

        function getAreasForFilter(areas) {
            if (!areas) {
                return [];
            }

            return  _.chain(areas)
                .filter((area) => !!area)
                .map((area) => ({
                    label: area.description,
                    id: area.id,
                    points: area.points,
                }))
                .value();
        }

        function getEmptyFilter() {
            return {
                areas: [],
                externalSystems: [],
                searchTerm: '',
                noPositionOnly: false,
            };
        }

        function handleAreaFilterChanged() {
            console.log("areas: ", $scope.filter.areas);
            municipalitySelected();
        }

        function loadMunicipalities() {
            showBusyIndicator();
            municipalityService.getMunicipalities().then((municipalities) => {
                const allMunicipalits = municipalities.map((m) => ({
                    label: m.name,
                    id: m.code,
                }));
                updateMunicipalitiesFilters(allMunicipalits);
            }).finally(hideBusyIndicator);
        }

        function updateMunicipalitiesFilters(municipalities) {
            $scope.filterMunicipalitiesData.municipalities = _.unionBy(
                getMunicipalitiesForFilter(municipalities),
                $scope.municipalityfilter.municipalities,
                'id'
            );
            $scope.filterMunicipalitiesData.municipalities = _.sortBy($scope.filterMunicipalitiesData.municipalities, 'name');
        }

        function getMunicipalitiesForFilter(municipalities) {
            if (!municipalities) {
                return [];
            }

            return  _.chain(municipalities)
                .filter((municipality) => !!municipality)
                .map((municipality) => ({
                    label: municipality.label,
                    id: municipality.id,
                }))
                .value();
        }

        function getMunicipalityEmptyFilter() {
            return {
                municipalities: [],
                externalSystems: [],
                searchTerm: '',
                noPositionOnly: false,
            };
        }

        function handleMunicipalityFilterChanged() {
            municipalitySelected()        
        }

        function loadMunicipalityLogs() {
            showBusyIndicator('municipalityLogsGrid');

            let from = moment($scope.dateRange.from).format('YYYY-MM-DD');
            let to = moment($scope.dateRange.to).format('YYYY-MM-DD');

            $q.all([
                containerService.getLogs(from, to),
                locationsService.getLocations(false, true, true)
            ]).then(function ([logs, locations]) {
                containerLogs = logs
                    .map(log => extendContainerLog(log, locations))
                    .filter(Boolean);

                municipalitySelected();
            }).catch(function (error) {
                console.error(error);
            }).finally(function () {
                hideBusyIndicator('municipalityLogsGrid');
            });
        }

        function extendContainerLog(containerLog, locations) {
            let locationIdOfLog = containerLog.container && containerLog.container.locationId;
            let relatedLocation = locations.find(l => l.id === locationIdOfLog);
            if (!relatedLocation) {
                return null;
            }

            return {
                ...containerLog,
                locationName: (relatedLocation && relatedLocation.name) || '',
                latitude: (relatedLocation && relatedLocation.latitude) || null,
                longitude: (relatedLocation && relatedLocation.longitude) || null,
                deviceExternalId: (containerLog.device && containerLog.device.externalId) || '',
                fractionName: (containerLog.container.fraction && containerLog.container.fraction.name) || '',
                municipalityCode: relatedLocation && relatedLocation.municipalityCode,
            }
        }

        function municipalitySelected() {
            let filteredLogs = [];

            filteredLogs = filterBasedOnMunicipality(containerLogs);
            const filterdLogsByArea = filterBasedOnArea(filteredLogs);

            $scope.municipalityLogsGrid.dataSource.data(filterdLogsByArea);
        }

        function openMediaModal(media) {
            var pictureUrls = media.map(m => m.pictureUrl);
            $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant("G_IMAGE"),
                    resizable: false,
                    width: "30%",
                    position: {
                        top: "5%",
                        left: "35%"
                    },
                    visible: false
                },
                templateUrl: 'app/shared/pictureCarouselModal/picture-carousel.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'PictureCarouselController',
                resolve: {
                    pictureUrls: function () {
                        return pictureUrls;
                    }
                }
            });
        }

        function showBusyIndicator(id) {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'containerLog' + id,
                destination: '#' + id,
                message: $translate.instant("G_BUSY_INDICATOR"),
                overlay: true,
                positionClass: {
                    top: '50%',
                    left: '0px',
                    right: '0px'
                }
            });
        }

        function hideBusyIndicator(id) {
            $rootScope.$broadcast('hideBusyIndicator', 'containerLog' + id);
        }

        function exportToExcel() {
            if ($scope.selectedMunicipality.name) {
                $scope.municipalityLogsGrid.options.excel.fileName = `${$translate.instant("REPORTS_MUNINCIPALITY_LOGS_CONTROLLER_EXPORT_FILENAME")}_${$scope.selectedMunicipality.name}.xlsx`;
                $scope.municipalityLogsGrid.saveAsExcel();
            }
        }

        function exportAsPdf() {
            if ($scope.selectedMunicipality.name) {
                $scope.municipalityLogsGrid.options.pdf.fileName = `${$translate.instant("REPORTS_MUNINCIPALITY_LOGS_CONTROLLER_EXPORT_FILENAME")}_${$scope.selectedMunicipality.name}.pdf`;
                $scope.municipalityLogsGrid.saveAsPDF();
            }
        }

        function filterBasedOnMunicipality(allLogs) {
            let filteredByMunicipality;
            if ($scope.municipalityfilter.municipalities.length != 0) {
                filteredByMunicipality = allLogs.filter( containerLog => isInSelectedMunicipalities(containerLog));
            }
            else {
                filteredByMunicipality = allLogs;
            }

            return filteredByMunicipality;
        }

        function isInSelectedMunicipalities(containerLog) {
            for(var i = 0; i < $scope.municipalityfilter.municipalities.length; i++) {
                if ($scope.municipalityfilter.municipalities[i].id ===  containerLog.municipalityCode)
                {
                    return true;
                }
            }
            return false;
        }

        function filterBasedOnArea(filteredLogs) {
            let filteredByArea;
            if ($scope.filter.areas.length != 0) {
                filteredByArea = filteredLogs.filter( filteredLog => isInSelectedAreas(filteredLog));
            }
            else {
                filteredByArea = filteredLogs;
            }

            return filteredByArea;
        }

        function isInSelectedAreas(filteredLog) {
            for(var i = 0; i < $scope.filter.areas.length; i++) {
                if (isInArea($scope.filter.areas[i],  filteredLog))
                {
                    return true;
                }
            }
            return false;
        }

        function isInArea(area, filteredLog) {
            if (!area || !filteredLog || !filteredLog.latitude && !filteredLog.longitude) {
                return false;
            }
           
           let inside = false;
           let x = filteredLog.longitude, y = filteredLog.latitude;
           for (let i = 0, j = area.points.length - 1; i < area.points.length; j = i++) {
               let xi = area.points[i].longitude, yi = area.points[i].latitude;
               let xj = area.points[j].longitude, yj = area.points[j].latitude;
           
               let intersect = ((yi > y) !== (yj > y))
                   && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
           
                   if (intersect) inside = !inside;
                }
           
           return inside;
        }
    }
})();
