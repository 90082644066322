(function () {

    angular.module('UndergroundWebApp').factory('undergroundAPIService', undergroundAPIService);

    undergroundAPIService.$inject = [
        '$http',
        'qPool'
    ];

    function undergroundAPIService(
        $http,
        qPool
    ) {
        return {
            getS2WOverviewExpress: getS2WOverviewExpress
        };

        function getS2WOverviewExpress() {
            let deferred = qPool.defer('undergroundAPIService.getS2WOverviewExpress');

            if (!deferred.hasCompleted() && deferred.isFirst) {
                $http.get('api/containersv2/express').then(function (result) {
                    deferred.resolve(result.data);
                }, function () {
                    deferred.reject();
                });
            }

            return deferred.promise;
        }
    }
})();
