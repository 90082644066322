(function() {

    angular.module('UndergroundWebApp').factory('serverInfoService', serverInfoService);

    serverInfoService.$inject = [
        '$http'
    ];

    function serverInfoService(
        $http
    ) {
        return {
            getServers
        };

        function getServers() {
            return $http.get('api/server')
                .then(result => result.data);
        }
    }
}());
