(function () {
    'use strict';

    angular.module('UndergroundWebApp').directive('fileUpload', function () {
        return {
            require: 'ngModel',
            restrict: 'A',
            scope: {
                fileread: "="
            },
            link: function (scope, element, attrs, ctrl) {
                element.bind('change', function (changeEvent) {
                    const reader = new FileReader();
                    reader.onload = function (loadEvent) {
                        const model = getModel(loadEvent.target.result);
                        ctrl.$setViewValue(model);
                        ctrl.$render();
                    }
                    reader.readAsDataURL(changeEvent.target.files[0]);
                });
            }
        };
    });

    function getModel(dataUrl) {
        const [metaData, data] = dataUrl.split(',');
        const mediaType = metaData.substring('data:'.length, metaData.length - ';base64'.length);

        return { data, mediaType };
    }
})();
