/**
 * This file contains all the environment specific configurations.
 */
(function () {
    var undergroundWebApp = angular.module('UndergroundWebApp');

    undergroundWebApp.config(['$localForageProvider', function ($localForageProvider) {
        $localForageProvider.config({
            driver: localforage.INDEXEDDB
        });
    }]);

    undergroundWebApp.constant('environmentConfig', {
        "showMenu": false,
        "disableCache": true,
        statusCacheMaxAge: 60 * 60 * 1000,
    });

    undergroundWebApp.constant('s2wExternalSystemId', '1851D315-6413-400E-998A-797EF5CAE143');

    undergroundWebApp.constant('serviceUrls', {
        geocodeingServiceUrl: 'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/',
        reenHubContainer: 'https://hub.reen.com/status/container/',
        reenHubLocation: 'https://hub.reen.com/status/site/',
    });
})();
