(function () {
    'use strict';

    angular.module('UndergroundWebApp').controller('AttachVehicleModalController', AttachVehicleModalController);

    AttachVehicleModalController.$inject = [
        '$scope',
        '$windowInstance',
        'vehicleService',
        'currentVehicles',
        '$translate'
    ];

    function AttachVehicleModalController(
        $scope,
        $windowInstance,
        vehicleService,
        currentVehicles,
        $translate
    ) {
        $scope.vehiclesOptions = {
            dataSource: {
                transport: {
                    read: readVehicles
                }
            },
            dataTextField: 'name',
            dataValueField: 'id',
            optionLabel: $translate.instant('AREA_ATTACH_VEHICLE_MODAL_CONTROLLER_SELECT_CAR')
        };

        $scope.saveAndClose = saveAndClose;
        $scope.close = close;

        function readVehicles(e) {
            vehicleService.getVehicles().then((vehicles) => {
                const availableVehicles = vehicles
                    .filter(v => currentVehicles.every(cv => v.id !== cv.id));
                e.success(availableVehicles);
            }, () => {
                e.reject();
            });
        }

        function saveAndClose() {
            if ($scope.validator.validate()) {
                $windowInstance.close($scope.vehicle.id);
            }
        }

        function close($event) {
            $event.preventDefault();
            $windowInstance.close(false);
        }
    }
})();
