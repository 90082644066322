angular.module('UndergroundWebApp').factory('flyFotoLayerFactory', ['$q', 'esriLoader', function ($q, esriLoader) {
    'ues strict';

    var readyDeferred = null;

    /**
     * Initializes the vessel layer ready function.
     */
    var flyFotoLayer = {
        createLayerOnAdd: true,

        ready: function () {
            if (readyDeferred === null) {
                readyDeferred = $q.defer();
            }

            return readyDeferred.promise;
        }
    }

    esriLoader.require([
        'esri/layers/TileLayer'
    ], function (TileLayer) {
        if (readyDeferred === null) {
            readyDeferred = $q.defer();
        }

        flyFotoLayer.createLayer = function () {
            var layer = new TileLayer({
                url: 'https://services.geodataonline.no/arcgis/rest/services/Geocache_UTM33_WGS84/GeocacheBilder/MapServer',
                visible: false
            });
            layer.name = 'FlyFotoLayer';
            layer.zIndex = 2;

            //Define additional functions
            layer.toggleVisibility = function () {
                layer.visible = !layer.visible;
            }

            return layer;
        }

        readyDeferred.resolve(flyFotoLayer);
    });

    return flyFotoLayer;
}]);
