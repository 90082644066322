(function () {
    'use strict';

    angular.module('UndergroundWebApp').controller('ContainerLogModalController', ContainerLogModalController);

    ContainerLogModalController.$inject = [
        '$scope',
        '$rootScope',
        '$q',
        '$windowInstance',
        'logActionService',
        'containerService',
        'containers',
        'fallbackErrorMessageText',
        '$translate'
    ];

    function ContainerLogModalController(
        $scope, 
        $rootScope, 
        $q, 
        $windowInstance, 
        logActionService,
        containerService, 
        containers, 
        fallbackErrorMessageText,
        $translate
        ) {
        $scope.saveAndClose = saveAndClose;
        $scope.closeWindow = closeWindow;

        $scope.containerOptions = {
            dataSource: containers,
            dataTextField: 'containerNumber',
            dataValueField: 'id',
            optionLabel: $translate.instant("CONTAINER_LOG_MODAL_SELECT_CONTAINER"),
        };

        $scope.deviceOptions = {
            dataSource: {
                transport: {
                    read: getDevices
                }
            },
            dataTextField: 'externalId',
            dataValueField: 'id',
            optionLabel: $translate.instant("CONTAINER_LOG_MODAL_CHOOSE_UNIT"),
        };

        $scope.logActionOptions = {
            dataSource: {
                transport: {
                    read: readLogActions
                }
            },
            dataTextField: 'name',
            dataValueField: 'id',
            optionLabel: $translate.instant("CONTAINER_LOG_MODAL_SELECT_EVENT"),
        }

        $scope.log = {};
        $scope.time = new Date();
        $scope.$watch('container', () => $scope.deviceDropDown && $scope.deviceDropDown.dataSource.read());

        function readLogActions(e) {
            logActionService.getLogActions().then(function (logActions) {
                $scope.logActionsDdlServerErrorText = null;
                e.success(logActions);
            }).catch(function (error) {
                $scope.logActionsDdlServerErrorText = error.localizedMessage || fallbackErrorMessageText;
                e.error();
            });
        }

        function saveAndClose(event) {
            event.preventDefault();

            if ($scope.validator.validate()) {
                var log = {
                    containerId: $scope.container.id,
                    time: moment($scope.time).format('YYYY-MM-DDTHH:mm:ss'),
                    logAction: $scope.logAction.name,
                    logActionId: $scope.logAction.id,
                    comment: $scope.comment,
                    deviceId: $scope.device.id,
                }

                save(log).then(function (result) {
                    $windowInstance.close(result);
                });
            }
        }

        function save(log) {
            var deferred = $q.defer();

            $rootScope.$broadcast('showGlobalBusyIndicator', 'containerTypeIndicator');

            containerService.addContainerLog(log)
                .then(result => deferred.resolve(result),
                    () => deferred.reject())
                .finally(() => $rootScope.$broadcast('hideBusyIndicator', 'containerTypeIndicator'));

            return deferred.promise;
        }

        function closeWindow(result) {
            $windowInstance.close(result);
        }

        function getDevices(e) {
            if (
                !$scope.container
                || !$scope.container.devices
            ) {
                return e.success([]);
            }

            $scope.device = $scope.container.fillPriorityDevice
            e.success($scope.container.devices);
        }
    }
})();
