angular.module('UndergroundWebApp').directive('nSplitter', ['$state', function($state) {
    'use strict';

    var halfOpenedStates = [
        'main.containerDetails', 
        'main.containerOverview', 
        'main.administration.fractions',
        'main.administration.deviationAlert',
        'main.administration.userAdministration',
        'main.administration.containerTypes', 
        'main.locations',
        'main.locationDetails',
        'main.locationDetails.info',
        'main.locationDetails.statistics',
        'main.locationDetails.keycard',
        'main.locationDetails.log',
        'main.statistics',
    ];

    const fullOpenedStates = [
        'main.containerOverviewV2',
    ];

    function getPaneSizes(stateName) {
        var paneSizes = {
            left: '50%',
            right: '50%'
        };

        if (halfOpenedStates.includes(stateName)) {
            paneSizes.left = '50%';
            paneSizes.right = '50%';
        } else if (fullOpenedStates.includes(stateName)) {
            paneSizes.left = '0%';
            paneSizes.right = '100%';
        } else if (stateName === 'main.keycardsOverview') {
            paneSizes.left = '35%';
            paneSizes.right = '65%';
        } else if (stateName === 'main') {
            paneSizes.left = '100%';
            paneSizes.right = '0%';
        }

        return paneSizes;
    }

    return {
        restrict: 'AE',
        replace: true,
        transclude: true,
        templateUrl: 'app/shared/directives/n-splitter.html',
        link: function (scope, element, attributes, controller, transclude) {
            var paneSizes = getPaneSizes($state.current.name);

            $('#splitter').kendoSplitter({
                orientation: 'horizontal',
                panes: [
                    { collapsible: false, size: paneSizes.left },
                    { collapsible: false, size: paneSizes.right }
                ],
                resize: function (resizeEvent) {
                    if ($state.current.name !== 'main') {
                        //Always needs to readd toggle on resize
                        var splitbar = $('.k-splitbar'),
                            splitter = resizeEvent.sender;

                        splitbar.find('.k-resize-handle').remove();

                        if ($('#splitter .splitter-toggle').length === 0) {
                            splitbar.append('<div class="splitter-toggle"><i class="fa fa-chevron-left" aria-hidden="true"></i><i class="fa fa-chevron-right" aria-hidden="true"></i></div>');
                        }

                        var $splitterToggle = $('#splitter .splitter-toggle');
                        $splitterToggle.css('top', (resizeEvent.height / 2 - $splitterToggle.height() / 2) + 'px');

                        if (splitter.options.panes[1].size === '0%'
                            || splitter.options.panes[1].size === '0px') {
                            $splitterToggle.addClass('closed');
                            $splitterToggle.removeClass('opened');
                        } else {
                            $splitterToggle.addClass('opened');
                            $splitterToggle.removeClass('closed');
                        }

                        $splitterToggle.mousedown(function () {
                            var paneSizes = getPaneSizes($state.current.name);

                            if (splitter.options.panes[1].size === '0%'
                                || splitter.options.panes[1].size === '0px') {
                                splitter.options.panes[0].size = paneSizes.left;
                                splitter.options.panes[1].size = paneSizes.right;
                            } else {
                                splitter.options.panes[0].size = '100%';
                                splitter.options.panes[1].size = '0%';
                            }

                            splitter.resize(true);
                        });
                    }
                }
            });
            
            scope.$on('setSplitterSize', function (event, paneSizes) {
                var splitter = $('#splitter').data('kendoSplitter');

                //Resize only if values differ
                if (splitter.options.panes[0].size !== paneSizes.left
                    || splitter.options.panes[1].size !== paneSizes.right) {

                    splitter.options.panes[0].size = paneSizes.left;
                    splitter.options.panes[1].size = paneSizes.right;
                    splitter.resize(true);
                }
            });
        }
    }
}]);
