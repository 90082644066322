(function () {
    'use strict';

    angular.module('UndergroundWebApp').factory('statusService', statusService);

    statusService.$inject = [
        '$http'
    ];

    function statusService(
        $http
    ) {
        var service = {
            getStatusesByDate,
            getLatestStatuses,
        };

        function getStatusesByDate(from, to, includeContainers = false, errorOnly = false) {
            let filter = {
                fromDate: from,
                toDate: to,
                errorOnly,
            };

            return $http.post(`/api/status/filter?includeContainers=${!!includeContainers}`, filter)
                .then(result => result.data);
        }

        function getLatestStatuses(includeContainers = false, from = null, errorOnly = false) {
            return $http.get('/api/status', {
                params: {
                    includeContainers,
                    from,
                    errorOnly,
                },
            }).then(result => result.data);
        }

        return service;
    }
})();
