(function () {
    'use strict';

    angular.module('UndergroundWebApp').controller('ContainerTypesController', ContainerTypesController);

    ContainerTypesController.$inject = [
        '$scope',
        '$kWindow',
        'containerTypesService',
        'formTypes',
        'containerTypeUnits',
        '$filter',
        'containerTypeStatuses',
        'filterStateService',
        '$translate'
    ];

    function ContainerTypesController(
        $scope,
        $kWindow,
        containerTypesService,
        formTypes,
        containerTypeUnits,
        $filter,
        containerTypeStatuses,
        filterStateService,
        $translate
    ) {
        $scope.sortedContainerTypes = [];

        $scope.openNewContainerType = openNewContainerType;
        $scope.openUpdateContainerType = openUpdateContainerType;
        $scope.deleteContainerType = deleteContainerType;

        $scope.containerTypeOverviewGridOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: readContainerTypes
                },
                pageSize: 70,
                sort: filterStateService.getSortFromQueryString(),
                filter: filterStateService.getFilterFromQueryString(),
            }),
            columns: [
                {
                    field: 'name',
                    title: $translate.instant('G_NAME')
                },
                {
                    field: 'size',
                    title: $translate.instant('ADMINISTRATION_CONTAINERTYPE_TABLE_HEADER_VOLUME'),
                    template: dataItem => $filter('decimalWithComma')(dataItem.size)
                },
                {
                    field: 'unit',
                    title: $translate.instant('ADMINISTRATION_CONTAINERTYPE_TABLE_HEADER_UNIT'),
                    template: dataItem => containerTypeUnits[dataItem.unit].LocalizedName
                },
                {
                    field: 'edit',
                    filterable: false,
                    sortable: false,
                    groupable: false,
                    title: ' ',
                    width: '80px',
                    template: '<i class="fa fa-1-5x fa-pencil fa-fw" ng-click="openUpdateContainerType(dataItem)" aria-hidden="true"></i>' +
                        '<i class="fa fa-1-5x fa-trash fa-fw" ng-click="deleteContainerType(dataItem)" aria-hidden="true"></i>'
                }
            ],
            scrollable: {
                virtual: true
            },
            sortable: true,
            filterable: true,
            noRecords: true,
            selectable: false,
            sort: filterStateService.storeGridSortInQueryString,
            filter: filterStateService.storeGridFilterInQueryString,
        };

        function readContainerTypes(e) {
            $scope.showBusyIndicator();
            containerTypesService
                .getContainerTypes()
                .then(
                    containerTypes => {
                        $scope.sortedContainerTypes = _.chain(containerTypes)
                            .filter(f => f.status === containerTypeStatuses.active.Id)
                            .sortBy(f => f.name)
                            .value();
                        e.success($scope.sortedContainerTypes);
                    },
                    () => e.success([])
                )
                .finally(() => $scope.hideBusyIndicator());
        }

        function openNewContainerType() {
            openContainerTypeModal($translate.instant('ADMINISTRATION_CONTAINERTYPE_NEW_CONTAINERTYPE'), formTypes.add)
                .result.then(() => { });
        }

        function openUpdateContainerType(dataItem) {
            openContainerTypeModal($translate.instant('ADMINISTRATION_CONTAINERTYPE'), formTypes.edit, dataItem)
                .result.then(() => { });
        }

        function deleteContainerType(dataItem) {
            let confirmText = $translate.instant('ADMINISTRATION_CONTAINERTYPE_DIALOG_CONFIRM_DELETE');

            if (confirm(`${confirmText} ${dataItem.name}?`)) {
                dataItem.status = containerTypeStatuses.deleted.Id;
                containerTypesService.updateContainerType(dataItem).then(() => {
                    $('#container-types-grid').getKendoGrid().dataSource.read();
                });
            }
        }

        function openContainerTypeModal(title, formType, containerType) {
            return $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: title,
                    resizable: false,
                    height: 284,
                    width: 400,
                    visible: false
                },
                templateUrl: 'app/administration/containerType/container-type-details-modal-view.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'ContainerTypeDetailsModalController',
                resolve: {
                    currentFormType: () => formType,
                    containerType: () => containerType,
                    containerTypeUnits: () => containerTypeUnits
                }
            });
        }
    }
})();
