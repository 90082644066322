/**
 * Caching service
 * Used to cache the result of the dataservice calls.
 * Use a composite key made from 
 *   - function name
 *   - parameters
 *   - app version
 */
(function () {
    angular.module('UndergroundWebApp').factory('cachingService', cachingService);

    cachingService.$inject = [
        '$q',
        '$localForage',
        '$http',
        'localStorageService',
        'environmentConfig'
    ];

    function cachingService(
        $q,
        $localForage,
        $http,
        localStorageService,
        environmentConfig
    ) {
        var cacheLocks = {};
        var cacheCalls = {};
        var authData = null;

        return {
            getItem: getItem,
            setItem: setItem,
            addTo: addTo,
            appendTo: appendTo,
            clear: clear,
            get: get,
            getAll: getAll,
            getByPrefix: getByPrefix,
            has: has,
            isValid: isValid,
            prependTo: prependTo,
            remove: remove,
            removeFrom: removeFrom,
            removeFromBy: removeFromBy,
            replaceIn: replaceIn,
            set: set,
            getWithCache: getWithCache,
            getWithoutCache: getWithoutCache
        };

        function getItem(key) {
            if (environmentConfig.disableCache === true) {
                return null;
            }

            var cacheItem = localStorageService.get(key);
            return cacheItem;
        }

        function setItem(key, item) {
            localStorageService.set(key, item);
        }

        function getWithCache(serviceName, serviceUrl) {
            var deferred = $q.defer();
            var methodName = serviceUrl.match(/\/rest\/(.*)/)[1];
            var cacheKey = serviceName + '_' + methodName;
            var data;
            if (!environmentConfig.disableCache) {
                isValid(cacheKey)
                    .then(function (exists) {
                        if (exists) {
                            return get(cacheKey);
                        } else {
                            return $http.get(serviceUrl)
                                .then(function (response) {
                                    data = response.data;
                                    return set(cacheKey, data);
                                }).then(function () {
                                    return isValid(cacheKey)
                                        .then(function (exists) {
                                            if (exists) {
                                                return get(cacheKey);
                                            } else {
                                                deferred.reject();
                                            }
                                        })
                                        .then(function (data) {
                                            deferred.resolve(data);
                                        })
                                        .catch(function () {
                                            deferred.reject();
                                        });
                                });
                        }
                    })
                    .then(function (data) {
                        deferred.resolve(data);
                    })
                    .catch(function () {
                        if (data) {
                            deferred.resolve(data);
                        }
                        else {
                            return getWithoutCache(serviceUrl);
                        }

                    });
            }
            else {
                return getWithoutCache(serviceUrl);
            }
            return deferred.promise;
        }

        function getWithoutCache(serviceUrl) {
            var deferred = $q.defer();
            $http.get(serviceUrl)
                .then(function (response) {
                    deferred.resolve(response.data);
                })
                .catch(function () {
                    deferred.reject();
                });

            return deferred.promise;
        }

        function appendTo(key, item) {
            var deferred = $q.defer();

            addTo(key, item, false).then(function () {
                deferred.resolve();
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function clear() {
            return $localForage.clear();
        }

        function get(key) {
            var deferred = $q.defer();

            getCompositeKey(key)
                .then(function (compositeKey) {
                    return $localForage.getItem(compositeKey);
                })
                .then(function (cacheItem) {
                    var item = cacheItem ? cacheItem.data : null;
                    deferred.resolve(item);
                })
                .catch(function () {
                    deferred.reject();
                });

            return deferred.promise;
        }

        function getAll() {
            var deferred = $q.defer();

            getByPrefix('').then(function (matches) {
                deferred.resolve(matches);
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function getByPrefix(prefix) {
            var deferred = $q.defer();

            var keyPrefix;
            var matchedKeys = [];

            getCompositeKey(prefix)
                .then(function (compositePrefix) {
                    keyPrefix = compositePrefix;
                    return $localForage.keys();
                })
                .then(function (keys) {
                    matchedKeys = _.filter(keys, function (key) {
                        return _.startsWith(key, keyPrefix);
                    });

                    return $localForage.getItem(matchedKeys);
                })
                .then(function (cacheItems) {
                    var matches = {};

                    _.forEach(matchedKeys, function (matchedKey, index) {
                        var pattern = new RegExp('^(' + keyPrefix + ')', 'g');
                        var matchKey = _.replace(matchedKey, pattern, '');
                        matches[matchKey] = cacheItems[index].data;
                    });

                    deferred.resolve(matches);
                })
                .catch(function () {
                    deferred.reject();
                });

            return deferred.promise;
        }

        function has(key) {
            var deferred = $q.defer();

            get(key).then(function (item) {
                var has = item !== undefined && item !== null;
                deferred.resolve(has);
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function isValid(key) {
            var deferred = $q.defer();
            var date = new Date(new Date() - environmentConfig.cacheTimeoutInMin * 60000);
            getCompositeKey(key)
                .then(function (compositeKey) {
                    return $localForage.getItem(compositeKey);
                })
                .then(function (item) {
                    var isValid = item !== undefined && item !== null && new Date(item.createdAt) >= date;
                    deferred.resolve(isValid);
                }, function () {
                    deferred.reject();
                });

            return deferred.promise;
        }

        function prependTo(key, item) {
            var deferred = $q.defer();

            addTo(key, item, true).then(function () {
                deferred.resolve();
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function remove(key) {
            var deferred = $q.defer();

            getCompositeKey(key)
                .then(function (compositeKey) {
                    return $localForage.removeItem(compositeKey);
                })
                .then(function () {
                    deferred.resolve();
                })
                .catch(function () {
                    deferred.reject();
                });

            return deferred.promise;
        }

        function removeFromBy(key, nestedKey, filterKey, filterValue, operator) {
            var deferred = $q.defer();

            lockingFunction(key, 'removeFromBy', arguments, function (value) {
                var array = nestedKey ? (value ? value[nestedKey] : null) : (value || null);
                if (!_.isArray(array)) throw Error('Cannot replace item in non-array cache item.');

                _.remove(array, function (item) {
                    if (operator === 'eq')
                        return item[filterKey] === filterValue;
                    else if (operator === 'gt')
                        return item[filterKey] > filterValue;
                    else if (operator === 'lt')
                        return item[filterKey] < filterValue;
                    else if (operator === 'gteq')
                        return item[filterKey] >= filterValue;
                    else if (operator === 'lteq')
                        return item[filterKey] <= filterValue;
                    else if (operator === 'in')
                        return _.includes(item[filterKey], filterValue);
                    else
                        return false;
                });

                return value;
            }).then(function () {
                deferred.resolve();
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function removeFrom(key, nestedKey, filterKey, filterValue) {
            var deferred = $q.defer();

            removeFromBy(key, nestedKey, filterKey, filterValue, 'eq').then(function () {
                deferred.resolve();
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function replaceIn(key, nestedKey, item, filterKey, filterValue) {
            var deferred = $q.defer();

            lockingFunction(key, 'replaceIn', arguments, function (value) {
                var array = nestedKey ? (value ? value[nestedKey] : null) : (value || null);
                if (!_.isArray(array)) throw Error('Cannot replace item in non-array cache item.');

                var itemIndex = _.findIndex(array, function (item) {
                    return item[filterKey] === filterValue;
                });
                array.splice(itemIndex, 1, item);

                return value;
            }).then(function () {
                deferred.resolve();
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function set(key, item) {
            var deferred = $q.defer();

            //Add caching metadata
            var cacheItem = {
                createdAt: new Date().toISOString(),
                data: removeUnstorableProperties(item)
            };

            getCompositeKey(key)
                .then(function (compositeKey) {
                    return $localForage.setItem(compositeKey, cacheItem);
                })
                .then(function () {
                    deferred.resolve();
                })
                .catch(function () {
                    deferred.reject();
                });

            return deferred.promise;
        }

        //Private functions
        function lockingFunction(key, functionName, args, callback) {
            var deferred = $q.defer();

            if (!cacheLocks[key]) {
                cacheLocks[key] = true;

                get(key)
                    .then(function (value) {
                        return callback(value);
                    })
                    .then(function (newValue) {
                        if (newValue) return set(key, newValue);
                    })
                    .then(function () {
                        cacheLocks[key] = false;
                        var cacheCall = cacheCalls[key] ? cacheCalls[key].pop() : null;
                        if (cacheCall) {
                            cacheService[cacheCall.functionName].apply(null, cacheCall.args).then(function () {
                                if (cacheCall.deferred) cacheCall.deferred.resolve();
                            }, function () {
                                if (cacheCall.deferred) cacheCall.deferred.reject();
                            });
                        }

                        deferred.resolve();
                    })
                    .then(function () {
                        deferred.resolve();
                    })
                    .catch(function () {
                        deferred.reject();
                    });
            } else {
                cacheCalls[key] = cacheCalls[key] || [];
                cacheCalls[key].unshift({ functionName: functionName, args: args, deferred: deferred });
            }

            return deferred.promise;
        }

        function addTo(key, item, isPrepend) {
            var deferred = $q.defer();

            lockingFunction(key, 'addTo', arguments, function (array) {
                array = array || [];
                if (!_.isArray(array)) throw Error('Cannot append to non-array cache item.');

                if (isPrepend) {
                    array.unshift(item);
                } else {
                    array.push(item);
                }

                return array;
            }).then(function () {
                deferred.resolve();
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function getCompositeKey(itemKey) {
            var deferred = $q.defer();

            authData = localStorageService.get('authenticationData');

            var compositeKey = authData.username + "_" + itemKey;
            deferred.resolve(compositeKey);

            return deferred.promise;
        }

        function removeUnstorableProperties(object) {
            if (_.isArray(object)) {
                return _.map(object, removeUnstorableProperties);
            } else if (_.isObject(object)) {
                _.forIn(object, function (value, key) {
                    if (_.isArray(value)) {
                        object[key] = removeUnstorableProperties(value);
                    }
                });

                return _.pickBy(object, isStorable);
            } else {
                return object;
            }
        }

        function isStorable(value) {
            return !_.isFunction(value);
        }
    }
})();
