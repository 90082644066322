(function () {
    angular.module('UndergroundWebApp').filter('decimalWithComma', function () {
        //intentionally changed to hungarian format (comma notation is needed for compatibility), no-NO causes it to format with decimal dot
        return function (input, fractionDigits, defaultIfZero) {
            if ((input == null || input === 0) && defaultIfZero != null) {
                return defaultIfZero;
            }

            return input.toLocaleString("hu-HU", {
                minimumFractionDigits: fractionDigits,
                maximumFractionDigits: fractionDigits,
                useGrouping: false
            });
        };
    });
})();
