(function () {
    angular.module('UndergroundWebApp').controller('PictureCarouselController', PictureCarouselController);

    PictureCarouselController.$inject = ['$scope', 'pictureUrls'];

    function PictureCarouselController($scope, pictureUrls) {

        $scope.source = {};

        initController();

        function initController() {
            $scope.source = new kendo.data.DataSource({
                data: pictureUrls,
                pageSize: 1
            });
        }
    }
})();
