(function () {
    'use strict';

    angular.module('UndergroundWebApp').factory('selectDisplayedDevices', selectDisplayedDevices);

    function selectDisplayedDevices() {
        return function(devices) {
            const activeDevices = devices
                .filter((device) => device.isActive)
                .sort((a, b) => a.id.localeCompare(b.id))
                .sort((a, b) => new Date(b.lastModified) - new Date(a.lastModified));

            const devicesToDisplay = [];
            const priorityDeviceIndex = activeDevices.findIndex((device) => device.isPriority);
            if (priorityDeviceIndex !== -1) {
                devicesToDisplay.push(activeDevices[priorityDeviceIndex]);
                activeDevices.splice(priorityDeviceIndex, 1);
            }

            devicesToDisplay.push(...activeDevices.slice(0, devicesToDisplay.length === 0 ? 2 : 1));

            return devicesToDisplay;
        }
    }
})();
