(function () {
    'use strict';

    angular.module('UndergroundWebApp').controller('AreaDetailsController', AreaDetailsController);

    AreaDetailsController.$inject = [
        '$rootScope',
        '$scope',
        '$stateParams',
        '$state',
        '$kWindow',
        'globalEvents',
        'mapService',
        'sensorApiAreaService',
        'filterStateService',
        '$translate'
    ];

    function AreaDetailsController(
        $rootScope,
        $scope,
        $stateParams,
        $state,
        $kWindow,
        globalEvents,
        mapService,
        sensorApiAreaService,
        filterStateService,
        $translate
    ) {
        $scope.attachVehicle = attachVehicle;
        $scope.detachVehicle = detachVehicle;

        $scope.areaVehiclesGridOptions = {
            dataSource: {
                data: [],
                sort: filterStateService.getSortFromQueryString({
                    field: 'name',
                    dir: 'asc'
                }),
                filter: filterStateService.getFilterFromQueryString(),
            },
            columns: [
                { field: 'name', title: $translate.instant('G_NAME') },
                { field: 'externalId', title: $translate.instant('AREA_DETAILS_CONTROLLER_HEADER_EXTERNAL_ID'), width: 100 },
                { field: 'brand', title: $translate.instant('AREA_DETAILS_CONTROLLER_HEADER_BRAND'), width: 100 },
                {
                    field: 'edit',
                    filterable: false,
                    sortable: false,
                    groupable: false,
                    title: ' ',
                    width: 60,
                    template: `<i class="fa fa-1-5x fa-unlink fa-fw" ng-click="detachVehicle(dataItem)" aria-hidden="true" ng-show="isAccessible('Vehicle', 'W') && isAccessible('Area', 'W')"></i>`,
                }
            ],
            sortable: true,
            filterable: true,
            noRecords: {
                template: $translate.instant('AREA_DETAILS_CONTROLLER_TABLE_NO_RECORDS'),
            },
            selectable: false,
            sort: filterStateService.storeGridSortInQueryString,
            filter: filterStateService.storeGridFilterInQueryString,
        };

        initController();

        function initController() {
            if ($stateParams.areaId === 'new') {
                $state.go('main.areaDetails.edit', { areaId: 'new' }, { location: 'replace' });
                return;
            }

            $scope.$on(globalEvents.$stateChangeStart, function (event, toState, params) {
                if (!toState.name.startsWith('main.areaDetails')) {
                    mapService.getLayer('areaLayer').clearSelectedArea();
                }
            });

            loadArea();
        };

        //Event handlers
        function attachVehicle() {
            openAttachVehicleModal().then((result) => {
                if (!result) return;

                showBusyIndicator();
                sensorApiAreaService.attachVehicle($scope.area.id, result)
                    .then(handleAreaLoaded)
                    .finally(hideBusyIndicator);
            });
        }

        function detachVehicle(vehicle) {
            let confirmText = $translate.instant('AREA_DETAILS_CONTROLLER_DETACH_VEHICLE_CONFIRM');

            if (!confirm(`${confirmText}: ${vehicle.name}?`)) return;

            showBusyIndicator();
            sensorApiAreaService.detachVehicle($scope.area.id, vehicle.id)
                .then(handleAreaLoaded)
                .finally(hideBusyIndicator);
        }

        function openAttachVehicleModal() {
            return $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('AREA_DETAILS_CONTROLLER_ASSIGN_CAR'),
                    resizable: false,
                    height: 150,
                    width: 400,
                    visible: false
                },
                templateUrl: 'app/area/attach-vehicle-modal-view.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'AttachVehicleModalController',
                resolve: {
                    currentVehicles: () => $scope.area.vehicles,
                },
            }).result;
        }

        function handleAreaLoaded(area) {
            $scope.area = area;
            mapService.getLayer('areaLayer').selectArea(area.id);
            $scope.areaVehiclesGrid.dataSource.data(area.vehicles);
        }

        //Data loading
        function loadArea() {
            showBusyIndicator();
            sensorApiAreaService.getArea($stateParams.areaId)
                .then(handleAreaLoaded)
                .finally(hideBusyIndicator);
        }

        //Private functions
        function showBusyIndicator() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'areaDetailsIndicator',
                destination: '#area-details-view',
                message: $translate.instant('G_BUSY_INDICATOR'),
                overlay: true,
                positionClass: {
                    top: '50%',
                    left: '0px',
                    right: '0px'
                },
            });
        }

        function hideBusyIndicator() {
            $rootScope.$broadcast('hideBusyIndicator', 'areaDetailsIndicator');
        }
    }
})();
