(function () {
    'use strict';

    angular.module('UndergroundWebApp').controller('NavbarController', NavbarController);

    NavbarController.$inject = [
        '$q',
        '$scope',
        '$rootScope',
        '$state',
        '$kWindow',
        '$timeout',
        's2wExternalSystemId',
        'globalEvents',
        'setAttribute',
        'focus',
        'undergroundAPIService',
        'geocodeAPIService',
        'mapUtility',
        'mapService',
        'authService',
        'keycardService',
        'groupService',
        'locationsService',
        'keycardUtility',
        'containerService',
        'formTypes',
        'externalLinkService',
        '$translate'
    ];

    function NavbarController(
        $q,
        $scope,
        $rootScope,
        $state,
        $kWindow,
        $timeout,
        s2wExternalSystemId,
        globalEvents,
        setAttribute,
        focus,
        undergroundAPIService,
        geocodeAPIService,
        mapUtility,
        mapService,
        authService,
        keycardService,
        groupService,
        locationsService,
        keycardUtility,
        containerService,
        formTypes,
        externalLinkService,
        $translate,
    ) {
        $scope.searchItems = [];
        $scope.filteredSearchItems = [];
        $scope.searchFilterType = 'Container';
        $scope.searchBoxText = '';
        $scope.showLoginLoadingIcon = false;

        $scope.overviewContainers = [];

        $scope.handleLoginClick = handleLoginClick;
        $scope.handleLogoutClick = handleLogoutClick;
        $scope.searchFilter = searchFilter;
        $scope.handleSearchClick = handleSearchClick;
        $scope.openAddKeycardModal = openAddKeycardModal;

        $scope.addressSearchOptions = {
            noDataTemplate:  $translate.instant("G_NO_DATA"),
            filter: 'contains',
            dataTextField: 'text',
            highlightFirst: true,
            dataSource: new kendo.data.DataSource({
                serverFiltering: true,
                transport: {
                    read: readAddressSearch
                }
            }),
            select: handleSearch
        };

        $timeout(250).then(initController);

        function initController() {
            //Global event handler subscriptions
            $rootScope.$on(globalEvents.$stateChangeSuccess, handleRouteStateChanged);

            //fetching searchable items
            $scope.showLoginLoadingIcon = true;

            authService.ready()
                .then(authData => {
                    $scope.showLoginLoadingIcon = false;
                    loadNavbar(authData);
                });
        }

        function loadNavbar(authData) {
            if (!authData) return;

            if (authData.isAuth) {
                if (shouldOpenKeycardModal()) {
                    $scope.openAddKeycardModal();
                }

                loadExternalLinks();
            }
        }

        //Global event handler
        function handleSearch(e) {
            if (e.dataItem.searchType === 'Address') {
                $q.all([
                    geocodeAPIService.getGeocodePosition(e.dataItem.magicKey)
                ]).then(function (data) {
                    if (data
                        && data.length > 0
                        && data[0].candidates
                        && data[0].candidates.length > 0
                        && data[0].candidates[0].location) {
                        mapService.zoomToLocation({ x: data[0].candidates[0].location.x, y: data[0].candidates[0].location.y });
                    }
                });

                return;
            }

            if (e.dataItem.searchType === 'Container') {
                var idx = _.findIndex($scope.mergedContainers, function (container) {
                    if (container.displayName === e.dataItem.text) {
                        return true;
                    } else if (container.location && container.location.name) {
                        let alternateDisplayText = container.displayName.replace(container.location.address, container.location.name);
                        if (alternateDisplayText === e.dataItem.text) {
                            return true;
                        }
                    }

                    return false;
                });

                if (idx >= 0) {
                    var container = $scope.mergedContainers[idx];
                    var isReenDevice = container.displayText && container.displayText.toString().length > 5;

                    if (!isReenDevice) {
                        goToContainerDetails($scope.mergedContainers[idx], 'Info');
                    } else {
                        if (container.lat && container.lon) {                            
                            goToLocationDetails($scope.mergedContainers[idx])
                            mapService.zoomToLocation({ x: container.lon, y: container.lat });
                        }
                    }
                }
            }

            if (e.dataItem.searchType === 'KeyCard') {
                groupService.getContainerIdsByGroupId(e.dataItem.groupId).then(function (result) {
                    var containerId = _.first(result);
                    if (containerId >= 0) {
                        goToContainerDetails({ containerId: containerId }, 'KeyCard', $scope.searchBoxText);
                    }
                });
            }
        }

        function handleRouteStateChanged(event, toState, toParams) {
            //Initialize search by current route state
            if (toState.name === 'main.containerDetails') {
                var relatedItem = _.find($scope.searchItems, { containerId: toParams.containerId });
                if (relatedItem) {
                    $scope.searchBoxText = relatedItem.displayName;
                }
            }
        }

        //Page event handlers
        function handleLoginClick() {
            authService.login();
        }

        function handleLogoutClick() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'logout',
                destination: 'body',
                message: $translate.instant("NAVBAR_LOGOUT_BUSY_INDICATOR"),
                overlay: true,
                positionClass: {
                    top: '50%',
                    left: '0px',
                    right: '0px'
                }
            });

            authService.logout().then(function (authData) {
                $rootScope.authData = authData;
            }).finally(function () {
                $rootScope.$broadcast('hideBusyIndicator', 'logout');
            });

            $rootScope.$broadcast('setSplitterSize', {
                left: '100%',
                right: '0%'
            });

            $state.go('main');
        }

        function openAddKeycardModal() {
            $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant("NAVBAR_ADD_KEYCARD"),
                    resizable: false,
                    height: 800,
                    width: 500,
                    visible: false
                },
                templateUrl: 'app/keyCards/views/keycard-registration-modal-view.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'KeyCardRegistrationModalController',
                resolve: {
                    currentFormType: function () {
                        return formTypes.add;
                    },
                    groupName: function () {
                        return null;
                    },
                    groupId: function () {
                        return null;
                    },
                    keyCard: function () {
                        return null;
                    }
                }
            });
        }

        function searchFilter(searchFilterType) {
            focus('main-search-box');

            $scope.searchBoxText = '';
            $scope.searchFilterType = searchFilterType;

            switch (searchFilterType) {
                case 'Container':
                    setAttribute('main-search-box', 'placeholder', $translate.instant("NAVBAR_SEARCH_PLACEHOLDER_CONTAINER"));
                    break;
                case 'Address':
                    setAttribute('main-search-box', 'placeholder', $translate.instant("NAVBAR_SEARCH_PLACEHOLDER_ADDRESS"));
                    break;
                case 'KeyCard':
                    setAttribute('main-search-box', 'placeholder', $translate.instant("NAVBAR_SEARCH_PLACEHOLDER_KEYCARD"));
                    break;
            }
        }

        function handleSearchClick() {
            if ($scope.searchBoxText !== '' && $scope.searchBox.dataItems()) {

                $scope.searchBox.search($scope.searchBoxText);
                $scope.searchBox.select($scope.searchBox.ul.children().eq(0));
                var firstItem = $scope.searchBox.dataItem();

                goToContainerDetails(firstItem);
            }
        }

        //Data loading
        function readAddressSearch(e) {

            switch ($scope.searchFilterType) {
                case 'Container':
                    readContainerSuggestions(e);
                    break;
                case 'Address':
                    readAddressSuggestions(e);
                    break;
                case 'KeyCard':
                    readKeyCardSuggestions(e);
                    break;
            }
        }

        function readContainerSuggestions(e) {
            if ($scope.mergedContainers && $scope.mergedContainers.length > 0) {
                readContainerSuggestionsInternal(e)
            } else {
                loadContainers($rootScope.authData.isAuth).then(() => readContainerSuggestionsInternal(e));
            }
        }

        function readContainerSuggestionsInternal(e) {
            let suggestions = getContainerSuggestions();
            e.success(_.uniqBy(suggestions, 'text'));
        }

        function getContainerSuggestions() {
            let suggestions = [];

            _.forEach($scope.mergedContainers, container => {
                //Check based on container's name
                if (container.displayName.toLowerCase().indexOf($scope.searchBoxText.toLowerCase()) >= 0) {
                    suggestions.push({
                        text: container.displayName,
                        magicKey: container.magicKey,
                        searchType: 'Container'
                    });
                }
                //Check based on container's location name
                else if (container.location
                    && container.location.name
                    && container.location.name.toLowerCase().indexOf($scope.searchBoxText.toLowerCase()) >= 0) {

                    let currentText = container.displayName.replace(
                        container.location.address,
                        container.location.name
                    );

                    suggestions.push({
                        text: currentText,
                        magicKey: container.magicKey,
                        searchType: 'Container'
                    });
                }
            });

            return suggestions;
        }

        function readAddressSuggestions(e) {
            geocodeAPIService.getGeocodeSuggestions($scope.searchBoxText, 'NOR').then(function (searchResult) {
                var suggestions = [];

                if (searchResult && searchResult.suggestions) {
                    for (var i = 0; i < searchResult.suggestions.length; i++) {
                        var dataItem = searchResult.suggestions[i];
                        suggestions.push({
                            text: dataItem.text,
                            magicKey: dataItem.magicKey,
                            searchType: 'Address'
                        });
                    }
                }

                e.success(_.uniqBy(suggestions, 'text'));
            });
        }

        function readKeyCardSuggestions(e) {
            var suggestions = [];

            var convertResult = keycardUtility.convertKeycardNumber($scope.searchBoxText);
            //convert keycard if read from key-fob
            if (!convertResult.errorMessage) {
                $scope.searchBoxText = convertResult.returnStr;
            }

            if ($scope.searchBoxText.length > 2) {
                keycardService.keyCardSearch($scope.searchBoxText).then(function (keycards) {
                    if (keycards && keycards.length > 0) {
                        var distinctNumberKeycards = _.uniqBy(keycards, 'cardNumber');
                        for (var i = 0; i < distinctNumberKeycards.length; ++i) {
                            var dataItem = distinctNumberKeycards[i];
                            suggestions.push({
                                text: dataItem.cardNumber ? dataItem.cardNumber : '',
                                magicKey: dataItem.magicKey,
                                searchType: 'KeyCard',
                                groupId: dataItem.groupId ? dataItem.groupId : ''
                            });
                        }
                    }

                    e.success(suggestions);
                }, function () {
                    e.success(suggestions);
                });
            }
            e.success(suggestions);
        }

        function loadContainers(isLoggedIn) {
            showSearchLoading();

            if (isLoggedIn) {
                return $q.all([
                    containerService.getContainersV2(),
                    locationsService.getLocations(false)
                ]).then(data => {
                    let containers = data[0],
                        locations = data[1];
            
                    return extendContainerV2List(containers, locations);
                }).then(containers => {
                    $scope.searchItems = containers;
                    $scope.mergedContainers = containers;
                }).finally(hideSearchLoading);
            } else {
                return undergroundAPIService.getS2WOverviewExpress(true).then(containers => {
                    addSearchItems(containers);
                    $scope.mergedContainers = $scope.searchItems;
                }).finally(hideSearchLoading);
            }
        }

        function loadExternalLinks() {
            return authService.getAccessRights().then(() => (
                $rootScope.isAccessible('ExternalLink', 'R')
                    ? externalLinkService.getExternalLinks()
                    : $q.resolve([])
            )).then(function (externalLinks) {
                $rootScope.externalLinks = externalLinks;
            });
        }

        //Private functions
        function goToContainerDetails(container, tab, keycardNumber) {
            $rootScope.$broadcast('setSplitterSize', {
                left: '50%',
                right: '50%'
            });

            if (container) {
                let containerId = container.displayText || container.containerId;
                let param = {
                    containerId: containerId,
                    externalSystemId: s2wExternalSystemId,
                    startTab: tab,
                    keycardNumber: keycardNumber
                };

                $state.go('main.containerDetails', param);
            }
        }

        function goToLocationDetails(container) {
            $rootScope.$broadcast('setSplitterSize', {
                left: '50%',
                right: '50%'
            });

            if (container && container.location) {
                let locationId = container.location.id ;
                let param = {
                    locationId: locationId,
                };

                $state.go('main.locationDetails.info', param);
            }
        }

        function shouldOpenKeycardModal() {
            return $rootScope.authData
                && $rootScope.authData.authUser
                && Array.isArray($rootScope.authData.authUser.groups)
                && $rootScope.authData.authUser.groups.some(g => g.groupName === 'Kommune');
        }

        function extendContainerV2List(containers, locations) {
            locations = locations || [];

            containers.forEach(container => {
                let relatedLocation = locations.find(l => l.id === container.locationId),
                    address = (relatedLocation && relatedLocation.address) || container.containerName;

                container.displayName = container.displayText + ' - ' + address + ' - ' + container.fraction;
                container.location = relatedLocation;

                if (container.location) {
                    const coords = mapUtility.convertToUTM33N(container.location.latitude, container.location.longitude);

                    container.lat = coords.Y;
                    container.lon = coords.X;
                }
            });

            return containers;
        }

        function addSearchItems(s2wOverviewContainers) {
            for (var i = 0; i < s2wOverviewContainers.length; i++) {
                var dataItem = s2wOverviewContainers[i];

                const coords = mapUtility.convertToUTM33N(dataItem.degLat, dataItem.degLong);

                $scope.searchItems.push({
                    commune: dataItem.commune,
                    containerId: dataItem.containerId,
                    containerType: dataItem.containerType,
                    fill: dataItem.fill,
                    hasPosition: dataItem.hasPosition,
                    lat: coords.Y,
                    lon: coords.X,
                    place: dataItem.place,
                    placeNumber: dataItem.placeNr,
                    poiId: dataItem.poiId,
                    time: dataItem.time,
                    volt: dataItem.volt,
                    displayName: dataItem.containerId + " - " + dataItem.place + " - " + dataItem.containerType
                });
            }
        }

        function showSearchLoading() {
            $('.search-box').find('span.k-i-loading').show();
        }

        function hideSearchLoading() {
            $('.search-box').find('span.k-i-loading').hide();
        }
    }
})();
