(function () {
    'use strict';

    angular.module('UndergroundWebApp').controller('UserAdministrationController', UserAdministrationController);

    UserAdministrationController.$inject = [
        '$rootScope',
        '$scope',
        '$kWindow',
        'userService',
        'formTypes',
        'filterStateService',
        '$translate'
    ];

    function UserAdministrationController(
        $rootScope,
        $scope,
        $kWindow,
        userService,
        formTypes,
        filterStateService,
        $translate
    ) {
        $scope.userDetailsModalHeight = $scope.isShop2WinModuleEnabled ? 600 : 300;
        $scope.sortedUsers = [];
        $scope.openNewUser = openNewUser;
        $scope.openUpdateUser = openUpdateUser;

        $scope.userOverviewGridOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: readUsers
                },
                pageSize: 70,
                sort: filterStateService.getSortFromQueryString(),
                filter: filterStateService.getFilterFromQueryString(),
            }),
            columns: [
                { 
                    field: 'userName', 
                    title: $translate.instant("ADMINISTRATION_USERS_USERNAME")
                },
                { 
                    field: 'groupsText', 
                    title: $translate.instant("ADMINISTRATION_USERS_GROUPS")
                },
                {
                    field: 'edit',
                    filterable: false,
                    sortable: false,
                    groupable: false,
                    title: ' ',
                    width: '50px',
                    template: '<i class="fa fa-1-5x fa-pencil action-hover" ng-click="openUpdateUser(dataItem)" aria-hidden="true">'
                }
            ],
            scrollable: {
                virtual: true
            },
            sortable: true,
            filterable: true,
            noRecords: true,
            groupable: true,
            selectable: false,
            change: onUserRowSelected,
            sort: filterStateService.storeGridSortInQueryString,
            filter: filterStateService.storeGridFilterInQueryString,
        };

        function readUsers(e) {
            $scope.showBusyIndicator();
            userService.getUsers().then(function (users) {
                var unsortedUsers = _.forEach(users, extendUser);
                $scope.sortedUsers = unsortedUsers.sort(function (a, b) {
                    return (a.userName.toLowerCase() < b.userName.toLowerCase() ? -1 : (a.userName.toLowerCase() > b.userName.toLowerCase() ? 1 : 0));
                });
                e.success($scope.sortedUsers);
                //add empty array on failure
                $scope.hideBusyIndicator();
            }, function () {
                $scope.hideBusyIndicator();
                e.success([]);
            });
        }

        //Event handlers
        function openNewUser() {
            $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant("ADMINISTRATION_USERS_NEW_USER"),
                    resizable: false,
                    height: $scope.userDetailsModalHeight,
                    width: 400,
                    visible: false
                },
                templateUrl: 'app/administration/user/user-details-modal-view.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'UserDetailsModalController',
                resolve: {
                    currentFormType: function () {
                        return formTypes.add;
                    },
                    user: function () {
                        return null;
                    },
                    allUsers: function () {
                        return $scope.sortedUsers;
                    }

                }
            });
        }

        function openUpdateUser(dataItem) {
            $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant("ADMINISTRATION_USERS_USER"),
                    resizable: false,
                    height: $scope.userDetailsModalHeight,
                    width: 400,
                    visible: false
                },
                templateUrl: 'app/administration/user/user-details-modal-view.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'UserDetailsModalController',
                resolve: {
                    currentFormType: function () {
                        return formTypes.edit;
                    },
                    user: function () {
                        return dataItem;
                    },
                    allUsers: function () {
                        return $scope.sortedUsers;
                    }
                }
            });
        }

        function onUserRowSelected() {
            var selectedRows = this.select();
            $rootScope.selectedContainerId = this.dataItem(selectedRows[0]).id;
        }

        //Private functions
        function extendUser(user) {
            user.groupsText = _.join(_.map(user.authTypes, 'groupName'), ', ');
        }
    }
})();
