(function () {
    'use strict';

    angular.module('UndergroundWebApp').controller('StatisticsController', StatisticsController);

    StatisticsController.$inject = [
        '$scope',
        '$rootScope',
        '$stateParams',
        'containerService',
        'chartOptionsService',
        '$translate'
    ];

    function StatisticsController(
        $scope,
        $rootScope,
        $stateParams,
        containerService,
        chartOptionsService,
        $translate
    ) {
        $scope.locationId = null;
        $scope.dateRange = {};

        $scope.charts = [];
        $scope.getChartData = getChartData;

        initController();

        function initController() {
            loadContainer();
        }

        function loadContainer() {
            containerService.getContainerByRelatedDevice($stateParams.deviceId)
                .then(function (container) {
                    if (container) {
                        $scope.locationId = container.locationId;
                        $scope.title = getPageTitle(container);
                    }
                });
        }

        function getPageTitle(container) {
            var titleParts = [];
            if (container.location && container.location.name) {
                titleParts.push(container.location.name);
            }
            if (container.containerNumber) {
                titleParts.push(container.containerNumber);
            }
            if (container.fraction && container.fraction.name) {
                titleParts.push(container.fraction.name);
            }

            return titleParts.join(' - ');
        }

        function getChartData() {
            containerService.getContainerStatistics(
                $stateParams.deviceId,
                moment($scope.dateRange.from).format('YYYY-MM-DD'),
                moment($scope.dateRange.to).format('YYYY-MM-DD'),
            ).then(function (statistics) {
                var charts = [];

                charts.push(chartOptionsService.createChart(statistics, 'fill', $translate.instant('STATISTICS_CONTROLLER_CHART_FILL'), 'blue', {
                    valueAxis: {
                        min: 0,
                        max: 100,
                    },
                }));

                if ($rootScope.isAccessible('SignalStrengthReport', 'R')) {
                    charts.push(chartOptionsService.createChart(statistics, 'rssi', $translate.instant('STATISTICS_CONTROLLER_CHART_SIGNAL_STRENGTH'), 'red'));
                }

                charts.push(chartOptionsService.createChart(statistics, 'volt', $translate.instant('STATISTICS_CONTROLLER_CHART_BATTERY'), 'green'));
                charts.push(chartOptionsService.createChart(statistics, 'temperature', $translate.instant('STATISTICS_CONTROLLER_CHART_TEMPERATURE'), 'orange'));

                if (
                    $rootScope.isAccessible('ContainerUsageForPeriodeReport', 'R')
                    || $rootScope.isAccessible('ContainerUsageReport', 'R')
                ) {
                    charts.push(chartOptionsService.createChart(statistics, 'usageCount', $translate.instant('STATISTICS_CONTROLLER_CHART_USAGE_COUNT'), 'purple'));
                }

                $scope.charts = charts;
            });
        }
    }
})();
