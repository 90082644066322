(function () {
    angular.module('UndergroundWebApp').factory('deviceUtility', deviceUtility);

    deviceUtility.$inject = [
        'serviceUrls',
        's2wExternalSystemId'
    ];

    function deviceUtility(
        serviceUrls,
        s2wExternalSystemId
    ) {
        function getDeviceDetailsLink(externalSystemName, deviceExternalId, forceHref = false) {
            if (!externalSystemName || !deviceExternalId) {
                return null;
            }

            switch (externalSystemName.toLowerCase()) {
                case 'shop2win':
                    return {
                        type: 'internal',
                        url: getLinkToS2WDetails(deviceExternalId, forceHref),
                    };
                case 'reen':
                    return {
                        type: 'external',
                        url: getLinkToReenDetails(deviceExternalId),
                    };
            }

            return null;
        }

        function getLinkToS2WDetails(deviceExternalId, forceHref) {
            if (forceHref) {
                return `/main/container/details?containerId=${deviceExternalId}&externalSystemId=${s2wExternalSystemId}`;
            }

            return `main.containerDetails({ containerId: '${deviceExternalId}', externalSystemId: '${s2wExternalSystemId}' })`;
        }

        function getLinkToReenDetails(deviceExternalId) {
            return serviceUrls.reenHubContainer + deviceExternalId;
        }

        var utility = {
            getDeviceDetailsLink,
        }

        return utility;
    }
})();
