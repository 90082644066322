angular.module('UndergroundWebApp').factory('externalSystemUtility', function () {
    function getExternalSystemIconName(externalSystemName) {
        switch (externalSystemName.toLowerCase()) {
            case 'reen':
                return 'reen.png';
            case 'shop2win':
                return 'shop2win.png';
            default:
                return '';
        }
    }

    function getExternalSystemIcon(externalSystem) {
        if (externalSystem && externalSystem.name) {
            var externalSystemIconName = getExternalSystemIconName(externalSystem.name);
            if (externalSystemIconName) {
                return '/img/icons/' + externalSystemIconName;
            }
        }
        return '';
    };

    function getExtSystemPlaceholderColor(externalSystemName) {
        return {
            'background-color': '#' + numberToRGB(hashCode(externalSystemName)),
        };
    }

    function hashCode(str) {
        var hash = 0;
        for (var i = 0; i < str.length; i++) {
           hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        return hash;
    } 
    
    function numberToRGB(i){
        var c = (i & 0x00FFFFFF)
            .toString(16)
            .toUpperCase();
    
        return "00000".substring(0, 6 - c.length) + c;
    }

    return {
        getExternalSystemIcon,
        getExtSystemPlaceholderColor,
    }
});

