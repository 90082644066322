(function () {
    'use strict';

    angular.module('UndergroundWebApp').factory('groupService', groupService);

    groupService.$inject = [
        '$http'
    ];

    function groupService(
        $http
    ) {
        return {
            addGroup,
            getGroups,
            getContainerIdsByGroupId,
            updateGroup
        };

        function addGroup(group) {
            return $http.post('api/groups', {
                name: group.name,
                info: group.info,
                userId: group.userId
            }).then(result => result.data);
        }

        function getGroups() {
            return $http.get('api/groups')
                .then(result => result.data);
        }

        function getContainerIdsByGroupId(groupId) {
            return $http.get('api/groups/containers/' + groupId)
                .then(result => result.data);
        }

        function updateGroup(group) {
            return $http.put('api/groups', group)
                .then(result => result.data);
        }
    }
})();
