(function () {
    'use strict';

    angular.module('UndergroundWebApp').factory('logActionService', logActionService);

    logActionService.$inject = [
        '$q',
        '$http'
    ];

    function logActionService($q, $http) {
        var service = {
            getLogActions: getLogActions,
        };

        return service;

        function getLogActions() {
            var deferred = $q.defer();
  
            $http.get("api/logAction").then(
                function (result) {
                    if (result && result.data) {
                        deferred.resolve(result.data);
                    } else {
                        deferred.reject();
                    }
                },
                function (result) {
                    deferred.reject(result.data);
                }
            );
            return deferred.promise;
      }
  }
})();
