(function () {
    'use strict';

    angular.module('UndergroundWebApp').controller('UserDetailsModalController', UserDetailsModalController);

    UserDetailsModalController.$inject = [
        '$q',
        '$scope',
        '$rootScope',
        '$timeout',
        '$windowInstance',
        'userService',
        'authService',
        'areaService',
        'formTypes',
        'currentFormType',
        'user',
        'allUsers',
        'fallbackErrorMessageText',
        '$translate'
    ];

    function UserDetailsModalController($q,
        $scope,
        $rootScope,
        $timeout,
        $windowInstance,
        userService,
        authService,
        areaService,
        formTypes,
        currentFormType,
        user,
        allUsers,
        fallbackErrorMessageText,
        $translate
    ) {
        $scope.isEdit = currentFormType === formTypes.edit;
        $scope.auth0UserId = null;
        $scope.userName = '';
        $scope.sendPasswordChange = false;
        $scope.area = '';

        $scope.userNameValid = true;
        $scope.isEmailFormatValid = true;

        $scope.authTypes = [];
        $scope.selectedAuthTypes = [];
        $scope.addedAreas = [];

        $scope.areaOptions = {
            dataSource: {
                transport: {
                    read: readAreas
                }
            },
            dataTextField: 'areaName',
            dataValueField: 'areaName'
        };

        //Public functions
        $scope.addArea = addArea;
        $scope.removeArea = removeArea;
        $scope.saveAndClose = saveAndClose;
        $scope.closeWindow = closeWindow;

        $timeout(250).then(initController);

        function initController() {
            readAuthTypes();

            if ($scope.isEdit) {
                $scope.auth0UserId = user.auth0UserId;
                $scope.userId = user.userId;
                $scope.userName = user.userName;
                $scope.selectedAuthTypes = user.authTypes;
                $scope.addedAreas = _.map(user.areas, 'areaName');
            }
        }

        //Public functions
        function addArea() {
            event.preventDefault();
            var p = _.indexOf($scope.addedAreas, $scope.area);
            if (p < 0) {
                $scope.addedAreas.push($scope.area);
            }
        }

        function removeArea(a) {
            var index = $scope.addedAreas.indexOf(a);
            if (index > -1) {
                $scope.addedAreas.splice(index, 1);
            }
        }

        function saveAndClose() {
            save().then(function (result) {
                $windowInstance.close(result);
                $('#userOverviewGrid').getKendoGrid().dataSource.read();
            });
        }

        function closeWindow(result) {
            event.preventDefault();
            $windowInstance.close(result);
        }

        function save() {
            event.preventDefault();

            let deferred = $q.defer();
            let areasValid = validateAtLeastOneArea();

            $scope.userNameValid = validateUserName();
            $scope.isEmailFormatValid = validateEmailFormat($scope.userName);

            if ($scope.validator.validate()
                && (!$scope.isShop2WinModuleEnabled || areasValid)
                && $scope.userNameValid
                && $scope.isEmailFormatValid) {

                $rootScope.$broadcast('showGlobalBusyIndicator', 'userIndicator');

                var user = getUserData();
                if (currentFormType === formTypes.edit) {
                    userService.updateUser(user).then(function (result) {
                        deferred.resolve(result);
                        $rootScope.$broadcast('hideBusyIndicator', 'userIndicator');
                    }, function () {
                        deferred.reject();
                        $rootScope.$broadcast('hideBusyIndicator', 'userIndicator');
                    });
                }
                else {
                    userService.addUser(user).then(function (result) {
                        deferred.resolve(result);
                        $rootScope.$broadcast('hideBusyIndicator', 'userIndicator');
                    }, function () {
                        deferred.reject();
                        $rootScope.$broadcast('hideBusyIndicator', 'userIndicator');
                    });
                }
            }

            return deferred.promise;
        }

        //Data retrieval
        function readAuthTypes(e) {
            authService.getAuthGroups().then(function (authTypes) {
                $scope.authTypes = authTypes;
                //filter the list of areas for the ones the user has
                if ($scope.authTypes && currentFormType === formTypes.edit) {
                    $scope.selectedAuthTypes = _.filter($scope.authTypes, function (type) {
                        return _.find($scope.selectedAuthTypes, ['groupId', type.groupId]);
                    });
                }
            });
        }

        function readAreas(e) {
            if (!$scope.isShop2WinModuleEnabled) return [];

            areaService.getAreas().then(function (areas) {
                $scope.AllAreas = areas;
                e.success(areas);
            }, function () {
                e.success([]);
            });
        }

        //Helper functions
        function getUserData() {
            var selectedAreas = _.filter($scope.AllAreas, function (area) {
                return _.indexOf($scope.addedAreas, area.areaName) > -1;
            });

            var userData = {
                UserId: $scope.userId,
                Auth0UserId: $scope.auth0UserId,
                UserName: $scope.isEdit ? $scope.userName : $scope.userName.toLowerCase(),
                SendPasswordChange: $scope.sendPasswordChange,
                AuthTypes: $scope.selectedAuthTypes,
                Areas: selectedAreas
            };

            return userData;
        }

        function validateUserName() {
            var matchUsers;
            if (currentFormType === formTypes.edit) {
                //can edit own name
                matchUsers = _.filter(allUsers, ['userName', $scope.userName]);
                if (matchUsers) {
                    if (matchUsers.length === 1 && matchUsers[0].userId == user.userId) {
                        return true;
                    }
                    return false;
                }
                return true;
            } else {
                //only can add user once
                matchUsers = _.filter(allUsers, ['userName', $scope.userName]);
                if (matchUsers.length) {
                    return false;
                }
                return true;
            }
        }

        function validateAtLeastOneArea() {
            if ($scope.addedAreas.length > 0) {
                $scope.showAtleastOneAreaWarn = false;
                return true;
            }
            else {
                $scope.showAtleastOneAreaWarn = true;
                return false;
            }
        }

        function validateEmailFormat(email) {
            let emailRegexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return emailRegexp.test(email);
        }
    }
})();
