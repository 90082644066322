(function () {
    'use strict';

    angular.module('UndergroundWebApp').controller('FractionsController', FractionsController);

    FractionsController.$inject = ['$scope', '$rootScope', '$kWindow', 'fractionsService', 'formTypes', 'fractionStatuses', 'filterStateService', '$translate'];

    function FractionsController($scope, $rootScope, $kWindow, fractionsService, formTypes, fractionStatuses, filterStateService, $translate) {
        $scope.sortedFractions = [];
        $scope.openNewFraction = openNewFraction;
        $scope.deleteFraction = deleteFraction;
        $scope.openUpdateFraction = openUpdateFraction;

        $scope.fractionOverviewGridOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: readFractions
                },
                pageSize: 70,
                sort: filterStateService.getSortFromQueryString(),
                filter: filterStateService.getFilterFromQueryString(),
            }),
            columns: [
                { 
                    field: 'externalIdNumber', 
                    title: $translate.instant('G_NUMBER'),
                    width: '120px' 
                },
                { 
                    field: 'name', 
                    title: $translate.instant('G_NAME')
                },
                {
                    field: 'edit',
                    filterable: false,
                    sortable: false,
                    groupable: false,
                    title: ' ',
                    width: '80px',
                    template: '<i class="fa fa-1-5x fa-pencil fa-fw" ng-click="openUpdateFraction(dataItem)" aria-hidden="true"></i>' +
                        '<i class="fa fa-1-5x fa-trash fa-fw" ng-click="deleteFraction(dataItem)" aria-hidden="true"></i>'
                }
            ],
            scrollable: {
                virtual: true
            },
            sortable: true,
            filterable: true,
            noRecords: true,
            selectable: false,
            change: onFractionRowSelected,
            sort: filterStateService.storeGridSortInQueryString,
            filter: filterStateService.storeGridFilterInQueryString,
        };

        function readFractions(e) {
            $scope.showBusyIndicator();
            fractionsService.getFractions().then(function (fractions) {
                extFractions(fractions);
                $scope.sortedFractions = _.chain(fractions)
                    .filter(f => f.status === fractionStatuses.active.Id)
                    .sortBy(f => f.name.toLowerCase())
                    .value();
                $scope.hideBusyIndicator();
                e.success($scope.sortedFractions);
            }, function () {
                $scope.hideBusyIndicator();
                e.success([]);
            });
        }
        function extFractions(fractions) {
            _.forEach(fractions, f => f.externalIdNumber = Number(f.externalId));
        }
        function openNewFraction() {
            openFractionModal($translate.instant('ADMINISTRATION_FRACTION_NEW_FRACTION'), formTypes.add)
                .result.then(function () { });
        }

        function openUpdateFraction(dataItem) {
            openFractionModal($translate.instant('ADMINISTRATION_FRACTION_FRACTION'), formTypes.edit, dataItem)
                .result.then(function () { });
        }

        function deleteFraction(dataItem) {
            let confirmText = $translate.instant('ADMINISTRATION_FRACTION_DIALOG_CONFIRM_DELETE');

            if (confirm(`${confirmText} ${dataItem.name}?`)) {
                dataItem.status = fractionStatuses.deleted.Id;
                fractionsService.updateFraction(dataItem).then(() => {
                    $('#fractionsGrid').getKendoGrid().dataSource.read();
                });
            }
        }

        function onFractionRowSelected() {
            var selectedRows = this.select();
            $rootScope.selectedContainerId = this.dataItem(selectedRows[0]).id;
        }

        function openFractionModal(title, formType, fraction) {
            return $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: title,
                    resizable: false,
                    height: 210,
                    width: 400,
                    visible: false
                },
                templateUrl: 'app/administration/fraction/fraction-details-modal-view.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'FractionDetailsModalController',
                resolve: {
                    currentFormType: () => formType,
                    fraction: () => fraction
                }
            });
        }
    }
})();

