!function ($) {    
    $.extend($.fn, {
        kendoBusyIndicator: function (c) {
            b = $(this);
            var d = b.find(".k-loading-mask");
            c ? d.length || (d = $("<div class='k-loading-mask'><span class='custom-loading-text'>Loading...</span><div class='k-loading-image'/><div class='k-loading-color'/></div>").width(b.outerWidth()).height(b.outerHeight()).prependTo(b)) : d && d.remove()
        }        
    });
}(jQuery);


//Usage
// $("my element").kendoBusyIndicator(true); // show
// $("my element").kendoBusyIndicator(false); // hide