(function () {
    'use strict';

    angular.module('UndergroundWebApp').controller('GenericReportsController', GenericReportsController);

    GenericReportsController.$inject = [
        '$timeout',
        '$scope',
        'externalReportsService',
        '$translate'
    ];

    function GenericReportsController(
        $timeout,
        $scope,
        externalReportsService,
        $translate
    ) {
        const minFrameWidth = 450;
        const minFrameHeight = 300;

        let windowSize = {
            width: $(document).width(),
            height: $(document).height()
        };

        $scope.frameWidth = Math.max(windowSize.width / 2 - 40, minFrameWidth);
        $scope.frameHeight = Math.max(windowSize.height - 224, minFrameHeight);

        $scope.selectedReportId = null;
        $scope.allReports = [];
        $scope.changeReport = changeReport;

        $scope.reportsDdlOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: readReports
                }
            }),
            dataTextField: 'reportName',
            optionLabel: $translate.instant('G_CHOOSE_GENERIC_REPORT'),
            dataValueField: 'id'
        };

        $timeout(250).then(initController);

        function initController() {
            let windowSize = {
                width: $(document).width(),
                height: $(document).height()
            };

            let viewSize = $('#generic-reports').width();
            if (viewSize > 1400) {
                $scope.frameWidth = Math.max(viewSize, minFrameWidth);
            } else {
                $scope.frameWidth = Math.max(windowSize.width / 2 - 40, minFrameWidth);
            }

            $scope.frameHeight = Math.max(windowSize.height - 270, minFrameHeight);
        }

        function changeReport(selectedReportId) {
            $('#powerbi-statistics').empty();
            $scope.selectedReportId = selectedReportId;
            if (selectedReportId != null && $scope.selectedReportId.length > 0) {
                $('#stat-placeholder').hide();
                let selectedReport = $scope.allReports.find(x => x.id == $scope.selectedReportId);
                $scope.powerBILink = selectedReport.reportUrlBase;

                let powerBiFrame = $('<iframe frameborder="0" allowFullScreen="true" src="{{powerBILink | trustAsResourceUrl}}" />');
                powerBiFrame.attr('width', $scope.frameWidth);
                powerBiFrame.attr('height', $scope.frameHeight);
                powerBiFrame.attr('src', $scope.powerBILink);

                $('#powerbi-statistics').append(powerBiFrame);
            } else {
                $('#stat-placeholder').show();
            }
        }

        function readReports(e) {
            externalReportsService.getAllExternalReports()
                .then(reports => {
                    if (reports && Array.isArray(reports)) {
                        let genericReports = reports.filter(x => x.reportType === 'GenericReport');

                        $scope.allReports = genericReports;
                        e.success(genericReports);
                    }
                })
                .catch(e.error);
        }
    }
})();
