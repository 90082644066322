(function () {
    angular.module("UndergroundWebApp").factory("locationUtility", ['coordinateBounds', locationUtility]);

    function locationUtility(coordinateBounds) {
        function isValidLocation(lat, long) {
            return coordinateBounds.latMin < lat
                && coordinateBounds.latMax > lat
                && coordinateBounds.longMin < long
                && coordinateBounds.longMax > long;
        }

        var utility = {
            isValidLocation
        }

        return utility;
    }
})();
