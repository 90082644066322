angular.module('UndergroundWebApp').factory('locationLayerFactory', [
    '$q',
    '$rootScope',
    'esriLoader',
    'globalEvents',
    'authService',
    'locationsService',
    'locationUtility',
    'mapUtility',

    function (
        $q,
        $rootScope,
        esriLoader,
        globalEvents,
        authService,
        locationsService,
        locationUtility,
        mapUtility,
    ) {
        'use strict';

        const readyDeferred = $q.defer();

        var locationLayerFactory = {
            createLayerOnAdd: true,

            ready: function () {
                return readyDeferred.promise;
            },
        };

        esriLoader.require([
            'esri/geometry/Point',
            'mcl/MarkerClusterLayer'
        ], function (Point, MarkerClusterLayer) {
            locationLayerFactory.createLayer = function () {
                let popupVisible = false;

                const locationLayer = new MarkerClusterLayer({ singleLocationIcon: '../img/location.png' });
                locationLayer.name = 'locationLayer';
                locationLayer.zIndex = 16;
                locationLayer.locationFilterIds = null;

                locationLayer.toggleVisibility = function () {
                    locationLayer.visible = !locationLayer.visible;
                }

                locationLayer.onMouseMove = function (evt, mapView, hitResponse) {
                    if (!hitResponse) {
                        if (popupVisible) {
                            $rootScope.$broadcast('hideSensorApiLocationPopup');
                            popupVisible = false;
                        }
                        return;
                    }

                    var graphic = hitResponse.results[0].graphic;
                    if (
                        popupVisible
                        || !graphic.layer
                        || graphic.layer.name !== locationLayer.name
                        || graphic.attributes.isCluster
                    ) return;

                    var graphicScreenPoint = getScreenPoint(graphic, mapView);

                    $rootScope.$broadcast('showSensorApiLocationPopup', {
                        left: graphicScreenPoint.x + mapView.position[0] + 20,
                        top: graphicScreenPoint.y + mapView.position[1] + 10,
                        ...graphic.attributes,
                    });
                    popupVisible = true;
                };

                locationLayer.onRightClick = function (evt, mapView, hitResponse) {
                    if (!hitResponse) return;

                    var graphic = hitResponse.results[hitResponse.results.length - 1].graphic;
                    if (graphic.layer && graphic.layer.name !== locationLayer.name) return;
                    var screenPoint = getScreenPoint(graphic, mapView);

                    if (graphic.attributes && !graphic.attributes.isCluster && !(graphic.attributes.type === 'sublocation')) {
                        $rootScope.$broadcast('showContextMenu', {
                            item: { id: graphic.attributes.id },
                            mapPosition: mapView.position,
                            screenPoint: screenPoint,
                            type: 'Location',
                        });
                    } else if (graphic.attributes && graphic.attributes.type === 'sublocation') {
                        $rootScope.$broadcast('showContextMenu', {
                            item: { id: graphic.attributes.id },
                            mapPosition: mapView.position,
                            screenPoint: screenPoint,
                            focusPoint: mapView.toScreen(graphic.attributes.point),
                            type: 'Location',
                        });
                    } else if (graphic.attributes && graphic.attributes.isCluster) {
                        $rootScope.$broadcast('showContextMenu', {
                            mapPosition: mapView.position,
                            screenPoint: screenPoint,
                            clusterPoints: graphic.attributes.points,
                            type: 'Cluster'
                        });
                    }
                };

                $rootScope.$on(globalEvents.redrawCluster, function () {
                    locationLayer.redrawData();
                });

                /**
                 * Filters the displayed locations with the provided location IDs
                 * @param locationIds The list of location IDs used to filter the locations by `location.id`. 
                 * Leave empty to reset the filtering.
                 */
                locationLayer.setLocationFilter = function (locationIds) {
                    locationLayer.locationFilterIds = locationIds;
                    if (!locationIds) {
                        locationLayer.updateData(locationLayer._locations);
                        return;
                    }

                    if (locationIds.length === 0) {
                        locationLayer.updateData([]);
                        return;
                    }

                    var filteredLocations = locationLayer._locations.filter(function (location) {
                        return locationIds.includes(location.id);
                    });
                    locationLayer.updateData(filteredLocations);
                }

                locationLayer.loadData = function () {
                    authService.ready().then((authData) => {
                        const getLocations = authData.isAuth
                            ? locationsService.getLocations()
                            : $q.resolve([]);

                        return getLocations.then(function (locations) {
                            const validLocations = locations
                                .filter((location) =>
                                    locationUtility.isValidLocation(location.latitude, location.longitude))
                                .map((location) => {
                                    const coords = mapUtility.convertToUTM33N(location.latitude, location.longitude);
                                    return {
                                        ...location,
                                        degLong: coords.X,
                                        degLat: coords.Y,
                                    }
                                });

                            locationLayer._locations = validLocations;
                            if (locationLayer.locationFilterIds) {
                                locationLayer.setLocationFilter(locationLayer.locationFilterIds);
                            } else {
                                locationLayer.updateData(validLocations);
                            }
                        });
                    });
                }

                function getScreenPoint(graphic, mapView) {
                    var point = new Point(graphic.geometry.x, graphic.geometry.y);
                    return mapView.toScreen(point);
                }

                locationLayer.loadData();

                return locationLayer;
            };

            readyDeferred.resolve(locationLayerFactory);
        });

        return locationLayerFactory;
    }]);
