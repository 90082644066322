angular.module('UndergroundWebApp').directive('busyIndicator', ['$q', function ($q) {
    'use strict';

    return {
        restrict: 'E',
        replace: true,
        templateUrl: 'app/shared/directives/busy-indicator.html',
        link: function (scope, element) {
            scope.$on('showBusyIndicator', showBusyIndicator);

            scope.$on('showGlobalBusyIndicator', function(event, id) {
                showBusyIndicator(event, {
                    id: id,
                    destination: 'body',
                    message: 'Henter data...',
                    overlay: true,
                    positionClass: {
                        top: '50%',
                        left: '0px',
                        right: '0px'
                    }
                });
            });

            scope.$on('showMainBusyIndicator', function (event, id) {
                showBusyIndicator(event, {
                    id: id,
                    message: 'Henter data...',
                    destination: '#main-view',
                    overlay: true,
                    positionClass: {
                        top: '50%',
                        left: '0px',
                        right: '0px'
                    }
                });
            });

            scope.$on('hideBusyIndicator', function (event, id) {
                if (id) {
                    $('#' + id).remove();
                }
            });

            function showBusyIndicator(event, model) {
                var busyIndicator = $('#' + model.id);
                if (busyIndicator.length === 0) {
                    busyIndicator = element.clone();
                }
                busyIndicator.removeClass('hidden');
                busyIndicator.attr('id', model.id);
                busyIndicator.find('.busy-indicator-text').text(model.message);

                if (model.overlay) {
                    busyIndicator.addClass('overlay');
                } else {
                    busyIndicator.removeClass('overlay');
                }

                if (model.positionClass) {
                    busyIndicator.find('.busy-indicator-box').css(model.positionClass);
                }
                if (model.class) {
                    busyIndicator.css(model.class);
                }

                $(model.destination).append(busyIndicator);
            }
        }
    };
}]);
