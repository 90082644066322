(function () {
    'use strict';

    angular.module('UndergroundWebApp').controller('SensorApiContainerDetailsModalController', SensorApiContainerDetailsModalController);

    SensorApiContainerDetailsModalController.$inject = [
        '$q',
        '$scope',
        '$rootScope',
        '$kWindow',
        '$windowInstance',
        'containerService',
        'customerSystemsService',
        'guidService',
        'formTypes',
        'currentFormType',
        'containerId',
        'externalSystemsService',
        'locationsService',
        'fractionsService',
        'containerTypesService',
        'updateCallbacks',
        'deviceService',
        'locationId',
        'fallbackErrorMessageText',
        '$translate'
    ];

    function SensorApiContainerDetailsModalController(
        $q,
        $scope,
        $rootScope,
        $kWindow,
        $windowInstance,
        containerService,
        customerSystemsService,
        guidService,
        formTypes,
        currentFormType,
        containerId,
        externalSystemsService,
        locationsService,
        fractionsService,
        containerTypesService,
        updateCallbacks,
        deviceService,
        locationId,
        fallbackErrorMessageText,
        $translate,
    ) {
        var customerSystems = [];
        var containerCustomerSystems = [];
        var allContainerCustomerSystems = [];
        var container = {};
        var externalSystems = [];
        var addedSystemsIds = [];
        var isDirty = false;
        var isInitialized = false;

        $scope.isBusy = false;
        $scope.isEdit = currentFormType === formTypes.edit;

        initController();

        function initController() {
            $scope.number = '';
            $scope.name = '';
            $scope.location = '';
            $scope.externalId = '';
            $scope.fraction = '';
            $scope.saveAndClose = saveAndClose;
            $scope.close = close;
            $scope.save = save;
            $scope.openAddSensor = openAddSensor;
            $scope.openUpdateSensor = openUpdateSensor;
            $scope.detachSensor = detachSensor;
            $scope.updateDevice = updateDevice;
            $scope.isContainerDeletable = isContainerDeletable;
            $scope.deleteContainer = deleteContainer;
            $scope.togglePriority = togglePriority;
            $scope.toggleStatus = toggleStatus;
            $scope.devices = [];

            $scope.selectedLocationId = null;
            $scope.selectedContainerTypeId = null;
            $scope.selectedFractionId = null;

            $scope.fractionValid = true;
            $scope.containerTypeValid = true;
            $scope.nameValid = true;

            var requests = [];
            if ($scope.isEdit) {
                requests = [
                    customerSystemsService.getCustomerSystems(),
                    externalSystemsService.getExternalSystems(),
                    customerSystemsService.getAllContainerCustomerSystems(),
                    containerService.getContainerByContainerId(containerId),
                    containerService.getCustomerSystemsForContainer(containerId)];
            } else {
                requests = [
                    customerSystemsService.getCustomerSystems(),
                    externalSystemsService.getExternalSystems()];
            }

            $q.all(requests)
                .then(data => {
                    customerSystems = data[0];
                    externalSystems = data[1];

                    $scope.selectedLocationId = container.locationId = locationId;

                    if (data.length > 2) {
                        allContainerCustomerSystems = data[2];
                        container = data[3];
                        containerCustomerSystems = data[4];
                        $scope.devices = extDevices(container.devices);
                        $scope.number = container.containerNumber;
                        $scope.name = container.name;
                        $scope.location = container.locationId
                        $scope.externalId = container.externalId;

                        $scope.selectedLocationId = container.locationId;
                        $scope.selectedContainerTypeId = container.containerTypeId;
                        $scope.selectedFractionId = container.fractionId;
                    }

                    _.forEach(containerCustomerSystems, ccSystem => ccSystem.selectedCustomerSystemId = ccSystem.customerSystem.id);

                    setTimeout(() => {
                        $('.k-item.k-state-default.k-first').click();
                    }, 1000);

                    $scope.locationOptions = {
                        dataSource: {
                            transport: {
                                read: readLocations
                            }
                        },
                        dataTextField: 'name',
                        dataValueField: 'id',
                        change: function (e) {
                            $scope.selectedLocationId = this.value();
                        },
                        filter: 'contains'
                    };

                    $scope.containerTypeOptions = {
                        dataSource: {
                            transport: {
                                read: readContainerTypes
                            }
                        },
                        dataTextField: 'label',
                        dataValueField: 'id',
                        change: function (e) {
                            $scope.selectedContainerTypeId = this.value();
                        }
                    };

                    $scope.fractionOptions = {
                        dataSource: {
                            transport: {
                                read: readFractions
                            }
                        },
                        dataTextField: 'name',
                        dataValueField: 'id',
                        change: function (e) {
                            $scope.selectedFractionId = this.value();
                        },
                        filter: 'contains'
                    };

                    $scope.sensorGridOptions = {
                        dataSource: new kendo.data.DataSource({
                            transport: {
                                read: function (e) {
                                    e.success($scope.devices);
                                }
                            }
                        }),
                        columns: [
                            {
                                title: $translate.instant("SENSOR_API_CONTAINER_DETAILS_MODAL_TABLE_HEADER_EXTERNAL_SYSTEM"),
                                field: 'externalSystem',
                            },
                            {
                                title: $translate.instant("SENSOR_API_CONTAINER_DETAILS_MODAL_TABLE_HEADER_EXTERNAL_ID"),
                                field: 'externalId',
                            },
                            {
                                title: $translate.instant("SENSOR_API_CONTAINER_DETAILS_MODAL_TABLE_HEADER_SERIAL"),
                                field: 'serial',
                            },
                            {
                                title: $translate.instant("SENSOR_API_CONTAINER_DETAILS_MODAL_TABLE_HEADER_FULL_HEIGHT"),
                                field: 'fullHeight',
                            },
                            {
                                title: $translate.instant("SENSOR_API_CONTAINER_DETAILS_MODAL_TABLE_HEADER_SENSOR_HEIGHT"),
                                field: 'sensorHeight',
                            },
                            {
                                title: $translate.instant("SENSOR_API_CONTAINER_DETAILS_MODAL_TABLE_HEADER_CUSTOMER_KEY"),
                                field: 'customerKey',
                            },
                            {
                                title: $translate.instant("SENSOR_API_CONTAINER_DETAILS_MODAL_TABLE_HEADER_IS_PRIORITY"),
                                field: 'isPriority',
                                width: '60px',
                                template: '<input type="checkbox" #= isPriority? "checked=checked" : "" # ng-click="togglePriority(dataItem)" />'
                            },
                            {
                                title: $translate.instant("SENSOR_API_CONTAINER_DETAILS_MODAL_TABLE_HEADER_IS_ACTIVE"),
                                field: 'isActive',
                                width: '40px',
                                template: '<input type="checkbox" #= isActive? "checked=checked" : "" # ng-click="toggleStatus(dataItem)" />'
                            },
                            {
                                field: 'actions',
                                title: ' ',
                                width: '50px',
                                template: '<div class="grid-tooltip" ng-click="openUpdateSensor(dataItem)"><i class="fa fa-1-5x fa-pencil" aria-hidden="true"></i></div>' +
                                    '<div class="grid-tooltip" ng-click="detachSensor(dataItem)"><i class="fa fa-second fa-1-5x fa-unlink" aria-hidden="true"></div>',
                                filterable: false,
                                sortable: false,
                                groupable: false
                            },
                            { hidden: true, field: "id" },
                        ],
                        editable: false,
                        noRecords: {
                            template: $translate.instant("SENSOR_API_CONTAINER_DETAILS_MODAL_TABLE_NO_RECORDS"),
                        },
                        scrollable: false,
                    };

                    $scope.tabOptions = {
                        animation: false,
                        value: $translate.instant("SENSOR_API_CONTAINER_DETAILS_MODAL_TAB_OPTIONS"),
                        dataTextField: 'Name',
                        dataContentUrlField: 'ContentUrl',
                        activate: function (e) {
                            var activeTab = e.item.innerText.toLowerCase();
                            $scope.$applyAsync(() => {
                                $scope.isSensorTabActive = (activeTab === 'sensorer');
                                $scope.isCustomerSystemTabActive = (activeTab === 'kundesystem');
                            })

                        },
                        dataSource: new kendo.data.DataSource({
                            data: [
                                { Name: $translate.instant("SENSOR_API_CONTAINER_DETAILS_MODAL_KENDO_DATA_SOURCE_SENSORS"), ContentUrl: 'app/containerOverviewV2/views/sensor-details-tab.html' },
                                { Name: $translate.instant("SENSOR_API_CONTAINER_DETAILS_MODAL_KENDO_DATA_SOURCE_CUSTOMER_SYSTEM"), ContentUrl: 'app/containerOverviewV2/views/customer-system-tab.html' }
                            ]
                        })
                    };

                    isInitialized = true;

                    if (!isContainerDeletable()) {
                        $('.k-window .btn.btn-danger').kendoTooltip({
                            width: 300,
                            position: 'bottom',
                            content: $translate.instant("SENSOR_API_CONTAINER_DETAILS_MODAL_CUSTOMER_SYSTEM_GRID_CANNOT_DELETE_CONTAINER_MESSAGE"),
                            animation: {
                                open: {
                                    effects: 'zoom',
                                    duration: 150
                                }
                            }
                        }).data('kendoTooltip');
                    }
                });

            $scope.addCustomerSystem = function () {
                $('#customer-systems-grid').getKendoGrid().addRow();
            }

            $scope.customerSystemGridOptions = {
                dataSource: new kendo.data.DataSource({
                    transport: {
                        read: function (e) {
                            if ($scope.isEdit === formTypes.add) {
                                e.success([]);
                            } else {
                                e.success(containerCustomerSystems);
                            }
                        },
                        create: function (e) {
                            var containerCustomerSystem = angular.copy(e.data);
                            if (_.some(addedSystemsIds, a => a === containerCustomerSystem.selectedCustomerSystemId)) {
                                e.error();
                            } else {
                                var usedCustomerSystem = allContainerCustomerSystems.find(c =>
                                    c.customerSystem
                                    && c.customerSystem.id === containerCustomerSystem.selectedCustomerSystemId
                                    && c.externalId === containerCustomerSystem.externalId
                                );

                                if (usedCustomerSystem) {
                                    var createWithExisting = confirm(
                                        $translate.instant("SENSOR_API_CONTAINER_DETAILS_MODAL_CUSTOMER_SYSTEM_GRID_EXTERNAL_ID_IS_IN_USE_MESSAGE"),
                                    );
                                    if (!createWithExisting) {
                                        $scope.customerSystemsGrid.cancelRow();
                                        return;
                                    }
                                }

                                containerService.addCustomerSystemForContainer(containerCustomerSystem, container.id)
                                    .then(function (response) {
                                        response.selectedCustomerSystemId =
                                            response.customerSystem
                                            && response.customerSystem.id;
                                        e.success(response);
                                        addedSystemsIds.push(response.id);
                                    }, function (error) {
                                        alert('adding customer system failed');
                                        e.error(error);
                                    });
                            }
                            //create in service
                        },
                        update: function (e) {
                            var containerCustomerSystem = angular.copy(e.data);
                            containerCustomerSystem.customerSystem = _.find(customerSystems, cs => cs.id === containerCustomerSystem.selectedCustomerSystemId);
                            containerService.updateContainerCustomerSystem(containerCustomerSystem)
                                .then(function (response) {
                                    e.success();
                                }, function (error) {
                                    alert('update customer system failed')
                                    e.error(error);
                                });
                        },
                        destroy: function (e) {
                            var containerCustomerSystem = angular.copy(e.data);
                            containerService.removeCustomerSystemFromContainer(containerCustomerSystem.id)
                                .then(function (response) {
                                    e.success();
                                    var ind = _.findIndex(addedSystemsIds, a => a === response)
                                    addedSystemsIds.splice(ind, 1);
                                }, function (error) {
                                    alert('delete customer system failed')
                                    e.error(error);
                                });
                        }
                    },
                    batch: false,
                    schema: {
                        model: {
                            id: "id",
                            fields: {
                                externalId: {
                                    editable: true,
                                    validation: { required: { message: $translate.instant("SENSOR_API_CONTAINER_DETAILS_MODAL_CUSTOMER_SYSTEM_GRID_EXTERNAL_ID_REQUIRED_MESSAGE") } }
                                },
                                selectedCustomerSystemId: { editable: true }
                            }
                        }
                    }
                }),
                columns: [
                    {
                        field: 'selectedCustomerSystemId',
                        title: $translate.instant("SENSOR_API_CONTAINER_DETAILS_MODAL_CUSTOMER_SYSTEM_GRID_HEADER_CUSTOMER_SYSTEM"),
                        editor: customerSystemDropDownEditor,
                        template: function (dataItem) {
                            if (dataItem.selectedCustomerSystemId) {
                                var index = customerSystems.findIndex(function (system) {
                                    return system.id === dataItem.selectedCustomerSystemId;
                                });
                                return customerSystems[index].name;
                            }
                            return '';
                        }
                    },
                    {
                        field: 'externalId',
                        title: $translate.instant("SENSOR_API_CONTAINER_DETAILS_MODAL_CUSTOMER_SYSTEM_GRID_HEADER_CUSTOMER_SYSTEM_ID"),
                        width: 240,
                    },
                    {
                        command: [
                            { name: "edit", text: { edit: " ", update: " ", cancel: " " } },
                            { name: "destroy", text: " " }
                        ],
                        title: '&nbsp;',
                        width: 88
                    }
                ],
                scrollable: false,
                noRecords: {
                    template: $translate.instant("SENSOR_API_CONTAINER_DETAILS_MODAL_CUSTOMER_SYSTEM_GRID_NO_RECORDS"),
                },
                editable: 'inline'
            };
        }

        function openUpdateSensor(device) {
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: 'Sensor',
                    resizable: false,
                    height: 550,
                    width: 450,
                    visible: false
                },
                templateUrl: 'app/device/device-details-modal-view.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'DeviceDetailsModalController',
                resolve: {
                    currentFormType: function () {
                        return formTypes.edit;
                    },
                    containerId: function () {
                        return containerId;
                    },
                    updateCallbacks: function () {
                        return {
                            addDeviceToGrid,
                            updateDeviceToGrid
                        };
                    },
                    device: function () {
                        return device;
                    }
                }
            });
        }

        function addDeviceToGrid(device) {
            container.devices.push(device);
            $('#sensor-grid').getKendoGrid().dataSource.read();
        }

        function updateDeviceToGrid(device) {
            if (device.isPriority) {
                container.devices.forEach((d) => d.isPriority = false);
            }

            if (
                typeof device.externalSystem === 'object'
                && device.externalSystem.name
            ) {
                device.externalSystem = device.externalSystem.name;
            }

            var deviceIndex = _.findIndex(container.devices, d => d.id == device.id);
            container.devices.splice(deviceIndex, 1, device);
            $('#sensor-grid').getKendoGrid().dataSource.read();
        }

        function openAddSensor() {
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant("SENSOR_API_CONTAINER_DETAILS_MODAL_ADD_SENSOR_MODAL_TITLE"),
                    resizable: false,
                    height: 360,
                    width: 400,
                    visible: false
                },
                templateUrl: 'app/device/connect-sensor-modal-view.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'ConnectSensorModalController',
                resolve: {
                    currentFormType: function () {
                        return formTypes.edit;
                    },
                    containerId: function () {
                        return containerId;
                    },
                    updateCallbacks: function () {
                        return {
                            addDeviceToGrid,
                            updateDeviceToGrid
                        };
                    },
                    device: function () {
                        return null;
                    }
                }
            });

            windowInstance.result
                .then((devices) => {
                    showSensorBusyIndicator();
                    if (devices && devices.length) {
                        return $q.all(_.map(devices, d => containerService.connectDevice(d, containerId)));
                    }
                })
                .then(() => {
                    containerService.getContainerByContainerId(containerId).then((cont) => {
                        extDevices(cont.devices);
                        $scope.devices = cont.devices;
                        container.devices = $scope.devices;
                        $('#sensor-grid').getKendoGrid().dataSource.read();
                        hideSensorBusyIndicator();
                    });
                });
        }

        function showSensorBusyIndicator(message) {
            showBusyIndicator('sensor-tab-container', message);
        }

        function showMainBusyIndicator(message) {
            showBusyIndicator('sensorapi-container-details-modal', message);
        }

        function showBusyIndicator(destination, message) {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'container-modal-' + destination,
                destination: '#' + destination,
                message: message,
                overlay: true,
                positionClass: {
                    top: '50%',
                    left: '0px',
                    right: '0px'
                }
            });
        }

        function hideSensorBusyIndicator() {
            $rootScope.$broadcast('hideBusyIndicator', 'container-modal-sensor-tab-container');
        }

        function hideMainBusyIndicator() {
            $rootScope.$broadcast('hideBusyIndicator', 'container-modal-sensorapi-container-details-modal');
        }

        function detachSensor(device) {
            let confirmText = $translate.instant("SENSOR_API_CONTAINER_DETAILS_MODAL_DETACH_SENSOR_CONFIRM_MESSAGE");
            if (confirm(`${confirmText}: ${device.externalId}?`)) {
                if (device && device.id) {
                    showSensorBusyIndicator($translate.instant("G_BUSY_INDICATOR"));

                    deviceService.disconnectDevice(device.externalId, device.externalSystemId).then(() => {
                        var deviceIndex = _.findIndex(container.devices, d => d.id == device.id);
                        if (deviceIndex > -1) {
                            container.devices.splice(deviceIndex, 1);
                            $('#sensor-grid').getKendoGrid().dataSource.read();
                        }
                        hideSensorBusyIndicator();
                    }, function () {
                        hideSensorBusyIndicator();
                    });
                }
            }
        }

        function close(refresh) {
            if (!$scope.isBusy) {
                $windowInstance.close(refresh || isDirty);
            }
        }

        function save() {
            if (!$scope.isBusy) {
                showMainBusyIndicator();
                $scope.isBusy = true;
                $scope.fractionValid = $scope.selectedFraction ? true : false;
                $scope.containerTypeValid = $scope.selectedContainerType ? true : false;
                $scope.nameValid = $scope.name ? true : false;

                var deferred = $q.defer();

                var updateContainerDto = {
                    id: containerId ? containerId : guidService.emptyGuid(),
                    containerNumber: $scope.number,
                    name: $scope.name,
                    locationId: $scope.selectedLocationId,
                    fractionId: $scope.selectedFractionId,
                    containerCustomerSystems: [],
                    externalId: $scope.externalId,
                    containerTypeId: $scope.selectedContainerTypeId,
                    devices: []
                }

                if ($scope.fractionValid && $scope.containerTypeValid && $scope.nameValid) {
                    if ($scope.isEdit) {
                        containerService.updateContainerV2(updateContainerDto)
                            .then((container) => {
                                if (updateCallbacks) {
                                    var containerOverview = { ...container };
                                    containerOverview.fraction = $scope.selectedFraction.name;
                                    containerOverview.containerId = container.id;
                                    updateCallbacks.updateContainerToGrid(containerOverview);
                                }
                                isDirty = true;
                                $scope.isBusy = false;
                                hideMainBusyIndicator();
                                deferred.resolve();
                            }).catch(() => {
                                $scope.isBusy = false;
                                hideMainBusyIndicator();
                            });;
                    } else {
                        containerService.addContainerV2(updateContainerDto)
                            .then((container) => {
                                $scope.number = container.containerNumber;
                                $scope.isEdit = formTypes.edit;
                                containerId = container.id;
                                if (updateCallbacks) {
                                    var containerOverview = { ...container };
                                    containerOverview.fraction = $scope.selectedFraction.name;
                                    containerOverview.containerId = container.id;
                                    updateCallbacks.addContainerToGrid(containerOverview);
                                }
                                $scope.isBusy = false;
                                hideMainBusyIndicator();
                                deferred.resolve();
                            }).catch(() => {
                                $scope.isBusy = false;
                                hideMainBusyIndicator();
                            });
                    }
                }
                else {
                    $scope.isBusy = false;
                    hideMainBusyIndicator();
                }

                return deferred.promise;
            }
        }

        function saveAndClose() {
            if (!$scope.isBusy) {
                save()
                    .then(() => {
                        close(true);
                    }).catch(() => {
                        alert($translate.instant("G_SAVE_FAILED"));
                    });
            }
        }

        function customerSystemDropDownEditor(container, options) {
            $('<input required name="' + options.field + '"/>')
                .appendTo(container)
                .kendoDropDownList({
                    dataTextField: 'name',
                    dataValueField: 'id',
                    dataSource: new kendo.data.DataSource({
                        transport: {
                            read: function (e) {
                                e.success(getAvailableSystems(customerSystems));
                            }
                        }
                    })
                });
        }

        function extDevices(devices) {
            try {
                _.forEach(devices, d =>
                    d.externalSystem = _.find(externalSystems, ex => ex.id.toLowerCase() == d.externalSystemId.toLowerCase()).name);
                return devices;
            } catch{
                return [];
            }
        }

        function extContainerTypes(containerTypes) {
            _.forEach(containerTypes, c => c.label = (c.name ? c.name : 'unnamed'));
        }

        function readFractions(e) {
            fractionsService.getFractions().then(fractions => {
                $scope.fractionsDdlServerErrorText = null;
                e.success(fractions || []);
                $scope.selectedFraction = _.find(fractions, f => f.id === container.fractionId);
            }).catch(function (error) {
                $scope.fractionsDdlServerErrorText = error.localizedMessage || fallbackErrorMessageText;
                e.error();
            });
        }

        function readLocations(e) {
            locationsService.getLocations().then(locations => {
                $scope.locationsDdlServerErrorText = null;
                e.success(locations || []);
                $scope.selectedLocation = _.find(locations, loc => loc.id === container.locationId);
            }).catch(function (error) {
                $scope.locationsDdlServerErrorText = error.localizedMessage || fallbackErrorMessageText;
                e.error();
            });
        }

        function readContainerTypes(e) {
            containerTypesService.getContainerTypes().then(containerTypes => {
                extContainerTypes(containerTypes || []);
                $scope.containerTypesDdlServerErrorText = null;
                e.success(containerTypes || []);
                $scope.selectedContainerType = _.find(containerTypes, ct => ct.id === container.containerTypeId);
            }).catch(function (error) {
                $scope.containerTypesDdlServerErrorText = error.localizedMessage || fallbackErrorMessageText;
                e.error();
            });
        }

        function getAvailableSystems(customerSystems) {
            var customerSystemGridCellContents = _.map($('#customer-systems-grid td'), item => $(item).html());
            return _.reject(customerSystems, c => _.some(customerSystemGridCellContents, content => content === c.name))
        }

        function updateDevice(device) {
            var deviceDto = {
                id: device.id,
                externalId: device.externalId,
                externalSystemId: device.externalSystemId,
                isActive: device.isActive,
                isPriority: device.isPriority
            }

            showSensorBusyIndicator();
            deviceService.updateDevice(deviceDto).then(function (updatedDevice) {
                updateDeviceToGrid(updatedDevice);
                hideSensorBusyIndicator();
            });
        }

        //ng-bind alternative workaround 
        function togglePriority(device) {
           device.isPriority = !device.isPriority;
           updateDevice(device);
        }

        //ng-bind alternative workaround 
        function toggleStatus(device) {
           device.isActive = !device.isActive;
           updateDevice(device);
        }

        function isContainerDeletable() {
            return isInitialized
                && $scope.devices.length === 0
                && containerCustomerSystems.length === 0;
        }

        function deleteContainer() {
            if (
                !isContainerDeletable()
                || !confirm($translate.instant("SENSOR_API_CONTAINER_DETAILS_MODAL_DELETE_CONTAINER_CONFIRM_MESSAGE"))
            ) {
                return;
            }

            containerService.deleteContainer(containerId)
                .then(() => close(true));
        }
    }
})();
