(function () {
    'use strict';

    angular.module('UndergroundWebApp').controller('FractionDetailsModalController', FractionDetailsModalController);

    FractionDetailsModalController.$inject = [
        '$q',
        '$scope',
        '$rootScope',
        '$windowInstance',
        'fractionsService',
        'formTypes',
        'currentFormType',
        'fraction'
    ];

    function FractionDetailsModalController(
        $q,
        $scope,
        $rootScope,
        $windowInstance,
        fractionsService,
        formTypes,
        currentFormType,
        fraction
    ) {

        $scope.saveAndClose = saveAndClose;
        $scope.closeWindow = closeWindow;

        $scope.fraction = fraction != null ? { ...fraction } : {};

        //Event handlers
        function saveAndClose(event) {
            event.preventDefault();

            if ($scope.validator.validate()) {
                save().then(function (result) {
                    $windowInstance.close(result);
                    $('#fractionsGrid').getKendoGrid().dataSource.read();
                });
            }
        }

        function closeWindow(result) {
            $windowInstance.close(result);
        }

        //Private functions
        function getSaveFunction(currentFormType) {
            if (currentFormType === formTypes.edit) {
                return fractionsService.updateFraction.bind(fractionsService);
            }

            return fractionsService.addFraction.bind(fractionsService);
        }

        function save() {
            var deferred = $q.defer();
            
            $rootScope.$broadcast('showGlobalBusyIndicator', 'fractionIndicator');

            var saveFunction = getSaveFunction(currentFormType);

            saveFunction($scope.fraction)
                .then(result => deferred.resolve(result),
                    () => deferred.reject())
                .finally(() => $rootScope.$broadcast('hideBusyIndicator', 'fractionIndicator'));

            return deferred.promise;
        }

    }
})();
