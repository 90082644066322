(function () {
    angular.module('UndergroundWebApp').directive('mapLayersPanel', mapLayersPanel);

    mapLayersPanel.$inject = [
        '$rootScope',
        'globalEvents',
        'mapService'
    ];

    function mapLayersPanel(
        $rootScope,
        globalEvents,
        mapService
    ) {
        return {
            restrict: 'AE',
            replace: true,
            templateUrl: 'app/main/directives/map-layers-panel.html',
            link: function (scope, element) {
                $rootScope.$on(globalEvents.layerVisibilityChanged, (_scope, visibilityList) => {
                    Object.entries(visibilityList).forEach(([layerName, visible]) => {
                        const button = element.find(`.dropdown-item[name='${layerName}']`);
                        if (visible) {
                            button.addClass('active');
                        } else {
                            button.removeClass('active');
                        }
                    });
                });

                scope.toggleLayer = function (event) {
                    event.stopPropagation();
                    mapService.toggleLayerVisibility(event.target.name);
                };
            }
        };
    }

})();

