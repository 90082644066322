(function () {

    angular.module('UndergroundWebApp').factory('userService', userService);

    userService.$inject = ['$http'];

    function userService($http) {
        return {
            addUser,
            getUsers,
            updateUser
        };

        function addUser(user) {
            return $http.post('api/user/', user)
                .then(result => result.data);
        }

        function getUsers() {
            return $http.get('api/user')
                .then(result => result.data);
        }

        function updateUser(user) {
            return $http.put('api/user/', user)
                .then(result => result.data);
        }
    }
}());
