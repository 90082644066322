(function () {
    'use strict';

    angular.module('UndergroundWebApp').factory('sensorApiAreaService', sensorApiAreaService);

    sensorApiAreaService.$inject = ['$http'];

    function sensorApiAreaService($http) {
        var service = {
            getAreas,
            getArea,
            updateArea,
            createArea,
            deleteArea,
            attachVehicle,
            detachVehicle,
        };

        function getAreas() {
            return $http.get('/api/sensorApiArea')
                .then((result) => result.data);
        }

        function getArea(areaId) {
            return $http.get(`/api/sensorApiArea/${areaId}`)
                .then((result) => result.data);
        }

        function updateArea(area) {
            return $http.put('/api/sensorApiArea', area)
                .then((result) => result.data);
        }

        function createArea(area) {
            return $http.post('/api/sensorApiArea', area)
                .then((result) => result.data);
        }

        function deleteArea(areaId) {
            return $http.delete(`/api/sensorApiArea/${areaId}`)
                .then((result) => result.data);
        }

        function attachVehicle(areaId, vehicleId) {
            return $http.put(`/api/sensorApiArea/${areaId}/vehicles/${vehicleId}`)
                .then((result) => result.data);
        }

        function detachVehicle(areaId, vehicleId) {
            return $http.delete(`/api/sensorApiArea/${areaId}/vehicles/${vehicleId}`)
                .then((result) => result.data);
        }

        return service;
    }
})();
