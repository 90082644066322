(function () {
    'use strict';

    angular.module('UndergroundWebApp').controller('LocationDetailsController', LocationDetailsController);

    LocationDetailsController.$inject = [
        '$rootScope',
        '$scope',
        '$state',
        '$stateParams',
        'globalEvents',
        'locationsService',
        's2wClusterLayerFactory',
        'mapService',
        'mapUtility',
        'serviceUrls',
        'formTypes',
        '$kWindow',
        'tabstripUtility',
    ];

    function LocationDetailsController(
        $rootScope,
        $scope,
        $state,
        $stateParams,
        globalEvents,
        locationsService,
        s2wClusterLayerFactory,
        mapService,
        mapUtility,
        serviceUrls,
        formTypes,
        $kWindow,
        tabstripUtility,
    ) {
        var locationId = $stateParams.locationId;

        $scope.openUpdateLocation = openUpdateLocation;
        $scope.openNewContainerModal = openNewContainerModal;
        $scope.location = null;
        $scope.locationsFilter = $stateParams.locationsFilter;
        $scope.hasContainers = hasContainers;
        $scope.isLocationDeletable = isLocationDeletable;
        $scope.deleteLocation = deleteLocation;

        $scope.tabStripOptions = {
            animation: false,
            select: tabstripUtility.handleTabSelected,
            dataTextField: 'name',
            dataContentUrlField: 'contentUrl',
            dataSource: [
                {
                    name: 'Info',
                    contentUrl: 'app/location/info/location-info-tab-view.html',
                    state: 'main.locationDetails.info',
                },
                {
                    name: 'Fyll (%)',
                    contentUrl: 'app/location/statistics/location-statistics-tab-view.html',
                    state: 'main.locationDetails.statistics',
                },
                {
                    name: 'Logg',
                    contentUrl: 'app/location/log/location-log-tab-view.html',
                    state: 'main.locationDetails.log',
                },
                {
                    name: 'Statistikk',
                    contentUrl: 'app/location/powerbi/location-powerbi-tab-view.html',
                    state: 'main.locationDetails.powerbi',
                },
            ],
        };

        initController();

        function initController() {
            $scope.$watch(() => $state.current.name, (state) => {
                tabstripUtility.changeTabByState($scope.locationDetailsTab, state);
            });

            loadLocation();
        };

        function setExternalLink() {
            // Reen externalId is at least 5 characters long while S2W group id is below 10000
            if ($scope.location.externalId && $scope.location.externalId.length > 4) {
                $scope.externalLink = serviceUrls.reenHubLocation + $scope.location.externalId;
            }
        }

        function loadLocation() {
            locationsService.getLocation(locationId).then(function (location) {
                $scope.location = location;
                zoomToLocation(location.latitude, location.longitude, 16);
                setExternalLink();
            });
        }

        function zoomToLocation(latitude, longitude, zoomLevel) {
            if (latitude && longitude) {
                s2wClusterLayerFactory.initialized().then(function () {
                    var point = mapUtility.convertToUTM33N(latitude, longitude);
                    mapService.zoomToLocation({
                        x: point.X,
                        y: point.Y
                    }, zoomLevel);
                    $rootScope.$broadcast(globalEvents.redrawCluster);
                }, function () {
                });
            }
        }

        function openUpdateLocation(location) {
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: true,
                    title: 'Hentested',
                    resizable: true,
                    height: 680,
                    width: 400,
                    visible: false
                },
                templateUrl: 'app/location/location-details-modal-view.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'LocationDetailsModalController',
                resolve: {
                    currentFormType: () => formTypes.edit,
                    location: () => location
                }
            });

            windowInstance.result.then(function (location) {
                if (location) {
                    $scope.location = location;
                }
            });
        }

        function openNewContainerModal() {
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: 'Container',
                    resizable: false,
                    height: 600,
                    width: 980,
                    visible: false
                },
                templateUrl: 'app/containerOverviewV2/views/sensor-api-container-details-modal-view.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'SensorApiContainerDetailsModalController',
                resolve: {
                    currentFormType: function () {
                        return formTypes.add;
                    },
                    containerId: function () {
                        return null;
                    },
                    updateCallbacks: function () {
                        return null;
                    },
                    locationId: function () {
                        return $scope.location && $scope.location.id;
                    }
                }
            });

            windowInstance.result.then(function () {
                $rootScope.$broadcast(globalEvents.refreshLocationContainers);
            });
        }

        function hasContainers() {
            return $scope.location
                && $scope.location.containers
                && $scope.location.containers.length !== 0;
        }

        function isLocationDeletable() {
            return $scope.location
                && $scope.location.containers
                && $scope.location.containers.length === 0
                && $scope.location.externalWMPlaceNr === null;
        }

        function deleteLocation() {
            if (!$scope.location) {
                return;
            }

            if (!hasContainers() && $scope.location.externalWMPlaceNr !== null) {
                if (!confirm(
                    'Dette hentestedet ('
                    + $scope.location.externalWMPlaceNr
                    + ') er knyttet til eksternt kundesystem, er du sikker på at du ønsker å slette?')) {
                    return;
                }
            } else if (!confirm('Er du sikker på at du vil slette dette hentestedet?')) {
                return;
            }

            locationsService.deleteLocation($scope.location.id).then(() =>
                $state.go('main.locations', { filter: $scope.locationsFilter })
            );
        }
    }
})();
