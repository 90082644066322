(function () {
    'use strict';

    var undergroundWebApp = angular.module('UndergroundWebApp');

    undergroundWebApp.constant('formTypes', {
        add: 'add',
        edit: 'edit'
    });

    undergroundWebApp.provider('containerTypeUnits', function() {
        this.$get = ['$translate', function($translate) {
            return [
                { Id: 0, Name: 'Liter', LocalizedName: $translate.instant('CONTSANT_CONTAINER_TYPE_UNITS_LITER') }
            ];
        }];
    });

    undergroundWebApp.provider('containerTypeStatuses', function() {
        this.$get = ['$translate', function($translate) {
            return {
                     active: { Id: 0, Name: 'Active', LocalizedName: $translate.instant('CONTSANT_STATUS_ACTIVE') },
                     deleted: { Id: 99, Name: 'Deleted', LocalizedName: $translate.instant('CONTSANT_STATUS_DELETED') }
                 };
        }];
    });

    undergroundWebApp.provider('deviceTypes', function() {
        this.$get = ['$translate', function($translate) {
            return [
                { id: 0, name: $translate.instant('CONTSANT_DEVICE_TYPES_ACCESS_CONTROL') },
                { id: 1, name: $translate.instant('CONTSANT_DEVICE_TYPES_LEVEL_MEASUREMENT') },
                { id: 2, name: $translate.instant('CONTSANT_DEVICE_TYPES_ACCESS_CONTROL_AND_LEVEL_MEASUREMENT')  }
            ];
        }];
    });
   
    undergroundWebApp.provider('fractionStatuses', function() {
        this.$get = ['$translate', function($translate) {
            return {
                     active: { Id: 0, Name: 'Active', LocalizedName: $translate.instant('CONTSANT_STATUS_ACTIVE') },
                     deleted: { Id: 99, Name: 'Deleted', LocalizedName: $translate.instant('CONTSANT_STATUS_DELETED') }
                 };
        }];
    });

    undergroundWebApp.provider('deviationStatuses', function() {
        this.$get = ['$translate', function($translate) {
            return {
                     active: { Id: 0, Name: 'Active', LocalizedName: $translate.instant('CONTSANT_STATUS_ACTIVE') },
                     inactive: { Id: 1, Name: 'Inactive', LocalizedName: $translate.instant('CONTSANT_STATUS_INACTIVE') },
                     invisible: { Id: 2, Name: 'Invisible', LocalizedName: $translate.instant('CONTSANT_STATUS_INVISIBLE') },
                     deleted: { Id: 99, Name: 'Deleted', LocalizedName: $translate.instant('CONTSANT_STATUS_DELETED') }
                 };
        }];
    });

    undergroundWebApp.provider('deviationAlertRecurrenceTypes', function() {
        this.$get = ['$translate', function($translate) {
            return {
                0: { id: 0, Name: 'Continuous run', LocalizedName: $translate.instant('ADMINISTRATION_DEVIATION_RECURRENCE_TYPE_CONTINUS_RUN') },
                1: { id: 1, Name: 'Scheduled alert', LocalizedName: $translate.instant('ADMINISTRATION_DEVIATION_RECURRENCE_TYPE_SCHEDULED_ALERT') }
            };
        }];
    });

    undergroundWebApp.provider('daysOfTheWeek', function() {
        this.$get = ['$translate', function($translate) {
            return [
                $translate.instant('ADMINISTRATION_DEVIATION_DAYS_OF_WEEK_MONDAY'),
                $translate.instant('ADMINISTRATION_DEVIATION_DAYS_OF_WEEK_TUESDAY'),
                $translate.instant('ADMINISTRATION_DEVIATION_DAYS_OF_WEEK_WEDNESDAY'),
                $translate.instant('ADMINISTRATION_DEVIATION_DAYS_OF_WEEK_THURSDAY'),
                $translate.instant('ADMINISTRATION_DEVIATION_DAYS_OF_WEEK_FRIDAY'),
                $translate.instant('ADMINISTRATION_DEVIATION_DAYS_OF_WEEK_SATURDAY'),
                $translate.instant('ADMINISTRATION_DEVIATION_DAYS_OF_WEEK_SUNDAY')
            ];
        }];
    });

    undergroundWebApp.provider('fuelTypes', function() {
        this.$get = ['$translate', function($translate) {
            return [
                { id: 0, name: $translate.instant('CONTSANT_GAS') },
                { id: 1, name: $translate.instant('CONTSANT_DIESEL') },
                { id: 2, name: $translate.instant('CONTSANT_HYBRID')  },
                { id: 3, name: $translate.instant('CONTSANT_ELECTRIC')  },
            ];
        }];
    });

    undergroundWebApp.constant('mapTypes', {
        WGS84: 'WGS84',
        UTM33N: 'UTM33N'
    });

    const viewModes = {
        map: 'map',
        split: 'split',
        full: 'full'
    };

    undergroundWebApp.constant('viewModes', viewModes);

    const splitViewMode = { viewMode: viewModes.split },
        fullViewMode = { viewMode: viewModes.full };

    undergroundWebApp.constant('pageSpecificDisplays', {
        'main': { viewMode: viewModes.map, isPriority: true },
        'main.administration': splitViewMode,
        'main.area': splitViewMode,
        'main.areaDetails': splitViewMode,
        'main.containerOverview': splitViewMode,
        'main.containerOverviewV2': fullViewMode,
        'main.locations': splitViewMode,
        'main.locationDetails': splitViewMode,
        'main.reports': splitViewMode,
        'main.reports.info': splitViewMode,
        'main.reports.containerlogs': splitViewMode,
        'main.reports.municipalityLogs': splitViewMode,
        'main.reports.errorStatus': splitViewMode,
        'main.reports.batteryVoltage': splitViewMode,
        'main.reports.genericReports': splitViewMode,
        'main.sensorOverview': splitViewMode,
    });

    undergroundWebApp.constant('globalEvents', {
        accessTokenExpired: 'accessTokenExpired',
        layerVisibilityChanged: 'layerVisibilityChanged',
        overviewContainersChanged: 'overviewContainersChanged',
        redrawCluster: 'redrawCluster',
        refreshLocationContainers: 'refreshLocationContainers',
        zoomToLocation: 'zoomToLocation',
        $stateChangeStart: '$stateChangeStart',
        $stateChangeSuccess: '$stateChangeSuccess',
    });
})();
