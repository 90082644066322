(function () {
    'use strict';

    angular.module('UndergroundWebApp').controller('DeviationReportModalController', DeviationReportModalController);

    DeviationReportModalController.$inject = [
        '$q',
        '$scope',
        '$rootScope',
        '$windowInstance',
        '$translate',
        'deviationReportService',
        'formTypes',
        'currentFormType',
        'deviationAlert',
        'deviations',
        'deviationStatuses',
        'deviationAlertRecurrenceTypes',
        'userService',
        'reportQueries',
        'daysOfTheWeek'
    ];

    function DeviationReportModalController(
        $q,
        $scope,
        $rootScope,
        $windowInstance,
        $translate,
        deviationReportService,
        formTypes,
        currentFormType,
        deviationAlert,
        deviations,
        deviationStatuses,
        deviationAlertRecurrenceTypes,
        userService,
        reportQueries,
        daysOfTheWeek
    ) {
        $scope.init = init;
        $scope.typeChanged = typeChanged;
        $scope.reportQueryTypeChanged = reportQueryTypeChanged;
        $scope.operatorChanged = operatorChanged;
        $scope.saveAndClose = saveAndClose;
        $scope.deleteAndClose = deleteAndClose;
        $scope.closeWindow = closeWindow;
        $scope.addEmail = addEmail;
        $scope.removeEmail = removeEmail;
        $scope.addDeviation = addDeviation;
        $scope.removeDeviation = removeDeviation;
        $scope.toggleScheduledDay = toggleScheduledDay;
        $scope.togglExceptionDay = togglExceptionDay;
        $scope.handleShowEmailAddressesSearchBox = handleShowEmailAddressesSearchBox;
        $scope.handleShowDeviationsSearchBox = handleShowDeviationsSearchBox;
        $scope.validateForm = validateForm;

        $scope.statuses = Object.keys(deviationStatuses)
            .filter(status => status !== 'invisible' && status !== 'deleted')
            .map(key => deviationStatuses[key]);

        $scope.reportTypes = [
            { name: $translate.instant('ADMINISTRATION_DEVIATION_DEAVIATION_BASED'), value: 0 },
            { name: $translate.instant('ADMINISTRATION_DEVIATION_PARAMETER_BASED'), value: 1 }
        ];

        $scope.reportStatusField = [
            { name: $translate.instant('REPORTS_BATTERY_VOLTAGE_CONTROLLER_TABLE_HEADER_VOLT'), value: 0 },
            { name: $translate.instant('STATISTICS_CONTROLLER_CHART_TEMPERATURE'), value: 1 },
            { name: 'Rssi', value: 2 },
            { name: $translate.instant('ADMINISTRATION_DEVIATION_FILL'), value: 3 },
            { name: 'Solenoid', value: 4 },
            { name: $translate.instant('ADMINISTRATION_DEVIATION_USAGE_COUNT'), value: 5 }
        ];

        $scope.reportStatusOperator = [
            { name: 'LessThan', value: 0, operator: '<' },
            { name: 'GreaterThan', value: 1, operator: '>' },
            { name: 'EqualTo', value: 2, operator: '=' },
            { name: 'NotEqualTo', value: 3, operator: '<>' },
            { name: 'LessThanOrEqualTo', value: 4, operator: '<=' },
            { name: 'GreaterThanOrEqualTo', value: 5, operator: '>=' },
            { name: 'IsNull', value: 6, operator: 'IS NULL' },
            { name: 'IsNotNull', value: 7, operator: 'IS NOT NULL' }
        ];

        
        $scope.recurrenceTypes = deviationAlertRecurrenceTypes;
        $scope.daysOfTheWeek = daysOfTheWeek;
        
        $scope.deviations = formatDeviationsForInit(deviations);
        $scope.deviationAlert = deviationAlert != null ? formatForInit(deviationAlert) : getAnEmptyJob();
        $scope.isEditMode = deviationAlert != null;

        $scope.deviationAlert.selectedReportType = $scope.deviationAlert.selectedReportType ? $scope.deviationAlert.selectedReportType : $scope.reportTypes[0].value
                
        $scope.getUsersPromise = userService.getUsers();
        $scope.emailAddresses = [];

        $scope.reportQueries = reportQueries;
        $scope.selectedType = reportQueries.find(t => t.id === $scope.deviationAlert.queryId)
            ?? ($scope.deviationAlert.queryId ? reportQueries[0] : null);
        $scope.addedDeviations = [];

        $scope.isPeriodRangeVisible = ($scope.deviationAlert.startDate || $scope.deviationAlert.endDate) ? true : false;

        $scope.validation = {
            name: true,
            status: true,
            queryId: true,
            emailAddresses: true,
            deviations: true,
            recurrence: true,
            startDate: true,
            endDate: true,

            //Continuous
            firstDailyRun: true,
            lastDailyRun: true,
            exceptions: true,

            //Scheduled
            daysOfWeek: true,
            runOnceADayAtTime: true,

            //Parameters
            attribute: true,
            operator: true,
            statusValue: true
        };

        $scope.emailAddressesSearchBoxOptions = {
            noDataTemplate: $translate.instant("G_NO_DATA"),
            filter: 'contains',
            highlightFirst: true,
            clearButton: false,
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: readEmailAddresses
                }
            })
        }

        $scope.deviationsSearchBoxOptions = {
            noDataTemplate: $translate.instant("G_NO_DATA"),
            filter: 'contains',
            highlightFirst: true,
            clearButton: false,
            dataTextField: 'textField',
            placeholder: $translate.instant('ADMINISTRATION_DEVIATION_SELECT_DEVIATION'),
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: (e) => e.success($scope.deviations)
                }
            })
        }

        //Event handlers
        function init() {

            if ($scope.deviationAlert.deviations.length) {
                $scope.addedDeviations = $scope.deviations.filter(deviation => deviationAlert.deviations.includes(deviation.id));
            }

            reportQueryTypeChanged($scope.deviationAlert.selectedReportType);

            const formEl = document.getElementById('deviation-alert-modal-form');
            if (formEl) {
                formEl.addEventListener('keydown', preventSubmittingOnEnterKey);
            }
        }

        function typeChanged(deviationAlertTypeId) {
            $scope.validation.queryId = true;
            $scope.selectedType = reportQueries.find(t => t.id === deviationAlertTypeId);
        }

        function operatorChanged() {
            $scope.validation.operator = true
            if ($scope.deviationAlert.selectedReportStatusOperator === 6 || $scope.deviationAlert.selectedReportStatusOperator === 7) {
                $scope.deviationAlert.statusValue = null;
            }
            console.log($scope.deviationAlert)
        }

        function reportQueryTypeChanged(selectedReportType) {
            $scope.reportQueries = reportQueries.filter(t => t.deviationReportType === selectedReportType);
        }

        function toggleScheduledDay(dayIndex) {
            $scope.validation.daysOfWeek = true;
            $scope.deviationAlert.daysOfWeek[dayIndex] = Number($scope.deviationAlert.daysOfWeek[dayIndex]) === 0 ? 1 : 0;
        }

        function togglExceptionDay(dayIndex) {
            $scope.validation.exceptions = true;
            $scope.deviationAlert.exceptions[dayIndex] = Number($scope.deviationAlert.exceptions[dayIndex]) === 0 ? 1 : 0;
        }

        function addEmail(isParametered) {
            const input = !isParametered ? $scope.emailAddressesSearchBox : $scope.emailAddressesSearchBox1;


            if (!input) return;

            const inputValue = input.value() ?? '';

            if (!inputValue.length || !input || !validateEmail(inputValue)) {
                input.value('');
                return;
            }
            if (!$scope.deviationAlert.emailAddresses) {
                $scope.deviationAlert.emailAddresses = [];
            }
            if (!$scope.deviationAlert.emailAddresses.includes(inputValue)) {
                $scope.deviationAlert.emailAddresses.push(inputValue);
            }

            $scope.validation.emailAddresses = true;

            input.value('');
        }

        function addDeviation() {

            const input = $scope.deviationsSearchBox;

            if (!input) return;

            const inputValue = input.value() ?? '';

            if (!inputValue.length || !input) {
                input.value('');
                return;
            }

            const deviation = $scope.deviations.find(d => d.textField === inputValue);

            if (!deviation) return;

            input.value('');

            const isAlreadyAdded = $scope.deviationAlert.deviations.find(d => d.id === deviation.id);

            if (!isAlreadyAdded && deviation) {
                $scope.addedDeviations.push(deviation);
                $scope.deviationAlert.deviations.push(deviation);
                $scope.validation.deviations = true;
            }
        }

        function removeEmail(email) {
            $scope.deviationAlert.emailAddresses = $scope.deviationAlert.emailAddresses.filter(e => e !== email);
        }

        function removeDeviation(deviation) {
            $scope.deviationAlert.deviations = $scope.deviationAlert.deviations.filter(d => d.id !== deviation.id);
            $scope.addedDeviations = $scope.addedDeviations.filter(d => d.id !== deviation.id);
        }

        function handleShowEmailAddressesSearchBox(isParametered) {
            const input = !isParametered ? $scope.emailAddressesSearchBox : $scope.emailAddressesSearchBox1;

            if (!input) return;

            input.search();
        }

        function handleShowDeviationsSearchBox() {
            const input = $scope.deviationsSearchBox;

            if (!input) return;

            input.search();
        }

        function deleteAndClose(event) {
            event.preventDefault();

            const confirmText = $translate.instant('ADMINISTRATION_DEVIATION_DIALOG_CONFIRM_DELETE');

            if (confirm(`${confirmText} ${$scope.deviationAlert.name}?`)) {
                deviationReportService.deleteDeviationReport($scope.deviationAlert.id)
                .finally(function () {
                    $windowInstance.close();
                    $('#deviationReportsGrid').getKendoGrid().dataSource.read();
                });
            }
        }

        function saveAndClose(event) {
            event.preventDefault();

            const isFormValidated = validateForm();

            if (isFormValidated) {
                save().finally(function (result) {
                    $windowInstance.close(result);
                    $('#deviationReportsGrid').getKendoGrid().dataSource.read();
                });
            }
        }

        function closeWindow(result) {
            const formDeviation = formatForInit(formatBeforeSave($scope.deviationAlert));
            const parameterDeviation = formatForInit(deviationAlert);
            const newDeviation = formatForInit(formatBeforeSave(getAnEmptyJob()));
            const editChanges = deviationAlert && !_.isEqual(formDeviation, parameterDeviation);
            const modificationsToNewDeviation = !deviationAlert && !_.isEqual(formDeviation, newDeviation);

            if (editChanges || modificationsToNewDeviation) {
                if (window.confirm($translate.instant('G_EXIT_CONFIRMATION'))) {
                    $windowInstance.close(result);
                }
            } else {
                $windowInstance.close(result);
            }
        }

        //Private functions
        function getAnEmptyJob() {
            return {
                name: '',
                queryId: null,
                lastSuccessfullRun: null,
                status: ($scope.statuses && $scope.statuses[0] && $scope.statuses[0].id) || 0,
                emailAddresses: [],
                deviations: [],
                subject: '',
                body: '',
                sendEmptyReport: false,
                recurrence: null,
                startDate: null,
                endDate: null,
                intervalInMinutes: 15,
                firstDailyRun: new Date('2000-01-01T08:00:00'),
                lastDailyRun: new Date('2000-01-01T17:00:00'),
                exceptions: [0, 0, 0, 0, 0, 1, 1],
                daysOfWeek: [1, 1, 1, 1, 1, 0, 0],
                runOnceADayAtTime: new Date('2000-01-01T06:00:00'),
            };
        }

        function validateForm() {
            const dateCorrectedFirstDailyRun = new Date(`2000-01-01T${moment($scope.deviationAlert.firstDailyRun).format('HH:mm:00')}`);
            const dateCorrectedLastDailyRun = new Date(`2000-01-01T${moment($scope.deviationAlert.lastDailyRun).format('HH:mm:00')}`);

            //Values should be true if fields are valid
            $scope.validation = {
                name: !!$scope.deviationAlert.name,
                status:
                    !!Number($scope.deviationAlert.status) ||
                    Number($scope.deviationAlert.status) === 0,
                queryId:
                    !!$scope.deviationAlert.queryId ||
                    $scope.deviationAlert.queryId == 0,
                emailAddresses: !!$scope.deviationAlert.emailAddresses.length,
                deviations:
                    !!$scope.deviationAlert.deviations.length ||
                    $scope.deviationAlert.selectedReportType == 1,
                recurrence: !!$scope.deviationAlert.recurrence,
                startDate:
                    (!$scope.deviationAlert.startDate &&
                        !$scope.deviationAlert.endDate) ||
                    ($scope.deviationAlert.startDate &&
                        $scope.deviationAlert.endDate),
                endDate:
                    (!$scope.deviationAlert.startDate &&
                        !$scope.deviationAlert.endDate) ||
                    ($scope.deviationAlert.startDate &&
                        $scope.deviationAlert.endDate &&
                        $scope.deviationAlert.startDate <
                            $scope.deviationAlert.endDate),

                //Continuous
                firstDailyRun:
                    $scope.deviationAlert.recurrence != 0
                        ? true
                        : !!$scope.deviationAlert.firstDailyRun,
                lastDailyRun:
                    $scope.deviationAlert.recurrence != 0
                        ? true
                        : $scope.deviationAlert.lastDailyRun &&
                          dateCorrectedLastDailyRun >
                              dateCorrectedFirstDailyRun,
                exceptions:
                    $scope.deviationAlert.recurrence != 0
                        ? true
                        : $scope.deviationAlert.exceptions.some(
                              (day) => day == 0
                          ),

                //Scheduled
                daysOfWeek:
                    $scope.deviationAlert.recurrence != 1
                        ? true
                        : $scope.deviationAlert.daysOfWeek.some(
                              (day) => day == 1
                          ),
                runOnceADayAtTime:
                    $scope.deviationAlert.recurrence != 1
                        ? true
                        : !!$scope.deviationAlert.runOnceADayAtTime,

                // Parametered
                attribute:
                    !!$scope.deviationAlert.selectedReportStatusField ||
                    $scope.deviationAlert.selectedReportStatusField >= 0 ||
                    $scope.deviationAlert.selectedReportType == 0,
                operator:
                    !!$scope.deviationAlert.selectedReportStatusOperator ||
                    $scope.deviationAlert.selectedReportStatusOperator == 0 ||
                    $scope.deviationAlert.selectedReportType == 0,
                statusValue:
                    !!$scope.deviationAlert.statusValue ||
                    $scope.deviationAlert.statusValue == 0 ||
                    $scope.deviationAlert.selectedReportStatusOperator == 6 ||
                    $scope.deviationAlert.selectedReportStatusOperatorr == 7 ||
                    $scope.deviationAlert.selectedReportType == 0,
            };

            const result = Object.values($scope.validation).every(value => value);
            return result;
        }

        function readEmailAddresses(e) {
            $scope.getUsersPromise
                .then(function (users) {
                    const emails = users.map(user => user.userName);
                    const sortedEmails = emails.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
                    e.success(sortedEmails);
                })
                .catch(function () {
                    e.success([]);
                });
        }

        function preventSubmittingOnEnterKey(event) {
            if (event.keyCode === 13 && event.target.nodeName !== 'TEXTAREA' && event.target.nodeName !== 'BUTTON') {
                event.preventDefault();
            }
        }

        function getSaveFunction(currentFormType) {
            if (currentFormType === formTypes.edit) {
                return deviationReportService.updateDeviationReport.bind(deviationReportService);
            }

            return deviationReportService.addDeviationReport.bind(deviationReportService);
        }

        function formatDeviationsForInit(deviations) {
            if (!deviations || !deviations.length) return [];

            const sortedDeviations = deviations.sort((a, b) => a.description.localeCompare(b.description));
            const textFieldAddedDeviations = sortedDeviations.map(deviation => ({ ...deviation, textField: `${deviation.description} (${deviation.externalId})` }));

            return textFieldAddedDeviations;
        }

        function formatForInit(deviationAlert) {
            if (!deviationAlert) return getAnEmptyJob();

            let statusFieldValue = deviationAlert.deviationReportParameters[0]?.field;
            let statusOperatorValue = deviationAlert.deviationReportParameters[0]?.operator;
            let statusValue = deviationAlert.deviationReportParameters[0]?.value;

            const formattedDeviation = {
                ...deviationAlert,
                deviations: deviationAlert.deviations
                    ? $scope.deviations.filter((d) =>
                          deviationAlert.deviations.includes(d.id)
                      )
                    : [],
                emailAddresses: Array.isArray(deviationAlert.emailAddresses)
                    ? [...new Set(deviationAlert.emailAddresses)]
                    : deviationAlert.emailAddresses.split(";") || [],
                recurrence: String(deviationAlert.recurrence),
                firstDailyRun: new Date(
                    `2000-01-01T${deviationAlert.firstDailyRun}`
                ),
                lastDailyRun: new Date(
                    `2000-01-01T${deviationAlert.lastDailyRun}`
                ),
                runOnceADayAtTime: new Date(
                    `2000-01-01T${deviationAlert.runOnceADayAtTime}`
                ),
                daysOfWeek:
                    deviationAlert.recurrence == 1
                        ? deviationAlert.daysOfWeek
                        : [1, 1, 1, 1, 1, 0, 0],
                exceptions:
                    deviationAlert.recurrence == 0
                        ? deviationAlert.exceptions
                        : [0, 0, 0, 0, 0, 1, 1],
                sendEmptyReport: deviationAlert.sendEmptyReport ?? false,
                startDate: deviationAlert.startDate
                    ? new Date(deviationAlert.startDate)
                    : null,
                endDate: deviationAlert.endDate
                    ? new Date(deviationAlert.endDate)
                    : null,
                };

                if (deviationAlert.deviationReportParameters?.length > 0) {
                    formattedDeviation.selectedReportType =
                        deviationAlert.deviationReportParameters?.length > 0
                            ? $scope.reportTypes[1].value
                            : $scope.reportTypes[0].value;
                    formattedDeviation.selectedReportStatusField = (statusFieldValue === 0 || statusFieldValue) ? statusFieldValue : null;
                    formattedDeviation.selectedReportStatusOperator = (statusOperatorValue === 0 || statusOperatorValue) ? statusOperatorValue : null;
                    formattedDeviation.statusValue = (statusValue === 0 || statusValue) ? statusValue : null;
                }               

            return formattedDeviation;
        }

        function formatBeforeSave(deviationAlert) {
            const formattedDeviation = {
                ...deviationAlert,
                deviations: deviationAlert.deviations.map((d) => d.id),
                recurrence: Number(deviationAlert.recurrence),
                status: Number(deviationAlert.status),
                emailAddresses: deviationAlert.emailAddresses.join(";"),
                firstDailyRun:
                    deviationAlert.firstDailyRun &&
                    moment(deviationAlert.firstDailyRun).format("HH:mm:00"),
                lastDailyRun:
                    deviationAlert.lastDailyRun &&
                    moment(deviationAlert.lastDailyRun).format("HH:mm:00"),
                intervalInMinutes: Number(deviationAlert.intervalInMinutes),
                runOnceADayAtTime:
                    deviationAlert.runOnceADayAtTime &&
                    moment(deviationAlert.runOnceADayAtTime).format("HH:mm:00"),      
                deviationReportParameters: [],
            };
            if (deviationAlert.selectedReportType) {
                formattedDeviation.deviationReportParameters = [
                    {
                        field: deviationAlert.selectedReportStatusField,
                        operator: deviationAlert.selectedReportStatusOperator,
                        value: deviationAlert.statusValue,
                    },
                ];
            }

            return formattedDeviation;
        }

        function save() {
            const deferred = $q.defer();

            $rootScope.$broadcast('showGlobalBusyIndicator', 'deviationIndicator');

            const saveFunction = getSaveFunction(currentFormType);
            const formattedDeviation = formatBeforeSave($scope.deviationAlert);

            saveFunction(formattedDeviation)
                .then(result => deferred.resolve(result),
                    () => deferred.reject())
                .finally(() => $rootScope.$broadcast('hideBusyIndicator', 'deviationIndicator'));

            return deferred.promise;
        }

        function validateEmail(email) {
            return String(email)
                .toLowerCase()
                .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                );
        };
    }
})();
