(function () {
    angular.module('UndergroundWebApp').factory('displayService', displayService);

    displayService.$inject = [
        '$localForage',
        '$rootScope',
        'viewModes',
        'authService'
    ];

    function displayService(
        $localForage,
        $rootScope,
        viewModes,
        authService
    ) {
        const userViewModeKey = 'userViewModeKey';

        let displayService = {
            applyPageViewMode,
            getUserViewMode,
            setUserViewMode,
        };

        return displayService;

        /**
         * Apply page specific view mode if there is no
         * user specified view mode.
         */
        function applyPageViewMode(pageViewMode, isPriority) {
            if (!pageViewMode) return;

            getUserViewMode().then(currentViewMode => {
                if (!currentViewMode || isPriority) {
                    setViewMode(pageViewMode);
                } else {
                    setViewMode(currentViewMode);
                }
            });
        }

        function getUserViewMode() {
            let compositeKey = getCompositeKey(userViewModeKey);

            return $localForage.getItem(compositeKey);
        }

        function setUserViewMode(viewMode) {
            let compositeKey = getCompositeKey(userViewModeKey);

            return $localForage.setItem(compositeKey, viewMode)
                .then(() => setViewMode(viewMode));
        }

        //Private helpers
        function getCompositeKey(key) {
            let authData = authService.getAuthData;

            return authData.username + '_' + key;
        }

        function setViewMode(viewMode) {
            if (viewMode === viewModes.map) {
                $rootScope.$broadcast('setSplitterSize', {
                    left: '100%',
                    right: '0%'
                });
            } else if (viewMode === viewModes.split) {
                $rootScope.$broadcast('setSplitterSize', {
                    left: '50%',
                    right: '50%'
                });
            } else if (viewMode === viewModes.full) {
                $rootScope.$broadcast('setSplitterSize', {
                    left: '0%',
                    right: '100%'
                });
            }
        }
    }
})();
