(function () {
    'use strict';

    angular.module('UndergroundWebApp').controller('ContainerLogsController', ContainerLogsController);

    ContainerLogsController.$inject = [
        '$q',
        '$timeout',
        '$scope',
        '$rootScope',
        '$kWindow',
        'kendoGridUtility',
        'containerService',
        'locationsService',
        'municipalityService',
        'sensorApiAreaService',
        'filterStateService',
        '$translate'
    ];

    const containerLogsTableVersion = 1.0;

    function ContainerLogsController(
        $q,
        $timeout,
        $scope,
        $rootScope,
        $kWindow,
        kendoGridUtility,
        containerService,
        locationsService,
        municipalityService,
        sensorApiAreaService,
        filterStateService,
        $translate
    ) {
        $scope.dateRange = {};

        $scope.openMediaModal = openMediaModal;
        $scope.loadContainerLogs = loadContainerLogs;
        $scope.exportToExcel = exportToExcel;
        $scope.exportAsPdf = exportAsPdf;

        $scope.selectedMunicipalities = [];

        $scope.selectedMunicipalyy = { code: '' };
        $scope.filterMunicipalitiesData = {
            municipalities: [],
            externalSystems: [],
        };

        $scope.municipalityfilter = filterStateService.getStateFromQueryString('filter', getMunicipalityEmptyFilter());

        $scope.municipalitiesDropdownOptions = {
            settings: {
                externalIdProp: '',
            },
            events: {
                onSelectionChanged: handleMunicipalityFilterChanged,
            },
        }

        $scope.dropdownMunicipalityTranslations = {
            checkAll: $translate.instant("G_CHECKALL"),
            uncheckAll: $translate.instant("G_UNCHECK_ALL"),
            buttonDefaultText: $translate.instant("REPORTS_CONTAINER_LOGS_CONTROLLER_MUNINCIPALITY_DDL_BUTTON_DEFAULT_TEXT"),
            dynamicButtonTextSuffix: $translate.instant("G_DYNAMIC_BTN_TEXT_SUFFIX")
        };

        $scope.selectedAreas = [];
        $scope.allContainerLogInTimeSlot = [];

        $scope.selectedArea = { code: '' };
        $scope.filterData = {
            areas: [],
            externalSystems: [],
        };

        $scope.filter = filterStateService.getStateFromQueryString('filter', getEmptyFilter());

        $scope.areasDropdownOptions = {
            settings: {
                externalIdProp: '',
            },
            events: {
                onSelectionChanged: handleAreaFilterChanged,
            },
        }

        $scope.dropdownTranslations = {
            checkAll: $translate.instant("G_CHECKALL"),
            uncheckAll: $translate.instant("G_UNCHECK_ALL"),
            buttonDefaultText: $translate.instant("REPORTS_CONTAINER_LOGS_CONTROLLER_DDL_BUTTON_DEFAULT_TEXT"),
            dynamicButtonTextSuffix: $translate.instant("G_DYNAMIC_BTN_TEXT_SUFFIX")
        };

        const persistOptions = kendoGridUtility.persistOptions(containerLogsTableVersion);

        $scope.containerLogsGridOptions = {
            dataSource: {
                data: [],
                sort: { field: 'time', dir: 'desc' },
                pageSize: 70,
            },
            groupable: true,
            sortable: true,
            filterable: true,
            resizable: true,
            columnMenu: true,
            noRecords: true,
            messages: {
                noRecords: ' '
            },
            scrollable: {
                virtual: true
            },
            excel: {
                allPages: true,
                fileName: `${$translate.instant("REPORTS_CONTAINER_LOGS_CONTROLLER_EXPORT_FILENAME")}.xlsx`,
            },
            pdf: {
                allPages: true,
                fileName: `${$translate.instant("REPORTS_CONTAINER_LOGS_CONTROLLER_EXPORT_FILENAME")}.pdf`,
            },
            columnHide: persistOptions,
            columnShow: persistOptions,
            columnResize: persistOptions,
            columnReorder: persistOptions,
            columns: [
                {
                    field: 'time',
                    title: $translate.instant("REPORTS_CONTAINER_LOGS_CONTROLLER_TABLE_HEADER_TIME"),
                    template: `#= kendo.toString(kendo.parseDate(time), "${$translate.instant("G_DEFAULT_DATE_FORMAT")}") #`,
                    filterable: false,
                    width: 145,
                },
                {
                    field: 'locationName',
                    title: $translate.instant("REPORTS_CONTAINER_LOGS_CONTROLLER_TABLE_HEADER_LOCATION_NAME"),
                    width: 200,
                    template: '<a href="#= locationHref#">#= locationName#</>',
                },
                {
                    field: 'deviceExternalId',
                    title: $translate.instant("REPORTS_CONTAINER_LOGS_CONTROLLER_TABLE_HEADER_DEVICE_EXTERNAL_ID"),
                    width: 70,
                },
                {
                    field: 'container.containerNumber',
                    title: $translate.instant("REPORTS_CONTAINER_LOGS_CONTROLLER_TABLE_HEADER_CONTAINER_NUMBER"),
                    width: 80,
                },
                {
                    field: 'fractionName',
                    title: $translate.instant("REPORTS_CONTAINER_LOGS_CONTROLLER_TABLE_HEADER_FRACTION"),
                    width: 110,
                    filterable: {
                        multi: true,
                    },
                },
                {
                    field: 'logAction',
                    title: $translate.instant("REPORTS_CONTAINER_LOGS_CONTROLLER_TABLE_HEADER_LOG_ACTION"),
                    width: 120,
                    filterable: {
                        multi: true,
                    },
                },
                {
                    field: 'comment',
                    title: $translate.instant("REPORTS_CONTAINER_LOGS_CONTROLLER_TABLE_HEADER_COMMENT"),
                    width: 160,
                },
                {
                    field: 'weight',
                    title: $translate.instant("REPORTS_CONTAINER_LOGS_CONTROLLER_TABLE_HEADER_WEIGHT"),
                    width: 80,
                },
                {
                    field: 'extUser',
                    title: $translate.instant("REPORTS_CONTAINER_LOGS_CONTROLLER_TABLE_HEADER_EXTERNAL_USER"),
                    width: 100,
                },
                {
                    field: 'image',
                    title: $translate.instant("G_IMAGE"),
                    width: 60,
                    sortable: false,
                    filterable: false,
                    template: function (dataItem) {
                        if (dataItem.media.length) {
                            return '<i class="fa fa-1-5x fa-image" ng-click="openMediaModal(dataItem.media)"></i>'
                        }
                        return '';
                    }
                }
            ],
        };

        $timeout(250).then(initController);

        function initController() {
            showBusyIndicator('containerLogsGrid');

            $q.all([
                loadAreas(),
                loadMunicipalities()
            ]).then(() => {
                return loadContainerLogs();
            }).finally(() => {
                loadContainerLogsGridOptions();

                hideBusyIndicator('containerLogsGrid');
            });
        }

        function loadAreas() {
            return sensorApiAreaService.getAreas().then((areas) => {
                const activeAreas = areas.filter(area => area.isActive === true);
                activeAreas.sort((a, b) => a.description > b.description ? 1 : -1);

                updateFilters(activeAreas);
            });
        }

        function updateFilters(areas) {
            $scope.filterData.areas = _.unionBy(
                getAreasForFilter(areas),
                $scope.filter.areas,
                'id'
            );
            $scope.filterData.areas = _.sortBy($scope.filterData.areas, 'description');
        }

        function getAreasForFilter(areas) {
            if (!areas) {
                return [];
            }

            return _.chain(areas)
                .filter((area) => !!area)
                .map((area) => ({
                    label: area.description,
                    id: area.id,
                    points: area.points,
                }))
                .value();
        }

        function getEmptyFilter() {
            return {
                areas: [],
                externalSystems: [],
                searchTerm: '',
                noPositionOnly: false,
            };
        }

        function handleAreaFilterChanged() {
            filterLog();
        }

        function loadContainerLogs() {
            let from = moment($scope.dateRange.from).format('YYYY-MM-DD');
            let to = moment($scope.dateRange.to).format('YYYY-MM-DD');

            return $q.all([
                containerService.getLogs(from, to),
                locationsService.getLocations(false, true, true)
            ]).then(function (data) {
                let logs = data[0],
                    locations = data[1];

                let containerLogs = logs
                    .map(log => extendContainerLog(log, locations))
                    .filter(Boolean);

                $scope.allContainerLogInTimeSlot = containerLogs;

                filterLog();
            }).catch(function (error) {
                console.error(error);
            }).finally(function () {
                kendoGridUtility.displayNoRecordsConditionally($scope.containerLogsGrid);

                hideBusyIndicator('containerLogsGrid');
            });
        }

        function openMediaModal(media) {
            var pictureUrls = media.map(m => m.pictureUrl);
            $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant("G_IMAGE"),
                    resizable: false,
                    width: "30%",
                    position: {
                        top: "5%",
                        left: "35%"
                    },
                    visible: false
                },
                templateUrl: 'app/shared/pictureCarouselModal/picture-carousel.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'PictureCarouselController',
                resolve: {
                    pictureUrls: function () {
                        return pictureUrls;
                    }
                }
            });
        }

        function exportToExcel() {
            $scope.containerLogsGrid.saveAsExcel();
        }

        function exportAsPdf() {
            $scope.containerLogsGrid.saveAsPDF();
        }

        //Private functions
        function extendContainerLog(containerLog, locations) {
            let locationIdOfLog = containerLog.container && containerLog.container.locationId;
            let relatedLocation = locations.find(l => l.id === locationIdOfLog);
            if (!relatedLocation) {
                return null;
            }

            let locationHref = '';
            if (relatedLocation.id) {
                locationHref = location.origin + '/main/location/' + relatedLocation.id + '/info';
            }

            return {
                ...containerLog,
                locationName: (relatedLocation && relatedLocation.name) || '',
                latitude: (relatedLocation && relatedLocation.latitude) || null,
                longitude: (relatedLocation && relatedLocation.longitude) || null,
                deviceExternalId: (containerLog.device && containerLog.device.externalId) || '',
                fractionName: (containerLog.container.fraction && containerLog.container.fraction.name) || '',
                municipalityCode: relatedLocation && relatedLocation.municipalityCode,
                locationHref: locationHref
            }
        }

        function loadContainerLogsGridOptions() {
            const unwatch = $scope.$watch('containerLogsGrid', (grid) => {
                if (!grid) return;

                unwatch();
                const options = kendoGridUtility.loadOptions('containerLogsGrid', containerLogsTableVersion);
                if (options) {
                    grid.setOptions(options);
                }
            });
        }

        function showBusyIndicator(id) {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'containerLog' + id,
                destination: '#' + id,
                message: $translate.instant("G_BUSY_INDICATOR"),
                overlay: true,
                positionClass: {
                    top: '50%',
                    left: '0px',
                    right: '0px'
                }
            });
        }

        function hideBusyIndicator(id) {
            $rootScope.$broadcast('hideBusyIndicator', 'containerLog' + id);
        }

        function isInSelectedAreas(containerLogs) {
            for (var i = 0; i < $scope.selectedAreas.length; i++) {
                if (isInArea($scope.selectedAreas[i], containerLogs)) {
                    return true;
                }
            }
            return false;
        }

        function filterBasedOnArea(containerLogs) {
            let filteredByArea;
            if ($scope.filter.areas.length != 0) {
                filteredByArea = containerLogs.filter(containerLog => isInSelectedAreas(containerLog));
            }
            else {
                filteredByArea = containerLogs;
            }

            return filteredByArea;
        }

        function isInArea(area, containerLog) {
            if (!area || !containerLog || !containerLog.latitude && !containerLog.longitude) {
                return false;
            }

            let inside = false;
            let x = containerLog.longitude, y = containerLog.latitude;
            for (let i = 0, j = area.points.length - 1; i < area.points.length; j = i++) {
                let xi = area.points[i].longitude, yi = area.points[i].latitude;
                let xj = area.points[j].longitude, yj = area.points[j].latitude;

                let intersect = ((yi > y) !== (yj > y))
                    && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);

                if (intersect) inside = !inside;
            }

            return inside;
        }

        function loadMunicipalities() {
            return municipalityService.getMunicipalities().then((municipalities) => {
                const allMunicipalits = municipalities.map((m) => ({
                    label: m.name,
                    id: m.code,
                }));

                allMunicipalits.sort((a, b) => a.label > b.label ? 1 : -1);
                updateMunicipalitiesFilters(allMunicipalits);
            });
        }

        function updateMunicipalitiesFilters(municipalities) {
            $scope.filterMunicipalitiesData.municipalities = _.unionBy(
                getMunicipalitiesForFilter(municipalities),
                $scope.municipalityfilter.municipalities,
                'id'
            );
            $scope.filterMunicipalitiesData.municipalities = _.sortBy($scope.filterMunicipalitiesData.municipalities, 'name');
        }

        function getMunicipalitiesForFilter(municipalities) {
            if (!municipalities) {
                return [];
            }

            return _.chain(municipalities)
                .filter((municipality) => !!municipality)
                .map((municipality) => ({
                    label: municipality.label,
                    id: municipality.id,
                }))
                .value();
        }

        function getMunicipalityEmptyFilter() {
            return {
                municipalities: [],
                externalSystems: [],
                searchTerm: '',
                noPositionOnly: false,
            };
        }

        function filterLog() {
            const logByMunicipality = municipalitySelected($scope.allContainerLogInTimeSlot);

            try {
                $scope.selectedAreas = $scope.filter.areas;

                const containerLogsByArea = filterBasedOnArea(logByMunicipality);

                $('#containerLogsGrid').getKendoGrid().dataSource.data(containerLogsByArea);
            } catch (error) {
                console.error(error);
            }
        }

        function handleMunicipalityFilterChanged() {
            filterLog();
        }

        function municipalitySelected(logs) {
            let filteredLogs = [];

            filteredLogs = filterBasedOnMunicipality(logs);
            return filteredLogs;
        }
        function filterBasedOnMunicipality(allLogs) {
            let filteredByMunicipality;
            if ($scope.municipalityfilter.municipalities.length != 0) {
                filteredByMunicipality = allLogs.filter(log => isInSelectedMunicipalities(log));
            }
            else {
                filteredByMunicipality = allLogs;
            }

            return filteredByMunicipality;
        }

        function isInSelectedMunicipalities(log) {
            for (var i = 0; i < $scope.municipalityfilter.municipalities.length; i++) {
                if ($scope.municipalityfilter.municipalities[i].id === log.municipalityCode) {
                    return true;
                }
            }
            return false;
        }
    }
})();
