angular.module('UndergroundWebApp').directive('locationPopup', [function() {
    'use strict';

    return {
        restrict: 'AE',
        replace: true,
        templateUrl: 'app/shared/popups/location-popup.html',
        link: function (scope, element) {
            scope.$on('showLocationPopup', function (event, model) {
                var ownerText = _.map(model.owners, 'Name').join(', ').trim();

                element.css('left', model.left);
                element.css('top', model.top);

                element.find('.location-number').text(model.number);
                element.find('.location-name').text(model.name);
                element.find('.location-owner').text(ownerText);

                element.show();
            });

            scope.$on('hideLocationPopup', function () {
                element.hide();
            });
        }
    }
}]);
