(function () {
    angular.module('UndergroundWebApp').factory('mediaUtility', ['localStorageService', mediaUtility]);

    function mediaUtility(localStorageService) {
        function getMediaUrl(mediaId) {
            var authData = localStorageService.get('authenticationData');
            return `/api/media/${mediaId}?token=${authData.token}`;
        }

        var utility = {
            getMediaUrl,
        }

        return utility;
    }
})();
