angular.module('UndergroundWebApp').config(['$stateProvider', '$urlRouterProvider', 'appVersion',
    function ($stateProvider, $urlRouterProvider, appVersion) {
        'use strict';

        const ieIE = !!window.document.documentMode;

        var appVersionString = appVersion.replace(/\./g, '');

        var navbarView = {
            templateUrl: 'app/shared/navbar/navbar.html',
            // NavbarController and its dependencies are not compatible with Internet Explorer, 
            // so it's disabled and a prompt is displayed for the user.
            controller: !ieIE ? 'NavbarController' : null
        };

        var footerView = {
            templateUrl: 'app/footer/footer-view.html',
            controller: 'FooterController'
        };

        $urlRouterProvider.otherwise('/main');

        $stateProvider
            .state('main', {
                url: '/main',
                views: {
                    'main': {
                        templateUrl: 'app/main/main-view.html' + '?v=' + appVersionString,
                        controller: 'MainController'
                    },
                    'navbar': navbarView,
                    'footer': footerView,
                }
            })
            .state('main.containerDetails', {
                url: '/container/details?containerId&externalSystemId&showInactive/groupId',
                templateUrl: 'app/containerOverview/views/container-details-view.html' + '?v=' + appVersionString,
                controller: 'ContainerInfoController',
                params: {
                    containerId: null,
                    externalSystemId: null,
                    startTab: null,
                    keycardNumber: null,
                    showInactive: 'false'
                }
            })
            .state('main.containerOverview', {
                url: '/container-overview?showInactive',
                templateUrl: 'app/containerOverview/views/container-overview-view.html' + '?v=' + appVersionString,
                controller: 'ContainerOverviewController',
                params: {
                    showInactive: 'false',
                    noCache: null
                }
            })
            .state('main.containerOverviewV2', {
                url: '/container-overview-v2',
                templateUrl: 'app/containerOverviewV2/views/container-overview-v2-view.html' + '?v=' + appVersionString,
                controller: 'ContainerOverviewV2Controller',
                params: {
                    noCache: null
                }
            })
            .state('main.keycardsOverview', {
                url: '/keycards-overview',
                templateUrl: 'app/keycards/views/keycards-overview-view.html' + '?v=' + appVersionString,
                controller: 'KeycardsOverviewController',
                params: {
                    noCache: null
                }
            })
            .state('main.administration', {
                url: '/administration',
                templateUrl: 'app/administration/administration-view.html' + '?v=' + appVersionString,
                controller: 'AdministrationController',
                redirectTo: 'main.administration.fractions',
            })
            .state('main.administration.fractions', {
                url: '/fractions',
                templateUrl: 'app/administration/administration-view.html' + '?v=' + appVersionString,
                controller: 'AdministrationController'
            })
            .state('main.administration.deviationAlert', {
                url: '/deviation-alert',
                templateUrl: 'app/administration/administration-view.html' + '?v=' + appVersionString,
                controller: 'AdministrationController'
            })
            .state('main.administration.userAdministration', {
                url: '/users',
                templateUrl: 'app/administration/administration-view.html' + '?v=' + appVersionString,
                controller: 'AdministrationController'
            })
            .state('main.administration.containerTypes', {
                url: '/container-types',
                templateUrl: 'app/administration/administration-view.html' + '?v=' + appVersionString,
                controller: 'AdministrationController'
            })
            .state('main.administration.vehicles', {
                url: '/vehicles',
                templateUrl: 'app/administration/administration-view.html' + '?v=' + appVersionString,
                controller: 'AdministrationController'
            })
            .state('main.administration.externalLinks', {
                url: '/external-links',
                templateUrl: 'app/administration/administration-view.html' + '?v=' + appVersionString,
                controller: 'AdministrationController'
            })
            .state('main.locations', {
                url: '/location?filter',
                params: {
                    filter: null,
                },
                templateUrl: 'app/location/locations-view.html' + '?v=' + appVersionString,
                controller: 'LocationsController',
                reloadOnSearch: false,
            })
            .state('main.locationDetails', {
                url: '/location/:locationId',
                params: {
                    locationId: null,
                    locationsFilter: null,
                },
                templateUrl: 'app/location/location-details-view.html' + '?v=' + appVersionString,
                controller: 'LocationDetailsController',
                redirectTo: 'main.locationDetails.info',
            })
            .state('main.locationDetails.info', {
                url: '/info',
                templateUrl: 'app/location/location-details-view.html' + '?v=' + appVersionString,
                controller: 'LocationDetailsController'
            })
            .state('main.locationDetails.keycard', {
                url: '/keycard',
                templateUrl: 'app/location/location-details-view.html' + '?v=' + appVersionString,
                controller: 'LocationDetailsController'
            })
            .state('main.locationDetails.log', {
                url: '/log',
                templateUrl: 'app/location/location-details-view.html' + '?v=' + appVersionString,
                controller: 'LocationDetailsController'
            })
            .state('main.locationDetails.statistics', {
                url: '/statistics',
                templateUrl: 'app/location/location-details-view.html' + '?v=' + appVersionString,
                controller: 'LocationDetailsController'
            })
            .state('main.locationDetails.powerbi', {
                url: '/powerbi',
                templateUrl: 'app/location/location-details-view.html' + '?v=' + appVersionString,
                controller: 'LocationDetailsController'
            })
            .state('main.statistics', {
                url: '/statistics?deviceId',
                params: {
                    deviceId: null
                },
                templateUrl: 'app/statistics/statistics-view.html' + '?v=' + appVersionString,
                controller: 'StatisticsController'
            })
            .state('main.reports', {
                url: '/reports',
                templateUrl: 'app/reports/reports-view.html' + '?v=' + appVersionString,
                controller: 'ReportsController',
                redirectTo: 'main.reports.containerlogs',
            })
            .state('main.reports.containerlogs', {
                url: '/logs',
                templateUrl: 'app/reports/reports-view.html' + '?v=' + appVersionString,
                controller: 'ReportsController'
            })
            .state('main.reports.municipalityLogs', {
                url: '/municipality-logs',
                templateUrl: 'app/reports/reports-view.html' + '?v=' + appVersionString,
                controller: 'ReportsController'
            })
            .state('main.reports.errorStatus', {
                url: '/error-status',
                templateUrl: 'app/reports/reports-view.html' + '?v=' + appVersionString,
                controller: 'ReportsController'
            })
            .state('main.reports.batteryVoltage', {
                url: '/battery-voltage',
                templateUrl: 'app/reports/reports-view.html' + '?v=' + appVersionString,
                controller: 'ReportsController'
            })
            .state('main.reports.genericReports', {
                url: '/generic-reports',
                templateUrl: 'app/reports/reports-view.html' + '?v=' + appVersionString,
                controller: 'ReportsController'
            })
            .state('main.sensorOverview', {
                url: '/sensor-overview',
                templateUrl: 'app/sensorOverview/sensor-overview.html' + '?v=' + appVersionString,
                controller: 'SensorOverviewController',
            })
            .state('main.area', {
                url: '/area',
                templateUrl: 'app/area/area-overview.html' + '?v=' + appVersionString,
                controller: 'AreaOverviewController',
            })
            .state('main.areaDetails', {
                url: '/area/:areaId',
                params: {
                    areaId: null,
                },
                templateUrl: 'app/area/area-details-view.html' + '?v=' + appVersionString,
                controller: 'AreaDetailsController',
            })
            .state('main.areaDetails.edit', {
                url: '/edit',
                views: {
                    '@main': {
                        templateUrl: 'app/area/area-details-editor-view.html' + '?v=' + appVersionString,
                        controller: 'areaDetailsEditorController',
                    }
                },
            });
    }]);
