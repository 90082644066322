(function () {

    angular.module('UndergroundWebApp').factory('geocodeAPIService', geocodeAPIService);

    geocodeAPIService.$inject = [
        '$q',
        '$http',
        'serviceUrls'
    ];

    function geocodeAPIService(
        $q,
        $http,
        serviceUrls
    ) {
        return {
            getGeocodeSuggestions: getGeocodeSuggestions,
            getGeocodePosition: getGeocodePosition
        };

        function getGeocodeSuggestions(searchString, countryCode) {
            var deferred = $q.defer();

            var cCode = countryCode || null;
            if (typeof (cCode) !== "undefined") {
                searchString = searchString + "&sourceCountry=" + cCode;
            }

            var requestUrl = serviceUrls.geocodeingServiceUrl + 'suggest?text=' + searchString + '&f=pjson';

            $http.get(requestUrl).then(function (response) {
                deferred.resolve(response.data);
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function getGeocodePosition(magicKey) {
            var deferred = $q.defer();
            var requestUrl = serviceUrls.geocodeingServiceUrl + 'findAddressCandidates?&magicKey=' + magicKey + '&maxLocations=10&outFields=Match_addr,Place_addr,Type&f=pjson&outSR=32633';

            $http.get(requestUrl).then(function (response) {
                deferred.resolve(response.data);
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }
    }
})();
