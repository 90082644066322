(function () {
    angular.module('UndergroundWebApp').factory('cacheService', cacheService);

    cacheService.$inject = [
        '$q',
        '$localForage',
        '$rootScope'
    ];

    function cacheService(
        $q,
        $localForage,
        $rootScope
    ) {
        const defaultExpirationMinutes = 60;

        return {
            get: get,
            set: set,
            remove: remove,
            removeByRegex: removeByRegex,
            serialize: serialize
        };

        function get(key) {
            var cacheKey = $rootScope.getCachePrefix() + key;

            return $localForage.getItem(cacheKey)
                .then(function (cacheItem) {
                    var now = new Date();
                    if (cacheItem && cacheItem.meta && cacheItem.meta.expiresAt) {
                        if (cacheItem.meta.expiresAt < now) {
                            $localForage.removeItem(cacheKey);

                            return null;
                        }
                    }

                    return cacheItem && cacheItem.value;
                });
        }

        function set(key, item) {
            var cacheKey = $rootScope.getCachePrefix() + key,
                now = new Date(),
                expiresAt = new Date(now.getTime());

            expiresAt.setMinutes(now.getMinutes() + defaultExpirationMinutes)

            var cacheItem = {
                meta: {
                    createdAt: now,
                    expiresAt: expiresAt
                },
                value: item
            };

            return $localForage.setItem(cacheKey, cacheItem);
        }

        function remove(key) {
            return $localForage.remove(key);
        }

        function removeByRegex(regex) {
            return $localForage.keys().then(function (keys) {
                var removePromises = [];

                for (var i = keys.length; i >= 0; --i) {
                    if (regex.test(keys[i])) {
                        removePromises.push($localForage.removeItem(keys[i]));
                    }
                }

                return $q.all(removePromises);
            });
        }

        function serialize(args) {
            if (args) {
                let serialized = Array.prototype.slice.call(args, 0);
                if (serialized.length > 0) {
                    return '_' + encodeURI(serialized).replace(/,/gi, '_');
                }
            }

            return '';
        }
    }
})();
