(function () {
    'use strict';

    angular.module('UndergroundWebApp').factory('tabstripUtility', ['$state', tabstripUtility]);

    function tabstripUtility($state) {
        var service = {
            handleTabSelected,
            changeTabByState,
        };

        function handleTabSelected(e) {
            const tabs = e.sender.dataSource.data();
            const selectedTab = tabs.find(t => t.name === e.item.textContent);
            if (selectedTab) {
                $state.go(selectedTab.state);
            }
        }

        function changeTabByState(tabstrip, stateName) {
            const tabs = tabstrip.dataSource.data();
            const tabToSelect = tabs.find(t => t.state === stateName);
            if (tabToSelect) {
                tabstrip.select(`li:contains('${tabToSelect.name}')`);
            }
        }

        return service;
    }
})();
