angular.module('UndergroundWebApp').directive('radialMenu', ['$q', '$state', '$rootScope', 'mapService', 'esriLoader', 'mapUtility',
        function ($q, $state, $rootScope, mapService, esriLoader, mapUtility) {
    'use strict';

    var startWidth = 24,
        startHeight = 24,
        maxWidth = 265,
        maxHeight = 265,
        startLeft = 0,
        startTop = 0,
        animationDuration = 300,
        iconsFadeDuration = 200;

    var isOpen = false,
        lastScreenPoint = null,
        lastScreenPointType = null,
        clusterPoints = null,
        item = null;
    return {
        restrict: 'AE',
        replace: true,
        scope: {
            'options': '=',
           
        },
        link: function (scope, element, attrs) {
            scope.$on('showContextMenu', function (event, options) {
                item = options.item;
                lastScreenPointType = options.type;
                clusterPoints = options.clusterPoints ? options.clusterPoints : null;
                scope.options.open(options.screenPoint, options.mapPosition, options.type, options.focusPoint);
                scope.containerId = $rootScope.selectedContainerId;
                if (options.location || item?.location) {
                    scope.location = options.location || item.location;
                } else {
                    scope.location = undefined;
                }
            });

            scope.$on('hideContextMenu', function () {
                item = null;
                lastScreenPointType = null;
                clusterPoints = null;
                scope.options.close();
            });

            esriLoader.require(["esri/geometry/support/webMercatorUtils", "esri/geometry/SpatialReference", "esri/geometry/Point", "esri/geometry/Extent"], function (webMercatorUtils, SpatialReference, Point, Extent) {
                scope.zoom = function () {
                    if (lastScreenPoint) {
                        mapService.zoomTo(lastScreenPoint);

                        switch (lastScreenPointType) {
                            case 'Vessel':
                                $state.go('main.containerDetails', {
                                    commune: item.commune,
                                    containerId: item.containerId,
                                    containerType: item.containerType,
                                    fill: item.fill,
                                    hasPosition: item.hasPosition,
                                    lat: item.degLat,
                                    lon: item.degLong,
                                    place: item.place,
                                    placeNumber: item.placeNr,
                                    poiId: item.poiId,
                                    time: item.time,
                                    volt: item.volt,
                                    startTab: 'Info'
                                });
                                break;
                            case 'Location':
                                $state.go('main.locationDetails.info', {
                                    locationId: item.id,
                                });
                                break;
                            case 'Cluster':
                                const extent = scope.getExtentFromClusterPoints(clusterPoints);
                                mapService.zoomToExtent(extent);
                                break;
                        }

                        scope.options.close();
                    }
                };

                scope.zoomToLocation = function () {
                    if (lastScreenPoint &&  scope.location) {
                        mapService.zoomTo(lastScreenPoint);

                        $state.go('main.locationDetails.info', {
                            locationId: scope.location.id,
                            locationsFilter: null,
                        });

                        scope.options.close();
                    }
                };

                scope.setPosition = function (){
                    var a = $rootScope.selectedContainerId;
                };
                scope.setLocationPosition = function () {
                    var mapPoint = mapService.getMapPoint(lastScreenPoint);
                    if (mapPoint && mapPoint.x && mapPoint.y) {
                        $rootScope.$broadcast('setLocationPosition',
                            {
                                position: mapUtility.convertToWGS84(mapPoint.x, mapPoint.y)
                            });
                    }
                    scope.options.close();
                };
                scope.getExtentFromClusterPoints = function (points) {

                    let ymin = Math.min(...points.map(({degLat}) => degLat));
                    let ymax = Math.max(...points.map(({degLat}) => degLat));
                    let xmin = Math.min(...points.map(({degLong}) => degLong));
                    let xmax = Math.max(...points.map(({degLong}) => degLong));

                    let minFactor = 0.9999;
                    let maxFactor = 1.0001;

                    xmin = xmin * minFactor;
                    ymin = ymin * minFactor;
                    xmax = xmax * maxFactor;
                    ymax = ymax * maxFactor;
                                    
                    const fullExt = new Extent(xmin, ymin, xmax, ymax, new SpatialReference(32632));
    
                    return fullExt;
                };
            });

            scope.options.setOpen = function (newValue, screenPoint, mapPosition, contextType, focusPoint) {
                var deferred = $q.defer();

                try {
                    if (isOpen !== newValue) {
                        if (newValue) {
                            var type = contextType || 'Map';

                            if (focusPoint) {
                                lastScreenPoint = focusPoint;
                            } else {
                                lastScreenPoint = screenPoint;
                            }

                            $(element).find('.menu-item-container').hide();

                            if (type === 'Map') {
                                $(element).find('.map-menu').show();
                            } else if (type === 'Location') {
                                $(element).find('.location-menu').show();
                            } else if (type === 'Vessel') {
                                $(element).find('.vessel-menu').show();
                            } else if (type === 'Cluster') {
                                $(element).find('.cluster-menu').show();
                            }

                            element.width(startWidth);
                            element.height(startHeight);

                            startLeft = screenPoint.x - startWidth / 2;
                            startTop = screenPoint.y + mapPosition[1] - startWidth / 2;
                            element.css('top', startTop + 'px');
                            element.css('left', startLeft + 'px');

                            //Calculate end left and top
                            var endLeft = screenPoint.x - maxHeight / 2;
                            var endTop = screenPoint.y + mapPosition[1] - maxWidth / 2;

                            element.show();

                            element.animate({ width: maxWidth }, { queue: false, duration: animationDuration });
                            element.animate({ height: maxHeight }, { queue: false, duration: animationDuration });
                            element.animate({ left: endLeft }, { queue: false, duration: animationDuration });
                            element.animate({ top: endTop }, {
                                queue: false, duration: animationDuration, complete: function () {
                                    $('.inner-circle .row').fadeIn(iconsFadeDuration);
                                    isOpen = newValue;
                                    deferred.resolve();
                                }
                            });
                        } else {
                            $('.inner-circle .row').fadeOut(iconsFadeDuration, function () {
                                element.animate({ width: startWidth }, { queue: false, duration: animationDuration });
                                element.animate({ height: startHeight }, { queue: false, duration: animationDuration });
                                element.animate({ left: startLeft }, { queue: false, duration: animationDuration });
                                element.animate({ top: startTop }, {
                                    queue: false, duration: animationDuration, complete: function () {
                                        isOpen = newValue;
                                        element.hide();
                                        deferred.resolve();
                                    }
                                });
                            });
                        }
                    }
                } catch (error) {
                    deferred.reject();
                }

                return deferred.promise;
            };

            scope.options.open = function (screenPoint, mapPosition, contextType, focusPoint) {
                var deferred = $q.defer();

                if (isOpen) {
                    scope.options.setOpen(false).then(function () {
                        isOpen = false;
                        scope.options.setOpen(true, screenPoint, mapPosition, contextType, focusPoint).then(deferred.resolve);
                    });
                } else {
                    scope.options.setOpen(true, screenPoint, mapPosition, contextType, focusPoint).then(deferred.resolve);
                }

                return deferred.promise;
            };

            scope.options.close = function () {
                scope.options.setOpen(false);
            };

            scope.locationSetPositionShown = function() {
                return $state.includes('main.locationDetails')
                    && $rootScope.isAccessible('Location', 'W');
            };
        },
        templateUrl: 'app/shared/directives/radial-menu.html'
    }
}]);
