(function () {
    'use strict';

    angular.module('UndergroundWebApp').controller('ContainerStatusController', ContainerStatusController);

    ContainerStatusController.$inject = [
        '$rootScope',
        '$scope',
        '$stateParams',
        'containerService',
        'localStorageService',
        '$translate'
    ];

    function ContainerStatusController(
        $rootScope,
        $scope,
        $stateParams,
        containerService,
        localStorageService,
        $translate
    ) {
        $scope.containerId = $stateParams.containerId;
        $scope.containerStatusGridData = [];

        $scope.containerStatusGridOptions = {
            dataSource: {
                data: $scope.containerStatusGridData,
                sort: {
                    field: "time",
                    dir: "desc"
                },
            },
            sortable: true,
            filterable: true,
            noRecords: true,
            messages: {
                noRecords: $translate.instant("G_NO_DATA"),
            },
            pageable: {
                pageSize: 100
            },
            columns: [
                {
                    field: 'time',
                    title: $translate.instant("CONTAINER_STATUS_CONTROLLER_TABLE_HEADER_TIME"),
                    width: '200px',
                    template: `#= kendo.toString(kendo.parseDate(time), "${$translate.instant("G_DEFAULT_DATE_FORMAT")}") #`
                },
                { field: 'errorText', title: $translate.instant("CONTAINER_STATUS_CONTROLLER_TABLE_HEADER_ERROR_TEXT") }
            ]
        };
        initController();

        function initController() {
            var authData = localStorageService.get('authenticationData');
            if (authData && authData.isAuth) {
                getContainerStatusGridData();
            }
        };

        function getContainerStatusGridData() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'containerStatusIndicator',
                destination: '#containerStatusGrid',
                message: $translate.instant("G_BUSY_INDICATOR"),
                overlay: true,
                positionClass: {
                    top: '50%',
                    left: '0px',
                    right: '0px'
                }
            });

            $scope.containerStatusGridData = [];
            containerService.getErrorsForContainerId($scope.containerId).then(function (containers) {
                if (containers) {
                    $scope.containerStatusGridData = containers;
                    $scope.containerStatusGrid.dataSource.data($scope.containerStatusGridData);
                    $scope.containerStatusGrid.refresh();
                }
                $rootScope.$broadcast('hideBusyIndicator', 'containerStatusIndicator');
            }, function () {
                $rootScope.$broadcast('hideBusyIndicator', 'containerStatusIndicator');
            });
        };
        function extendGridData(item) {
            item.time = moment(item.time).format('DD.MM.YYYY HH:mm:ss') == '01.01.1900' ? ' ' : moment(item.time).format($translate.instant("G_DEFAULT_DATE_FORMAT"));
        }
    };
})();
