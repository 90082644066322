(function () {
    'use strict';

    angular.module('UndergroundWebApp').controller('DeviceDetailsModalController', DeviceDetailsModalController);

    DeviceDetailsModalController.$inject = [
        '$scope', 
        '$q', 
        '$windowInstance', 
        'formTypes', 
        'currentFormType', 
        'externalSystemsService', 
        'deviceService', 
        'guidService', 
        'containerId', 
        'device', 
        'updateCallbacks', 
        'deviceTypes', 
        'fallbackErrorMessageText',
        '$translate'
    ];

    function DeviceDetailsModalController(
        $scope, 
        $q, 
        $windowInstance, 
        formTypes, 
        currentFormType, 
        externalSystemsService, 
        deviceService, 
        guidService, 
        containerId, 
        device, 
        updateCallbacks, 
        deviceTypes, 
        fallbackErrorMessageText,
        $translate
        ) {

        $scope.externalSystem = '';
        $scope.externalId = '';
        $scope.serial = '';
        $scope.sensorHeight = '';
        $scope.fullHeight = '';
        $scope.customerKey = '';
        $scope.isPriority = false;

        $scope.save = save;
        $scope.saveAndClose = saveAndClose;
        $scope.close = close;

        $scope.externalSystemValid = true;
        $scope.externalIdValid = true;
        $scope.deviceTypeValid = true;

        if (formTypes.edit === currentFormType && device) {
            $scope.isEdit = true;
            $scope.selectedExternalSystemId = device.externalSystemId;
            $scope.externalId = device.externalId;
            $scope.serial = device.serial;
            $scope.sensorHeight = device.sensorHeight;
            $scope.fullHeight = device.fullHeight;
            $scope.customerKey = device.customerKey;
            $scope.isActive = device.isActive;
            $scope.isPriority = device.isPriority;
            $scope.selectedDeviceType = _.find(deviceTypes, { id: device.deviceType });
        }

        initController();
        
        function initController() {
            externalSystemsService.getExternalSystems().then(systems => {
                $scope.externalSystems = systems;
                $scope.selectedExternalSystem = _.find($scope.externalSystems, s => s.id.toLowerCase() == $scope.selectedExternalSystemId)
                $scope.externalSystemOptions = {
                    dataSource: {
                        transport: {
                            read: readExternalSystems
                        }
                    },
                    dataTextField: 'name',
                    dataValueField: 'id',
                    change: function (e) {
                        $scope.selectedExternalSystemId = this.value();
                    }
                };

                $scope.deviceTypeOptions = {
                    dataSource: deviceTypes,
                    dataTextField: 'name',
                    dataValueField: 'id',
                    optionLabel: {
                        name: $translate.instant("DEVICE_DETAILS_CONTROLLER_CHOOSE_TYPE"),
                        id: '',
                    },
                };
            }).catch(function (error) {
                $scope.extSystemsDdlServerErrorText = error.localizedMessage || fallbackErrorMessageText;
            });
        }

        function close() {
            $windowInstance.close();
        }

        function save() {
            $scope.externalSystemValid = !!$scope.selectedExternalSystem;
            $scope.externalIdValid = !!$scope.externalId;
            $scope.deviceTypeValid = !!$scope.selectedDeviceType &&
                $scope.selectedDeviceType.id !== '';

            var deferred = $q.defer();
            
            var deviceDto = {
                id: device ? device.id : guidService.emptyGuid(),
                externalId: $scope.externalId,
                serial: $scope.serial,
                customerKey: $scope.customerKey,
                externalSystem: $scope.selectedExternalSystem,
                externalSystemId: $scope.selectedExternalSystem.id,
                sensorHeight: $scope.sensorHeight,
                fullHeight: $scope.fullHeight,
                containerId: containerId,
                isActive: $scope.isActive,
                isPriority: $scope.isPriority,
                deviceType: $scope.selectedDeviceType.id,
            }

            if ($scope.externalSystemValid && $scope.externalIdValid && $scope.deviceTypeValid) {
                if ($scope.isEdit) {
                    deviceService.updateDevice(deviceDto).then((updatedDevice) => {
                        device = updatedDevice;
                        if (updateCallbacks && updateCallbacks.updateDeviceToGrid) {
                            var gridDevice = {...device};
                            gridDevice.externalSystem = $scope.selectedExternalSystem.name;
                            updateCallbacks.updateDeviceToGrid(gridDevice);
                        }

                        deferred.resolve();
                    });
                } else {
                    deviceService.addDevice(deviceDto).then((addedDevice) => {
                        device = addedDevice;
                        $scope.isEdit = formTypes.edit;
                        if (updateCallbacks && updateCallbacks.addDeviceToGrid) {
                            var gridDevice = {...device};
                            gridDevice.externalSystem = $scope.selectedExternalSystem.name;
                            updateCallbacks.addDeviceToGrid(gridDevice);
                        }
                        deferred.resolve();
                    });
                }
            }

            return deferred.promise;
        }

        function saveAndClose() {
            save()
                .then(() => {
                    close();
                }).catch(() => {
                    alert($translate.instant("G_SAVE_FAILED"));
                });
        }

        function readExternalSystems(e){
            e.success($scope.externalSystems ? $scope.externalSystems : []);
        }
    }
})();

