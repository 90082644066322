(function () {
    'use strict';

    angular.module('UndergroundWebApp').factory('containerService', containerService);

    containerService.$inject = [
        '$q',
        '$http',
        'qPool'
    ];

    function containerService(
        $q,
        $http,
        qPool
    ) {
        let containerService = {
            getContainers,
            getContainersV2,
            getContainerByContainerId,
            getContainerById,
            getContainerTypes,
            getErrorsForContainerId,
            getEmptyingReportsForContainerById,
            getContainerReportsBasedOnDate,
            getLogsByExtDeviceIdAndSystem,
            getLogs,
            getLogsByFilter,
            getLogsByFilterBulk,
            getDevice,
            getCustomerSystems,
            getContainerInfoByLocationId,
            getContainerStatistics,
            getContainerByRelatedDevice,

            addContainer,
            addContainerV2,
            updateContainer,
            updateContainerV2,
            updateContainerSettings,
            updateDeviceCustomerSystem,
            updateContainerCustomerSystem,
            getCustomerSystemsForContainer,
            addCustomerSystemForContainer,
            removeCustomerSystemFromContainer,
            connectDevice,
            addContainerLog,
            deleteContainer,
        };

        return containerService;

        function connectDevice(device, containerId) {
            let connectDeviceDto = {
                containerId: containerId,
                externalId: device.externalId,
                externalSystemId: device.externalSystemId
            };

            return $http.post('api/containersv2/connect', connectDeviceDto)
                .then(result => result.data);
        }

        function removeCustomerSystemFromContainer(containerCustomerSystemId) {
            return $http.delete('api/containersv2/customerSystem/' + containerCustomerSystemId)
                .then(result => result.data);
        }

        function addCustomerSystemForContainer(containerCustomerSystem, containerId) {
            let ccSystem = {
                containerId: containerId,
                externalId: containerCustomerSystem.externalId,
                customerSystemId: containerCustomerSystem.selectedCustomerSystemId
            };

            return $http.post('api/containersv2/customerSystem', ccSystem)
                .then(result => result.data);
        }

        function updateContainerCustomerSystem(containerCustomerSystem) {
            let ccSystem = {
                id: containerCustomerSystem.id,
                externalId: containerCustomerSystem.externalId,
                customerSystem: containerCustomerSystem.customerSystem
            };

            return $http.put('api/containersv2/customerSystem', ccSystem)
                .then(result => result.data);
        }

        function getCustomerSystemsForContainer(containerId) {
            return $http.get('api/customerSystems/' + containerId)
                .then(result => result.data);
        }

        function addContainer(container) {
            return $http.post('api/containers/AddContainer/', container)
                .then(result => result.data);
        }

        function addContainerV2(container) {
            return $http.post('api/containersV2/', container)
                .then(result => result.data);
        }

        function getContainers() {
            let deferred = qPool.defer('containerService.getContainers');

            if (!deferred.hasCompleted() && deferred.isFirst) {
                $http.get('api/containers/GetAllContainersWithGroupIdByUserIds').then(function (result) {
                    if (result && result.data) {
                        deferred.resolve(result.data);
                    }
                    else {
                        deferred.reject();
                    }
                });
            }

            return deferred.promise;
        }

        function getContainersV2() {
            return $http.get('api/containersV2')
                .then(result => result.data);
        }

        function getContainerByContainerId(containerId) {
            return $http.get('api/containersV2/' + containerId)
                .then(result => result.data);
        }

        function getContainerInfoByLocationId(locationId) {
            return $http.get('api/containersV2/ByLocationId/' + locationId)
                .then(result => result.data);
        }

        
        function getContainerById(containerId) {
            let deferred = $q.defer();

                $http.get('api/containers/GetContainerByExternalIdWithGroupIdByUserIds/' + containerId).then(function (result) {
                    if (result && result.data) {

                        var groups = [{
                            groupId: result.data.groupId,
                            group: result.data.group
                        }];

                        result.data.groups = groups;
                        deferred.resolve(result.data);
                    }
                    else {
                        deferred.reject();
                    }
                });
            
            return deferred.promise;
        }

        function getContainerTypes() {
            return $http.get('api/containers/getContainerTypes/')
                .then(result => result.data);
        }

        function getErrorsForContainerId(containerId) {
            let deferred = $q.defer();

            $http.get('api/containers/GetErrorsForContainerIdAsync/' + containerId).then(function (result) {
                if (result && result.data) {
                    var uniqData = _.flatten(_.map(_.groupBy(result.data, 'time'), g => _.uniqBy(g, 'errorText')));
                    deferred.resolve(uniqData);
                }
                else {
                    deferred.reject();
                }
            }, deferred.reject);
            return deferred.promise;
        }

        function getEmptyingReportsForContainerById(containerId) {
            return $http.get('api/containers/GetEmptyingReportsForContainerByIdAsync/' + containerId)
                .then(result => result.data);
        }

        function getContainerReportsBasedOnDate(containerId, from, to) {
            return $http.get('api/containers/getContainerReportsBasedOnDate/' + containerId + '/' + from + '/' + to)
                .then(result => result.data);
        }

        function getContainerStatistics(deviceId, from, to) {
            return $http.get('api/containersv2/statistics?deviceId=' + deviceId + '&from=' + from + '&to=' + to)
                .then(result => result.data);
        }

        function getContainerByRelatedDevice(deviceId) {
            return $http.get('api/containersv2/byDevice/' + deviceId)
                .then(result => result.data);
        }

        function updateContainer(container) {
            return $http.post('api/containers/UpdateContainer/', container)
                .then(result => result.data);
        }

        function updateContainerV2(container) {
            return $http.put('api/containersv2/', container)
                .then(result => result.data);
        }

        function updateContainerSettings(containerSettings) {
            return $http.put('api/containers/UpdateContainerSettings', containerSettings)
                .then(result => result.data);
        }

        function getLogsByExtDeviceIdAndSystem(containerId) {
            return $http.get('api/containers/GetLogsByExtDeviceIdAndSystem/' + containerId)
                .then(result => result.data);
        }

        function getLogs(from, to) {
            return $http.get('api/containersv2/logs?from=' + from + '&to=' + to)
                .then(result => {
                    extendLogs(result.data);

                    return result.data;
                });
        }

        function getLogsByFilter(filter) {
            return $http.post('api/containersv2/filter', filter)
                .then(result => result.data);
        }

        function getLogsByFilterBulk(filterList) {
            return $http.post('api/containersv2/filter/bulk', filterList)
                .then(result => result.data);
        }

        function getDevice(containerId, externalSystemId) {
            return $http.get('api/containers/GetDeviceExtDeviceIdAndSystem/' + containerId + '/' + externalSystemId)
                .then(result => result.data);
        }

        function getCustomerSystems() {
            return $http.get('api/containers/GetCustomerSystems')
                .then(result => result.data);
        }

        function updateDeviceCustomerSystem(containerId, externalSystemId, customerSystemId, customerSystemExternalId) {
            let deviceDto = {
                externalId: containerId,
                externalSystemId: externalSystemId,
                CustomerSystemId: customerSystemId,
                customerSystemExternalId: customerSystemExternalId
            };

            return $http.put('api/containers/UpdateDeviceCustomerSystem', deviceDto)
                .then(result => result.data);
        }

        function addContainerLog(containerLog) {
            return $http.post('api/containerLog', containerLog)
                .then(result => result.data);
        }

        function deleteContainer(containerId) {
            return $http.delete(`api/containersV2/${containerId}`)
                .then(result => result.data);
        }

        //Helper functions
        function extendLogs(logs) {
            if (!logs || logs.length === 0) return;

            //Adjust time
            for (let log of logs) {
                let utcLogTime = new Date(log.time);
                utcLogTime.setHours(utcLogTime.getHours() + 2);

                log.time = utcLogTime;
            }
        }
    }
})();
