(function () {
    'use strict';

    angular.module('UndergroundWebApp').factory('systemService', systemService)

    systemService.$inject = [
        '$q',
        '$http',
        '$rootScope'
    ];

    function systemService(
        $q,
        $http,
        $rootScope
    ) {
        let systemInfoData = null

        let service = {
            getSystemInfo: getSystemInfo
        };

        return service;

        function getSystemInfo() {
            var deferred = $q.defer();

            if (systemInfoData) {
                $rootScope.masterMode = systemInfoData.masterMode;
                $rootScope.isShop2WinModuleEnabled = systemInfoData.isShop2WinModuleEnabled;
                deferred.resolve(systemInfoData);
            }
            else {
                if ($rootScope.authData && $rootScope.authData.isAuth) {
                    $http.get('api/system/info').then(function (result) {
                        if (result && result.data) {

                            systemInfoData = result.data;
                            $rootScope.systemInfo = systemInfoData;
                            $rootScope.masterMode = result.data.masterMode;
                            $rootScope.isShop2WinModuleEnabled = result.data.isShop2WinModuleEnabled;

                            deferred.resolve(result.data);
                        }
                        else {
                            deferred.reject();
                        }
                    }, function () {
                        deferred.reject();
                    });
                } else {
                    deferred.reject();
                }
            }

            return deferred.promise;
        }
    }
})();
