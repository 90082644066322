/**
 * Collection of service calls related to unmodified or rarely modified data.
 */
(function () {
    'use strict';

    angular.module('UndergroundWebApp').factory(BaseDataService);

    BaseDataService.$inject = [
        '$http',
        'serviceUrls'
    ];

    function BaseDataService(
        $http,
        serviceUrls
    ) {
        let locationBaseUrl = serviceUrls.serviceBaseUrl + serviceUrls.locationServiceUrl;

        return {
            getMeasurementUnitTypes,
            getMeasurementTypes
        };

        function getMeasurementUnitTypes() {
            let getMeasurementUnitTypesUrl = locationBaseUrl + '/GetMeasurementUnitTypes';

            return $http.get(getMeasurementUnitTypesUrl)
                .then(result => result.data);
        }

        function getMeasurementTypes() {
            let getMeasurementTypesUrl = locationBaseUrl + '/getMeasurementTypes';

            return $http.get(getMeasurementTypesUrl)
                .then(result => result.data);
        }
    }
})();
