(function () {
    'use strict';

    angular.module('UndergroundWebApp').controller('ContainerDetailsModalController', ContainerDetailsModalController);

    ContainerDetailsModalController.$inject = [
        '$q',
        '$rootScope',
        '$scope',
        '$stateParams',
        '$windowInstance',
        's2wExternalSystemId',
        'containerService',
        'groupService',
        'keycardService',
        'formTypes',
        'currentFormType',
        'container',
        'allContainers',
        'fallbackErrorMessageText',
        'confirmModal',
        'deviceService',
        '$translate'
    ];

    function ContainerDetailsModalController(
        $q,
        $rootScope,
        $scope,
        $stateParams,
        $windowInstance,
        s2wExternalSystemId,
        containerService,
        groupService,
        keycardService,
        formTypes,
        currentFormType,
        container,
        allContainers,
        fallbackErrorMessageText,
        confirmModal,
        deviceService,
        $translate,
    ) {

        $scope.isEdit = currentFormType === formTypes.edit;

        $scope.containerId = $stateParams.containerId;
        $scope.containerId = { Value: '' };
        $scope.selectedContainerType = { Value: '' };
        $scope.address = { Value: '' };
        $scope.selectedGroup = { Value: '' };
        $scope.selectedUser = { Value: '' };
        $scope.users = [];
        $scope.groups = [];
        $scope.containerTypes = [];

        $scope.close = close;
        $scope.saveAndClose = saveAndClose;
        $scope.addGroup = addGroup;
        $scope.removeGroup = removeGroup;
        $scope.containerIdValid = true;
        $scope.showAtLeastOneGroupWarn = false;
        $scope.containers = allContainers;

        $scope.usersOptions = {
            dataSource: {
                transport: {
                    read: readUsers
                }
            },
            dataTextField: 'userName',
            dataValueField: 'userId',
            optionLabel: $translate.instant("CONTAINER_DETAILS_MODAL_CONTROLLER_USERS_OPTIONS_LABEL")
        };

        $scope.containerTypesOptions = {
            dataSource: {
                transport: {
                    read: readContainerTypes
                }
            },
            dataTextField: 'name',
            dataValueField: 'nummer',
            optionLabel: $translate.instant("CONTAINER_DETAILS_MODAL_CONTROLLER_CONTAINER_TYPES_OPTIONS_LABEL")
        };

        $scope.groupsOptions = {
            dataSource: {
                transport: {
                    read: readGroups
                },
                serverFiltering: false
            },
            filter: 'contains',
            dataTextField: 'name',
            dataValueField: 'nummer'
        };

        initController();

        function initController() {
            if (currentFormType === formTypes.edit) {
                $scope.isActive = !container.status;
                $scope.address.Value = container.address;
                $scope.containerId = { Value: container.id };
                $scope.selectedContainerType = { Value: { name: container.containerType, nummer: container.containerTypeId } };
                $scope.selectedUser = { Value: { userName: container.userName, userId: container.userId } };
            }
        }

        function addGroup(){
            event.preventDefault();
            var isAdded = _.map($scope.addedGroups, g => g.Value.nummer).includes($scope.selectedGroup.Value.nummer);
            if (!isAdded) {
                $scope.addedGroups.push(_.cloneDeep($scope.selectedGroup));
            }
            updateGroupValidationText();
        }

        function removeGroup(g) {
            var index = $scope.addedGroups.indexOf(g);
            if (index > -1) {
                $scope.addedGroups.splice(index, 1);
            }
        }

        function close(result) {
            $windowInstance.close(result);
        }

        function updateGroupValidationText(){
            if (!$scope.addedGroups || !$scope.addedGroups.length){
                $scope.showAtLeastOneGroupWarn = true;
            }else{
                $scope.showAtLeastOneGroupWarn = false;
            }
        }

        function saveAndClose() {
            save().then(function (result) {
                $windowInstance.close(result);
            });
        }

        function save() {
            var deferred = $q.defer();

            event.preventDefault();
            $scope.containerIdValid = validateContainerId();
            if ($scope.validator.validate() && $scope.containerIdValid && $scope.addedGroups.length) {

                var containerToSave = createContainer();
                if (currentFormType === formTypes.edit) {
                    let shouldDisconnectPromise;
                    const wasActive = container.status === 0;
                    if (wasActive && !$scope.isActive) {
                        shouldDisconnectPromise = confirmModal.confirm({
                            title: $translate.instant("CONTAINER_DETAILS_MODAL_CONTROLLER_SHOULD_DISCONNECT_CONFIRM_TITLE"),
                            content: $translate.instant("CONTAINER_DETAILS_MODAL_CONTROLLER_SHOULD_DISCONNECT_CONFIRM_CONTENT"),
                            messages: {
                                okText: $translate.instant("G_YES"),
                                cancel: $translate.instant("G_NO")
                            },
                        }).then(function (shouldDisconnectDevice) {
                            if (shouldDisconnectDevice) {
                                return deviceService.disconnectDevice(container.id, s2wExternalSystemId);
                            }
                        });
                    } else {
                        shouldDisconnectPromise = $q.resolve();
                    }

                    shouldDisconnectPromise.then(function () {
                        $rootScope.$broadcast('showGlobalBusyIndicator', 'containerIndicator');
                        return containerService.updateContainer(containerToSave)
                    }).then(function (result) {
                        resetWindow();
                        deferred.resolve(result);
                        $rootScope.$broadcast('hideBusyIndicator', 'containerIndicator');
                    }, function () {
                        deferred.reject();
                        $rootScope.$broadcast('hideBusyIndicator', 'containerIndicator');
                    });
                }
                else {
                    containerService.addContainer(containerToSave).then(function (result) {
                        resetWindow();
                        deferred.resolve(result);
                        $rootScope.$broadcast('hideBusyIndicator', 'containerIndicator');
                    }, function () {
                        deferred.reject();
                        $rootScope.$broadcast('hideBusyIndicator', 'containerIndicator');
                    });
                }
            }else{
                updateGroupValidationText();
            }

            return deferred.promise;
        }

        function readUsers(e) {
            keycardService.getUsers().then(function (users) {
                if (users) {
                    _.each(users, function (item) {
                        $scope.users.push(item);
                    });
                }
                $scope.usersDdlServerErrorText = null;
                e.success($scope.users);
            }).catch(function (error) {
                $scope.usersDdlServerErrorText = error.localizedMessage || fallbackErrorMessageText;
                e.error();
            });
        }

        function readContainerTypes(e) {
            containerService.getContainerTypes().then(function (containerTypes) {
                if (containerTypes) {
                    _.each(containerTypes, function (item) {
                        $scope.containerTypes.push(item);
                    });
                }
                $scope.containerTypesDdlServerErrorText = null;
                e.success($scope.containerTypes);
            }).catch(function (error) {
                $scope.containerTypesDdlServerErrorText = error.localizedMessage || fallbackErrorMessageText;
                e.error();
            });
        }

        function readGroups(e) {
            groupService.getGroups().then(function (groups) {
                if (groups) {
                    _.each(groups, function (item) {
                        $scope.groups.push(item);
                    });
                }
                $scope.groupsDdlServerErrorText = null;
                e.success($scope.groups);
                $scope.addedGroups = container && container.groupIds 
                ? _.map(_.filter($scope.groups, g => Array.from(container.groupIds).includes(g.nummer)), fg => {return { Value: fg }})
                : [];

                if (container)//edit
                {
                    var groupOfContainer = _.find($scope.groups, g => g.nummer === container.groupId)
                }
                $scope.selectedGroup = groupOfContainer 
                ? { Value: { name: groupOfContainer.name, nummer: container.groupId } } 
                : { Value: { name: $scope.groups[0].name, nummer: $scope.groups[0].nummer } }
            }).catch(function (error) {
                $scope.groupsDdlServerErrorText = error.localizedMessage || fallbackErrorMessageText;
                e.error();
            });
        }

        function resetWindow() {
            $scope.containerId.Value = '';
            $scope.selectedContainerType.Value = '';
            $scope.address.Value = '';
            $scope.selectedGroup.Value = '';
            $scope.selectedUser.Value = '';
            $scope.externalSystem = '';
        }

        function createContainer() {
            return {
                'groupId': $scope.selectedGroup.Value.nummer,
                'containerId': $scope.containerId.Value,
                'address': $scope.address.Value,
                'communeId': $scope.selectedUser.Value.userId,
                'containerType': $scope.selectedContainerType.Value.nummer,
                'isActive': $scope.isActive,
                'groupIds': _.map($scope.addedGroups, g=>g.Value.nummer),
                'externalSystemId': s2wExternalSystemId
            };
        }

        function validateContainerId() {
            var matchContainers;
            if (currentFormType === formTypes.add) {
                matchContainers = _.filter($scope.containers, function (c) { return c.id.toString() === $scope.containerId.Value });
                if (matchContainers.length) {
                    return false;
                }
                return true;
            } else {
                //cannot change id on edit.
                return true;
            }
        }

    }
})();
