(function () {
    'use strict';

    const persistenceRootKey = 'DATE_RANGE_PICKER';

    angular.module('UndergroundWebApp').directive('dateRangePicker', ['$location', 'defaultDateRangeOptions', dateRangePicker]);


    function dateRangePicker($location, defaultDateRangeOptions) {
        return {
            restrict: 'E',
            replace: true,
            templateUrl: 'app/shared/directives/date-range-picker.html',
            scope: {
                model: '=ngModel',
                id: '@',
                onInit: '&?',
                persistenceKeyPrefix: '@',
                dateRangeOptions: '<?',
                selectedDateRangeChanged: '<?',
            },
            link: function (scope) {
                scope.dateRangeOptions = scope.dateRangeOptions || defaultDateRangeOptions;
                loadPersistedValues(scope);

                setDateValues(scope.selectedDateRange, null, scope);
                scope.$watch('selectedDateRange', setDateValues);
                scope.$watch('model.from', persistDateIfCustom);
                scope.$watch('model.to', persistDateIfCustom);

                scope.onInit();
            },
        }

        function persistDateIfCustom(_, __, scope) {
            if (scope.selectedDateRange.value === 'custom') {
                persistValues(scope);
            }
        }

        function setDateValues(dateRange, _oldDateRange, scope) {
            if (dateRange.value !== 'custom' || scope.model.from == null) {
                scope.model.from = getFromDate(dateRange);
            }

            if (dateRange.value !== 'custom' || scope.model.to == null) {
                scope.model.to = new Date();
            }

            if (scope.selectedDateRangeChanged) {
                scope.selectedDateRangeChanged(dateRange);
            }

            persistValues(scope);
        }

        function getFromDate(dateRange) {
            const date = new Date();

            if (dateRange.setter) {
                dateRange.setter(date);
            } else {
                date.setDate(date.getDate() - 7);
            }

            return date;
        }

        function getPersistenceKey(scope) {
            let prefix;
            if (scope.persistenceKeyPrefix) {
                prefix = scope.persistenceKeyPrefix;
            } else {
                prefix = $location.path();
            }

            return prefix + '#' + scope.id;
        }

        function getStore() {
            return JSON.parse(window.localStorage.getItem(persistenceRootKey));
        }

        function saveStore(store) {
            return window.localStorage.setItem(persistenceRootKey, JSON.stringify(store));
        }

        function loadPersistedValues(scope) {
            const store = getStore() || {};

            const datePickerkey = getPersistenceKey(scope);
            const entry = store[datePickerkey] || {};
            const { dateRange, fromDate, toDate } = entry;

            if (!dateRange) {
                scope.selectedDateRange = scope.dateRangeOptions[0];
                return;
            }

            scope.selectedDateRange = scope.dateRangeOptions.find(range => range.value === dateRange)
                || scope.dateRangeOptions[0];
            if (dateRange === 'custom') {
                scope.model.from = moment(fromDate, 'YYYY-MM-DD').toDate();
                scope.model.to = moment(toDate, 'YYYY-MM-DD').toDate();
            }
        }

        function persistValues(scope) {
            const store = getStore() || {};
            const datePickerkey = getPersistenceKey(scope);
            const entry = {};

            entry.dateRange = scope.selectedDateRange.value;
            if (entry.dateRange === 'custom') {
                entry.fromDate = moment(scope.model.from).format('YYYY-MM-DD');
                entry.toDate = moment(scope.model.to).format('YYYY-MM-DD');
            }

            store[datePickerkey] = entry;
            saveStore(store);
        }
    }
})();
