(function () {
    'use strict';

    angular.module('UndergroundWebApp').factory('confirmModal', ['$q', confirmModal]);

    function confirmModal($q) {
        const service = {
            confirm,
        };

        function confirm(options) {
            const deferred = $q.defer();
            let actualOptions = options;
            if (typeof (options) === 'string') {
                actualOptions = {
                    content: options,
                };
            }

            $("<div></div>")
                .kendoConfirm(actualOptions)
                .data("kendoConfirm")
                .open()
                .result
                .done(() => deferred.resolve(true))
                .fail(() => deferred.resolve(false));

            return deferred.promise;
        }

        return service;
    }
})();
