(function () {
    'use strict';

    angular.module('UndergroundWebApp').controller('VehicleDetailsModalController', VehicleDetailsModalController);

    VehicleDetailsModalController.$inject = [
        '$q',
        '$scope',
        '$rootScope',
        '$windowInstance',
        'vehicleService',
        'formTypes',
        'currentFormType',
        'vehicle',
        'fuelTypes',
    ];

    function VehicleDetailsModalController(
        $q,
        $scope,
        $rootScope,
        $windowInstance,
        vehicleService,
        formTypes,
        currentFormType,
        vehicle,
        fuelTypes,
    ) {
        $scope.fuelTypes = fuelTypes;

        $scope.saveAndClose = saveAndClose;
        $scope.closeWindow = closeWindow;

        $scope.vehicle = vehicle != null
            ? { ...vehicle }
            : { fuelType: 0 };

        function saveAndClose(event) {
            event.preventDefault();

            if ($scope.validator.validate()) {
                save().then((result) => {
                    $windowInstance.close(result);
                });
            }
        }

        function closeWindow(event) {
            event.preventDefault();
            $windowInstance.close(false);
        }

        function save() {
            $rootScope.$broadcast('showGlobalBusyIndicator', 'vehicleIndicator');

            const vehiclePromise = currentFormType === formTypes.edit
                ? vehicleService.updateVehicle($scope.vehicle)
                : vehicleService.createVehicle($scope.vehicle);
            let savePromise = vehiclePromise;

            if ($scope.vehicleImage != null) {
                const imagePromise = vehiclePromise.then((vehicle) => {
                    return vehicleService.addImage(vehicle.id, $scope.vehicleImage);
                });
                savePromise = $q.all([vehiclePromise, imagePromise])
                    .then(([vehicle]) => vehicle);
            }

            return savePromise
                .finally(() => $rootScope.$broadcast('hideBusyIndicator', 'vehicleIndicator'));
        }

    }
})();
