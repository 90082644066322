(function () {
    var undergroundWebApp = angular.module('UndergroundWebApp', [
        'kendo.directives',
        'kendo.window',
        'ui.router',
        'LocalStorageModule',
        'LocalForageModule',
        'esri.map',
        'angularjs-dropdown-multiselect',
        'angulartics',
        'angulartics.google.analytics',
        'ui.toggle',
        'ui-iconpicker',
        'pascalprecht.translate',
        'ngCookies'
    ]);

    undergroundWebApp.constant('defaultSelectedLanguageId', 'no');

    undergroundWebApp.constant('availableLanguages', [
        { id: 'en', name: 'English', icon: 'UnitedStates-Flag-48.png' }, // icon:"GreatBritain-Flag-48.png"
        { id: 'no', name: 'Norsk', icon: 'Norway-Flag-48.png' }
    ]);

    undergroundWebApp.config([
        '$locationProvider',
        '$httpProvider',
        '$translateProvider',
        'availableLanguages',
        'defaultSelectedLanguageId',

        function ($locationProvider, $httpProvider, $translateProvider, availableLanguages, defaultSelectedLanguageId) {
            $locationProvider.html5Mode(true);
            $httpProvider.interceptors.push('authInterceptorService');
            $httpProvider.interceptors.push('httpErrorInterceptorService');

            $translateProvider
                .useStaticFilesLoader({
                    prefix: 'app/locales/',
                    suffix: '.json'
                })
                .registerAvailableLanguageKeys(
                    availableLanguages.map(l => l.id),
                    {
                        en: 'en',
                        no: 'no'
                    }
                )
                .preferredLanguage(defaultSelectedLanguageId)
                .fallbackLanguage('en')
                .useSanitizeValueStrategy('escapeParameters');

            $translateProvider.useLocalStorage();
        }
    ]);

    undergroundWebApp.constant('containerEditModalWidth', 980);

    undergroundWebApp.constant('fallbackErrorMessageText', 'En feil oppstod ved henting av data... Prøv å laste inn side på nytt');

    undergroundWebApp.constant('maxContainerOverviewSensorColumns', 3);

    undergroundWebApp.constant('coordinateBounds', {
        latMin: 58,
        latMax: 72,
        longMin: 5,
        longMax: 32,
    });

    undergroundWebApp.constant('selectionSettings', {
        outlineDefault: { color: [255, 255, 255], width: 1 },
        outlineSelected: { color: [0, 162, 235], width: 2 },
        lineSymbol: { color: [0, 162, 235], width: 2 },
        pointSymbol: { color: [255, 0, 0], size: 2 },
        edgeSymbol: { color: [230, 162, 110], size: 12 },
        edgeSelectedSymbol: { color: [230, 100, 10], size: 16 },
        pointerSize: 10,
        pointerSizeDefault: 2
    });

    undergroundWebApp.constant('mapSettings', {
        mapType: 'UTM33N',
        wkid: 32633,
    });

    undergroundWebApp.run([
        '$rootScope',
        '$timeout',
        'globalEvents',
        'pageSpecificDisplays',
        'authService',
        'systemService',
        'externalReportsService',
        'displayService',

        function ($rootScope, $timeout, globalEvents, pageSpecificDisplays, authService, systemService, externalReportsService, displayService) {
            authService.init().then(authData => {
                $rootScope.authData = authData;
                $rootScope.showMenu = authData.isAuth;

                systemService.getSystemInfo();
                externalReportsService.getAllExternalReports().then(reports => {
                    $rootScope.externalReports = reports;
                });
            });
            
            $rootScope.masterMode = false;
            $rootScope.isAdmin = isAdmin;
            $rootScope.isAccessible = isAccessible;
            $rootScope.getCachePrefix = getCachePrefix;

            //Subscribe to global events
            $rootScope.$on(globalEvents.$stateChangeSuccess, handleStateChangeSuccess);
            $rootScope.$on(globalEvents.accessTokenExpired, handleAccessTokenExpired);

            setKendoGridFilterOperatorOrder();

            kendo.culture("no-NO");

            //Global event handlers
            function handleStateChangeSuccess(_, toState) {
                $rootScope.selectedContainerId = undefined;

                let pageSpecificDisplay = pageSpecificDisplays[toState.name];
                if (pageSpecificDisplay) {
                    $timeout(250).then(displayService.applyPageViewMode(
                            pageSpecificDisplay.viewMode,
                            pageSpecificDisplay.isPriority)
                    );
                }
            }

            function handleAccessTokenExpired() {
                authService.logout();
            }

            //Private functions
            function getCachePrefix() {
                return $rootScope.authData.authUser.userName + '_';
            }

            function isAccessible(area, accessType) {
                return $rootScope.authData
                    && $rootScope.authData.isAuth
                    && $rootScope.authData.hasAccess
                    && $rootScope.authData.hasAccess(area, accessType);
            }

            function isAdmin() {
                return $rootScope.authData
                    && $rootScope.authData.authUser
                    && $rootScope.authData.authUser.groups
                    && $rootScope.authData.authUser.groups.some(g => g.groupName === 'Admin');
            }
        }]);

    //Private functions
    function setKendoGridFilterOperatorOrder() {
        kendo.ui.FilterMenu.fn.options.operators.string = {
            contains: kendo.ui.FilterCell.fn.options.operators.string.contains,
            doesnotcontain: kendo.ui.FilterCell.fn.options.operators.string.doesnotcontain,
            endswith: kendo.ui.FilterCell.fn.options.operators.string.endswith,
            eq: kendo.ui.FilterCell.fn.options.operators.string.eq,
            isempty: kendo.ui.FilterCell.fn.options.operators.string.isempty,
            isnotempty: kendo.ui.FilterCell.fn.options.operators.string.isnotempty,
            isnotnull: kendo.ui.FilterCell.fn.options.operators.string.isnotnull,
            isnotnullorempty: kendo.ui.FilterCell.fn.options.operators.string.isnotnullorempty,
            isnull: kendo.ui.FilterCell.fn.options.operators.string.isnull,
            isnullorempty: kendo.ui.FilterCell.fn.options.operators.string.isnullorempty,
            neq: kendo.ui.FilterCell.fn.options.operators.string.neq,
            startswith: kendo.ui.FilterCell.fn.options.operators.string.startswith,
        };
    }
}());
