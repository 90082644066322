(function () {
    'use strict';

    angular.module('UndergroundWebApp').factory('keycardService', keycardService);

    keycardService.$inject = [
        '$q',
        '$http'
    ];

    function keycardService(
        $q,
        $http
    ) {
        return {
            addKeycards,
            getKeyCardsForUsers,
            getKeyCardsByGroupId,
            getUsers,
            keyCardSearch,
            updateKeycard
        };

        function addKeycards(keyCards) {
            let deferred = $q.defer();

            if (keyCards.cardNumbers && keyCards.cardNumbers.length > 0) {
                $http.post('api/keycards/AddKeycards/', {
                    GroupId: keyCards.groupId,
                    CardNumbers: keyCards.cardNumbers,
                    LastName: keyCards.lastName,
                    CityAddress: keyCards.cityAddress,
                    CardDeactivated: keyCards.cardDeactivated,
                    Address: keyCards.address,
                    FirstName: keyCards.firstName,
                    CommuneId: keyCards.communeId,
                    Time: keyCards.time
                }).then(function (result) {
                    if (result && result.data !== undefined) {
                        deferred.resolve(result.data);
                    }
                    else {
                        deferred.reject();
                    }
                }, function () {
                    deferred.reject();
                });
            }

            return deferred.promise;
        }

        function getKeyCardsForUsers() {
            let deferred = $q.defer();

            $http.get('api/keyCards/GetKeyCardsForUsers').then(function (result) {
                if (result && result.data) {
                    deferred.resolve(result.data);
                }
                else {
                    deferred.reject();
                }
            });

            return deferred.promise;
        }

        function getKeyCardsByGroupId(groupId) {
            let deferred = $q.defer();

            $http.get('api/keyCards/GetKeyCardsByGroupId/' + groupId).then(function (result) {
                if (result && result.data) {
                    deferred.resolve(result.data);
                }
                else {
                    deferred.reject();
                }
            });

            return deferred.promise;
        }

        function getUsers() {
            return $http.get('api/keycards/getusers/')
                .then(result => result.data);
        }

        function keyCardSearch(filter) {
            return $http.get('api/keycards/KeyCardSearch/' + filter)
                .then(result => result.data);
        }

        function updateKeycard(keyCard) {
            return $http.put('api/keycards/updatekeycard', keyCard)
                .then(result => result.data);
        }
    }
})();
