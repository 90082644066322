(function () {
    'use strict';

    angular.module('UndergroundWebApp').factory('guidService', guidService)

    guidService.$inject = [];

    function guidService($) {
        var service = {
            uuidv4: uuidv4,
            emptyGuid: emptyGuid
        };

        return service;

        function uuidv4() {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
        }

        function emptyGuid(){
            return '00000000-0000-0000-0000-000000000000';
        }

    }
})();
