angular.module('UndergroundWebApp').directive('sensorApiLocationPopup', ['localStorageService', function (localStorageService) {
    'use strict';

    function setPlacement(element, model) {
        element.css('left', model.left);
        element.css('top', model.top);
    }

    return {
        restrict: 'E',
        replace: true,
        templateUrl: 'app/shared/popups/sensor-api-location-popup.html',
        link: function (scope, element) {
            scope.$on('showSensorApiLocationPopup', function (_event, model) {
                element.show();
                setPlacement(element, model);
                scope.model = model;
                scope.$apply()
            });

            scope.$on('hideSensorApiLocationPopup', function () {
                element.hide();
            });
        }
    }
}]);
