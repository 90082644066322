(function () {
    'use strict';

    angular.module('UndergroundWebApp').controller('KeycardsOverviewController', KeycardsOverviewController);

    KeycardsOverviewController.$inject = [
        '$rootScope',
        '$scope',
        '$stateParams',
        '$kWindow',
        '$timeout',
        'formTypes',
        'containerService',
        'keycardService'
    ];

    function KeycardsOverviewController(
        $rootScope,
        $scope,
        $stateParams,
        $kWindow,
        $timeout,
        formTypes,
        containerService,
        keycardService
    ) {
        $scope.containerId = $stateParams.containerId;
        $scope.noCache = $stateParams.noCache;

        $scope.groupName = '';
        $scope.KeyCardsOverviewGridData = [];
        $scope.exportToExcel = exportToExcel;
        $scope.excelFileName = 'Nokkelkortoversikt.xlsx';

        $scope.openKeyCardRegistrationModal = openKeyCardRegistrationModal;
        $scope.openUpdateKeyCardModal = openUpdateKeyCardModal;
        $scope.exportToExcel = exportToExcel;

        $scope.groupOptions = {
            dataSource: {
                transport: {
                    read: readGroups
                }
            },
            dataTextField: 'group',
            dataValueField: 'groupId',
            change: function (e) {
                var selectedGroupId = this.value();
                $scope.filteredKeyCardsData = _.filter($scope.KeyCardsOverviewGridData, kC => kC.groupId === Number(selectedGroupId));
                $scope.keycardsOverviewGrid.dataSource.data($scope.filteredKeyCardsData);
                $scope.keycardsOverviewGrid.refresh();
            }
        };

        var cardNumber = $stateParams.keycardNumber;
        var cardFilter = cardNumber ? { field: "cardNumber", operator: "eq", value: cardNumber } : null;
        $scope.keycardsOverviewGridOptions = {
            dataSource: {
                pageSize: 70,
                filter: cardFilter,
                data: $scope.KeyCardsOverviewGridData,
                sort: {
                    field: "cardNumber",
                    dir: "asc"
                }
            },
            sortable: true,
            filterable: true,
            noRecords: true,
            resizable: true,
            messages: {
                noRecords: 'Ingen treff'
            },
            scrollable: {
                virtual: true
            },
            columns: [
                {
                    title: 'Kortnummer',
                    field: 'cardNumber',
                    width: '150px'
                },
                {
                    title: 'Fornavn',
                    field: 'firstName',
                    width: '70px'
                },
                {
                    title: 'Etternavn',
                    field: 'lastName',
                    width: '70px'
                },
                {
                    title: 'Poststed',
                    field: 'cityAddress',
                    width: '150px'
                },
                {
                    title: 'Adresse',
                    field: 'streetAddress',
                    width: '150px'
                },
                {
                    title: 'Tid',
                    field: 'timeStamp',
                    width: '130px',
                    template: '#= kendo.toString(kendo.parseDate(timeStamp), "dd.MM.yyyy HH:mm:ss") #'
                },
                {
                    title: 'Område',
                    field: 'userName',
                    width: '70px'
                },
                {
                    field: 'actions',
                    title: ' ',
                    width: '35px',
                    template: '<div class="grid-tooltip" ng-click="openUpdateKeyCardModal(dataItem)"><i class="fa fa-1-5x fa-pencil" aria-hidden="true"></div>',
                    filterable: false,
                    sortable: false,
                    groupable: false
                }
            ],
            excel: {
                fileName: $scope.excelFileName,
                filterable: true,
                allPages: true
            },
            excelExport: keycardsOverviewGridExcelExport
        };

        $scope.getContainerPromise = $scope.getContainerPromise || containerService.getContainerById($scope.containerId);

        $timeout(250).then(initController);

        function initController() {
            if ($scope.authData && $scope.authData.isAuth) {
                getKeyCardsOverviewGridData();
            }

            $scope.$on('groupUpdated', onGroupUpdated);
        }

        function onGroupUpdated(evt, group) {
            $("#keyCardGroupCombo").data("kendoDropDownList").dataSource.read();

        }

        function getKeyCardsOverviewGridData() {
            $scope.KeyCardsOverviewGridData = [];

            if ($scope.containerId) {
                $rootScope.$broadcast('showBusyIndicator', {
                    id: 'KeyCardsOverviewIndicator',
                    destination: '#keycardsOverviewGrid',
                    message: 'Henter data...',
                    overlay: true,
                    positionClass: {
                        top: '50%',
                        left: '0px',
                        right: '0px'
                    }
                });

                $scope.getContainerPromise.then(function (container) {
                    if (container && container.groupId) {
                        $scope.groupId = container.groupId;

                        keycardService.getKeyCardsByGroupId(container.groupId).then(function (keyCards) {
                            if (keyCards && keyCards.length > 0) {
                                keyCards = _.uniqBy(keyCards, 'cardNumber');

                                _.each(keyCards, function (item) {
                                    extendGridData(item);
                                });

                                $scope.KeyCardsOverviewGridData = keyCards;
                                $scope.filteredKeyCardsData = $scope.selectedGroup ? _.filter($scope.KeyCardsOverviewGridData, kC => kC.groupId === $scope.selectedGroup.groupId) : [];
                                $scope.keycardsOverviewGrid.dataSource.data($scope.filteredKeyCardsData);
                                $scope.keycardsOverviewGrid.refresh();
                            }
                            $rootScope.$broadcast('hideBusyIndicator', 'KeyCardsOverviewIndicator');
                        }, function () {
                            $rootScope.$broadcast('hideBusyIndicator', 'KeyCardsOverviewIndicator');
                        });
                    }
                }, function () {
                    $rootScope.$broadcast('hideBusyIndicator', 'KeyCardsOverviewIndicator');
                });
            }
        }

        function extendGridData(item) {
            item.time = item.time == null ? '' : item.time;
        }

        function keycardsOverviewGridExcelExport(e) {
            e.workbook.fileName = 'Nokkelkortoversikt ' + kendo.toString(new Date, 'ddMMyyyy_HHmmss') + '.xlsx';
            var sheet = e.workbook.sheets[0];
            var columnWidths = this.element.find('.k-grid-header colgroup col');

            for (var i = 0; i < sheet.columns.length; i++) {
                if ($(columnWidths[i]).attr('style')) {
                    sheet.columns[i].width = 160;
                } else {
                    sheet.columns[i].width = 16;
                }
            }
        }

        function exportToExcel() {
            $scope.keycardsOverviewGrid.saveAsExcel();
        }

        function openKeyCardRegistrationModal() {
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: 'Registrer nøkkelkort',
                    resizable: false,
                    height: 800,
                    width: 500,
                    visible: false
                },
                templateUrl: 'app/keyCards/views/keycard-registration-modal-view.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'KeyCardRegistrationModalController',
                resolve: {
                    currentFormType: function () {
                        return formTypes.add;
                    },
                    groupName: function () {
                        return $scope.selectedGroup.group;
                    },
                    groupId: function () {
                        return $scope.selectedGroup.groupId;
                    },
                    keyCard: function () {
                        return null;
                    }
                }
            });

            windowInstance.result.then(function (result) {
                if (result) {
                    getKeyCardsOverviewGridData();
                }
            });
        }

        function openUpdateKeyCardModal(dataItem) {
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: 'Nøkkelkort',
                    resizable: false,
                    height: 800,
                    width: 500,
                    visible: false
                },
                templateUrl: 'app/keyCards/views/keycard-registration-modal-view.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'KeyCardRegistrationModalController',
                resolve: {
                    currentFormType: function () {
                        return formTypes.edit;
                    },
                    groupName: function () {
                        return dataItem.groupName;
                    },
                    groupId: function () {
                        return dataItem.groupId;
                    },
                    keyCard: function () {
                        return dataItem;
                    }
                }
            });

            windowInstance.result.then(function (result) {
                if (result) {
                    getKeyCardsOverviewGridData();
                }
            });
        }

        function readGroups(e) {
            $scope.getContainerPromise.then(container => {
                if (container && container.groups) {
                    $scope.groups = container.groups
                    e.success($scope.groups);
                    if ($scope.groups && $scope.groups.length > 0) {
                        $scope.selectedGroup = $scope.groups[0];
                    }
                } else {
                    e.success([]);
                }

            });
        }
    }
})();
