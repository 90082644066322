(function () {
    'use strict';

    angular.module('UndergroundWebApp').factory('areaUtility', areaUtility);

    areaUtility.$inject = [
        'mapSettings',
        'mapUtility'
    ];

    function areaUtility(
        mapSettings,
        mapUtility
    ) {
        return {
            createAreaPolygon,
            createPolygon,
            createAreaTextGraphic
        };

        function createAreaPolygon(area, fillSymbol, Graphic, Point, Polygon, SpatialReference) {
            let points = area.points
                .sort((a, b) => a.order - b.order)
                .map((point) => {
                    const coords = mapUtility.convertToUTM33N(point.latitude, point.longitude);
                    return [coords.X, coords.Y];
                });

            const areaGraphic = createPolygon(points, fillSymbol, { id: area.id }, Graphic, Point, Polygon, SpatialReference);
            areaGraphic.name = area.description;
            return areaGraphic;
        }

        function createPolygon(points, fillSymbol, additionalAttributes, Graphic, Point, Polygon, SpatialReference) {
            const pathPoints = points
                .map((point) => new Point(point[0], point[1], new SpatialReference({ wkid: mapSettings.wkid })));

            const polygon = new Polygon(new SpatialReference({ wkid: mapSettings.wkid }));
            polygon.addRing(pathPoints);

            const attributes = {
                type: 'area',
                ...additionalAttributes,
            };

            return new Graphic(polygon, fillSymbol, attributes);
        }

        function createAreaTextGraphic(graphic, Color, Font, TextSymbol, Point, Graphic) {
            var centroid = graphic.geometry.centroid;

            //draw text
            var font = new Font("12px", Font.STYLE_NORMAL, Font.VARIANT_NORMAL, Font.WEIGHT_BOLDER);
            var text = new TextSymbol(graphic.name, font, new Color([0, 0, 0]));

            //fix centroid sign
            var firstPoint = graphic.geometry.rings[0][0];
            var x = (Math.sign(firstPoint[0]) === Math.sign(centroid.x)) ? centroid.x : -centroid.x;
            var y = (Math.sign(firstPoint[1]) === Math.sign(centroid.y)) ? centroid.y : -centroid.y;

            var point = new Point(x, y);

            return new Graphic({
                geometry: point,
                symbol: text,
                attributes: { type: 'text' },
            });
        }
    }
})();
