(function () {
    'use strict';

    angular.module('UndergroundWebApp').controller('BatteryVoltageController', BatteryVoltageController);

    BatteryVoltageController.$inject = [
        '$q',
        '$scope',
        '$rootScope',
        'batteryVoltagesService',
        'sensorApiAreaService',
        'filterStateService',
        'municipalityService',
        'formattingUtility',
        '$translate'
    ];

    function BatteryVoltageController(
        $q,
        $scope,
        $rootScope,
        batteryVoltagesService,
        sensorApiAreaService,
        filterStateService,
        municipalityService,
        formattingUtility,
        $translate
    ) {
        const filterCacheKey = 'BatteryVoltageFilterKey';
        $scope.voltSlider = null;
        $scope.subVoltSlider = null;

        $scope.filter24NotReported = false;        
        $scope.detailsExpanded = false;
        $scope.allStatuses = [];
        $scope.detailGrids = [];
        $scope.detailExportPromises = [];

        //Event handlers
        $scope.handleNotReportedButtonClick = handleNotReportedButtonClick;
        $scope.toggleExpandDetails = toggleExpandDetails;
        $scope.exportToExcel = exportToExcel;
        $scope.exportAsPdf = exportAsPdf;
        $scope.show24NotReportedTrue = $translate.instant("REPORTS_BATTERY_VOLTAGE_VIEW_FILTER_SHOW24NOTREPORTED_BUTTON_TRUE");
        $scope.show24NotReportedFalse = $translate.instant("REPORTS_BATTERY_VOLTAGE_VIEW_FILTER_SHOW24NOTREPORTED_BUTTON_FALSE");

        $scope.voltIntervals = [
            { id: 1, min: 0.0, max: 11.9 },
            { id: 2, min: 12.0, max: 12.4 },
            { id: 3, min: 12.0, max: 12.6 },
            { id: 4, min: 12.6, max: 20.0 },
        ];

        $scope.filterData = {
            areas: [],
            municipalities: [],
            voltIntervals: [
                { id: 1, label: 'volt < 12' },
                { id: 2, label: '12.0 <= volt <= 12.4' },
                { id: 3, label: '12.0 <= volt <= 12.6' },
                { id: 4, label: '12.6 < volt' },
            ]
        };

        $scope.filter = filterStateService.getFilterFromLocalStorage(filterCacheKey, getEmptyFilter());

        $scope.areasDropdownOptions = {
            settings: {
                externalIdProp: '',
            },
            events: {
                onSelectionChanged: filterData,
            },
        }

        $scope.voltDropdownOptions = {
            settings: {
                externalIdProp: '',
            },
            events: {
                onSelectionChanged: () => {
                    //Update slider
                    if ($scope.filter.voltIntervals.length > 0) {
                        let voltInterval = getMinMaxVoltValues($scope.filter.voltIntervals);
                        $scope.voltSlider.set([voltInterval.min, voltInterval.max]);
                        setSubVoltSliderAboveMainSlider();
                    } else {
                        $scope.voltSlider.set([0.0, 20.0]);
                        setSubVoltSliderAboveMainSlider();
                    }

                    filterData();
                },
            }
        };

        $scope.voltDropdownTranslations = {
            checkAll: $translate.instant("G_CHECKALL"),
            uncheckAll: $translate.instant("G_UNCHECK_ALL"),
            buttonDefaultText: $translate.instant("REPORTS_BATTERY_VOLTAGE_CONTROLLER_VOLT_DDL_BUTTON_DEFAULT_TEXT"),
            dynamicButtonTextSuffix: $translate.instant("G_DYNAMIC_BTN_TEXT_SUFFIX")
        };

        $scope.dropdownTranslations = {
            checkAll: $translate.instant("G_CHECKALL"),
            uncheckAll: $translate.instant("G_UNCHECK_ALL"),
            buttonDefaultText: $translate.instant("REPORTS_BATTERY_VOLTAGE_CONTROLLER_DDL_BUTTON_DEFAULT_TEXT"),
            dynamicButtonTextSuffix: $translate.instant("G_DYNAMIC_BTN_TEXT_SUFFIX")
        };

        $scope.municipalitiesDropdownOptions = {
            settings: {
                externalIdProp: '',
            },
            events: {
                onSelectionChanged: filterData,
            },
        }

        $scope.dropdownMunicipalityTranslations = {
            checkAll: $translate.instant("G_CHECKALL"),
            uncheckAll: $translate.instant("G_UNCHECK_ALL"),
            buttonDefaultText: $translate.instant("REPORTS_BATTERY_VOLTAGE_CONTROLLER_MUNINCIPALITY_DDL_BUTTON_DEFAULT_TEXT"),
            dynamicButtonTextSuffix: $translate.instant("G_DYNAMIC_BTN_TEXT_SUFFIX")
        };

        $scope.batteryVoltagesGridOptions = {
            dataSource: {
                data: [],
                sort: { field: 'createdAt', dir: 'desc' },
                pageSize: 70,
                schema: {
                    model: {
                        fields: {
                            volt: { type: 'number' }
                        }
                    }
                }
            },
            dataBound: handleVoltagesGridDataBound,
            sortable: true,
            filterable: true,
            resizable: true,
            noRecords: true,
            detailInit: detailInit,
            detailTemplate: kendo.template($('#record-details').html()),
            messages: {
                noRecords: ' '
            },
            scrollable: {
                virtual: true
            },
            excel: {
                allPages: true,
                fileName: `${$translate.instant("REPORTS_BATTERY_VOLTAGE_CONTROLLER_EXPORT_FILENAME")}.xlsx`,
            },
            pdf: {
                allPages: true,
                fileName: `${$translate.instant("REPORTS_BATTERY_VOLTAGE_CONTROLLER_EXPORT_FILENAME")}.pdf`,
            },
            excelExport : overriddenExcelExport,
            columns: [
                {
                    field: 'createdAt',
                    title: $translate.instant("REPORTS_BATTERY_VOLTAGE_CONTROLLER_TABLE_HEADER_CREATED_AT"),
                    template: `#= kendo.toString(kendo.parseDate(createdAt), "${$translate.instant("G_DEFAULT_DATE_FORMAT")}") #`,
                    filterable: false,
                    width: 145,
                },
                {
                    field: 'locationName',
                    title: $translate.instant("REPORTS_BATTERY_VOLTAGE_CONTROLLER_TABLE_HEADER_LOCATION_NAME"),
                    template: '<a href="#= locationHref#">#= locationName#</>'
                },
                {
                    field: 'municipality',
                    template: '#= municipality==null ? " " : municipality #',
                    title: $translate.instant("REPORTS_BATTERY_VOLTAGE_CONTROLLER_TABLE_HEADER_MUNINCIPALITY"),
                },
                {
                    field: 'deviceExternalId',
                    title: $translate.instant("REPORTS_BATTERY_VOLTAGE_CONTROLLER_TABLE_HEADER_DEVICE_EXTERNAL_ID"),
                    width: 100,
                },
                {
                    field: 'fractionName',
                    title: $translate.instant("REPORTS_BATTERY_VOLTAGE_CONTROLLER_TABLE_HEADER_FRACTION"),
                    width: 110,
                    filterable: {
                        multi: true,
                    },
                },
                {
                    field: 'volt',
                    title: $translate.instant("REPORTS_BATTERY_VOLTAGE_CONTROLLER_TABLE_HEADER_VOLT"),
                    width: 85
                },
                {
                    field: 'locationId',
                    title: "LocId",
                    hidden: true
                }
            ],
        };
        
        $scope.detailGridOptions = {
            ...$scope.batteryVoltagesGridOptions,
            detailInit: null,
            detailTemplate: null,
        }

        setTimeout(initController, 250);

        function initController() {
            showBusyIndicator();

            $q.all([
                loadAllStatuses(),
                getMunicipalities(),
                getActiveAreas()
            ]).then(data => {
                let municipalities = data[1],
                    activeAreas = data[2];

                loadMunicipalitiesToFilter(municipalities);
                loadAreasToFilter(activeAreas);
                initSliders();
                filterData();
            }).finally(hideBusyIndicator);
        }

        function getVoltageRangeOfBatteries(){
            let volts = $scope.allStatuses.map(x => Number(x.volt));
            let max = Math.ceil(Math.max(...volts));
            let min = Math.floor(Math.min(...volts));

            max = max <= 20 ? max : 20; 
            
            return {
                max,
                min
            }
        }

        function initSliders() {
            const defaultFormatter = {
                to: (value) => value,
                from: (value) => value,
            };

            $scope.voltageRange = getVoltageRangeOfBatteries();

            if ($scope.voltageRange.min === $scope.voltageRange.max) return;

            let sliderDiv = document.getElementById('volt-slider');
            let subVoltSliderDiv = document.getElementById('sub-volt-slider');

            let sliderProps = {
                start: [0, 100],
                connect: true,
                tooltips: [defaultFormatter, defaultFormatter],
                step: 0.1,
                range: {
                    'min': $scope.voltageRange.min,
                    'max': $scope.voltageRange.max
                }
            };

            $scope.voltSlider = noUiSlider.create(sliderDiv, sliderProps);
            $scope.subVoltSlider = noUiSlider.create(subVoltSliderDiv, sliderProps);

            if ($scope.filter.voltIntervals.length > 0) {
                let voltInterval = getMinMaxVoltValues($scope.filter.voltIntervals);
                $scope.voltSlider.set([voltInterval.min, voltInterval.max]);
                $scope.subVoltSlider.set([voltInterval.min, voltInterval.max]);
            } else {
                $scope.voltSlider.set([$scope.voltageRange.min, $scope.voltageRange.max]);
                setSubVoltSliderAboveMainSlider();
            }

            $scope.voltSlider.on('end', filterData);
            $scope.voltSlider.on('end', setSubVoltSliderAboveMainSlider);
            $scope.subVoltSlider.on('end', filterDetailGridsData);
        }

        function setSubVoltSliderAboveMainSlider(){
            let voltRangeOfMainSlider = $scope.voltSlider.get().map(x => Number(x));

            if(voltRangeOfMainSlider[1] == $scope.voltageRange.max){
                $scope.subVoltSlider.set([$scope.voltageRange.max - 0.1, $scope.voltageRange.max]);
            }else{
                $scope.subVoltSlider.set([voltRangeOfMainSlider[1] + 0.1, $scope.voltageRange.max]);
            }
        }

        //Data retrieval
        function getActiveAreas() {
            return sensorApiAreaService.getAreas()
                .then((areas) => {
                const activeAreas = areas.filter(area => area.isActive === true);
                activeAreas.sort((a, b) => a.description > b.description ? 1 : -1);

                return activeAreas;
            });
        }

        function getMunicipalities() {
            return municipalityService.getMunicipalities().then((municipalities) => {
                const allMunicipalits = municipalities.map((m) => ({
                    label: m.name,
                    id: m.code,
                }));

                return allMunicipalits;
            });
        }

        function loadAllStatuses() {
            return batteryVoltagesService.getBatteryVoltages().then(function (batteryVoltages) {
                batteryVoltages.forEach(extBatteryVoltageReport);

                $scope.allStatuses = batteryVoltages;

                $scope.batteryVoltagesGrid.dataSource.data($scope.allStatuses);
            }).catch(function (error) {
                console.error(error);
            });
        }

        //Event handlers
        function handleVoltagesGridDataBound(e) {
            var items = e.sender.items();

            let dateToCheck = new Date();
            dateToCheck.setHours(dateToCheck.getHours() - 24);

            items.each(function () {
                var dataItem = e.sender.dataItem(this),
                    createdAtDate = new Date(dataItem.createdAt);

                if (createdAtDate < dateToCheck) {
                    this.className += ' old-voltage-value';
                }
            });
        }

        function detailInit(e) {           
            let locationId = e.data.locationId;
            let deviceId = e.data.deviceId;
            let sliderValues = $scope.subVoltSlider.get();

            let filteredStatuses = $scope.allStatuses.filter(x => x.locationId == locationId);
            filteredStatuses = getStatusesFilteredByVoltMinMax(filteredStatuses, parseFloat(sliderValues[0]), parseFloat(sliderValues[1]));
                      
            let detailGrid = e.detailRow.find('.detail-grid');
            detailGrid[0].setAttribute('id', deviceId);
            detailGrid[0].setAttribute('data-locationId', locationId);
            let data = detailGrid.data();
            data.kendoGrid.dataSource.data(filteredStatuses);
            
            $scope.detailGrids.push({
                id: deviceId,
                grid: data
            });

            cleanUpClosedDetailGrids()
        }
        
        function cleanUpClosedDetailGrids() { 
            
            let openedDetailGrids = [];
            
            $scope.detailGrids.forEach(element => {   
                let gridId = element.id;    
                let gridElement = $(`#${gridId}`);

                if(gridElement 
                    && element 
                    && element.grid 
                    && element.grid.kendoGrid 
                    && element.grid.kendoGrid.dataSource){
                    openedDetailGrids.push(element);
                }
            });

            $scope.detailGrids = openedDetailGrids;
        }

        function handleNotReportedButtonClick() {
            $scope.filter.show24NotReported = !$scope.filter.show24NotReported;

            filterData();
        }

        function exportToExcel() {
            $scope.batteryVoltagesGrid.saveAsExcel();
        }

        function exportAsPdf() {
            $scope.batteryVoltagesGrid.saveAsPDF();
        }

        async function overriddenExcelExport(e) {
            e.preventDefault();

            try {
                showBusyIndicator();
                $scope.detailsExpanded = true;
                await expandAll();
          
                var workbook = e.workbook;
          
                $scope.detailExportPromises = [];
          
                var masterData = e.data;
          
                for (var rowIndex = 0; rowIndex < masterData.length; rowIndex++) {                
                    var deviceid = masterData[rowIndex].deviceId;
                    let detailGridelement = document.getElementById(deviceid);
                    
                    if(detailGridelement){
                        exportChildData(masterData[rowIndex].locationId, rowIndex);
                    }              
                }
          
                $.when.apply(null, $scope.detailExportPromises)
                .then(function() {
                  // Get the export results.
                  var detailExports = $.makeArray(arguments);
          
                  detailExports = detailExports.filter(x => x.sheet.rows.length > 1);
                  // Sort by masterRowIndex.
                  detailExports.sort(function(a, b) {
                    return a.masterRowIndex - b.masterRowIndex;
                  });
          
                  // Add an empty column.
                  workbook.sheets[0].columns.unshift({
                    width: 30
                  });
          
                  // Prepend an empty cell to each row.
                  for (var i = 0; i < workbook.sheets[0].rows.length; i++) {
                    workbook.sheets[0].rows[i].cells.unshift({});
                  }
          
                  // Merge the detail export sheet rows with the master sheet rows.
                  // Loop backwards so the masterRowIndex does not need to be updated.
                  for (var i = detailExports.length - 1; i >= 0; i--) {
                    var masterRowIndex = detailExports[i].masterRowIndex + 1; // compensate for the header row
          
                    var sheet = detailExports[i].sheet;
          
                    // Prepend an empty cell to each row.
                    for (var ci = 0; ci < sheet.rows.length; ci++) {
                      if (sheet.rows[ci].cells[0].value) {
                        sheet.rows[ci].cells.unshift({});
                        sheet.rows[ci].cells.unshift({});
                      }                 
                    }
          
                    // Insert the detail sheet rows after the master row.
                    [].splice.apply(workbook.sheets[0].rows, [masterRowIndex + 1, 0].concat(sheet.rows));
                  }
          
                  // Save the workbook.
                  kendo.saveAs({
                    dataURI: new kendo.ooxml.Workbook(workbook).toDataURL(),
                    fileName: "Export.xlsx"
                  });      
          
                });
            }
            finally{
                hideBusyIndicator();
            }
        }
        function exportChildData(locationId, rowIndex) {
            var deferred = $.Deferred();
        
            $scope.detailExportPromises.push(deferred);
        
            var rows = [{
                cells: [
                { value: "createdAt" },
                { value: "locationName" },
                { value: "municipality" },
                { value: "deviceExternalId" },
                { value: "fractionName" },
                { value: "volt" }
                ]
            }];
            var sliderValues = $scope.subVoltSlider.get();
        
            var dataSource = $scope.allStatuses.filter(x => x.locationId == locationId);
            dataSource = getStatusesFilteredByVoltMinMax(dataSource,parseFloat(sliderValues[0]), parseFloat(sliderValues[1]))
        
            var exporter = new kendo.ExcelExporter({
                columns: [{
                field: "createdAt", title: "Dato/tid"
                }, {
                field: "locationName", title: "Adresse"
                }, {
                field: "municipality", title: "Kommunenavn"
                },{
                field: "deviceExternalId", title: "Ekst.ID"
                }, {
                field: "fractionName", title: "Fraksjon"
                },{
                field: "volt", title: "Volt"
                }],
                dataSource: dataSource
            });
        
            exporter.workbook().then(function(book, data) {
                deferred.resolve({
                masterRowIndex: rowIndex,
                sheet: book.sheets[0]
                });
            });
        }

        //Filter management
        function filterData() {
            try {
                const sliderValues = $scope.voltSlider ? $scope.voltSlider.get() : null;
                let filteredStatuses = $scope.allStatuses;

                $scope.selectedAreas = $scope.filter.areas;

                filteredStatuses = getStatusesFilteredByMunicipalities(filteredStatuses);
                filteredStatuses = getStatusesByNotReportedCheck(filteredStatuses, $scope.filter.show24NotReported);
                filteredStatuses = getStatusesFilteredByAreas(filteredStatuses);
                filteredStatuses = sliderValues ? getStatusesFilteredByVoltMinMax(filteredStatuses, parseFloat(sliderValues[0]), parseFloat(sliderValues[1])) : filteredStatuses;

                $scope.batteryVoltagesGrid.dataSource.data(filteredStatuses);
                $('#batteryVoltagesGrid').getKendoGrid().refresh();

                filterStateService.storeFilterInLocalStorage(filterCacheKey, getCurrentFilter());
            } catch (error) {
                console.error(error);
            }
        }

        function filterDetailGridsData() {
            let sliderValues = $scope.subVoltSlider.get();
            
            $scope.detailGrids.forEach(element => {       
                let gridElement = $(`#${element.id}`);

                if(gridElement){
                    let filteredStatuses = $scope.allStatuses.filter(x => x.locationId == element.id);  
                    filteredStatuses = getStatusesFilteredByVoltMinMax(filteredStatuses, parseFloat(sliderValues[0]), parseFloat(sliderValues[1]));     
    
                    element.grid.kendoGrid.dataSource.data(filteredStatuses);
                    gridElement.getKendoGrid().refresh();
                }
            });

            filterStateService.storeFilterInLocalStorage(filterCacheKey, getCurrentFilter());
        }

        function getEmptyFilter() {
            return {
                areas: [],
                municipalities: [],
                voltIntervals: [],
                searchTerm: '',
                show24NotReported: false,
            };
        }

        function getAreasForFilter(areas) {
            if (!areas) {
                return [];
            }

            return _.chain(areas)
                .filter((area) => !!area)
                .map((area) => ({
                    label: area.description,
                    id: area.id,
                    points: area.points,
                }))
                .value();
        }

        function loadAreasToFilter(areas) {
            $scope.filterData.areas = _.unionBy(
                getAreasForFilter(areas),
                $scope.filter.areas,
                'id'
            );
            $scope.filterData.areas = _.sortBy($scope.filterData.areas, 'description');
        }

        function loadMunicipalitiesToFilter(municipalities) {
            $scope.filterData.municipalities = _.unionBy(
                getMunicipalitiesForFilter(municipalities),
                $scope.filter.municipalities,
                'id'
            );
            $scope.filterData.municipalities = _.sortBy($scope.filterData.municipalities, 'name');
        }

        function getMunicipalitiesForFilter(municipalities) {
            if (!municipalities) {
                return [];
            }

            return _.chain(municipalities)
                .filter((municipality) => !!municipality)
                .map((municipality) => ({
                    label: municipality.label,
                    id: municipality.id,
                }))
                .value();
        }

        function getMinMaxVoltValues(voltIntervals) {
            let currentMin = 20.0,
                currentMax = 0.0;

            voltIntervals.forEach(interval => {
                let relatedMinMax = $scope.voltIntervals.find(i => i.id === interval.id);

                currentMin = relatedMinMax.min < currentMin ? relatedMinMax.min : currentMin;
                currentMax = relatedMinMax.max > currentMax ? relatedMinMax.max : currentMax;
            });

            return {
                min: currentMin,
                max: currentMax
            };
        }

        //Status filtering functions
        function getStatusesByNotReportedCheck(statuses, show24NotReported) {
            if (!show24NotReported) return statuses;

            let currentDate = new Date();
            currentDate.setHours(currentDate.getHours() - 24);

            return _.filter(statuses, function (b) {
                return new Date(b.createdAt) < currentDate
            });
        }

        function getStatusesFilteredByMunicipalities(statuses) {
            const filteringIsActive = $scope.filter.municipalities.length != 0;
            return filteringIsActive ? statuses.filter( status => isInSelectedMunicipalities(status)) : statuses;
        }

        function getStatusesFilteredByVoltMinMax(statuses, min, max) {
            if (!statuses) return [];

            return statuses.filter(s => min <= s.volt && s.volt <= max);
        }

        function getStatusesFilteredByAreas(statuses) {
            if ($scope.filter.areas.length === 0) return statuses;

            return statuses.filter(batteryStatus => isInSelectedAreas(batteryStatus));
        }

        //Filter serialization
        function getCurrentFilter() {
            return isFiltersEmpty()
                ? null
                : $scope.filter;
        }

        function isFiltersEmpty() {
            return $scope.filter.searchTerm === ''
                && isAdvancedFiltersEmptpy();
        }

        function isAdvancedFiltersEmptpy() {
            return $scope.filter.areas.length === 0
                && $scope.filter.municipalities.length === 0
                && $scope.filter.voltIntervals.length === 0
                && !$scope.filter.show24NotReported;
        }

        //Private functions
        function isInSelectedAreas(batteryStatus) {
            for (var i = 0; i < $scope.filter.areas.length; i++) {
                if (isBatteryInArea($scope.filter.areas[i], batteryStatus)) {
                    return true;
                }
            }

            return false;
        }

        function isBatteryInArea(area, batteryStatus) {
            if (!area || !batteryStatus || !batteryStatus.latitude && !batteryStatus.longitude) {
                return false;
            }

            let inside = false;
            let x = batteryStatus.longitude, y = batteryStatus.latitude;
            for (let i = 0, j = area.points.length - 1; i < area.points.length; j = i++) {
                let xi = area.points[i].longitude, yi = area.points[i].latitude;
                let xj = area.points[j].longitude, yj = area.points[j].latitude;

                let intersect = ((yi > y) !== (yj > y))
                    && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);

                if (intersect) inside = !inside;
            }

            return inside;
        }

        function isInSelectedMunicipalities(status) {
            for (var i = 0; i < $scope.filter.municipalities.length; i++) {
                if ($scope.filter.municipalities[i].id === status.municipalityCode) {
                    return true;
                }
            }
            return false;
        }

        function extBatteryVoltageReport(batteryVoltage) {
            if (batteryVoltage && batteryVoltage.volt && typeof batteryVoltage.volt === 'number') {
                try {
                    batteryVoltage.volt = formattingUtility.toFixedTrunc(batteryVoltage.volt, 2);
                } catch {
                    batteryVoltage.volt = 0;
                }               
            }

            // //Mock data for local testing
            // if(batteryVoltage.volt === 0){
            //     batteryVoltage.volt = Math.random()*16;
            //     batteryVoltage.volt = formattingUtility.toFixedTrunc(batteryVoltage.volt, 2);
            // }

            if (batteryVoltage.locationId) {
                batteryVoltage.locationHref = location.origin + '/main/location/' + batteryVoltage.locationId + '/info';
            } else {
                batteryVoltage.locationHref = '';
            }            
        }

        function showBusyIndicator() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'batteryVoltage',
                destination: '#batteryVoltagesGrid',
                message: $translate.instant("G_BUSY_INDICATOR"),
                overlay: true,
                positionClass: {
                    top: '50%',
                    left: '0px',
                    right: '0px'
                }
            });
        }

        function hideBusyIndicator() {
            $rootScope.$broadcast('hideBusyIndicator', 'batteryVoltage');
        }

        async function toggleExpandDetails() {
            $scope.detailsExpanded = !$scope.detailsExpanded;
            showBusyIndicator();

        
            if($scope.detailsExpanded){
                await expandAll();
            }else{
                await collapseAll();
            }
        
            hideBusyIndicator();
        }
        
        function expandAll(){
            return new Promise(resolve => {
                var grid = $("#batteryVoltagesGrid").data("kendoGrid");
                setTimeout(() => {
                    $(".k-master-row").each(function (index) {
                        grid.expandRow(this);
                    }).promise().done(resolve);
                }, 0);
            });
        }
        
        function collapseAll(){
            return new Promise(resolve => {
                var grid = $("#batteryVoltagesGrid").data("kendoGrid");
                setTimeout(() => {
                    $(".k-master-row").each(function (index) {
                        grid.collapseRow(this);
                    }).promise().done(resolve);
                }, 0);
            });
        }
    }
})();
