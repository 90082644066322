(function () {
    angular.module('UndergroundWebApp').controller('ContainerEventsPictureCarouselController', ContainerEventsPictureCarouselController);

    ContainerEventsPictureCarouselController.$inject = [
        '$scope',
        'pictureUrls'
    ];

    function ContainerEventsPictureCarouselController(
        $scope,
        pictureUrls
    ) {
        $scope.source = {};

        initController();

        function initController() {
            $scope.source = new kendo.data.DataSource({
                data: pictureUrls,
                pageSize: 1
            });
        }
    }
})();
