(function () {
    'use strict';

    angular.module('UndergroundWebApp').factory('externalSystemsService', externalSystemsService)

    externalSystemsService.$inject = [
        '$http',
    ];

    function externalSystemsService(
        $http,
    ) {
        var service = {
            getExternalSystems
        };

        return service;

        function getExternalSystems() {
            return $http.get('api/externalSystems')
                .then(result => result.data);
        }
    }
})();
