(function () {
    'use strict'

    angular.module('UndergroundWebApp').controller('ContainerEventsController', ContainerEventsController);

    ContainerEventsController.$inject = [
        '$scope',
        '$stateParams',
        'containerService',
        '$kWindow',
        '$translate'
    ];

    function ContainerEventsController(
        $scope,
        $stateParams,
        containerService,
        $kWindow,
        $translate
    ) {
        $scope.deviceLogs = [];
        $scope.openMediaModal = openMediaModal;

        var media = {};

        initController();

        function initController() {
            containerService.getLogsByExtDeviceIdAndSystem($stateParams.containerId)
                .then(function (response) {
                    $scope.deviceLogs = response;
                    $scope.containerEventsGridOptions = {
                        dataSource: {
                            data: $scope.deviceLogs,
                            sort: { field: 'time', dir: 'desc' },
                        },
                        sortable: true,
                        filterable: true,
                        noRecords: true,
                        messages: {
                            noRecords: $translate.instant("G_NO_DATA")
                        },
                        pageable: {
                            pageSize: 100
                        },
                        columns: [
                            {
                                field: 'time',
                                title: $translate.instant("CONTAINER_DETAILS_MODAL_CONTROLLER_TABLE_HEADER_TIME"),
                                template: '#= kendo.toString(kendo.parseDate(time), "dd.MM.yyyy HH:mm:ss") #',
                                filterable: false,
                            },
                            {
                                field: 'logAction',
                                title: $translate.instant("CONTAINER_DETAILS_MODAL_CONTROLLER_TABLE_HEADER_LOG_ACTION")
                            },
                            {
                                field: 'comment',
                                title: $translate.instant("CONTAINER_DETAILS_MODAL_CONTROLLER_TABLE_HEADER_COMMENT")
                            },
                            {
                                field: 'extUser',
                                title: $translate.instant("CONTAINER_DETAILS_MODAL_CONTROLLER_TABLE_HEADER_EXT_USER")
                            },
                            {
                                field: 'image',
                                title: $translate.instant("G_IMAGE"),
                                width: 70,
                                sortable: false,
                                filterable: false,
                                template: function (dataItem) {
                                    if (dataItem.media.length) {
                                        return '<i class="fa fa-1-5x fa-image" ng-click="openMediaModal(dataItem.media)"></i>'
                                    }
                                    return '';
                                }
                            }
                        ],
                    };
                }).catch(function (error) {
                    console.error(error);
                })
        }

        function openMediaModal(media) {
            var pictureUrls = media.map(m => m.pictureUrl);
            $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant("G_IMAGE"),
                    resizable: false,
                    width: "30%",
                    position: {
                        top: "5%",
                        left: "35%"
                    },
                    visible: false
                },
                templateUrl: 'app/containerOverview/views/container-events-picture-carousel.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'ContainerEventsPictureCarouselController',
                resolve: {
                    pictureUrls: function () {
                        return pictureUrls;
                    }
                }
            });
        }
    }
})();
