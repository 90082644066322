(function () {
    'use strict';

    angular.module('UndergroundWebApp').controller('ContainerInfoController', ContainerInfoController);

    ContainerInfoController.$inject = [
        '$rootScope',
        '$scope',
        '$state',
        '$stateParams',
        '$kWindow',
        'globalEvents',
        'authService',
        's2wClusterLayerFactory',
        'formTypes',
        'mapUtility',
        'mapService',
        'undergroundAPIService',
        'containerService',
        'groupService',
        'externalSystemsService',
        'deviceService',
        '$translate'
    ];

    function ContainerInfoController(
        $rootScope,
        $scope,
        $state,
        $stateParams,
        $kWindow,
        globalEvents,
        authService,
        s2wClusterLayerFactory,
        formTypes,
        mapUtility,
        mapService,
        undergroundAPIService,
        containerService,
        groupService,
        externalSystemsService,
        deviceService,
        $translate
    ) {

        $scope.containerId = $stateParams.containerId;
        $scope.showInactive = $stateParams.showInactive;
        $scope.startTab = $stateParams.startTab;

        $scope.showEditGroupButton = false;
        $scope.hideNavBar = true;
        $scope.containerData = {};
        $scope.activate = $translate.instant("CONTAINER_SETTINGS_VIEW_ACTIVATE");
        $scope.deactivate = $translate.instant("CONTAINER_SETTINGS_VIEW_DEACTIVATE");

        $scope.tabStripOptions = {
            animation: false,
            scrollable: false,
            dataTextField: 'name',
            dataContentUrlField: 'contentUrl',
            dataSource: getTabs(),
        };

        $scope.frequenceList = [
            { text: ' ', value: null },
            { text: '4', value: 4 },
            { text: '8', value: 8 },
            { text: '12', value: 12 },
            { text: '24', value: 24 }
        ];

        $scope.frequenceListOptions = {
            dataSource: $scope.frequenceList,
            dataTextField: 'text',
            dataValueField: 'value'
        };

        $scope.saveContainerSetting = saveContainerSetting;
        $scope.openEditGroup = openEditGroup;
        $scope.toggleClearCounter = toggleClearCounter;
        $scope.toggleTriggerUpdate = toggleTriggerUpdate;

        var externalSystems = [];
        externalSystemsService.getExternalSystems()
            .then(function (systems) {
                externalSystems = systems
            })
            .finally(function () {
                initController();
            });

        function initController() {
            if (!$stateParams.containerId) {
                $state.go('main.containerOverview');
                return;
            }

            authService.getAccessRights().then(() => {
                $scope.containeroverviewdetailstab.dataSource.data(getTabs());
                selectStartTab();
            });

            $rootScope.$broadcast('showBusyIndicator', {
                id: 'containerInfoIndicator',
                destination: '.container-overview-details-view',
                message: $translate.instant("G_BUSY_INDICATOR"),
                overlay: true,
                positionClass: {
                    top: '50%',
                    left: '0px',
                    right: '0px'
                }
            });

            if ($scope.containerId) {
                getContainer().then(function (container) {
                    if (!container) {
                        return;
                    }

                    //Avoid bookmarkerded / saved links usage that does not have the externalSystemId state param
                    let externalSystemId = container.externalSystemId || $stateParams.externalSystemId;
                    if (!externalSystemId) {
                        $state.go('main.containerOverview');
                        return;
                    }

                    let degLat = null,
                        degLong = null;

                    if (container.degLong && container.degLat) {
                        const coords = mapUtility.convertToUTM33N(container.degLat, container.degLong);

                        degLat = coords.Y;
                        degLong = coords.X;
                    }

                    $scope.containerData = {
                        containerId: container.id || container.containerId,
                        place: container.address || container.place,
                        groups: container.groups,
                        commune: container.commune || 'n/a',
                        containerType: container.containerType,
                        status: container.status,
                        cardsActive: container.cardsActive,
                        cardsActiveChangeIsPending: container.cardsActiveChangeIsPending,
                        cardsActiveText: boolToText(container.cardsActive),
                        soundActive: container.soundActive,
                        soundActiveText: boolToText(container.soundActive),
                        clearCounter: container.clearCounter,
                        fill: container.fill,
                        fillingHeight: container.fillingHeight,
                        fillText: container.fill ? container.fill : 'n/a',
                        frequence: container.frequence,
                        frequenceText: container.frequence ? container.frequence : 'n/a',
                        triggerCards: container.triggerCards,
                        triggerSound: container.triggerSound,
                        triggerUpdate: container.triggerUpdate,
                        updateTime: container.updateTime ? kendo.toString(kendo.parseDate(container.updateTime), "dd.MM.yyyy HH:mm:ss") : ' ',
                        usageCounter: container.usageCounter ? container.usageCounter : 'n/a',
                        version: container.version ? container.version : 'n/a',
                        magnetTime: container.magnetTime,
                        volt: container.battery,
                        degLong: degLong,
                        degLat: degLat,
                        hasPosition: container.degLong && container.degLat,
                        externalSystemId: externalSystemId,
                        externalSystem: getExternalSystem(externalSystemId)
                    };

                    deviceService.getDevice($scope.containerData.externalSystemId, $scope.containerData.containerId)
                        .then(function (device) {
                            if (device.container && device.container.locationId) {
                                $scope.locationId = device.container.locationId;
                            }
                        });

                    $scope.containerData.time = container.time ?
                        kendo.toString(kendo.parseDate(container.time), "dd.MM.yyyy HH:mm:ss") : 
                        kendo.toString(kendo.parseDate(container.time), "dd.MM.yyyy HH:mm:ss");

                    if ($scope.containerData.frequence) {
                        $scope.selectedFrequence = _.find($scope.frequenceList, f => f.value === container.frequence);
                    }

                    $scope.showEditGroupButton = container.groupId != undefined
                        && $rootScope.isAccessible('NewGroup', 'W');

                    return s2wClusterLayerFactory.initialized().then(function () {
                        if ($scope.containerData && $scope.containerData.hasPosition) {
                            mapService.zoomToLocation({
                                x: $scope.containerData.degLong,
                                y: $scope.containerData.degLat
                            }, 15);
                            $rootScope.$broadcast(globalEvents.redrawCluster);
                        }
                        else if (mapService._mapView.zoom > 4) {
                            mapService.zoomOut();
                        }
                    });
                }).finally(() => {
                    $rootScope.$broadcast('hideBusyIndicator', 'containerInfoIndicator');
                    $scope.hideNavBar = false;
                });
            }
        }

        function getTabs() {
            const tabs = [{ name: $translate.instant("CONTAINER_INFO_CONTROLLER_TABS_INFO"), contentUrl: 'app/containerOverview/views/container-info-view.html' }];
            if ($scope.authData.isAuth) {
                if ($scope.isAccessible('Containers', 'R')) {
                    tabs.push({ name: $translate.instant("CONTAINER_INFO_CONTROLLER_TABS_SETTINGS"), contentUrl: 'app/containerOverview/views/container-settings-view.html' });
                }
                tabs.push({ name: $translate.instant("CONTAINER_INFO_CONTROLLER_TABS_STATUS"), contentUrl: 'app/containerOverview/views/container-status-view.html' });
                if ($scope.isAccessible('NewKeyCard', 'R')) {
                    tabs.push({ name: $translate.instant("CONTAINER_INFO_CONTROLLER_TABS_KEYCARDS"), contentUrl: 'app/keycards/views/keycards-overview-view.html' });
                }
                if ($scope.isAccessible('LogLine', 'R')) {
                    tabs.push({ name: $translate.instant("CONTAINER_INFO_CONTROLLER_TABS_LOGLINE"), contentUrl: 'app/containerOverview/views/container-events-view.html' });
                }
            }
            return tabs;
        }

        function selectStartTab() {
            switch ($scope.startTab) {
                case 'Info':
                    $scope.containeroverviewdetailstab.select('li:contains("Info")');
                    break;
                case 'KeyCard':
                    $scope.containeroverviewdetailstab.select('li:contains("Nøkkelkort")');
                    break;
                default:
                    $scope.containeroverviewdetailstab.select(0);
                    break;
            }
        }

        function getExternalSystem(externalSystemId) {
            if (externalSystems && externalSystemId) {
                var system = _.find(externalSystems, { 'id': externalSystemId });
                return system ? system.name : "";
            }
            return "";
        }

        function openEditGroup(groupId) {
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant("G_EDIT"),
                    resizable: false,
                    height: 400,
                    width: 400,
                    visible: false
                },
                templateUrl: 'app/containerOverview/views/group-details-modal-view.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'GroupDetailsModalController',
                resolve: {
                    currentFormType: function () {
                        return formTypes.edit;
                    },
                    groupId: function () {
                        return groupId;
                    }
                }
            });

            //Reload group
            windowInstance.result.then(function (result) {
                if (result) {
                    if (result.groupId && result.groupName) {
                        groupService.getGroups().then(function (groups) {
                            var currentGroup = _.find(groups, function (group) {
                                return group.nummer === result.groupId;
                            });

                            if (currentGroup) {
                                var groupDisplayText = currentGroup.nummer + ' - ' + currentGroup.name;
                                var groupInGroups = _.find($scope.containerData.groups, g => g.groupId === currentGroup.nummer);
                                if (groupInGroups) {
                                    groupInGroups.group = groupDisplayText;
                                    $rootScope.$broadcast('groupUpdated', currentGroup);
                                }
                            }
                        });
                    }
                }
            });
        }

        function saveContainerSetting() {
            if ($scope.containerData) {
                $rootScope.$broadcast('showBusyIndicator', {
                    id: 'containerInfoIndicator',
                    destination: '#right-pane',
                    message: $translate.instant("G_BUSY_INDICATOR"),
                    overlay: true,
                    positionClass: {
                        top: '50%',
                        left: '0px',
                        right: '0px'
                    }
                });

                containerService.updateContainerSettings({
                    containerId: $scope.containerData.containerId,
                    magnetTime: $scope.containerData.magnetTime,
                    fillValue: $scope.containerData.fillingHeight,
                    frequence: $scope.selectedFrequence ? $scope.selectedFrequence.value : null,
                    cardsActive: $scope.containerData.cardsActive,
                    soundActive: $scope.containerData.soundActive,
                    clearCounter: $scope.containerData.clearCounter,
                    triggerUpdate: $scope.containerData.triggerUpdate
                }).then(function () {
                    $rootScope.$broadcast('hideBusyIndicator', 'containerInfoIndicator');
                }, function () {
                    $rootScope.$broadcast('hideBusyIndicator', 'containerInfoIndicator');
                });
            }
        }

        function toggleClearCounter() {
            $scope.containerData.clearCounter = !$scope.containerData.clearCounter;
        }

        function toggleTriggerUpdate() {
            $scope.containerData.triggerUpdate = !$scope.containerData.triggerUpdate;
        }

        function boolToText(item) {
            return item === true ? 
            $translate.instant("G_TRUE") 
            : $translate.instant("G_FALSE");
        }

        function getContainer() {
            if ($scope.authData && $scope.authData.isAuth) {
                return containerService.getContainerById($scope.containerId);
            }

            const containerId = Number($scope.containerId);
            return undergroundAPIService.getS2WOverviewExpress().then((result) => {
                var container = result && result.find(result, (item) => item.containerId === containerId);
                if (container && container.degLat && container.degLong) {
                    const coords = mapUtility.convertToUTM33N(container.degLat, container.degLong);

                    container.degLong = coords.X;
                    container.degLat = coords.Y;
                }

                return container;
            });
        }
    }
})();
