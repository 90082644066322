(function () {
    'use strict';

    var undergroundWebApp = angular.module('UndergroundWebApp');

    undergroundWebApp.provider('defaultDateRangeOptions', function() {
        this.$get = ['$translate', function($translate) {
            return [
                {
                    value: 'week',
                    label: $translate.instant("CONTSANT_DATE_RANGE_WEEK"),
                    setter: (date) => date.setDate(date.getDate() - 7),
                },
                {
                    value: 'half-month',
                    label: $translate.instant("CONTSANT_DATE_RANGE_HALF_MONTH"),
                    setter: (date) => date.setDate(date.getDate() - 15),
                },
                {
                    value: 'month',
                    label: $translate.instant("CONTSANT_DATE_RANGE_MONTH"),
                    setter: (date) => date.setMonth(date.getMonth() - 1)
                },
                {
                    value: 'quarter-year',
                    label: $translate.instant("CONTSANT_DATE_RANGE_QUARTER_YEAR"),
                    setter: (date) => date.setMonth(date.getMonth() - 3)
                },
                {
                    value: 'year',
                    label: $translate.instant("CONTSANT_DATE_RANGE_YEAR"),
                    setter: (date) => date.setFullYear(date.getFullYear() - 1)
                },
                {
                    value: 'custom',
                    label: $translate.instant("CONTSANT_DATE_RANGE_CUSTOM")
                },
            ];
        }];
    });
})();
