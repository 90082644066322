(function () {
    'use strict';

    angular.module('UndergroundWebApp').factory('fractionsService', fractionsService);

    fractionsService.$inject = ['$http'];

    function fractionsService($http) {
        return {
            getFractions,
            addFraction,
            updateFraction,
        };

        function getFractions() {
            return $http.get("api/fractions")
                .then(result => result.data);
        }

        function addFraction(fraction) {
            return $http.post("api/fractions", fraction)
                .then(result => result.data);
        }

        function updateFraction(fraction) {
            return $http.put("api/fractions", fraction)
                .then(result => result.data);
        }
    }
})();
