(function () {
    'use strict';

    angular.module('UndergroundWebApp').factory('containerTypesService', containerTypesService);

    containerTypesService.$inject = ['$http'];

    function containerTypesService($http) {
        return {
            addContainerType,
            getContainerTypes,
            updateContainerType,
        };

        function addContainerType(containerType) {
            return $http.post("api/containerType", containerType)
                .then(result => result.data);
        }

        function getContainerTypes() {
            return $http.get('api/containerType')
                .then(result => result.data);
        }

        function updateContainerType(containerType) {
            return $http.put("api/containerType", containerType)
                .then(result => result.data);
        }
    }
})();

