angular.module('UndergroundWebApp').directive('uiSrefIf', ['$compile', '$interpolate', function ($compile, $interpolate) {
    'use strict';

    return {
        restrict: 'A',
        replace: false,
        terminal: true,
        priority: 1000,
        link: function (scope, element, attrs) {
            element.removeAttr("ui-sref-if");
            
            const uiSref = $interpolate(attrs.uiSrefIf)(scope);
            if (uiSref) {
                element.attr('ui-sref', uiSref);
            }
            
            $compile(element)(scope);
        }
    };
}]);
