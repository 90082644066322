(function () {
    angular.module('UndergroundWebApp').factory('s2wClusterLayerFactory', s2wClusterLayerFactory);

    s2wClusterLayerFactory.$inject = [
        '$q',
        '$rootScope',
        'globalEvents',
        'authService',
        'mapUtility',
        'esriLoader',
        'undergroundAPIService',
        'locationsService'
    ]

    function s2wClusterLayerFactory(
        $q,
        $rootScope,
        globalEvents,
        authService,
        mapUtility,
        esriLoader,
        undergroundAPIService,
        locationsService
    ) {
        var readyDeferred = null,
            initDeferred = null;

        let locations = [];

        var s2wClusterLayerFactory = {
            createLayerOnAdd: true,

            ready: function () {
                if (readyDeferred === null) {
                    readyDeferred = $q.defer();
                }
                locationsService.getLocations(true, false, false, true).then(function (locs) {
                    locations = locs;
                });
                return readyDeferred.promise;
            },

            initialized: function () {
                if (initDeferred === null) {
                    initDeferred = $q.defer();
                }

                return initDeferred.promise;
            }
        };

        esriLoader.require([
            'esri/geometry/Point',
            'mcl/MarkerClusterLayer'
        ], function (Point, MarkerClusterLayer) {
            if (readyDeferred === null) {
                readyDeferred = $q.defer();
            }
            if (initDeferred === null) {
                initDeferred = $q.defer();
            }

            s2wClusterLayerFactory.createLayer = function () {
                var s2wClusterLayer = new MarkerClusterLayer({});
                s2wClusterLayer.name = 's2wClusterLayer';
                s2wClusterLayer.zIndex = 15;
                s2wClusterLayer.popupVisible = false;

                s2wClusterLayer.toggleVisibility = function () {
                    s2wClusterLayer.visible = !s2wClusterLayer.visible;
                }

                s2wClusterLayer.onMouseMove = function (evt, mapView, hitResponse) {
                    const hitResult = hitResponse && hitResponse.results
                        .find(r => r.graphic.layer && r.graphic.layer.name === s2wClusterLayer.name);
                    if (
                        !hitResult
                        || hitResult.graphic.attributes.isCluster
                    ) {
                        if (s2wClusterLayer.popupVisible) {
                            $rootScope.$broadcast('hides2wOverviewPopup');
                            s2wClusterLayer.popupVisible = false;
                        }
                        return;
                    }

                    const graphic = hitResult.graphic;
                    const point = new Point(graphic.geometry.x, graphic.geometry.y);
                    const graphicScreenPoint = mapView.toScreen(point);

                    s2wClusterLayer.popupVisible = true;
                    $rootScope.$broadcast('shows2wOverviewPopup', {
                        left: graphicScreenPoint.x + mapView.position[0] + 20,
                        top: graphicScreenPoint.y + mapView.position[1] + 10,
                        displayText: graphic.attributes.displayText || '',
                        fraction: graphic.attributes.fraction || '',
                        place: graphic.attributes.place || '',
                        containerType: graphic.attributes.containerType || '',
                        fill: graphic.attributes.fill || '',
                        volt: graphic.attributes.volt || ''
                    });
                };

                s2wClusterLayer.onRightClick = function (evt, mapView, hitResponse) {
                    if (hitResponse) {
                        var graphic = hitResponse.results[hitResponse.results.length - 1].graphic;

                        if (graphic.layer && graphic.layer.name === s2wClusterLayer.name) {
                            var point = new Point(graphic.geometry.x, graphic.geometry.y);
                            var screenPoint = mapView.toScreen(point);
                            const location = locations.filter(loc => loc.externalWMPlaceNr === graphic.attributes.placeNr)[0];
                            if (!graphic.attributes || (!graphic.attributes.isCluster && !(graphic.attributes.type === 'sublocation'))) {
                                const item = graphic.attributes.point;
                                if (location) {
                                    item.location = location;
                                }
                                $rootScope.$broadcast('showContextMenu', {
                                    item: item,
                                    mapPosition: mapView.position,
                                    screenPoint: screenPoint,
                                    type: 'Vessel'
                                });

                            } else if (graphic.attributes && graphic.attributes.type === 'sublocation') {
                                                         
                                var item = graphic.attributes.point;
                                item.locationNumber = graphic.attributes.id;
                                item.commune = graphic.attributes.commune;
                                item.containerId = graphic.attributes.containerId;
                                item.displayText = graphic.attributes.displayText;
                                item.fraction = graphic.attributes.fraction;
                                item.containerType = graphic.attributes.containerType;
                                item.degLat = graphic.attributes.lat;
                                item.degLong = graphic.attributes.lon;
                                item.fill = graphic.attributes.fill;
                                item.hasPosition = graphic.attributes.hasPosition;
                                item.poiId = graphic.attributes.poiId;
                                item.place = graphic.attributes.place;
                                item.placeNr = graphic.attributes.placeNr;
                                item.time = graphic.attributes.time;
                                item.volt = graphic.attributes.volt;
                                if (location) {
                                    item.location = location;
                                }
                                $rootScope.$broadcast('showContextMenu', {
                                    item: item,
                                    mapPosition: mapView.position,
                                    screenPoint: screenPoint,
                                    focusPoint: mapView.toScreen(graphic.attributes.point),
                                    type: 'Vessel'
                                });
                            } else if (graphic.attributes && graphic.attributes.isCluster) {
                                let clusterPoints = graphic.attributes.points;
                                const radialMenuOptions = {
                                    mapPosition: mapView.position,
                                    screenPoint: screenPoint,
                                    clusterPoints: clusterPoints,
                                    type: 'Cluster'
                                };
                                if (clusterPoints.every(cp => cp.placeNr === clusterPoints[0].placeNr)) {
                                    radialMenuOptions.location =  locations.filter(loc => loc.externalWMPlaceNr === clusterPoints[0].placeNr)[0]
                                }
                                $rootScope.$broadcast('showContextMenu', radialMenuOptions);
                            }
                        }
                    }
                };

                $rootScope.$on(globalEvents.redrawCluster, function () {
                    s2wClusterLayer.redrawData();
                });

                s2wClusterLayer.upsertLocationData = function (locations) {
                    s2wClusterLayer.updateData(locations);
                };

                s2wClusterLayer.loadData = function () {
                    return undergroundAPIService.getS2WOverviewExpress(true).then(function (locations) {
                        var validLocations = _.filter(locations, function (loc) {
                            return loc.degLat !== 0 && loc.degLong !== 0;
                        });

                        var locationsToLoad = [];

                        for (var i = 0; i < validLocations.length; ++i) {
                            const coords = mapUtility.convertToUTM33N(validLocations[i].degLat, validLocations[i].degLong);

                            locationsToLoad.push({
                                ...validLocations[i],
                                degLat: coords.Y,
                                degLong: coords.X
                            });
                        }

                        s2wClusterLayer._locations = locationsToLoad;
                        s2wClusterLayer.upsertLocationData(locationsToLoad);
                    });
                }

                /**
                 * Filters the displayed locations with the provided deviceExternalId
                 * @param deviceExternalIds The list of deviceExternalIds used to filter the locations by `location.containerId`. 
                 * Leave empty to reset the filtering.
                 */
                s2wClusterLayer.setLocationFilter = function (deviceExternalIds) {
                    s2wClusterLayerFactory.initialized().then(function () {
                        if (!deviceExternalIds) {
                            s2wClusterLayer.upsertLocationData(s2wClusterLayer._locations);
                            return;
                        }

                        if (deviceExternalIds.length === 0) {
                            s2wClusterLayer.upsertLocationData([]);
                            return;
                        }

                        // location.containerId is a number so the filter values have to be casted
                        var deviceIdNumbers = _.chain(deviceExternalIds)
                            .map(function (id) { return Number(id); })
                            .filter(function (id) { return !isNaN(id); })
                            .value();
                        var filteredLocations = _.filter(s2wClusterLayer._locations, function (location) {
                            return _.includes(deviceIdNumbers, location.containerId);
                        });
                        s2wClusterLayer.upsertLocationData(filteredLocations);
                    });
                }

                s2wClusterLayer.loadData().then((locations) => {
                    $rootScope.$broadcast('ContainerLayerLoaded', {
                        locations
                    });

                    initDeferred.resolve();
                });

                return s2wClusterLayer;
            };

            readyDeferred.resolve(s2wClusterLayerFactory);
        });

        return s2wClusterLayerFactory;
    }
})();
