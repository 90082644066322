angular
    .module('UndergroundWebApp')

  /**
   * @ngdoc directive
   * @name app.directive:numbersOnly
   * @restrict 'A'
   * @element ANY
   * @description
   * directive to only allows numbers
  */
    .directive('isDecimal', function ($window) {
      return {
        require: 'ngModel',
        link: function (scope, element, attr, ngModelCtrl) {
            function fromUser(text) {
                if (text) {
                    var transformedInput = text.replace(/[^0-9.]/g, '');
                    if(transformedInput){
                        var lst = transformedInput.split('.');
                        var isDecimal;
                        var number, numberDigits;
                        var decimal, decimalDigits;

                        if(lst.length === 2){
                            isDecimal = true;
                            number = lst[0]
                            numberDigits = number.length;
                            decimal = lst[1];
                            decimalDigits = decimal.length;
                        }else if(lst.length === 1){
                            isDecimal = false;
                            number = lst[0]
                            numberDigits = number.length;
                        }
                    }
                    
                    if (transformedInput !== text) {
                        ngModelCtrl.$setViewValue(transformedInput);
                        ngModelCtrl.$render();
                    }

                    return transformedInput;
                }
                return undefined;
            }            
            ngModelCtrl.$parsers.push(fromUser);
        }
      };
    });