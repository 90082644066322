angular.module('UndergroundWebApp').directive('numericInputWithComma', function () {
    return {
        require: 'ngModel',
        restrict: 'A',
        link: function (scope, element, attrs, ngModel) {
            if (ngModel) {

                ngModel.$parsers.push(function (value) {
                    if (value == null) {
                        return 0;
                    }
                    return parseFloat(value.replace(',', '.'));
                });

                ngModel.$formatters.push(function (value) {
                    if (value == null) {
                        return '';
                    }
                    return value.toLocaleString("hu-HU", { useGrouping: false });
                });

            }
        }
    };
});
