(function () {
    'use strict';

    angular.module('UndergroundWebApp').controller('ContainerTypeDetailsModalController', ContainerTypeDetailsModalController);

    ContainerTypeDetailsModalController.$inject = [
        '$q',
        '$scope',
        '$rootScope',
        '$windowInstance',
        'containerTypesService',
        'formTypes',
        'currentFormType',
        'containerType',
        'containerTypeUnits',
        '$translate'
    ];

    function ContainerTypeDetailsModalController(
        $q,
        $scope,
        $rootScope,
        $windowInstance,
        containerTypesService,
        formTypes,
        currentFormType,
        containerType,
        containerTypeUnits,
        $translate
    ) {
        $scope.saveAndClose = saveAndClose;
        $scope.closeWindow = closeWindow;

        $scope.containerTypeUnitOptions = {
            dataSource: containerTypeUnits,
            dataTextField: 'LocalizedName',
            dataValueField: 'Id',
            optionLabel: $translate.instant('ADMINISTRATION_CONTAINERTYPE_MODAL_CHOOSE_UNIT')
        };

        $scope.containerType = containerType != null ? { ...containerType } : { unit: 0 };

        //Event handlers
        function saveAndClose(event) {
            event.preventDefault();

            if ($scope.validator.validate()) {
                save().then(function (result) {
                    $windowInstance.close(result);
                    $('#container-types-grid').getKendoGrid().dataSource.read();
                });
            }
        }

        function closeWindow(result) {
            $windowInstance.close(result);
        }

        //Private functions
        function getSaveFunction(currentFormType) {
            if (currentFormType === formTypes.edit) {
                return containerTypesService.updateContainerType.bind(containerTypesService);
            }

            return containerTypesService.addContainerType.bind(containerTypesService);
        }

        function save() {
            var deferred = $q.defer();

            $rootScope.$broadcast('showGlobalBusyIndicator', 'containerTypeIndicator');

            var saveFunction = getSaveFunction(currentFormType);

            saveFunction($scope.containerType)
                .then(result => deferred.resolve(result),
                    () => deferred.reject())
                .finally(() => $rootScope.$broadcast('hideBusyIndicator', 'containerTypeIndicator'));

            return deferred.promise;
        }

    }
})();

