(function () {
    'use strict';

    angular.module('UndergroundWebApp').controller('KeyCardRegistrationModalController', KeyCardRegistrationModalController);

    KeyCardRegistrationModalController.$inject = [
        '$q',
        '$timeout',
        '$scope',
        '$stateParams',
        '$windowInstance',
        'keycardService',
        'formTypes',
        'currentFormType',
        'groupName',
        'groupId',
        'keyCard',
        'keycardUtility',
        'groupService',
        'fallbackErrorMessageText'
    ];

    function KeyCardRegistrationModalController(
        $q,
        $timeout,
        $scope,
        $stateParams,
        $windowInstance,
        keycardService,
        formTypes,
        currentFormType,
        groupName,
        groupId,
        keyCard,
        keycardUtility,
        groupService,
        fallbackErrorMessageText
    ) {

        $scope.isAdditionDisabled = currentFormType === formTypes.edit;

        $scope.containerId = $stateParams.containerId;
        $scope.lastName = { Value: '', Remember: true };
        $scope.firstName = { Value: '', Remember: true };
        $scope.address = { Value: '', Remember: true };
        $scope.city = { Value: '', Remember: true };
        $scope.status = { Value: 0, Remember: true };
        $scope.selectedUser = { Value: '', Remember: true };
        $scope.groupName = groupName;
        $scope.groupId = groupId;
        $scope.newKey = '';
        $scope.users = [];
        $scope.cardKeys = [];
        $scope.errorMessage = undefined;
        
        $scope.usersOptions = {
            dataSource: {
                transport: {
                    read: readUsers
                }
            },
            dataTextField: "userName",
            dataValueField: "userId",
            optionLabel: {
                userName: "Velg område",
                userId: ""
            },
            dataBound: (e) => {
                if (e.sender.dataSource.total() === 1) {
                    e.sender.select(1);
                    $scope.selectedUser.Value = e.sender.dataItem(1);
                }
            }
        };

        $scope.groupOptions = {
            dataSource: {
                transport: {
                    read: readGroups
                }
            },
            dataTextField: "name",
            dataValueField: "nummer",
            optionLabel: {
                name: "Velg gruppe",
                nummer: ""
            },
            filter: 'contains',
        }

        $scope.addKey = addKey;
        $scope.closeWindow = closeWindow;
        $scope.save = save;
        $scope.saveAndClose = saveAndClose;
        $scope.removeKey = removeKey;

        $scope.source = new kendo.data.DataSource({
            data: $scope.cardKeys
        });

        $timeout(250).then(initController);

        function initController() {
            if (currentFormType === formTypes.edit) {
                if (keyCard) {
                    $scope.newKey = keyCard.cardNumber;
                    $scope.cardKeys.push($scope.newKey);

                    $scope.firstName.Value = keyCard.firstName;
                    $scope.lastName.Value = keyCard.lastName;
                    $scope.address.Value = keyCard.streetAddress;
                    $scope.city.Value = keyCard.cityAddress;
                    $scope.status.Value = keyCard.cardDeactivated;

                    $scope.selectedUser.Value = {
                        userId: keyCard.userId,
                        userName: keyCard.userName
                    };
                }
            } else {
                $("#newKey").focus();
            }
        }

        function addKey(e) {
            $scope.errorMessage = undefined;
            var charCode = (e.which) ? e.which : e.keyCode;
            if (charCode === 13) {
                event.preventDefault();

                var exist = $scope.cardKeys.indexOf($scope.newKey);
                if ($scope.newKey && exist < 0) {
                    var convertResult = keycardUtility.convertKeycardNumber($scope.newKey);
                    if (!convertResult.errorMessage) {
                        $scope.cardKeys.push(convertResult.returnStr);
                        $scope.newKey = '';
                    } else {
                        $scope.errorMessage = convertResult.errorMessage;
                    }
                }
            }
        }

        function removeKey(e) {
            var index = $scope.cardKeys.indexOf(e);
            if (index > -1) {
                $scope.cardKeys.splice(index, 1);
            }
        }

        function closeWindow(result) {
            event.preventDefault();

            $windowInstance.close(result);
        }

        function saveAndClose() {
            event.preventDefault();

            saveKeycards().then(function () {
                $windowInstance.close(true);
            });
        }

        function save() {
            event.preventDefault();

            saveKeycards().then(function () {
                resetWindow();
            });
        }

        function saveKeycards() {
            var deferred = $q.defer();

            if ($scope.validator.validate()) {
                var keyCards = getKeyCardData();

                if (currentFormType === formTypes.add) {
                    keycardService.addKeycards(keyCards).then(function (result) {
                        $scope.cardKeys = [];
                        deferred.resolve(result);
                    }, function () {
                        deferred.reject();
                    });
                } else {
                    keycardService.updateKeycard(keyCards).then(function (result) {
                        deferred.resolve(result);
                    }, function () {
                        deferred.reject();
                    });
                }
            }

            return deferred.promise;
        }

        //Private functions
        function getKeyCardData() {
            return {
                "groupId": $scope.groupId || $scope.selectedGroup.nummer,
                "cardNumbers": $scope.cardKeys,
                "lastName": $scope.lastName.Value,
                "cityAddress": $scope.city.Value,
                "cardDeactivated": $scope.status.Value,
                "address": $scope.address.Value,
                "firstName": $scope.firstName.Value,
                "communeId": $scope.selectedUser.Value.userId
            };
        }

        function readUsers(e) {
            keycardService.getUsers().then(function (users) {
                if (users) {
                    _.each(users, function (item) {
                        $scope.users.push(item);
                    });
                }
                $scope.groupsDdlServerErrorText = null;
                e.success($scope.users);
            }).catch(function (error) {
                $scope.groupsDdlServerErrorText = error.localizedMessage || fallbackErrorMessageText;
                e.error();
            });
        }

        function resetWindow() {
            if (!$scope.lastName.Remember) { $scope.lastName.Value = ''; }
            if (!$scope.firstName.Remember) { $scope.firstName.TexValuet = ''; }
            if (!$scope.address.Remember) { $scope.address.Value = ''; }
            if (!$scope.city.Remember) { $scope.city.Value = ''; }
            if (!$scope.selectedUser.Remember) { $scope.selectedUser.Value = ''; }
            if (!$scope.status.Remember) { $scope.status.Value = 0; }
        }

        function readGroups(e) {
            groupService.getGroups().then(function (groups) {
                $scope.usersDdlServerErrorText = null;
                e.success(groups);
            }).catch(function (error) {
                $scope.usersDdlServerErrorText = error.localizedMessage || fallbackErrorMessageText;
                e.error();
            });
        }
    }
})();
