(function () {
    angular.module('UndergroundWebApp').factory('httpErrorInterceptorService', httpErrorInterceptorService);

    httpErrorInterceptorService.$inject = [
        '$q',
        '$rootScope',
        'globalEvents'
    ];

    function httpErrorInterceptorService(
        $q,
        $rootScope,
        globalEvents
    ) {
        return {
            responseError
        };

        function responseError(response) {
            if (response.status === 401 && response.config.url === 'api/accessrights') {
                $rootScope.$broadcast(globalEvents.accessTokenExpired, response);
            } else {
                $q.reject(response);
            }
        }
    }
})();
