(function () {
    'use strict';

    angular.module('UndergroundWebApp').factory('kendoGridUtility', kendoGridUtility);

    const OPTIONS_KEY_PREFIX = 'GRID_OPTIONS';

    function kendoGridUtility() {
        var service = {
            displayNoRecordsConditionally,
            sortMulticheckboxFilterValues,
            filterCheckboxTemplate,
            excludeColumnFromMenuList,
            persistOptions,
            loadOptions,
            checkBoxColumnTemplate,
        };

        function sortMulticheckboxFilterValues(fieldNames, direction = 'asc') {
            let fieldList = fieldNames;
            if (!Array.isArray(fieldList)) {
                fieldList = [fieldNames];
            }

            return function (e) {
                if (fieldList.includes(e.field)) {
                    let $filterMultiCheckElement = this.thead.find(`[data-field=${e.field}]`)

                    if ($filterMultiCheckElement && $filterMultiCheckElement.data('kendoFilterMultiCheck')) {
                        let filterMultiCheck = $filterMultiCheckElement.data('kendoFilterMultiCheck');

                        filterMultiCheck.container.empty();
                        filterMultiCheck.checkSource.sort({ field: e.field, dir: direction });
                        filterMultiCheck.checkSource.data(filterMultiCheck.checkSource.view().toJSON());
                        filterMultiCheck.createCheckBoxes();
                    }
                }
            }
        }

        function filterCheckboxTemplate(emptyFieldText) {
            return function (e) {
                return `<li class="k-item">
                    <label class="k-label">
                        <input type="checkbox" value="#= data.${e.field}#">
                        #= data.all || (data.${e.field} ? data.${e.field} : '${emptyFieldText}') # 
                    </label>
                </li>`;
            }
        };

        function excludeColumnFromMenuList(fieldNames) {
            let fieldList = fieldNames;
            if (!Array.isArray(fieldList)) {
                fieldList = [fieldNames];
            }

            return function (e) {
                e.container.find('li[role="menuitemcheckbox"]').each((_index, e) => {
                    const $checkElement = $(e);
                
                    const containsField = fieldList.some((field) => {
                        let inputElement = $checkElement.find(`input[data-field="${field}"]`);

                        return inputElement && inputElement.length === 1;
                    });
                
                    if (containsField) {
                        $checkElement.addClass('hidden');
                    }
                });
            }
        }

        function persistOptions(tableVersion) {
            return function () {
                const options = this.getOptions();
                options.dataSource = null;

                const entry = {
                    options,
                    tableVersion,
                }

                const id = this.element.attr('id');
                window.localStorage.setItem(`${OPTIONS_KEY_PREFIX}#${id}`, JSON.stringify(entry));
            }
        }

        function loadOptions(id, tableVersion) {
            const entry = JSON.parse(window.localStorage.getItem(getOptionsStorageKey(id)));
            if (entry && entry.tableVersion === tableVersion) {
                return entry.options;
            }

            return null;
        }

        function getOptionsStorageKey(id) {
            return `${OPTIONS_KEY_PREFIX}#${id}`;
        }

        function checkBoxColumnTemplate(fieldName) {
            return `<input type="checkbox" disabled #= ${fieldName} ? 'checked="checked"' : "" # />`;
        }

        function displayNoRecordsConditionally(kendoGrid) {
            if (!kendoGrid) return;

            if (kendoGrid.dataSource) {
                var data = kendoGrid.dataSource.data();
                if (data.length === 0) {
                    $('.k-grid-norecords-template').text('Ingen treff');
                }
            } else {
                $('.k-grid-norecords-template').text('Ingen treff');
            }
        }

        return service;
    }
})();
