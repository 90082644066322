angular.module('UndergroundWebApp').directive('municipalityPicker', ["municipalityService", function(municipalityService) {
    return {
        restrict: 'E',
        scope: {
            model: '=ngModel',
        },
        replace: true,
        transclude: true,
        templateUrl: 'app/shared/directives/municipality-picker.html',
        link: 
            function ($scope, element, attributes, controller, transclude) {
                $scope.filterData = {
                    municipalities: readMunicipalities,
                    externalSystems: [],
                }

                $scope.selectedMunicipalities = [];
                
                $scope.municipalitiesDropdownOptions = {
                    settings: {
                        externalIdProp: '',
                    },
                    events: {
                        onSelectionChanged: handleMunicipalitiesFilterChanged,
                    },
                }
                
                $scope.dropdownTranslations = {
                    checkAll: 'Velg alle',
                    uncheckAll: 'Velg ingen',
                    buttonDefaultText: 'Velg Kommune',
                    dynamicButtonTextSuffix: 'valgt',
                }
     
                readMunicipalities($scope);

                $scope.filter = {
                    municipalities : []
                }

                $scope.onItemSelect = function() {
                    console.log('select changed');
                    }
            }
    }

    function readMunicipalities($scope) {
        municipalityService.getMunicipalities().then((municipalities) => {
            const municipalityOptions = municipalities.map((m) => ({
                name: m.name,
                id: m.code,
                label: `${m.name} (${m.code})`,
            }));
            $scope.filterData.municipalities = municipalityOptions;
        });
    }

    function handleMunicipalitiesFilterChanged() {
        console.log("selectionchanged");
    }

}]);
