(function () {
    'use strict';

    angular.module('UndergroundWebApp').controller('ContainerOverviewController', ContainerOverviewController);

    ContainerOverviewController.$inject = [
        '$q',
        '$rootScope',
        '$scope',
        '$state',
        '$stateParams',
        '$kWindow',
        'globalEvents',
        'formTypes',
        'containerService',
        'externalSystemsService',
        'locationsService',
        'filterStateService',
        '$translate'
    ];

    function ContainerOverviewController(
        $q,
        $rootScope,
        $scope,
        $state,
        $stateParams,
        $kWindow,
        globalEvents,
        formTypes,
        containerService,
        externalSystemsService,
        locationsService,
        filterStateService,
        $translate
    ) {
        $scope.allContainers = [];
        $scope.containerOverviewGridData = [];
        $scope.noCache = $stateParams.noCache;
        $scope.yes = $translate.instant("G_YES"),
        $scope.no = $translate.instant("G_NO"),
        $scope.excelFileName = 'Shop2WinContaineroversikt.xlsx';

        $scope.isEditVisible = isEditVisible;
        $scope.showInactive = $stateParams.showInactive === 'true';

        //Public functions
        $scope.exportToExcel = exportToExcel;
        $scope.openNewContainerModal = openNewContainerModal;
        $scope.openNewGroupModal = openNewGroupModal;
        $scope.openUpdateContainerModal = openUpdateContainerModal;
        $scope.toggleInactive = toggleInactive;

        $scope.containerOverviewGridOptions = {
            dataSource: {
                data: $scope.containerOverviewGridData,
                schema: {
                    model: {
                        fields: {
                            id: { type: 'number' },
                            address: {},
                            containerType: {},
                            battery: { type: 'number' },
                            version: { type: 'number' },
                            fill: { type: 'number' },
                            signal: { type: 'number' },
                            group: {},
                            hasPosition: { type: 'boolean' },
                            groupIds: { type: 'array' },
                            updateTime: { type: 'date' },
                            cardsActive: { type: 'boolean' },
                        }
                    }
                },
                group: { field: 'group' },
                pageSize: 70,
                sort: filterStateService.getSortFromQueryString({
                    field: 'id',
                    dir: 'asc'
                }),
                filter: filterStateService.getFilterFromQueryString(),
            },
            sortable: true,
            filterable: true,
            noRecords: true,
            messages: {
                noRecords: $translate.instant("G_NO_DATA")
            },
            scrollable: {
                virtual: true
            },
            selectable: 'row',
            columns: [
                {
                    field: 'actions',
                    title: ' ',
                    width: '60px',
                    template: '<a class="grid-tooltip" ui-sref="main.containerDetails({ containerId: dataItem.id, externalSystemId: dataItem.externalSystemId, showInactive: showInactive })"><i class="fa fa-1-5x fa-info-circle" aria-hidden="true"></i></a>' +
                        '<div ng-show="isEditVisible()" class="grid-tooltip" ng-click="openUpdateContainerModal(dataItem)"><i class="fa fa-second fa-1-5x fa-pencil" aria-hidden="true"></div>',
                    filterable: false,
                    sortable: false,
                    groupable: false
                },
                { field: 'id', title: 'Id', width: '56px' },
                {
                    field: 'locationName',
                    title: $translate.instant("CONTAINER_OVERVIEW_CONTROLLER_TABLE_HEADER_LOCATION_NAME"),
                    template: '<a href="#= locationHref#">#= locationName#</>',
                    width: '120px'
                },
                { field: 'address', title: $translate.instant("CONTAINER_OVERVIEW_CONTROLLER_TABLE_HEADER_ADDRESS") },
                {
                    field: 'containerType',
                    title: $translate.instant("CONTAINER_OVERVIEW_CONTROLLER_TABLE_HEADER_CONTAINER_TYPE"),
                    width: '90px',
                    filterable: {
                        multi: true,
                    }
                },
                { field: 'battery', title: $translate.instant("CONTAINER_OVERVIEW_CONTROLLER_TABLE_HEADER_BATTERY"), width: '70px' },
                { field: 'fill', title: $translate.instant("CONTAINER_OVERVIEW_CONTROLLER_TABLE_FILL_PERCENT"), width: '65px' },
                { field: 'signal', title: $translate.instant("CONTAINER_OVERVIEW_CONTROLLER_TABLE_SIGNAL"), template: '<span class="td-fill-span #: SignalClass#" >#: signal#</span>', width: '70px' },
                {
                    field: 'cardsActive',
                    template: `#= cardsActive ? "${$translate.instant("G_TRUE")}" : "${$translate.instant("G_FALSE")}" #`,
                    title: $translate.instant("CONTAINER_OVERVIEW_CONTROLLER_TABLE_CARDS_ACTIVE"),
                    filterable: {
                        messages: {
                            isTrue: $translate.instant("G_TRUE"),
                            isFalse: $translate.instant("G_FALSE"),
                            info: '',
                        }
                    },
                    width: 60
                },
                {
                    field: 'version',
                    title: $translate.instant("CONTAINER_OVERVIEW_CONTROLLER_TABLE_VERSION"),
                    width: '70px'
                },
                {
                    title: $translate.instant("CONTAINER_OVERVIEW_CONTROLLER_TABLE_UPDATE_TIME"),
                    field: 'updateTime',
                    width: '100px',
                    template: '#= updateTimeDisplay #',
                    filterable: {
                        ui: 'datetimepicker',
                    },
                },
                { field: 'group', title: $translate.instant("CONTAINER_OVERVIEW_CONTROLLER_TABLE_GROUP") },
            ],
            excel: {
                fileName: $scope.excelFileName,
                filterable: true,
                allPages: true
            },
            excelExport: containerOverviewGridExcelExport,
            change: onRowSelected,
            sort: filterStateService.storeGridSortInQueryString,
            filter: filterStateService.storeGridFilterInQueryString,
        };

        initController();
     
        function initController() {
            externalSystemsService.getExternalSystems()
                .then(function(systems) {
                    $scope.externalSystems = systems;
                    getContainerOverviewGridData();
                });
        }

        function isEditVisible() {
            return $rootScope.isAccessible && $rootScope.isAccessible("NewContainer", "W");
        }

        function openNewContainerModal() {
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: 'Ny elektronikk',
                    resizable: false,
                    height: 570,
                    width: 450,
                    visible: false
                },
                templateUrl: 'app/containerOverview/views/container-details-modal-view.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'ContainerDetailsModalController',
                resolve: {
                    currentFormType: function() {
                        return formTypes.add;
                    },
                    container: function () {
                        return null;
                    },
                    allContainers: function () {
                        return $scope.allContainers;
                    }
                }
            });

            windowInstance.result.then(function (result) {
                if (result) {
                    getContainerOverviewGridData();
                }
            });
        }

        function openUpdateContainerModal(dataItem) {
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant("CONTAINER_OVERVIEW_CONTAINER"),
                    resizable: false,
                    height: 600,
                    width: 400,
                    visible: false
                },
                templateUrl: 'app/containerOverview/views/container-details-modal-view.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'ContainerDetailsModalController',
                resolve: {
                    currentFormType: function () {
                        return formTypes.edit;
                    },
                    container: function() {
                        return dataItem;
                    },
                    allContainers: function () {
                        return $scope.allContainers;
                    }
                }
            });

            windowInstance.result.then(function (result) {
                if (result) {
                    getContainerOverviewGridData();
                }
            });
        }

        function toggleInactive(timeout) {
            if ($scope.showInactive) {
                $scope.containerOverviewGridData = $scope.allContainers.filter(c => c.status !== 0);
            } else {
                $scope.containerOverviewGridData = $scope.allContainers.filter(c => c.status === 0);
            }

            if (!timeout) {
                $state.transitionTo('main.containerOverview',
                    {
                        showInactive: $scope.showInactive
                    },
                    {
                        location: true,
                        inherit: true,
                        relative: $state.$current,
                        notify: false
                    });
                timeout = 300;
            }

            setTimeout(function() {
                $scope.containerOverviewGrid.dataSource.data($scope.containerOverviewGridData);
                $scope.containerOverviewGrid.refresh();

                $rootScope.$broadcast(globalEvents.overviewContainersChanged, $scope.containerOverviewGridData);
            }, timeout);
        }

        function openNewGroupModal() {
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant("CONTAINER_OVERVIEW_CONTROLLER_NEW_GROUP"),
                    resizable: false,
                    height: 400,
                    width: 400,
                    visible: false
                },
                templateUrl: 'app/containerOverview/views/group-details-modal-view.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'GroupDetailsModalController',
                resolve: {
                    currentFormType: function () {
                        return formTypes.add;
                    },
                    groupId: function () {
                        return null;
                    },
                    externalSystems: function (){
                        return $scope.externalSystems;
                    }
                }
            });

            windowInstance.result.then(function (result) {
                if (result) {
                    getContainerOverviewGridData();
                }
            });
        }

        function exportToExcel() {
            $scope.containerOverviewGrid.saveAsExcel();
        }

        //Private functions
        function getContainerOverviewGridData() {
            showBusyIndicator();

            $scope.containerOverviewGridData = [];

            $q.all([
                containerService.getContainers($stateParams.noCache),
                locationsService.getLocations(false)
            ]).then(data => {
                let containers = data[0],
                    locations = data[1];

                loadContainerOverviewGridData(containers, locations);
            }).finally(hideBusyIndicator);
        }

        function loadContainerOverviewGridData(containers, locations) {
            if (containers) {
                $scope.allContainers = [];

                _.each(containers, function (item) {
                    let relatedLocation = item.placeNumber && locations.find(l => l.externalWMPlaceNr === item.placeNumber);

                    extendGridData(item, relatedLocation);

                    $scope.allContainers.push(item);
                    if (item.status === 0) {
                        $scope.containerOverviewGridData.push(item);
                    }
                });

                toggleInactive(0);
            }
        }

        function extendGridData(item, relatedLocation) {
            item.signal = item.signal === null ? ' ' : item.signal;
            item.hasPosition = item.hasPosition === true;
            item.SignalClass = getSignalStrength(item.signal);
            item.PositionClass = item.hasPosition ? 'hasPosition' : 'noPosition';
            item.updateTime = item.updateTime == null ? '' : new Date(item.updateTime);

            if (item.updateTime) {
                item.updateTimeDisplay = kendo.toString(kendo.parseDate(item.updateTime), 'dd.MM HH:mm');
            } else {
                item.updateTimeDisplay = '';
            }
           
            if (item.battery) {
                item.battery = item.battery.toFixed(1);
            }
            if ($scope.externalSystems && item.externalSystemId)
            {
                var system = _.find($scope.externalSystems, { 'id': item.externalSystemId});
                item.externalSystem = system ? system.name : "";
            }

            if (relatedLocation) {
                item.locationId = relatedLocation.id;
                item.locationName = relatedLocation.name;

                item.locationHref = location.origin + '/main/location/' + item.locationId + '/info';
            } else {
                item.locationId = '';
                item.locationName = '';
                item.locationHref = '';
            }
        }

        function getSignalStrength(signal) {
            if (signal <= 5) { return 'critical'; }
            else if (signal > 5 && signal <= 7) { return 'warning'; }
            else if (signal > 7) { return 'ok'; }
            else { return 'missing' }
        }

        function onRowSelected() {
            var selectedRows = this.select();
            $rootScope.selectedContainerId = this.dataItem(selectedRows[0]).id;
        }

        function containerOverviewGridExcelExport(e) {
            let dateFormat = $translate.instant("CONTAINER_OVERVIEW_CONTROLLER_EXCEL_EXPORT_DATE_FORMAT");
            e.workbook.fileName = 'Shop2WinContaineroversikt ' + kendo.toString(new Date, dateFormat) + '.xlsx';
            var sheet = e.workbook.sheets[0];
            var columnWidths = this.element.find('.k-grid-header colgroup col');

            for (var i = 0; i < sheet.columns.length; i++) {
                if ($(columnWidths[i]).attr('style')) {
                    sheet.columns[i].width = 160;
                } else {
                    sheet.columns[i].width = 16;
                }
            }
        }

        function showBusyIndicator() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'containerIndicator',
                destination: '#container-overview-view',
                message: $translate.instant("G_BUSY_INDICATOR"),
                overlay: true,
                positionClass: {
                    top: '50%',
                    left: '0px',
                    right: '0px'
                }
            });
        }

        function hideBusyIndicator() {
            $rootScope.$broadcast('hideBusyIndicator', 'containerIndicator');
        }
    }
})();
