(function () {
    'use strict';

    angular.module('UndergroundWebApp').factory('areaLayerFactory', areaLayerFactory);

    areaLayerFactory.$inject = [
        '$q',
        '$rootScope',
        'esriLoader',
        'authService',
        'sensorApiAreaService',
        'mapService',
        'areaUtility'
    ];

    function areaLayerFactory(
        $q,
        $rootScope,
        esriLoader,
        authService,
        sensorApiAreaService,
        mapService,
        areaUtility
    ) {
        var readyDeferred = $q.defer();

        var areaLayerFactory = {
            createLayerOnAdd: true,

            ready: () => readyDeferred.promise,
        };

        esriLoader.require([
            'dojo/_base/declare',
            'esri/Graphic',
            'esri/geometry/Point',
            'esri/layers/GraphicsLayer',
            'esri/symbols/SimpleLineSymbol',
            'esri/symbols/SimpleFillSymbol',
            'esri/geometry/Polygon',
            'esri/geometry/SpatialReference',
            'esri/symbols/Font',
            'esri/Color',
            'esri/symbols/TextSymbol',
        ], function (
            declare,
            Graphic,
            Point,
            GraphicsLayer,
            SimpleLineSymbol,
            SimpleFillSymbol,
            Polygon,
            SpatialReference,
            Font,
            Color,
            TextSymbol,
        ) {
            const areaLineStyle = {
                style: SimpleLineSymbol.STYLE_SOLID,
                color: [227, 139, 79, 0.8],
                width: 3
            }

            const areaFillColor = [255, 255, 127, 0.025];

            const selectedLineStyle = {
                style: SimpleLineSymbol.STYLE_SOLID,
                color: [0, 0, 178, 0.8],
                width: 3
            }

            const selectionFillColor = [100, 100, 255, 0.3];

            const selectionFill = new SimpleFillSymbol(
                SimpleFillSymbol.STYLE_SOLID,
                new SimpleLineSymbol(selectedLineStyle.style, selectedLineStyle.color, selectedLineStyle.width), selectionFillColor
            );

            const areaFill = new SimpleFillSymbol(
                SimpleFillSymbol.STYLE_SOLID,
                new SimpleLineSymbol(areaLineStyle.style, areaLineStyle.color, areaLineStyle.width), areaFillColor
            );

            areaLayerFactory.createLayer = () => new AreaLayer({});

            const AreaLayer = declare([GraphicsLayer], {
                constructor: function () {
                    this.name = 'areaLayer';
                    this.zIndex = 2;
                    this.textVisible = false;
                    this.textZoomTreshold = 9;
                    this.loadData();
                },

                toggleVisibility: function () {
                    this.visible = !this.visible;
                },

                onZoomFinished: function (currentZoomLevel, mapView) {
                    var newTextVisibility = currentZoomLevel >= this.textZoomTreshold;
                    if (newTextVisibility === this.textVisible) return;

                    this.textVisible = newTextVisibility;
                    if (this.textVisible) {
                        this._addTextGraphics();
                    } else {
                        this._removeTextGraphics();
                    }
                },

                loadData: function () {
                    return authService.getAccessRights().then(() => (
                        $rootScope.isAccessible('Area', 'R')
                            ? sensorApiAreaService.getAreas()
                            : $q.resolve([])
                    )).then((areas) => {
                        const areaGraphics = areas.filter((area) => area
                            && area.isActive
                            && area.points
                            && area.points.length > 2
                        ).map((area) => areaUtility.createAreaPolygon(area, areaFill, Graphic, Point, Polygon, SpatialReference));

                        this.removeAll();
                        this.addMany(areaGraphics);
                        if (this.textVisible) {
                            this._addTextGraphics();
                        }

                        if (this.selectedAreaId) {
                            this.selectArea(this.selectedAreaId);
                        }
                    });
                },

                drawTemporaryArea: function (points) {
                    this.clearTemporaryArea();

                    const polygonGraphic = areaUtility.createPolygon(
                        points, selectionFill, { isTemporary: true }, Graphic, Point, Polygon, SpatialReference
                    );
                    this.add(polygonGraphic);
                },

                clearTemporaryArea: function () {
                    const tempArea = this.graphics
                        .find((graphic) => graphic.attributes.isTemporary);

                    if (tempArea) {
                        this.remove(tempArea);
                    }
                },

                selectArea: function (id, zoomTo = true) {
                    if (
                        id === this.selectedAreaId
                        && !!this.graphics.find(g => g.attributes.isSelected)
                    ) {
                        return;
                    }

                    this.clearSelectedArea();
                    this.selectedAreaId = id;

                    const graphicToSelect = this.graphics.find(a => a.attributes.id === id);
                    if (!graphicToSelect) return;

                    const graphic = this.graphics.find(g => g.attributes.id === id);

                    const newGraphic = graphic.clone();
                    newGraphic.symbol = selectionFill;
                    newGraphic.attributes.isSelected = true;
                    this.add(newGraphic);

                    if (zoomTo) {
                        mapService.zoomToGraphics(newGraphic, 1.6);
                    }
                },

                clearSelectedArea: function () {
                    if (!this.selectedAreaId) return;

                    const graphic = this.graphics.find(g => g.attributes.isSelected);
                    this.remove(graphic);
                    this.selectedAreaId = null;
                },

                showArea: function (id) {
                    if (this.hiddenGraphic) {
                        this.add(this.hiddenGraphic);
                        this.hiddenGraphic = null;
                    }
                },

                hideArea: function (id) {
                    const graphicToHide = this.graphics.find(a => a.attributes.id === id);
                    if (!graphicToHide) return;

                    this.hiddenGraphic = graphicToHide;
                    this.remove(graphicToHide);
                },

                _removeTextGraphics: function () {
                    const textGraphics = this.graphics
                        .filter(g => g.attributes.type === 'text');

                    this.removeMany(textGraphics);
                },

                _addTextGraphics: function () {
                    const textGraphics = this.graphics
                        .filter(g => g.attributes.type === 'area')
                        .map(graphic => areaUtility.createAreaTextGraphic(graphic, Color, Font, TextSymbol, Point, Graphic));

                    this.addMany(textGraphics);
                },
            });

            function isValidArea(graphic) {
                return graphic.attributes
                    && graphic.attributes.type === 'area'
                    && graphic.attributes.id
                    && !graphic.attributes.isTemporary;
            }

            function isInPolynom(point, polygon) {
                var x = point.x, y = point.y;

                var inside = false;
                for (var i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
                    var xi = polygon[i].x, yi = polygon[i].y;
                    var xj = polygon[j].x, yj = polygon[j].y;

                    var intersect = ((yi > y) !== (yj > y))
                        && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
                    if (intersect) inside = !inside;
                }

                return inside;
            }  

            function getAreaHitResults(point, graphics) {
                return graphics.filter((graphic) => {
                    if (!isValidArea(graphic)) return false;

                    const polynom = graphic.geometry.rings[0]
                        .map((r) => ({ x: r[0], y: r[1] }));

                    return isInPolynom(point, polynom);
                });
            }

            readyDeferred.resolve(areaLayerFactory);
        });

        return areaLayerFactory;
    }
})();
