(function () {
    'use strict';

    angular.module('UndergroundWebApp').factory('vehicleService', vehicleService);

    vehicleService.$inject = ['$http'];

    function vehicleService($http) {
        var service = {
            getVehicles,
            getVehicle,
            updateVehicle,
            createVehicle,
            deleteVehicle,
            addImage,
        };

        function getVehicles() {
            return $http.get('/api/vehicle')
                .then((result) => result.data);
        }

        function getVehicle(vehicleId) {
            return $http.get(`/api/vehicle/${vehicleId}`)
                .then((result) => result.data);
        }

        function updateVehicle(vehicle) {
            return $http.put('/api/vehicle', vehicle)
                .then((result) => result.data);
        }

        function createVehicle(vehicle) {
            return $http.post('/api/vehicle', vehicle)
                .then((result) => result.data);
        }

        function deleteVehicle(vehicleId) {
            return $http.delete(`/api/vehicle/${vehicleId}`)
                .then((result) => result.data);
        }

        function addImage(vehicleId, file) {
            const imageModel = {
                mediaType: file.mediaType,
                mediaContent: { content: file.data },
            };
            return $http.put(`/api/vehicle/${vehicleId}/image`, imageModel)
                .then((result) => result.data);
        }

        return service;
    }
})();
