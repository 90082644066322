(function () {
    'use strict';

    angular.module('UndergroundWebApp').controller('AdministrationController', AdministrationController);

    AdministrationController.$inject = [
        '$scope',
        '$rootScope',
        '$state',
        'authService',
        'tabstripUtility',
        '$translate'
    ];

    function AdministrationController(
        $scope,
        $rootScope,
        $state,
        authService,
        tabstripUtility,
        $translate
    ) {
        var busyIndicatorCounter = 0;

        $scope.showBusyIndicator = showBusyIndicator;
        $scope.hideBusyIndicator = hideBusyIndicator;

        $scope.tabStripOptions = {
            animation: false,
            select: tabstripUtility.handleTabSelected,
            dataTextField: 'name',
            dataContentUrlField: 'contentUrl',
            dataSource: getTabs(),
            dataBound: (e) => e.sender.select(0),
        };

        initController();

        function initController() {
            $scope.$watch(() => $state.current.name, (state) => {
                tabstripUtility.changeTabByState($scope.administrationtab, state);
            });

            authService.getAccessRights().then(() => {
                $scope.administrationtab.dataSource.data(getTabs());
                tabstripUtility.changeTabByState($scope.administrationtab, $state.current.name);
            });
        };

        function showBusyIndicator() {
            if (busyIndicatorCounter === 0) {
                $rootScope.$broadcast('showBusyIndicator', {
                    id: 'adminIndicator',
                    destination: '.administration',
                    message: $translate.instant('G_BUSY_INDICATOR'),
                    overlay: true,
                    positionClass: {
                        top: '50%',
                        left: '0px',
                        right: '0px'
                    }
                });
            }

            busyIndicatorCounter += 1;
        }

        function hideBusyIndicator() {
            busyIndicatorCounter -= 1;

            if (busyIndicatorCounter === 0) {
                $rootScope.$broadcast('hideBusyIndicator', 'adminIndicator');
            }
        }

        function getTabs() {
            const tabs = [];

            if ($scope.isAccessible('NewFraction', 'R')) {
                tabs.push({
                    name: $translate.instant('ADMINISTRATION_FRACTION_FRACTIONS'),
                    contentUrl: 'app/administration/fraction/fractions-view.html',
                    state: 'main.administration.fractions',
                });
            }
            if ($scope.isAccessible('NewContainerType', 'R')) {
                tabs.push({
                    name: $translate.instant('ADMINISTRATION_CONTAINERTYPES'),
                    contentUrl: 'app/administration/containerType/container-types-view.html',
                    state: 'main.administration.containerTypes',
                });
            }
            if ($scope.isAccessible('UserAdmin', 'R')) {
                tabs.push({
                    name: $translate.instant('ADMINISTRATION_USERS'),
                    contentUrl: 'app/administration/user/user-administration-view.html',
                    state: 'main.administration.userAdministration',
                });
            }
            if ($scope.isAccessible('Vehicle', 'R')) {
                tabs.push({
                    name: $translate.instant('ADMINISTRATION_VEHICLE_VEHICLES'),
                    contentUrl: 'app/administration/vehicle/vehicles-view.html',
                    state: 'main.administration.vehicles',
                });
            }
            if ($scope.isAccessible('ExternalLink', 'R')) {
                tabs.push({
                    name: $translate.instant('ADMINISTRATION_EXTERNAL_LINKS'),
                    contentUrl: 'app/administration/externalLinks/external-links-view.html',
                    state: 'main.administration.externalLinks',
                });
            }
            if ($scope.isAccessible('NewFraction', 'R')) { //@todo ($scope.isAccessible('NewDeviationAlert', 'R')) {
                tabs.push({
                    name: $translate.instant('ADMINISTRATION_DEVIATION_JOBS'),
                    contentUrl: 'app/administration/deviationReport/deviation-report-view.html',
                    state: 'main.administration.deviationAlert',
                });
            }

            return tabs;
        }
    }
})();
