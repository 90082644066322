(function () {
    'use strict';

    angular.module('UndergroundWebApp').controller('ConnectSensorModalController', ConnectSensorModalController);

    ConnectSensorModalController.$inject = [
        '$q',
        '$rootScope',
        '$scope',
        '$windowInstance',
        'externalSystemsService',
        'deviceService',
        'containerId',
        '$translate'
    ];

    function ConnectSensorModalController(
        $q,
        $rootScope,
        $scope,
        $windowInstance,
        externalSystemsService,
        deviceService,
        containerId,
        $translate
    ) {
        $scope.close = close;
        $scope.selectedDevices = [];
        $scope.filterChanged = filterChanged;
        $scope.filterText = '';

        initController();

        function initController() {
            setTimeout(()=>$rootScope.$broadcast('showBusyIndicator', {
                id: "loadDevices",
                destination: '#connect-sensor-modal',
                message: $translate.instant("G_BUSY_INDICATOR"),
                overlay: true,
                positionClass: {
                    top: '50%',
                    left: '0px',
                    right: '0px'
                }
            }), 200);

            $scope.sensorsOptions = {
                dataSource: {
                    transport: {
                        read: readDevices
                    },
                    serverFiltering: false
                },
                filter: 'contains',
                dataTextField: 'name',
                dataValueField: 'id'
            };

            readDevices();
        }

        function readDevices() {
            
            var requests = [
                externalSystemsService.getExternalSystems(),
                deviceService.getDevices()
            ];
            $q.all(requests)
                .then(data => {
                    $scope.externalSystems = data[0];
                    $scope.allDisconnectedDevices = _.filter(data[1], d => d.containerId === null);
                    $scope.disconnectedDevices = $scope.allDisconnectedDevices;
                    extDevices($scope.disconnectedDevices);
                })
                .catch((error) => {
                })
                .finally(()=>{
                    $rootScope.$broadcast('hideBusyIndicator', 'loadDevices');
                });
        }
        function extDevices(devices) {
            _.forEach(devices, d => {
                var extSystem = _.find($scope.externalSystems, s => s.id.toLowerCase() === d.externalSystemId);
                d.name = d.externalId + (extSystem ? (' - ' + extSystem.name) : '');
            })
        }

        function close() {
            $windowInstance.close($scope.selectedDevices ? $scope.selectedDevices : null);
        }

        function filterChanged(){
            $scope.disconnectedDevices = _.filter($scope.allDisconnectedDevices, f => f.name.includes($scope.filterText));
        }
    }
})();

