angular.module('UndergroundWebApp').directive('deviceExternalSystemCell', [function () {
    'use strict';

    return {
        restrict: 'AE',
        replace: true,
        scope: {
            deviceInfo: '=',
        },
        templateUrl: 'app/location/info/device-external-system-cell.html',
    };
}]);
