(function () {
    'use strict';

    angular.module('UndergroundWebApp').controller('ContainerStatisticsController', ContainerStatisticsController);

    ContainerStatisticsController.$inject = [
        '$rootScope',
        '$scope',
        '$stateParams',
        'containerService',
        'localStorageService',
        '$translate'
    ];

    function ContainerStatisticsController(
        $rootScope,
        $scope,
        $stateParams,
        containerService,
        localStorageService,
        $translate,
    ) {
        $scope.containerId = $stateParams.containerId;
        $scope.containerReportData = [];
        $scope.dateRange = {};

        $scope.seriesDefaults = { 
            type: 'line',
            missingValues: "gap"
        };
        $scope.options = {
            dataSource: $scope.chartData,
            series: []
        };

        $scope.refreshChart = refreshChart;
        $scope.onLegendItemClick = onLegendItemClick;

        function refreshChart() {
            getContainerStatisticsChartData();
        }

        function getContainerStatisticsChartData() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'containerStatisticsIndicator',
                destination: '#statisticsChart',
                message: $translate.instant("G_BUSY_INDICATOR"),
                overlay: true,
                positionClass: {
                    top: '50%',
                    left: '0px',
                    right: '0px'
                }
            });
            $scope.options.valueAxes = [];
            $scope.options.series = [];
            $scope.containerStatisticsGridData = [];

            containerService.getContainerReportsBasedOnDate($scope.containerId, moment($scope.dateRange.from).format('YYYY-MM-DD'), moment($scope.dateRange.to).format('YYYY-MM-DD')).then(function (report) {
                if (report) {
                    if ($rootScope.isAccessible('SignalStrengthReport','R')) {
                        addChartData($translate.instant("CONTAINER_STATISTICS_CONTROLLER_CHART_SIGNAL_STRENGTH"), report, 'rssi', 'timestamp', 'red', 'line', true, getMinValue(report, 'rssi'), getMaxValue(report, 'rssi'));
                    }
                    addChartData($translate.instant("CONTAINER_STATISTICS_CONTROLLER_CHART_BATTERY"), report, 'volt', 'timestamp', 'green', 'line', true, getMinValue(report, 'volt'), getMaxValue(report, 'volt'));
                    addChartData($translate.instant("CONTAINER_STATISTICS_CONTROLLER_CHART_TEMPERATURE"), report, 'temperature', 'timestamp', 'orange', 'line', true, getMinValue(report, 'temperatur'), getMaxValue(report, 'temperatur'));
                    addChartData($translate.instant("CONTAINER_STATISTICS_CONTROLLER_CHART_FILL"), report, 'fill', 'timestamp', 'blue', 'line', true, 0, 110, function (item) { return Math.round(item); });
                    addChartData($translate.instant("CONTAINER_STATISTICS_CONTROLLER_CHART_ACCESS_COUNT"), report, 'accessCount', 'timestamp', 'gray', 'bar', true, getMinValue(report, 'accesscount'), getMaxValue(report, 'accessCount'));
                    if ($rootScope.isAccessible('ContainerUsageForPeriodeReport', 'R') || $rootScope.isAccessible('ContainerUsageReport', 'R')) {
                        addChartData($translate.instant("CONTAINER_STATISTICS_CONTROLLER_CHART_USAGE_COUNT"), report, 'usageCount', 'timestamp', 'purple', 'line', true, getMinValue(report, 'usageCount'), getMaxValue(report, 'usageCount'));
                    }
                    setCategoryAxis(report);
                }
                $rootScope.$broadcast('hideBusyIndicator', 'containerStatisticsIndicator');
            }, function () {
                $rootScope.$broadcast('hideBusyIndicator', 'containerStatisticsIndicator');
            });
        };

        function addChartData(name, items, column, category, color, chartType, tooltipVisible, min, max, special) {
            var data = [];
            var times = [];
            _.each(items, function (item) {
                if (special) {
                    data.push(special(item[column]));
                }
                else {
                    data.push(item[column]);
                }
                times.push(item[category]);
            });
            $scope.options.series.push({
                name: name,
                data: data,
                axis: name,
                color: color,
                type: chartType,
                tooltip: {
                    visible: tooltipVisible,
                    template: `${$translate.instant("G_DAY_CAPITAL")}: #= category #<br />#= series.name #: #= value #`
                },
            });
            $scope.options.valueAxes.push({
                name: name,
                color: color,
                min: min,
                max: max
            });
        };
        function getMinValue(report, column) {
            return _.min(report[column]);
        };
        function getMaxValue(report, column) {
            return _.max(report[column]);
        };
        function setCategoryAxis(items) {
            var times = [];
            _.each(items, function (item) {
                times.push(moment(item.timestamp).format('DD.MM.YYYY HH:mm:ss') == '01.01.1900' ? ' ' : moment(item.timestamp).format('DD'));
            });
            var c = _.size(times);
            $scope.options.categoryAxis = {
                categories: times,
                majorGridLines: {
                    visible: false
                },
                axisCrossingValues: [0, 0, 0, c, c, c]
            };
        };
        function onLegendItemClick(e) {
            console.log(e);
        }
    };
})();
