(function () {
    angular.module('UndergroundWebApp').factory('authInterceptorService', authInterceptorService);

    authInterceptorService.$inject = [
        'localStorageService'
    ];

    function authInterceptorService(
        localStorageService
    ) {
        return {
            request
        };

        function request(config) {
            config.headers = config.headers || {};

            var authData = localStorageService.get('authenticationData');
            if (authData && config.url
                && config.url.indexOf('services.geodataonline.no') === -1
                && config.url.indexOf('geocode.arcgis.com') === -1) {
                
                config.headers.Authorization = 'Bearer ' + authData.token;
            }

            return config;
        }
    }
})();
