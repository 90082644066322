(function () {
    'use strict';

    angular.module('UndergroundWebApp').controller('ContainerOverviewV2Controller', ContainerOverviewV2Controller);

    ContainerOverviewV2Controller.$inject = [
        '$q',
        '$rootScope',
        '$scope',
        '$stateParams',
        '$kWindow',
        'formTypes',
        'containerService',
        'locationsService',
        'containerEditModalWidth',
        'filterStateService',
        'kendoGridUtility',
        'deviceUtility',
        '$translate'
    ];
    const overviewTableVersion = 1.0;
    const defaultSensorSystemCount = 2;

    function ContainerOverviewV2Controller(
        $q,
        $rootScope,
        $scope,
        $stateParams,
        $kWindow,
        formTypes,
        containerService,
        locationsService,
        containerEditModalWidth,
        filterStateService,
        kendoGridUtility,
        deviceUtility,
        $translate,
    ) {
        var containerOverviewGridData = [];
        var locations = [];

        $scope.noCache = $stateParams.noCache;
        $scope.excelFileName = 'Containeroversikt.xlsx';

        $scope.isEditVisible = isEditVisible;
        $scope.exportToExcel = exportToExcel;
        $scope.openNewContainerModal = openNewContainerModal;
        $scope.openUpdateContainerModal = openUpdateContainerModal;

        const persistOptions = kendoGridUtility.persistOptions(overviewTableVersion);
        $scope.containerOverviewGridOptions = {
            dataSource: {
                data: containerOverviewGridData,
                schema: {
                    model: {
                        fields: {
                            displayText: {},
                            locationName: {},
                            commune: {},
                            containerNumberNum: { type: 'number' },
                            fraction: {},
                            battery: { type: 'number' },
                            fill: { type: 'number' },
                            sensor: {},
                            sensorSystemId: { type: 'number' },
                            updateTime: { type: 'date' },
                            dateWhenFull: { type: 'date' },
                            lastEmptyDate: { type: 'date' },
                        }
                    }
                },
                pageSize: 70,
                sort: filterStateService.getSortFromQueryString({
                    field: 'locationName',
                    dir: 'asc'
                }),
                filter: filterStateService.getFilterFromQueryString(),
            },
            sortable: true,
            filterable: true,
            noRecords: true,
            resizable: true,
            messages: {
                noRecords: ''
            },
            scrollable: {
                virtual: true
            },
            selectable: 'row',
            columns: getColumns(defaultSensorSystemCount),
            columnMenu: true,
            excel: {
                fileName: $scope.excelFileName,
                filterable: true,
                allPages: true
            },
            excelExport: containerOverviewGridExcelExport,
            change: onRowSelected,
            sort: filterStateService.storeGridSortInQueryString,
            filter: filterStateService.storeGridFilterInQueryString,
            filterMenuInit: kendoGridUtility.sortMulticheckboxFilterValues('commune'),
            columnMenuInit: kendoGridUtility.excludeColumnFromMenuList('actions'),
            columnHide: persistOptions,
            columnShow: persistOptions,
            columnResize: persistOptions,
            columnReorder: persistOptions,
        };

        function getColumns(sensorSystemsCount) {
            const columns = [
                {
                    field: 'actions',
                    title: ' ',
                    width: '50px',
                    template: '<div ng-show="isEditVisible()" class="grid-tooltip" ng-click="openUpdateContainerModal(dataItem)"><i class="fa fa-second fa-1-5x fa-pencil" aria-hidden="true"></div>',
                    filterable: false,
                    sortable: false,
                    groupable: false
                },
                {
                    field: 'displayText',
                    title: $translate.instant("G_ID"),
                    width: '100px'
                },
                {
                    field: 'locationName',
                    title: $translate.instant("G_ADDRESS"),
                    width: '200px',
                },
                {
                    field: 'commune',
                    title: $translate.instant("G_MUNINCIPALITY"),
                    width: '90px',
                    filterable: {
                        multi: true,
                        itemTemplate: kendoGridUtility.filterCheckboxTemplate($translate.instant("CONTAINER_OVERVIEW_NO_MUNINCIPALITY")),
                    },
                },
                { field: 'containerNumberNum', title: $translate.instant("CONTAINER_OVERVIEW_CONTAINER"), width: '100px' },
                { field: 'containerType', title: $translate.instant("CONTAINER_OVERVIEW_CONTAINER_TYPE"), width: '160px' },
                {
                    field: 'fraction',
                    title: $translate.instant("CONTAINER_OVERVIEW_FRACTION"),
                    width: '100px',
                    filterable: {
                        multi: true,
                    }
                },
            ];

            for (let index = 0; index < sensorSystemsCount; index++) {
                columns.push(...getDeviceOverviewColumns(index));
            }

            return columns;
        }

        function getDeviceOverviewColumns(index) {
            const columns = [];

            if (index === 0) {
                columns.push({
                    field: `deviceOverviews[${index}].battery`,
                    title: getIndexedColumnTitle($translate.instant("CONTAINER_OVERVIEW_BATTERY"), index),
                    width: '94px',
                    template: `#=  deviceOverviews[${index}].battery !== -1 ? deviceOverviews[${index}].battery.toFixed(1) : "N/A" #`
                })
            }

            columns.push(
                {
                    field: `deviceOverviews[${index}].fill`,
                    title: getIndexedColumnTitle($translate.instant("CONTAINER_OVERVIEW_FILL_PERCENT"), index),
                    width: '90px',
                    template: `#=  deviceOverviews[${index}].fill !== -1 ? deviceOverviews[${index}].fill : "N/A" #`
                },
                {
                    field: `deviceOverviews[${index}].deviceExternalId`,
                    title: getIndexedColumnTitle($translate.instant("CONTAINER_OVERVIEW_EXTERNAL_ID"), index),
                    width: '90px',
                },
                {
                    field: `deviceOverviews[${index}].externalSystem`,
                    title: getIndexedColumnTitle($translate.instant("CONTAINER_OVERVIEW_EXTERNAL_SYSTEM"), index),
                    width: '120px',
                    template: `#=  deviceOverviews[${index}].sensorColumnTemplate #`,
                    filterable: {
                        multi: true,
                    },
                },
                {
                    title: getIndexedColumnTitle($translate.instant("CONTAINER_OVERVIEW_CREATED_AT"), index),
                    field: `deviceOverviews[${index}].createdAt`,
                    width: '120px',
                    format: $translate.instant("CONTAINER_OVERVIEW_CREATED_AT_DATE_FORMAT"),
                    filterable: {
                        ui: 'datetimepicker',
                    },
                },
                {
                    title: getIndexedColumnTitle($translate.instant("CONTAINER_OVERVIEW_DATE_WHEN_FULL"), index),
                    field: `deviceOverviews[${index}].dateWhenFull`,
                    width: '148px',
                    format: $translate.instant("CONTAINER_OVERVIEW_DATE_WHEN_FULL_DATE_FORMAT"),
                    filterable: {
                        ui: 'datetimepicker',
                    },
                },
                {
                    title: getIndexedColumnTitle($translate.instant("CONTAINER_OVERVIEW_LAST_EMPTY_DATE"), index),
                    field: `deviceOverviews[${index}].lastEmptyDate`,
                    width: '120px',
                    format: $translate.instant("CONTAINER_OVERVIEW_LAST_EMPTY_DATE_DATE_FORMAT"),
                    filterable: {
                        ui: 'datetimepicker',
                    },
                },
            );

            return columns;
        }

        function getIndexedColumnTitle(title, index) {
            if (index === 0) {
                return title;
            }

            return `${title} ${index + 1}`;
        }

        initController();

        function initController() {
            loadOverviewGridOptions();
            getContainerOverviewGridData();
        }

        function loadOverviewGridOptions() {
            const unwatch = $scope.$watch('containerOverviewGrid', (grid) => {
                if (!grid) return;

                unwatch();
                const options = kendoGridUtility.loadOptions('container-overview-grid', overviewTableVersion);
                if (options) {
                    grid.setOptions(options);
                }
            })
        }

        function isEditVisible() {
            return $rootScope.isAccessible && $rootScope.isAccessible("NewContainer", "W");
        }

        function openNewContainerModal() {
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant("CONTAINER_OVERVIEW_NEW_CONTAINER_MODAL_TITLE"),
                    resizable: false,
                    height: 600,
                    width: 980,
                    visible: false
                },
                templateUrl: 'app/containerOverviewV2/views/sensor-api-container-details-modal-view.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'SensorApiContainerDetailsModalController',
                resolve: {
                    currentFormType: function () {
                        return formTypes.add;
                    },
                    containerId: function () {
                        return null;
                    },
                    updateCallbacks: function () {
                        return {
                            addContainerToGrid: () => { },
                            updateContainerToGrid: () => { },
                        };
                    },
                    locationId: function () {
                        return null;
                    }
                }
            });

            windowInstance.result.then(function (result) {
                if (result) {
                    getContainerOverviewGridData();
                }
            });
        }

        function openUpdateContainerModal(dataItem) {
            var titleFraction = dataItem.fraction ? (' - ' + dataItem.fraction) : '';
            var titleAddress = dataItem.address ? (' - ' + dataItem.address) : '';
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: dataItem.containerNumber + titleAddress + titleFraction,
                    resizable: false,
                    height: 600,
                    width: containerEditModalWidth,
                    visible: false
                },
                templateUrl: 'app/containerOverviewV2/views/sensor-api-container-details-modal-view.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'SensorApiContainerDetailsModalController',
                resolve: {
                    currentFormType: function () {
                        return formTypes.edit;
                    },
                    containerId: function () {
                        return dataItem.id;
                    },
                    updateCallbacks: function () {
                        return {
                            addContainerToGrid: () => { },
                            updateContainerToGrid: () => { },
                        };
                    },
                    locationId: function () {
                        return null;
                    }
                }
            });

            windowInstance.result.then(function (result) {
                if (result) {
                    getContainerOverviewGridData();
                }
            });
        }

        function exportToExcel() {
            $scope.containerOverviewGrid.hideColumn(0);
            $scope.containerOverviewGrid.saveAsExcel();
            $scope.containerOverviewGrid.showColumn(0);
        }

        //Private functions
        function getContainerOverviewGridData() {
            showBusyIndicator();

            containerOverviewGridData = [];
            var requests = [
                locationsService.getLocations(false),
                containerService.getContainersV2($stateParams.noCache)
            ];

            $q.all(requests)
                .then(function (data) {
                    locations = data[0];
                    var containers = data[1];
                    if (containers) {
                        containerOverviewGridData = containers;
                        var sensorSystemsCount = getCurrentSensorSystemColumnCount();
                        containerOverviewGridData.forEach(c => extendGridData(c, sensorSystemsCount));

                        $scope.containerOverviewGrid.dataSource.data(containerOverviewGridData);
                    }
                })
                .finally(hideBusyIndicator);
        }

        function extendGridData(container, sensorSystemsCount) {
            var location = container.locationId ? locations.find(loc => loc.id === container.locationId) : null;

            container.id = container.containerId;
            container.containerNumberNum = Number(container.containerNumber);
            container.locationName = location ? location.name : '';
            container.commune = location ? location.municipalityCode : '';

            padDeviceOverviews(container, sensorSystemsCount);
            container.deviceOverviews.forEach(extendDeviceOverview);
        }

        function extendDeviceOverview(device) {
            device.createdAt = toDateOrEmpty(device.createdAt);
            device.dateWhenFull = toDateOrEmpty(device.dateWhenFull);
            device.lastEmptyDate = toDateOrEmpty(device.lastEmptyDate);
            device.sensorColumnTemplate = getSensorColumnTemplate(device)

            // Negative numbers are used to represent empty values so sorting works
            device.battery = device.battery || -1;
            device.fill = device.fill || -1;
        }

        function getSensorColumnTemplate(device) {
            let template = device.externalSystem || '';

            const detailsLink = deviceUtility.getDeviceDetailsLink(device.externalSystem, device.deviceExternalId, true);
            if (detailsLink) {
                template += ` <a class="grid-tooltip" href="${detailsLink.url}" target="_blank"><i class="fa fa-external-link"></i></a>`;
            }

            return template;
        }

        function padDeviceOverviews(item, sensorSystemsCount) {
            for (let index = item.deviceOverviews.length; index < sensorSystemsCount; index++) {
                item.deviceOverviews[index] = {};
            }
        }

        function addContainerToGrid(container) {
            containerOverviewGridData.push(container);
            extendGridData(container, getCurrentSensorSystemColumnCount());
            $scope.containerOverviewGrid.dataSource.data(containerOverviewGridData);
        }

        function updateContainerToGrid(container) {
            var containerIndexOverview = _.findIndex(containerOverviewGridData, c => c.containerId == container.containerId);
            containerOverviewGridData.splice(containerIndexOverview, 1);

            containerOverviewGridData.push(container);
            extendGridData(container, getCurrentSensorSystemColumnCount());
            $scope.containerOverviewGrid.dataSource.data(containerOverviewGridData);
        }

        function toDateOrEmpty(date) {
            return date == null ? '' : new Date(date);
        }

        function getCurrentSensorSystemColumnCount() {
            return defaultSensorSystemCount;
        }

        function onRowSelected() {
            var selectedRows = this.select();
            $rootScope.selectedContainerId = this.dataItem(selectedRows[0]).id;
        }

        function containerOverviewGridExcelExport(e) {
            e.workbook.fileName = $translate.instant("CONTAINER_OVERVIEW_CONTAINER_OVERVIEW_FILENAME") + kendo.toString(new Date, 'ddMMyyyy_HHmmss') + '.xlsx';
            var sheet = e.workbook.sheets[0];
            var columnWidths = this.element.find('.k-grid-header colgroup col');

            for (var i = 0; i < sheet.columns.length; i++) {
                if ($(columnWidths[i]).attr('style')) {
                    sheet.columns[i].width = 160;
                } else {
                    sheet.columns[i].width = 16;
                }
            }
        }

        function showBusyIndicator() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'containerIndicator',
                destination: '#container-overview-view-v2',
                message: $translate.instant("G_BUSY_INDICATOR"),
                overlay: true,
                positionClass: {
                    top: '50%',
                    left: '0px',
                    right: '0px'
                }
            });
        }

        function hideBusyIndicator() {
            $rootScope.$broadcast('hideBusyIndicator', 'containerIndicator')
        }
    }
})();
