(function () {
    'use strict';

    angular.module('UndergroundWebApp').controller('CustomerSystemModalController', CustomerSystemModalController);

    CustomerSystemModalController.$inject = [
        '$timeout',
        '$scope',
        'currentCustomerSystemId',
        'currentCustomerSystemExternalId',
        '$windowInstance',
        'containerService',
        '$stateParams'
    ];

    function CustomerSystemModalController(
        $timeout,
        $scope,
        currentCustomerSystemId,
        currentCustomerSystemExternalId,
        $windowInstance,
        containerService,
        $stateParams
    ) {
        $scope.selectedCustomerSystemId = currentCustomerSystemId ? currentCustomerSystemId.toUpperCase() : '';
        $scope.customerSystemExternalId = currentCustomerSystemExternalId ? currentCustomerSystemExternalId : '';

        $scope.customerSystemOptions = {
            dataSource: {
                transport: {
                    read: readCustomerSystems
                }
            },
            dataTextField: "name",
            dataValueField: "id"
        };

        $scope.saveAndClose = saveAndClose;
        $scope.closeWindow = closeWindow;

        function saveAndClose() {
            if ($scope.selectedCustomerSystemId && $stateParams.containerId && $stateParams.externalSystemId) {
                containerService.updateDeviceCustomerSystem(
                    $stateParams.containerId,
                    $stateParams.externalSystemId,
                    $scope.selectedCustomerSystemId,
                    $scope.customerSystemExternalId
                ).then(function (result) {
                    if (result.customerSystem) {
                        $windowInstance.close({
                            customerSystemId: result.customerSystem.id,
                            customerSystemName: result.customerSystem.name,
                            customerSystemExternalId: result.customerSystemExternalId
                        });
                    }
                    $windowInstance.close();
                }).catch(function (error) {
                    $windowInstance.close();
                });
            }
        }

        function closeWindow() {
            $windowInstance.close();
        }

        function readCustomerSystems(e) {
            e.success([
                { id: '1208E98F-96CB-4B31-B8D5-0AC1B4BBBBDE', name: 'Unicon' },
                { id: '55780AE8-8230-480B-987E-1A33B8D39BE0', name: 'ISY Proaktiv' }
            ]);

            //Select hack -> ng-model not working
            $timeout(100).then(function() {
                if ($scope.selectedCustomerSystemId) {
                    $('#customer-systems').data('kendoDropDownList').value($scope.selectedCustomerSystemId);
                }
            });
        }
    }
})();
