(function () {
    'use strict';

    angular.module('UndergroundWebApp').controller('LocationLogTabController', LocationLogTabController);

    LocationLogTabController.$inject = [
        '$scope',
        '$rootScope',
        '$kWindow',
        'containerService',
        '$translate'
    ];

    function LocationLogTabController($scope, $rootScope, $kWindow, containerService, $translate) {
        var unwatchLocation = null;
        var containerLogs = [];

        $scope.dateRange = {};

        $scope.openMediaModal = openMediaModal;
        $scope.loadContainerLogs = loadContainerLogs;
        $scope.openNewLogModal = openNewLogModal;
        $scope.waitLocationLoaded = waitLocationLoaded;

        $scope.containerLogsGridOptions = {
            dataSource: {
                data: [],
                sort: { field: 'time', dir: 'desc' },
            },
            sortable: true,
            filterable: true,
            noRecords: true,
            messages: {
                noRecords: $translate.instant("G_NO_DATA")
            },
            pageable: {
                pageSize: 100
            },
            columns: [
                {
                    field: 'time',
                    title: $translate.instant("CONTAINER_LOG_TAB_CONTROLLER_HEADER_TIME"),
                    template: `#= kendo.toString(kendo.parseDate(time), "${$translate.instant("G_DEFAULT_DATE_FORMAT")}") #`,
                    filterable: false,
                    width: 150,
                },
                {
                    field: 'deviceExternalId',
                    title: $translate.instant("CONTAINER_LOG_TAB_CONTROLLER_HEADER_EXTERNAL_ID"),
                    width: 110,
                },
                {
                    field: 'container.containerNumber',
                    title: $translate.instant("CONTAINER_LOG_TAB_CONTROLLER_HEADER_CONTAINER_NUMBER"),
                    width: 110,
                },
                {
                    field: 'fractionName',
                    title: $translate.instant("CONTAINER_LOG_TAB_CONTROLLER_HEADER_FRACTION"),
                    width: 110,
                    filterable: {
                        multi: true,
                    },
                },
                {
                    field: 'logAction',
                    title: $translate.instant("CONTAINER_LOG_TAB_CONTROLLER_HEADER_LOG_ACTION"),
                    width: 120,
                    filterable: {
                        multi: true,
                    },
                },
                {
                    field: 'comment',
                    title: $translate.instant("CONTAINER_LOG_TAB_CONTROLLER_HEADER_COMMENT")
                },
                {
                    field: 'extUser',
                    title: $translate.instant("CONTAINER_LOG_TAB_CONTROLLER_HEADER_EXTERNAL_USER"),
                    width: 100,
                },
                {
                    field: 'image',
                    title: $translate.instant("G_IMAGE"),
                    width: 70,
                    sortable: false,
                    filterable: false,
                    template: function (dataItem) {
                        if (dataItem.media.length) {
                            return '<i class="fa fa-1-5x fa-image" ng-click="openMediaModal(dataItem.media)"></i>'
                        }
                        return '';
                    }
                }
            ],
        };

        function waitLocationLoaded() {
            showBusyIndicator('containerLogsGrid')
            if (!$scope.location) {
                unwatchLocation = $scope.$watch('location', handleLocationLoaded);
            } else {
                handleLocationLoaded();
            }
        }

        function handleLocationLoaded() {
            if (!$scope.location) return;

            if (unwatchLocation) {
                unwatchLocation();
            }

            loadContainerLogs();
        }

        function loadContainerLogs() {
            showBusyIndicator('containerLogsGrid')
            var filterList = getLogFilterList(
                $scope.location.containers,
                moment($scope.dateRange.from).format('YYYY-MM-DD'),
                moment($scope.dateRange.to).format('YYYY-MM-DD'),
            );
            if (!filterList) return;

            containerService.getLogsByFilterBulk(filterList).then(function (response) {
                containerLogs = response.map(extendContainerLog);
                $('#containerLogsGrid').getKendoGrid().dataSource.data(containerLogs);
            }).catch(function (error) {
                console.error(error);
            }).finally(function () {
                hideBusyIndicator('containerLogsGrid')
            });
        }

        function getLogFilterList(containers, from, to) {
            if (!containers || containers.length === 0) return null;

            return _.map(containers, function (container) {
                return {
                    containerNumber: container.containerNumber,
                    from: from,
                    to: to,
                };
            })
        }

        function extendContainerLog(containerLog) {
            return {
                ...containerLog,
                deviceExternalId: containerLog.device
                    ? containerLog.device.externalId
                    : '',
                fractionName: containerLog.container.fraction
                    ? containerLog.container.fraction.name
                    : '',
            }
        }

        function openMediaModal(media) {
            var pictureUrls = media.map(m => m.pictureUrl);
            $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant("G_IMAGE"),
                    resizable: false,
                    width: "30%",
                    position: {
                        top: "5%",
                        left: "35%"
                    },
                    visible: false
                },
                templateUrl: 'app/shared/pictureCarouselModal/picture-carousel.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'PictureCarouselController',
                resolve: {
                    pictureUrls: function () {
                        return pictureUrls;
                    }
                }
            });
        }

        function showBusyIndicator(id) {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'locationLog' + id,
                destination: '#' + id,
                message: $translate.instant("G_BUSY_INDICATOR"),
                overlay: true,
                positionClass: {
                    top: '50%',
                    left: '0px',
                    right: '0px'
                }
            });
        }

        function hideBusyIndicator(id) {
            $rootScope.$broadcast('hideBusyIndicator', 'locationLog' + id);
        }

        function openNewLogModal() {
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: true,
                    title: $translate.instant("CONTAINER_LOG_TAB_VIEW_NEW_LOG"),
                    resizable: true,
                    height: 400,
                    width: 400,
                    visible: false
                },
                templateUrl: 'app/location/log/container-log-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'ContainerLogModalController',
                resolve: {
                    containers: function () {
                        return $scope.location && $scope.location.containers;
                    }
                }
            });

            windowInstance.result.then(function (containerLog) {
                if (
                    containerLog
                    && moment(containerLog.time).isBetween($scope.dateRange.from, $scope.dateRange.to, 'days', '[]')
                ) {
                    containerLogs.push(containerLog);
                    $('#containerLogsGrid').getKendoGrid().dataSource.data(containerLogs);
                }
            });
        }
    }
})();
