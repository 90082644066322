(function () {
    'use strict';

    angular.module('UndergroundWebApp').controller('GroupDetailsModalController', GroupDetailsModalController);

    GroupDetailsModalController.$inject = [
        '$q',
        '$rootScope',
        '$scope',
        '$windowInstance',
        'groupService',
        'keycardService',
        'formTypes',
        'currentFormType',
        'groupId',
        'locationsService',
        '$translate'
    ];

    function GroupDetailsModalController(
        $q,
        $rootScope,
        $scope,
        $windowInstance,
        groupService,
        keycardService,
        formTypes,
        currentFormType,
        groupId,
        locationsService,
        $translate
    ) {
        var isEdit = currentFormType === formTypes.edit;

        $scope.isEdit = isEdit;
        $scope.createLocation = true;
        $scope.groupId = groupId;
        $scope.name = '';
        $scope.info = '';
        $scope.selectedUser = null;
        $scope.users = [];

        $scope.closeWindow = closeWindow;
        $scope.saveAndClose = saveAndClose;

        $scope.usersOptions = {
            dataSource: {
                transport: {
                    read: readUsers
                }
            },
            dataTextField: 'userName',
            dataValueField: 'userId',
            optionLabel: {
                userName: $translate.instant("GROUP_DETAILS_MODAL_CONTROLLER_USERS_OPTIONS_LABEL"),
                userId: ''
            }
        };

        initController();

        function initController() {
            if (isEdit) {
                groupService.getGroups().then(function (groups) {
                    var currentGroup = _.find(groups, function (group) {
                        return group.nummer === $scope.groupId;
                    });

                    if (currentGroup) {
                        $scope.name = currentGroup.name;
                        $scope.info = currentGroup.info.trim();
                        $scope.groupNummer = $scope.groupId;

                        if (currentGroup.user) {
                            keycardService.getUsers().then(function (users) {
                                var selectedUser = _.find(users, function (user) {
                                    return user.userId === currentGroup.user;
                                });

                                $scope.selectedUser = {
                                    userId: selectedUser.userId,
                                    userName: selectedUser.userName
                                };
                            });
                        }

                        $scope.selectedUser = currentGroup.user;
                    }
                });
            }
        }

        function readUsers(e) {
            keycardService.getUsers().then(function (users) {
                if (users) {
                    _.each(users, function (item) {
                        $scope.users.push(item);
                    });
                }
                $scope.usersDdlServerErrorText = null;
                e.success($scope.users);
            }).catch(function (error) {
                $scope.usersDdlServerErrorText = error.localizedMessage || fallbackErrorMessageText;
                e.error();
            });
        }

        function saveAndClose() {
            save().then(function (result) {
                $windowInstance.close(result);
            });
        }

        function save() {
            var deferred = $q.defer();

            event.preventDefault();

            if ($scope.validator.validate()) {
                $rootScope.$broadcast('showGlobalBusyIndicator', 'groupIndicator');

                var group = getGroupData();
                if (isEdit) {
                    groupService.updateGroup(group).then(function (result) {
                        deferred.resolve(result ? createResult(result, true) : null);
                        $rootScope.$broadcast('hideBusyIndicator', 'groupIndicator');
                    }, function () {
                        deferred.reject();
                        $rootScope.$broadcast('hideBusyIndicator', 'groupIndicator');
                    });
                }
                else {
                    var addGroup = groupService.addGroup(group);
                    var addLocation = $scope.createLocation
                        ? addGroup.then((groupResult) => {
                            return locationsService.addLocation({
                                name: group.name,
                                address: group.name,
                                country: $translate.instant("G_COUNTRY_NAME"),
                                externalId: groupResult.groupId,
                                municipalityCode: null,
                                city: '',
                            });
                        })
                        : $q.resolve();

                    $q.all([addGroup, addLocation]).then(function ([groupResult]) {
                        deferred.resolve(groupResult ? createResult(groupResult, false) : null);
                        $rootScope.$broadcast('hideBusyIndicator', 'groupIndicator');
                    }, function () {
                        deferred.reject();
                        $rootScope.$broadcast('hideBusyIndicator', 'groupIndicator');
                    });
                }
            }

            return deferred.promise;
        }

        function createResult(group, isEdit) {
            return {
                groupId: group.groupId,
                groupName: group.name,
                isEdit: isEdit
            }
        }

        function closeWindow(result) {

            event.preventDefault();
            $windowInstance.close(result);
        }

        function getGroupData() {
            return {
                "groupId": $scope.groupId,
                "name": $scope.name,
                "info": $scope.info,
                "userId": $scope.selectedUser.userId
            };
        }
    }
})();
