(function () {
    angular.module('UndergroundWebApp').factory('batteryVoltagesService', batteryVoltagesService);

    batteryVoltagesService.$inject = [
        '$http'
    ];

    function batteryVoltagesService(
        $http
    ) {
        return {
            getBatteryVoltages,
        };

        function getBatteryVoltages() {
            return $http.get('/api/battery-voltages')
                .then(result => result.data);
        }
    }
})();
