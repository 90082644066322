(function () {
    'use strict';

    angular.module('UndergroundWebApp').controller('SensorOverviewController', SensorOverviewController);

    SensorOverviewController.$inject = [
        '$scope',
        '$rootScope',
        '$kWindow',
        'deviceService',
        'filterStateService',
        'formTypes',
        'deviceTypes',
        '$translate',
        '$state'

    ];

    function SensorOverviewController(
        $scope,
        $rootScope,
        $kWindow,
        deviceService,
        filterStateService,
        formTypes,
        deviceTypes,
        $translate,
        $state
    ) {
        let devices = [];

        $scope.openUpdateSensor = openUpdateSensor;
        $scope.moveToLocationDetails = moveToLocationDetails;

        $scope.sensorOverviewGridOptions = {
            dataSource: {
                transport: {
                    read: readSensors,
                },
                pageSize: 70,
                sort: filterStateService.getSortFromQueryString(),
                filter: filterStateService.getFilterFromQueryString(),
            },
            columns: [
                {
                    field: 'externalSystem',
                    title: $translate.instant("SENSOR_OVERVIEW_CONTROLLER_HEADER_SYSTEM"),
                    width: 140,
                },
                {
                    field: 'externalId',
                    title: $translate.instant("SENSOR_OVERVIEW_CONTROLLER_HEADER_EXTERNAL_ID"),
                    width: 90,
                }, 
                {
                    field: 'container',
                    title: $translate.instant("SENSOR_OVERVIEW_CONTROLLER_HEADER_DEVICE_CONTAINER"),
                    template: getContainer,
                    width: 100,
                },
                {
                    field: 'deviceType',
                    title: $translate.instant("SENSOR_OVERVIEW_CONTROLLER_HEADER_DEVICE_TYPE"),
                    template: getDeviceTypeText,
                },
                {
                    field: 'modifiedAt',
                    title: $translate.instant("SENSOR_OVERVIEW_CONTROLLER_HEADER_MODIFIED_AT"),
                    format: $translate.instant("SENSOR_OVERVIEW_CONTROLLER_HEADER_MODIFIED_AT_FORMAT"),
                },
                {
                    field: 'isActive',
                    title: $translate.instant("SENSOR_OVERVIEW_CONTROLLER_HEADER_IS_ACTIVE"),
                    template: '<input type="checkbox" disabled #= isActive ? \'checked="checked"\' : "" # />',
                    width: 100,
                },
                {
                    field: 'isPriority',
                    title: $translate.instant("SENSOR_OVERVIEW_CONTROLLER_HEADER_IS_PRIORITY"),
                    template: '<input type="checkbox" disabled #= isPriority ? \'checked="checked"\' : "" # />',
                    width: 100,
                },
                {
                    field: 'edit',
                    filterable: false,
                    sortable: false,
                    groupable: false,
                    title: ' ',
                    width: 80,
                    template: '<i class="fa fa-1-5x fa-pencil fa-fw" ng-click="openUpdateSensor(dataItem)" aria-hidden="true"></i>',
                }
            ],
            scrollable: {
                virtual: true
            },
            sortable: true,
            filterable: true,
            noRecords: true,
            selectable: false,
            sort: filterStateService.storeGridSortInQueryString,
            filter: filterStateService.storeGridFilterInQueryString,
        };

        function readSensors(e) {
            showBusyIndicator();

            deviceService.getDevices().then((result) => {
                devices = result.map(extendDevice);

                hideBusyIndicator();
                e.success(devices);
            });
        }

        function openUpdateSensor(device) {
            $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant("SENSOR_OVERVIEW_CONTROLLER_OPEN_UPDATE_SENSOR"),
                    resizable: false,
                    height: 525,
                    width: 450,
                    visible: false
                },
                templateUrl: 'app/device/device-details-modal-view.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'DeviceDetailsModalController',
                resolve: {
                    currentFormType: () => formTypes.edit,
                    containerId: () => device.containerId,
                    updateCallbacks: () => ({ updateDeviceToGrid }),
                    device: () => device,
                }
            });
        }

        function updateDeviceToGrid(device) {
            const index = devices.findIndex(d => d.id === device.id);
            if (index === -1) return;

            devices[index] = device;
            $scope.sensorOverviewGrid.dataSource.data(devices);
        }

        function extendDevice(device) {
            let modifiedAt = '';
            if (device.lastModified) {
                modifiedAt = new Date(device.lastModified);
            } else if (device.createdAt) {
                modifiedAt = new Date(device.createdAt);
            }

            return {
                ...device,
                externalSystem: device.externalSystem ? device.externalSystem.name : '',
                modifiedAt: modifiedAt
            }
        }

        function getDeviceTypeText(device) {
            const type = deviceTypes.find(t => t.id === device.deviceType);
            return type ? type.name : '';
        }

        function getContainer(device) {
            if(device.container && device.container.location){
                return `<a ng-click="moveToLocationDetails(dataItem)" aria-hidden="true" style="cursor: pointer">${device.container.containerNumber}</a>`;
            }else{
                return "";
            }            
        }

        function moveToLocationDetails(dataItem){
            if (dataItem.container && dataItem.container.location) {
                let locationId = dataItem.container.location.id ;
                let param = {
                    locationId: locationId,
                };

                $state.go('main.locationDetails.info', param);
            }
        }

        function showBusyIndicator() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'sensorOverviewIndicator',
                destination: '.sensor-overview',
                message: $translate.instant("G_BUSY_INDICATOR"),
                overlay: true,
                positionClass: {
                    top: '50%',
                    left: '0px',
                    right: '0px'
                }
            });
        }

        function hideBusyIndicator() {
            $rootScope.$broadcast('hideBusyIndicator', 'sensorOverviewIndicator');
        }
    }
})();
