angular.module('UndergroundWebApp').factory('treeUtility', [function() {
    'use strict';
    
    /**
     *  Pre-order tree traversal visits each node using stack. 
     *  Checks if leaf node based on children === null otherwise 
     *  pushes all children into stack and continues traversal. 
     *  hashMap object literal used for deduping.
     *  @param root - deserialized JSON root to begin traversal
     *  @returns array  - final array of nodes in order with no dups
     */
    function convertTreeToList(root, childrenProp, idProp) {
        var stack = [], array = [], hashMap = {};
        stack.push(root);

        while (stack.length !== 0) {
            var node = stack.pop();

            if (node[childrenProp].length !== 0) {
                for (var i = node[childrenProp].length - 1; i >= 0; i--) {
                    stack.push(node[childrenProp][i]);
                }
            }

            visitNode(node, hashMap, array, idProp);
        }

        return array;
    }

    /**
     *  For each node visit if node not a hashMap key, insert 
     *  into array.  Then append node into end of the array.
     *  @params node - object to check
     *  @param hashMap - object literal used for deduping
     *  @param array - final array that nodes are inserted
     */
    function visitNode(node, hashMap, array, idProp) {
        if (!hashMap[node[idProp]]) {
            hashMap[node[idProp]] = true;
            array.push(node);
        }
    }

    var treeUtility = {
        convertTreeToList: convertTreeToList
    };

    return treeUtility;
}]);
