(function () {
    'use strict';

    angular.module('UndergroundWebApp').controller('ExternalLinkDetailsModalController', ExternalLinkDetailsModalController);

    ExternalLinkDetailsModalController.$inject = [
        '$scope',
        '$rootScope',
        '$windowInstance',
        'externalLinkService',
        'formTypes',
        'currentFormType',
        'externalLink',
    ];

    function ExternalLinkDetailsModalController(
        $scope,
        $rootScope,
        $windowInstance,
        externalLinkService,
        formTypes,
        currentFormType,
        externalLink,
    ) {
        $scope.saveAndClose = saveAndClose;
        $scope.closeWindow = closeWindow;

        $scope.externalLink = externalLink != null
            ? { ...externalLink }
            : {};

        function saveAndClose(event) {
            event.preventDefault();

            if ($scope.validator.validate()) {
                save().then((result) => {
                    $windowInstance.close(result);
                });
            }
        }

        function closeWindow(event) {
            event.preventDefault();
            $windowInstance.close(false);
        }

        function save() {
            $rootScope.$broadcast('showGlobalBusyIndicator', 'externalLinkIndicator');

            const savePromise = currentFormType === formTypes.edit
                ? externalLinkService.updateExternalLink($scope.externalLink)
                : externalLinkService.createExternalLink($scope.externalLink);

            return savePromise
                .finally(() => $rootScope.$broadcast('hideBusyIndicator', 'externalLinkIndicator'));
        }
    }
})();
