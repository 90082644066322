(function () {
    'use strict';

    angular.module('UndergroundWebApp').factory('authService', authService);

    authService.$inject = [
        '$q',
        '$http',
        '$state',
        '$rootScope',
        '$timeout',
        'qPool',
        'authConfig',
        'localStorageService',
        'mapService',
        'cachingService',
        'municipalityService'
    ];

    function authService(
        $q,
        $http,
        $state,
        $rootScope,
        $timeout,
        qPool,
        authConfig,
        localStorageService,
        mapService,
        cachingService,
        municipalityService
    ) {
        const readyDeferred = $q.defer();

        let authServiceVersion = '1.0.5',
            isInitialized = false,
            auth0Client = null;

        let authenticationData = {
            isAuth: false,
            token: '',
            username: '',
            userId: '',
            displayName: '',
            contractorId: '',
            activeDepartment: null,
            authUser: null,
            userRoles: [],
            modules: []
        };

        let authService = {
            login: function () {
                auth0Client.loginWithRedirect({
                    redirect_uri: window.location.origin + '/main'
                }).catch(() => {
                    //error while redirecting the user
                });
            },

            logout: function () {
                var authData = localStorageService.get('authenticationData');
                if (!authData) return $q.resolve(authenticationData);

                if (mapService.getLayer) {
                    const s2wClusterLayer = mapService.getLayer('s2wClusterLayer');
                    if (s2wClusterLayer) s2wClusterLayer.setLocationFilter();

                    const locationLayer = mapService.getLayer('locationLayer');
                    if (locationLayer) locationLayer.setLocationFilter();
                }

                return cachingService.clear()
                    .finally(() => {
                        authService.clearAuthData();
                        auth0Client.logout({
                            returnTo: window.location.origin
                        });
                    });
            },

            ready: function () {
                return readyDeferred.promise;
            },

            init: function () {
                let initDeferred = $q.defer();

                authService.configureClient()
                    .then(() => authService.handleRedirectCallback())
                    .then(() => {
                        authService.fillAuthData();

                        if (!authService.isUpToDate()) {
                            authService.logout();

                            readyDeferred.resolve({});
                        } else {
                            isInitialized = true;
                            initDeferred.resolve(authenticationData);

                            //Ensure that init promise callbacks are handled first
                            $timeout(100).then(() => readyDeferred.resolve(authenticationData));
                        }
                    });

                return initDeferred.promise;
            },

            isInitialized: function () {
                return isInitialized;
            },

            isUpToDate: function () {
                return authenticationData.version === authServiceVersion;
            },

            handleRedirectCallback() {
                let handleRedirectDeferred = $q.defer(),
                    query = window.location.search;

                if (query.includes("code=") && query.includes("state=")) {
                    auth0Client.handleRedirectCallback().then(() => {
                        window.history.replaceState({}, document.title, "/");
                        $state.transitionTo('main');

                        const getTokenSilentlyPromise = auth0Client.getTokenSilently(),
                            getUserPromise = auth0Client.getUser();

                        $q.all([
                            getTokenSilentlyPromise,
                            getUserPromise
                        ]).then(result => {
                            const accessToken = result[0],
                                user = result[1];

                            let authData = localStorageService.get('authenticationData') || {};

                            authData.token = accessToken;
                            authData.username = user.email;
                            authData.userId = user.email;
                            authData.version = authServiceVersion;
                            authData.isAuth = true;
                            authData.displayName = user.email;
                            authData.userId = user.email;
                            authData.authUser = user;

                            authenticationData = authData;
                            localStorageService.set('authenticationData', authData);

                            authService.getUserDetails().then(([groups, accessRights, municipalities]) => {
                                let authData = localStorageService.get('authenticationData');

                                authData.authUser.accessRights = accessRights;
                                authData.authUser.groups = groups;
                                authData.authUser.municipalities = municipalities;

                                localStorageService.set('authenticationData', authData);
                                authService.fillAuthData();

                                $rootScope.authData = authData;

                                handleRedirectDeferred.resolve(true);
                            });
                        });
                    });
                } else {
                    handleRedirectDeferred.resolve(false);
                }

                return handleRedirectDeferred.promise;
            },

            getAuthData: function () {
                return authenticationData;
            },

            getAuthGroups: function () {
                var deferred = $q.defer();

                $http.get('api/auth/groups')
                    .then(function (result) {
                        deferred.resolve(result.data);
                    }, function () {
                        deferred.reject();
                    });

                return deferred.promise;
            },

            getAccessRights: function () {
                return readyDeferred.promise.then(authService.getAccessRightsInternal);
            },

            //Private functions
            configureClient: function () {
                let deferred = $q.defer();

                createAuth0Client({
                    domain: authConfig.domain,
                    client_id: authConfig.clientId,
                    audience: authConfig.apiIdentifier,
                    useRefreshTokens: true,
                }).then(client => {
                    auth0Client = client;

                    deferred.resolve(client);
                }).catch(() => deferred.reject);

                return deferred.promise;
            },

            clearAuthData: function () {
                localStorageService.remove('authenticationData');

                authenticationData.version = authServiceVersion;
                authenticationData.isAuth = false;
                authenticationData.token = '';
                authenticationData.username = '';
                authenticationData.displayName = '';
                authenticationData.userId = '';
                authenticationData.authUser = null;
            },

            fillAuthData: function () {
                let authData = localStorageService.get('authenticationData');
                if (authData) {
                    authenticationData.version = authData.version;
                    authenticationData.isAuth = authData.isAuth;
                    authenticationData.token = authData.token;
                    authenticationData.username = authData.username;
                    authenticationData.userId = authData.userId;
                    authenticationData.displayName = authData.displayName;
                    authenticationData.authUser = authData.authUser;

                    authenticationData.hasAccess = (area, accessType) => {
                        if (authenticationData
                            && authenticationData.authUser
                            && authenticationData.authUser.accessRights) {
                            let currentRights = authenticationData.authUser.accessRights[area];

                            return currentRights && currentRights.includes(accessType);
                        }

                        return false;
                    };
                }
            },

            getUserDetails: function () {
                const getGroups = $http.get('api/user/groups?username=' + authenticationData.username)
                    .then((result) => (result.data));
                const getMunicipalities = municipalityService.getMunicipalities();
                const getAccessRights = authService.getAccessRightsInternal();

                return $q.all([getGroups, getAccessRights, getMunicipalities]);
            },

            //Required to call before readyDeferred is resolved
            getAccessRightsInternal: function () {
                let deferred = qPool.defer('accessrights');

                if (!deferred.hasCompleted() && deferred.isFirst) {
                    $http.get('api/accessrights').then(result => {
                        let accessRights = Object.fromEntries(result.data.accessRights
                            .map(a => [a.area, a.accessType]));

                        deferred.resolve(accessRights);
                    }).catch(deferred.reject);
                }

                return deferred.promise;
            }
        };

        return authService;
    }
})();
