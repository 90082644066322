(function () {
    'use strict';

    angular.module('UndergroundWebApp').factory('externalReportsService', externalReportsService);

    externalReportsService.$inject = ['$http'];

    function externalReportsService($http) {
        return {
            getAllExternalReports,
        };

        function getAllExternalReports() {
            return $http.get('/api/externalReports')
                .then(result => result.data);
        }
    }
})();
