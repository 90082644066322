(function () {
    angular.module('UndergroundWebApp').controller('MainController', MainController);

    MainController.$inject = [
        '$scope',
        '$rootScope',
        '$state',
        '$timeout',
        'globalEvents',
        'environmentConfig',
        'mapService',
        'baseLayerFactory',
        'flyFotoLayerFactory',
        's2wClusterLayerFactory',
        'esriLoader',
        '$kWindow',
        'locationLayerFactory',
        'selectionLayerFactory',
        'areaLayerFactory',
        'areaEditLayerFactory'
    ];

    function MainController(
        $scope,
        $rootScope,
        $state,
        $timeout,
        globalEvents,
        environmentConfig,
        mapService,
        baseLayerFactory,
        flyFotoLayerFactory,
        s2wClusterLayerFactory,
        esriLoader,
        $kWindow,
        locationLayerFactory,
        selectionLayerFactory,
        areaLayerFactory,
        areaEditLayerFactory
    ) {
        const zoomToContainersExceptions = [
            'main.containerDetails',
            'main.areaDetails.edit',
            'main.areaDetails',
        ];

        $scope.currentLocationId = null;
        $scope.mapLayers = [];
        $scope.menuOptions = {};
        $rootScope.showMenu = environmentConfig.showMenu || false;
        $scope.showNavbar = true;

        initController();

        function initController() {
            $rootScope.$on(globalEvents.zoomToLocation, handleZoomToLocation);

            handleInternetExplorer();
        }

        esriLoader.require([
            "esri/geometry/Extent",
            "esri/geometry/SpatialReference"
        ], function (Extent, SpatialReference) {
            $scope.$on('ContainerLayerLoaded', function () {
                if (zoomToContainersExceptions.includes($state.current.name)) return;

                var clusterLayer = mapService.getLayer('s2wClusterLayer');
                if (!clusterLayer) return;

                $timeout(250).then(function () {
                    var clusterLayerGfx = clusterLayer.graphics.items;

                    if (clusterLayerGfx.length > 0 && clusterLayerGfx[0].attributes) {
                        var xmin = clusterLayerGfx[0].attributes.points[0].degLong;
                        var ymin = clusterLayerGfx[0].attributes.points[0].degLat;
                        var xmax = clusterLayerGfx[0].attributes.points[0].degLong;
                        var ymax = clusterLayerGfx[0].attributes.points[0].degLat;

                        for (var i = 0; i < clusterLayerGfx.length; i++) {
                            if (clusterLayerGfx[i].attributes.points) {
                                for (var j = 0; j < clusterLayerGfx[i].attributes.points.length; j++) {
                                    var currentPoint = clusterLayerGfx[i].attributes.points[j];

                                    if (currentPoint.degLong !== 0 && currentPoint.degLat !== 0) {
                                        xmax = currentPoint.degLong > xmax ? currentPoint.degLong : xmax;
                                        xmin = currentPoint.degLong < xmin ? currentPoint.degLong : xmin;
                                        ymax = currentPoint.degLat > ymax ? currentPoint.degLat : ymax;
                                        ymin = currentPoint.degLat < ymin ? currentPoint.degLat : ymin;
                                    }
                                }
                            }
                        }

                        var fullExt = new Extent(xmin, ymin, xmax, ymax, new SpatialReference(32632));
                        mapService.zoomToExtent(fullExt);
                    }
                });
            });
        });

        mapService.ready().then(function () {
            mapService.createMap('map');

            mapService.on('zoom', function () {
                if ($scope.menuOptions.close) {
                    $scope.menuOptions.close();
                }
            });
            mapService.on('center', function () {
                if ($scope.menuOptions.close) {
                    $scope.menuOptions.close();
                }
            });
            mapService.on('click', function () {
                if ($scope.menuOptions.close) {
                    $scope.menuOptions.close();
                }
            });

            if (!mapService.isInitialized()) {
                mapService.addLayerFactory([
                    baseLayerFactory,
                    flyFotoLayerFactory,
                    s2wClusterLayerFactory,
                    locationLayerFactory,
                    selectionLayerFactory,
                    areaLayerFactory,
                    areaEditLayerFactory,
                ]);
            } else if ($rootScope.initRequired) {
                mapService.reInitialize();
                $rootScope.initRequired = false;
            }
        });

        //Event handlers
        function handleZoomToLocation(_, location) {
            mapService.ready()
                .then(() => mapService.setInitialPosition(location, 17))
                .then(mapService.mapCreated)
                .then(() => mapService.zoomToLocation(location));
        }

        //Private functions
        function handleInternetExplorer() {
            if (window.document.documentMode) {
                $kWindow.open({
                    options: {
                        modal: true,
                        draggable: false,
                        title: 'Advarsel',
                        resizable: false,
                        height: 120,
                        width: 400,
                        visible: false,
                        close: function (e) {
                            e.preventDefault();
                        },
                        actions: []
                    },
                    templateUrl: 'app/main/internet-explorer-warning.html',
                    windowTemplateUrl: 'app/shared/modal-base.html'
                });
            }
        }
    }
})();
