(function() {

    angular.module('UndergroundWebApp').factory('areaService', areaService);

    areaService.$inject = ['$http'];

    function areaService($http) {
        return {
            getAreas
        };

        function getAreas() {
            return $http.get('api/area')
                .then(result => result.data);
        }
    }


}());
